import classes from './CashSalesVisualAnalysis.module.css'
import ApChartMini from '../../../../SharedComp/ApexChartMiniWrapper/ApChartMini';
import { useAuth } from '../../../../AuthProvider';
import { useEffect, useRef } from 'react';
import { useToast } from '../../../../ToastContext';
import { useState } from 'react';
import { QueryBakerySalesByGroup, QueryBaverageSalesByGroup, QueryMerchSalesByGroup, QueryPastrySalesByGroup } from '../../../../SharedComp/API/DeskView/API_FE_CloseShift';

import UIButton from '../../../Components/UIButton/UIButton';
import { ChartLineUp, SealCheck } from '@phosphor-icons/react';


const getRandomValue = () => Math.floor(Math.random() * 100);

const TestChartDataB = {
    "Sourdough": getRandomValue(),
    "Baguette": getRandomValue(),
    "Croissant": getRandomValue()
};
export default function CashSalesVisualAnalysis({GroupData})
{
    const auth = useAuth();
    const notify = useToast();

    const [ActiveViewIdx, SetActiveViewIdx] = useState(0);

    const [ActiveChartData, SetActiveChartData] = useState({});

    const [OverAllSales, SetOverAllSales] = useState({});
    const [BakerySales, SetBakerySales] = useState({});
    const [PastrySales, SetPastrySales] = useState({});
    const [MerchSales, SetMerchSales] = useState({});
    const [BaverageSales, SetBaverageSales] = useState({});
    const OverAllSalesMap = useRef(new Map());

    const ReadTargetGroupBakery =(data_array)=>
    {
        let _total_bakery_sales = 0;
        const temp = {};
        for (let i = 0; i < data_array.length; i++) {
            temp[data_array[i].DCCB_Summery_ItemName] = data_array[i].DCCB_Summery_Sold_Quantity;
            _total_bakery_sales += (data_array[i].DCCB_Summery_Sold_Quantity * data_array[i].DCCB_Summery_UnitPrice);
        }
        
        OverAllSalesMap.current.set("Bakery", _total_bakery_sales);
        SetBakerySales(temp)
    }
    const ReadTargetGroupPastry =(data_array)=>
    {
        let _total_pastry_sales = 0;
        const temp = {};
        for (let i = 0; i < data_array.length; i++) {
            temp[data_array[i].DCCP_Summery_ItemName] = data_array[i].DCCP_Summery_Sold_Quantity;
            _total_pastry_sales += (data_array[i].DCCP_Summery_Sold_Quantity * data_array[i].DCCP_Summery_UnitPrice);
        }

        OverAllSalesMap.current.set("Pastry", _total_pastry_sales);
        SetPastrySales(temp);
    }
    const ReadTargetGroupMerch =(data_array)=>
    {
        let _total_merch_sales = 0;
        const temp = {};
        for (let i = 0; i < data_array.length; i++) {
            temp[data_array[i].DCCM_Summery_ItemName] = data_array[i].DCCM_Summery_Sold_Quantity;
            _total_merch_sales += (data_array[i].DCCM_Summery_Sold_Quantity * data_array[i].DCCM_Summery_UnitPrice);
        }

        OverAllSalesMap.current.set("Merchandise", _total_merch_sales);
        SetMerchSales(temp);
    }
    const ReadTargetGroupBaverage =(data_array)=>
    {
        let _total_baverage_sales = 0;
        const temp = {};
        for (let i = 0; i < data_array.length; i++) {
            temp[data_array[i].DCCB_Summery_ItemName] = data_array[i].DCCB_Summery_Sold_Quantity;
            _total_baverage_sales += (data_array[i].DCCB_Summery_Sold_Quantity * data_array[i].DCCB_Summery_UnitPrice);
        }

        OverAllSalesMap.current.set("Baverage", _total_baverage_sales);
        SetBaverageSales(temp);
    }

    const PrepOverAllData = ()=>
    {
        const temp = {};
        for(const [key, value] of OverAllSalesMap.current.entries()) 
        {
            temp[key] = value;
        }
        SetActiveChartData(temp);
    }

    useEffect(()=>{

        if(GroupData) 
        {
            SetActiveViewIdx(0);
            const __parse_master_data = JSON.parse(GroupData.CCMDPV2_DataPackage);
            ReadTargetGroupBakery(__parse_master_data.Bakery_Summery);
            ReadTargetGroupPastry(__parse_master_data.Pastry_Summery);
            ReadTargetGroupMerch(__parse_master_data.Merchandise_Summery);
            ReadTargetGroupBaverage(__parse_master_data.Beverage_Summery);
            PrepOverAllData();
        }

    }, [GroupData])




    return (
        <div className={`${classes.chart_whole_wrapper}`}>

            <div className={`${classes.chart_head}`}>
                <UIButton Variant={ActiveViewIdx === 0 ? "Primary" : "Secondary" }  Icon={<ChartLineUp/>} label={"Over All"} onClick={()=>{PrepOverAllData(); SetActiveViewIdx(0);}} />
                <UIButton Variant={ActiveViewIdx === 1 ? "Primary" : "Secondary" }  Icon={<ChartLineUp/>} label={"Bakery"} onClick={()=>{SetActiveChartData(BakerySales); SetActiveViewIdx(1);}} />
                <UIButton Variant={ActiveViewIdx === 2 ? "Primary" : "Secondary" }  Icon={<ChartLineUp/>} label={"Pastry"} onClick={()=>{SetActiveChartData(PastrySales); SetActiveViewIdx(2);}} />
                <UIButton Variant={ActiveViewIdx === 3 ? "Primary" : "Secondary" }  Icon={<ChartLineUp/>} label={"Merchandise"} onClick={()=>{SetActiveChartData(MerchSales); SetActiveViewIdx(3);}} />
                <UIButton Variant={ActiveViewIdx === 4 ? "Primary" : "Secondary" }  Icon={<ChartLineUp/>} label={"Baverage"} onClick={()=>{SetActiveChartData(BaverageSales); SetActiveViewIdx(4);}} />
            </div>

            <ApChartMini 
                key={JSON.stringify(ActiveChartData)}
                Type={"area"} 
                Data={ActiveChartData} 
                Height={250} 
                Tag={ActiveViewIdx === 0 ? "Total Cash" : "Product Sale Count"}/>
        </div>
    )
}
import classes from './PayrollArchive.module.css'


export default function PayrollArchive() 
{


    return (
        <div>
            <h1>Payroll Archive</h1>
        </div>
    )
}
import classes from './SupportDocMiniCard.module.css'
import { Eye, Paperclip, SealCheck, Warning } from '@phosphor-icons/react';
import UIButton from '../../../../../../../Components/UIButton/UIButton';
export default function SupportDocMiniCard({CardName, SharedDoc, ClickCallback}) 
{
    return (
        <div className={`${classes.doc_card_valid}`}>
                        <h3>{CardName}</h3>
                        <div className={`${classes.card_icon_wrapper}`}>
                            <div className={`${classes.card_icon_signed}`}>
                                <span className={`${SharedDoc ? classes.avial_doc : classes.unavail_doc}`}>
                                   {SharedDoc ? <SealCheck weight='bold'/> : <Warning weight='fill'/>} 
                                </span>
                            </div>
                        </div>
                        <div className={`${classes.card_doc_info}`}>
                            <UIButton Variant={SharedDoc ? 'Primary' : 'Disabled'} onClick={()=>{ClickCallback(SharedDoc)}} Icon={<Eye weight='bold'/>} label={"Preview"}/>
                            {/* <UIButton Variant={'Secondary'} onClick={()=>{}} Icon={<Paperclip weight='bold'/>} label={"Upload New"}/> */}
                        </div>
                    </div>
    )
}
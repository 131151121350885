import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const QueryHRPreparedData = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/query-hr-prepared-data-for-accounting-package', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const FinishPayrollPreparation = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/finish-payroll-accouting-data-post-entry', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
import classes from './MCAT_Edit.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

 

//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { UpdateCashAllowanceType } from '../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I'

export default function MCAT_Edit({UpdateView, OpenController, TargetData})
{

    const auth = useAuth();
    const notify = useToast();

    const [CashAllowanceName, SetCashAllowanceName] = useState(TargetData.HRMCAT_Name);
    const [CashAllowanceTaxAmount, SetCashAllowanceTaxAmount] = useState(TargetData.HRMCAT_TaxPercentage ? TargetData.HRMCAT_TaxPercentage : '');
    const [CashAllowanceDesc, SetCashAllowanceDesc] = useState(TargetData.HRMCAT_Description);
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("allowance_name", CashAllowanceName);
            fd.append("allowance_tax", CashAllowanceTaxAmount);
            fd.append("allownace_desc", CashAllowanceDesc);
            fd.append("target_id", TargetData.HRMCAT_ID );

            const res = await UpdateCashAllowanceType(fd, auth.token);
            notify.ShowSuccessUpdate(res.Msg, 10);
            UpdateView();
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Update <span className={`${data_win.data_create_name}`}>Allowance Type</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input
                                                    value={CashAllowanceName}
                                                    onChange={(e)=>{SetCashAllowanceName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Cash Allowance Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                    input={
                                                    <input  
                                                    value={CashAllowanceTaxAmount}
                                                    onChange={(e)=>{SetCashAllowanceTaxAmount(e.target.value)}}
                                                    type="number"
                                                    step={"any"}
                                                    min={0}
                                                    max={100} 
                                                    placeholder='Tax'
                                                    title='' 
                                                    />
                                                }
                                                tip={"Some cash allowances may be subject to taxation. If the allowance is taxable, please specify the taxable percentage in this field. If it is not taxable, you can leave this field blank"}
                                            />
                                            <MasterDataRegInput
                                                 input={
                                                    <input  
                                                    value={CashAllowanceDesc}
                                                    onChange={(e)=>{SetCashAllowanceDesc(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Description'
                                                    required 
                                                    />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}
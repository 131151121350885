import classes from './CreateRole.module.css'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import axios from 'axios'
import { Custom_DD_Style } from '../../../../../SharedComp/Utility'
import Select from 'react-select'
import { useRef } from 'react'

import { MasterMenu } from '../../../../../SharedComp/MasterMenu'
import { CreateNewUserAccess } from '../../../../../SharedComp/API/UserAccess/API_FE_UserAccess'
import { useToast } from '../../../../../ToastContext'
import UIButton from '../../../../Components/UIButton/UIButton'
import { Checks } from '@phosphor-icons/react'
import { LU_PermissionsScheme } from '../../../../../SharedComp/LocalUserPermissionsScheme'

export default function CreateRole({Close_CALLBACK}) 
{

    const [Loading, SetLoading] = useState(false);
    const [RoleName, SetRoleName] = useState('');
    const [LandingPageRoute, SetLandingPageRoute] = useState();
    const [ClientPage, SetClientPage] = useState(false);
    const [SpecialAccessView, SetSpecialAccessView] = useState(false);
    const [LocalAccessConfig, SetLocalAccessConfig] = useState({})
    
    const notify = useToast();

    const RefRouteLlist = useRef(new Map());

    const [RouteCount, SetRouteCount] = useState(0);
    const [ValidRouteCount, SetValidRouteCount] = useState(0);

    const [AvialRoute, SetAvialRoute] = useState([]);

    const [RoutePackage, SetRoutePackage] = useState(MasterMenu);


    const PrepRouteData = () =>
    {
        const __route = [];
        for(const [key, value] of RefRouteLlist.current.entries()) 
        {
            __route.push({value: key, label: value});
        }

        SetAvialRoute(__route);
    }

    const handleCheckboxChange = (groupName) => {
        SetRoutePackage((prevRoutePackage) => {
            const groupData = prevRoutePackage.get(groupName);
            const newValidRouteGroup = !groupData.ValidRouteGroup; // Toggle ValidRouteGroup

            if(newValidRouteGroup) 
            {
                for(const [key, value] of groupData.Config.entries()) 
                {
                    RefRouteLlist.current.set(key, value.Label);
                }

            } else 
            {
                // if it is false clear it which is a bit tricky
                for(const [key, value] of groupData.Config.entries()) 
                {
                    RefRouteLlist.current.delete(key);
                }
            }
    
            // Update the child routes' HasAccess based on the new ValidRouteGroup
            const updatedConfig = new Map([...groupData.Config].map(([route, config]) => [
                route, 
                { ...config, HasAccess: newValidRouteGroup } // Set HasAccess to match ValidRouteGroup
            ]));
    
            const updatedGroup = {
                ...groupData, 
                ValidRouteGroup: newValidRouteGroup, // Update ValidRouteGroup
                Config: updatedConfig // Update the Config with new HasAccess values
            };
    
            return new Map(prevRoutePackage).set(groupName, updatedGroup); // Update RoutePackage
        });

        PrepRouteData();
    };
    const handleChildCheckboxChange = (groupName, route) => {
        SetRoutePackage((prevRoutePackage) => {
            const groupData = prevRoutePackage.get(groupName);
            const updatedConfig = new Map(groupData.Config);
    
            // Toggle the HasAccess state of the individual child route
            const config = updatedConfig.get(route);
            updatedConfig.set(route, { ...config, HasAccess: !config.HasAccess });

            if(!config.HasAccess) 
            {
                RefRouteLlist.current.set(route, config.Label);
            }
            else 
            {
                RefRouteLlist.current.delete(route);
            }
    
            // Check if any child routes are checked to update the parent's ValidRouteGroup
            const anyChildChecked = [...updatedConfig.values()].some(child => child.HasAccess);
    
            const updatedGroup = {
                ...groupData,
                Config: updatedConfig, // Update the Config with the new child state
                ValidRouteGroup: anyChildChecked // Update parent's ValidRouteGroup
            };
    
            return new Map(prevRoutePackage).set(groupName, updatedGroup); // Update RoutePackage
        });

        PrepRouteData();
    };
    

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing your request...", 10);
        SetLoading(true);

        try 
        {
            const routePackageObject = {};

            RoutePackage.forEach((groupData, groupName) => {
                routePackageObject[groupName] = {
                    ValidRouteGroup: groupData.ValidRouteGroup,
                    Icon: groupData.Icon,
                    Config: {}
                };
    
                groupData.Config.forEach((config, route) => {
                    routePackageObject[groupName].Config[route] = {
                        HasAccess: config.HasAccess,
                        Route: config.Route,
                        Label: config.Label
                    };
                });
            });
    
            // Stringify the object
            const serializedData = JSON.stringify(routePackageObject);


            let route_count = 0;
            let valid_rout_count = 0;
            for(const [key, val] of RoutePackage.entries()) 
            {
                for(const [in_key, in_val] of val.Config.entries())
                {
                    route_count++;
                    if(in_val.HasAccess)
                    {
                        valid_rout_count++;
                    }
                }
            }
    
            const fd = new FormData();
            fd.append("Name", RoleName);
            fd.append("landing_page", JSON.stringify(LandingPageRoute));
            fd.append("client_page", ClientPage);
            fd.append("access_package", serializedData);
            fd.append("special_access", JSON.stringify(LocalAccessConfig));
            fd.append("access_level", parseFloat((parseFloat(valid_rout_count) / parseFloat(route_count)) * 100).toFixed(1));
            const main_res = await CreateNewUserAccess(fd, 'token');

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            Close_CALLBACK(false);

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const UpdateConfigProp = (prop_name, value)=>
    {
        SetLocalAccessConfig((prev) => {
            const _updated = { ...prev }; 
            _updated[prop_name] = value; 
            return _updated;
        });
        
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Create Access Method</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>

            <form onSubmit={HandleSubmition} action="POST">

                <div className={`${classes.form_wrapper}`}>

                        <div className={`${classes.input_wrap}`}>
                            <MasterDataRegInput 
                                input={
                                    <input 
                                        onChange={(e)=>{SetRoleName(e.target.value)}} 
                                        name='role_name' 
                                        type="text" 
                                        placeholder='Role Name' 
                                        title='' 
                                        autoComplete='off' 
                                        required />} 
                                />
                        </div>

                        <div className={`${classes.input_wrap}`}>
                                <MasterDataRegInput input={
                                    <Select
                                        onChange={(data)=>{SetLandingPageRoute(data);}}
                                        name={"landing_page_route"}
                                        options={AvialRoute}
                                        placeholder={"Landing Page..."}
                                        isSearchable={true}
                                        styles={Custom_DD_Style}
                                        required ={true}
                                /> }/>
                        </div>

                        <div onClick={()=>{SetClientPage(!ClientPage)}} className={`${classes.input_wrap_check_box}`}>
                            <span>Client Access</span>

                            <input 
                                checked={ClientPage}
                                onChange={(e)=>{}} 
                                name='is_client' 
                                type="checkbox" 
                            />
                        </div>

                    </div>


                    <div className={`${classes.page_list}`}>
                        {/* <div className={`${classes.list_header}`}>
                            

                            <div className={`${classes.filter_role}`}>
                               <div className={`${classes.filter_role_input}`}>
                                    <span className={`${classes.filter_role_icon}`}><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span>
                                    <input type="text" placeholder='Eg Branch Manager...' />
                               </div>
                            </div>

                        </div> */}

                        <div className={`${classes.open_special_access_menu_wrapper}`}>
                            <UIButton Type='button' Icon={<Checks weight='bold'/>} onClick={()=>{SetSpecialAccessView(!SpecialAccessView)}} label={`Switch To ${SpecialAccessView ? 'Page Access Managment' : 'Special Access Managment'}`}/>
                        </div>
                        {!SpecialAccessView ? 
                        <div className={`${classes.pages}`}>
                            {
                                Array.from(RoutePackage.entries()).map(([group_name, group_data], idx)=>(
                                        <div key={group_name + idx} className={`${classes.page_access_tick}`}>
                                            <div onClick={()=>{handleCheckboxChange(group_name)}} className={`${classes.clickable_route_group_wrapper}`}>
                                                <div>
                                                    <li>{group_name}</li>
                                                </div>
                                                <div>
                                                    <input onChange={()=>{}} checked={RoutePackage.get(group_name).ValidRouteGroup} type="checkbox" name={group_name}  />
                                                </div>
                                            </div>

                                            <div className={`${classes.router_inner_items}`}>

                                                {
                                                    Array.from(group_data.Config.entries()).map(([route, config]) => (
                                                        <div onClick={()=>{handleChildCheckboxChange(group_name, route)}} key={config.Label} className={`${classes.inner_route}`}>
                                                            <li>
                                                                <span>{config.Label}</span>
                                                                <input onChange={()=>{}} checked={config.HasAccess} type="checkbox" name={config.Label}  />
                                                            </li>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                ))
                            }
                                

                        </div> : 
                        <div className={`${classes.pages}`}>

                            {
                                LU_PermissionsScheme.map((cat, index) => (
                                    <div key={cat.category + index} className={`${classes.part_of_option}`}>
                                        <ul>{cat.category}</ul>
                                        <div className={`${classes.setting_items_config}`}>
                                            {cat.options.map((opt, idx) => (

                                                <li key={opt.label + idx}><span>{opt.label}</span> <span><input onChange={(e)=>{UpdateConfigProp(opt.stateKey, e.target.checked)}} checked={LocalAccessConfig[opt.stateKey]}  type="checkbox" /></span></li>
                                            ))}
                                        </div>  
                                    </div>
                                ))
                            }

                        </div>
                        }

                </div>

                <div className={`${data_win.form_action_button_wrapper}`}>
                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                    <button disabled={Loading}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                </div>
            </form>

            </div>

        </div>

    </div>
    )
}
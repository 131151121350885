import { useState } from 'react'
import classes from './MasterProductGroupCard.module.css'
import UIButton from '../../../../Components/UIButton/UIButton';
import { CheckCircle, Gear, XCircle } from '@phosphor-icons/react';

import {FetchWorkspaceAllNotOffice} from '../../../../../SharedComp/API/Workspace/API_FE_Workspace'
import { objectToMap} from '../../../../../SharedComp/Utility'
import { useAuth } from '../../../../../AuthProvider';
import { useEffect } from 'react';


export default function MasterProductGroupCard({TargetData, ConfigCallback}) 
{

    const auth = useAuth();
    // Only Outlet
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());


    const FetchAllOutlet = async()=>
    {
        try 
        {
            const main_res = await FetchWorkspaceAllNotOffice(auth.token);
            SetWorkspaceList(main_res);
            
        }catch(err) 
        {

        }
    }

    useEffect(()=>{

        HandleAvailWorkshopSerlization();

    }, [WorkspaceList])
    
    useEffect(()=>{
        FetchAllOutlet();
    }, [])

    function HandleAvailWorkshopSerlization() 
    {
        
        const crt_data =  new Map(objectToMap(JSON.parse(TargetData.ProdCatAvailBranch)));

        let updated_list = new Map();
        for(let  i = 0; i < WorkspaceList.length; i++) 
        {
            const _workspace_id = WorkspaceList[i].BranchID;
            if(crt_data.has(_workspace_id))
            {
                updated_list.set(_workspace_id, crt_data.get(_workspace_id));
            } else 
            {
                updated_list.set(_workspace_id, false);
            }
        }
        SetWorkspaceChecked(updated_list);
    }


    return (
        <div className={`${classes.master_product_group_card_main_wrapper}`}>
            
            <div className={`${classes.header_wrapper}`}>
                <h1>{TargetData.ProdCatName}</h1>
            </div>

            <div className={`${classes.master_product_group_body_wrapper}`}>

                <div className={`${classes.master_product_avial_workspace_list_wrapper}`}>
                    {
                        WorkspaceList.map((item, idx)=>(
                            <li><span>{item.BranchName}</span> <span className={`${WorkspaceChecked.get(item.BranchID) ? classes.avail_state_icon_has : classes.avail_state_icon_has_not}`}> {WorkspaceChecked.get(item.BranchID) ? <CheckCircle weight='fill' /> : <XCircle weight='fill' />  } </span> </li>
                        ))
                    }
                </div>

            </div>

            <div className={`${classes.master_product_action_button}`}>
                <UIButton Variant='Secondary' onClick={()=>{ConfigCallback(TargetData)}} Icon={<Gear weight='bold'/>} label={"Config"}/>
            </div>

        </div>
    )
}
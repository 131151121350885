import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

/////////////////////////////////////////////////// MASTER PENALITY

export const CreateNewPenalityType = async(data, token)=>
{
        try  
        {
            const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/create-hr-master-penality-type', data, GetRouteConfig(token));
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdatePenalityType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/update-hr-master-penality-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgePenalityType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/purge-hr-master-penality-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterPenality = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/query-all-hr-master-penality-type', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

//////////////////////////////////////////////////// MASTER PTO

export const CreateNewPTOType = async(data, token)=>
{
        try  
        {
            const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/create-hr-master-pto-type', data, GetRouteConfig(token));
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdatePTOType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/update-hr-master-pto-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgePTOType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/purge-hr-master-pto-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterPTO = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/query-all-hr-master-pto-type', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


//////////////////////////////////////////////////// MASTER SHIFT

export const CreateNewShiftType = async(data, token)=>
{
        try  
        {
            const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/create-hr-master-shift-type', data, GetRouteConfig(token));
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateShiftType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/update-hr-master-shift-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeShiftType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/purge-hr-master-shift-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterShift = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/query-all-hr-master-shift-type', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


//////////////////////////////////////////////////// MASTER UTILITY EXPENSE

export const CreateNewUtilityExpenseType = async(data, token)=>
{
        try  
        {
            const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/create-hr-master-utility-expense-type', data, GetRouteConfig(token));
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateUtilityExpenseType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/update-hr-master-utility-expense-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeUtilityExpenseType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/purge-hr-master-utility-expense-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterUtilityExpense = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-beta/query-all-hr-master-utility-expense-type', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
} 
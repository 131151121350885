import classes from './SingleEntryGroup.module.css'
import UIButton from '../../../../../Components/UIButton/UIButton'
import { Camera, CheckCircle, Key, SealQuestion, SpinnerGap, Truck } from '@phosphor-icons/react'
import TimeAgo from '../../../../../../SharedComp/TimeElapsed/TimeAgo'
import { useState } from 'react'

import QRCodeReader from '../../../../../../SharedComp/QRCodeReader/QRCodeReader'
import { useToast } from '../../../../../../ToastContext'

import ReceiveOrderedItem from '../ReceiveOrderedItem/ReceiveOrderedItem'
import { useAuth } from '../../../../../../AuthProvider'

export default function SingleEntryGroup({item, UpdateRefreshView}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [PreviewReadyItems, SetPreviewReadyItems] = useState(false);
    const [ShowReceiver, SetShowReceiver] = useState(false);
    const [UseKeyCode, SetUseKeyCode] = useState(false);

    const [OpenQRCodeReader, SetOpenQRCodeReader] = useState(false);
    const [TargetDataItem, SetTargetDataItem] = useState();

    const OnQrFailToRead = async(err) =>
    {

    }

    const OpenDataProview = async(incoming_key)=>
    {
        notify.ShowSuccess("Key verification completed successfully");
        SetOpenQRCodeReader(false);
        SetUseKeyCode(false);
        SetShowReceiver(false);
        SetPreviewReadyItems(true);
    }

    const CloseDatPreview = ()=>
    {
        SetOpenQRCodeReader(false);
        SetUseKeyCode(false);
        SetShowReceiver(false);
        SetPreviewReadyItems(false);
        UpdateRefreshView();
    }

    const OnQrCodeScan = async(data)=>
    {
        if(data) 
        {
            if(data.text === item.KOPSRG_TranscationHash) 
            {
                OpenDataProview(data.text)
            }
        }
    }

    const OnKeyInput = (input_key)=>
    {
        if(input_key === item.KOPSRG_TranscationHash) 
        {
            OpenDataProview(input_key)
        }

    }


    return (
        <>
        {PreviewReadyItems && <ReceiveOrderedItem TargetItem={TargetDataItem} OpenController={SetPreviewReadyItems} /> }
        {OpenQRCodeReader && <QRCodeReader onQRRead={OnQrCodeScan} OnError={OnQrFailToRead} Open={OpenQRCodeReader} OpenController={CloseDatPreview}/>}
        <li className={`${classes.single_entry_li_dash}`} key={item.PROCI_Name}>


            <span>{item.FirstName} {item.LastName}</span>
            <span><TimeAgo show_suffix={true} date={item.KOPREOPKWS_OrdereTimeStamp}/></span>
            <span><TimeAgo show_suffix={true} date={item.KOPREOPKWS_ExpectedTimeStamp}/></span>

            <span className={`${classes.order_state_button_wrap}`}>
                <UIButton Variant={item.KOPSRG_Status === "Shipped" ? 'Primary' : 'Secondary'} onClick={()=>{}} Icon={item.KOPSRG_Status === "Shipped" ? <CheckCircle weight='fill'/> : <SpinnerGap weight='bold'/>} label={item.KOPSRG_Status}/>
                {item.KOPSRG_ArrivalState === "Yes" && <UIButton Variant={'Primary'} onClick={()=>{}} Icon={<Truck weight='fill'/>} label={"Arrived"}/>}
                {/* <span className={`${classes.order_status_pending}`}> 
                    <SpinnerGap weight='bold'/> <span className={`${classes.order_stat_text}`}> {item.KOPSRG_Status}</span>
                </span> */}
            </span>

            <span className={`${classes.action_button_wrapper}`}>
                {/* <UIButton Variant='Secondary' onClick={()=>{}} Icon={<MapPin weight='fill'/>} label={"Locate"}/> */}
             {item.KOPSRG_Status === "Shipped" ? 
             item.KOPSRG_ArrivalState === "Yes" && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ReceiveAuthorizeOrder ? <UIButton Variant='Secondary' onClick={()=>{}} Icon={<CheckCircle weight='fill'/>} label={"Completed"}/>:
                <UIButton Variant='Secondary' onClick={()=>{SetShowReceiver(!ShowReceiver)}} Icon={<SealQuestion weight='fill'/>} label={"Receive"}/> : '--'}   
            </span>

          {ShowReceiver &&   <div className={`${classes.receive_order_methods}`}>
                <UIButton Variant='Primary' onClick={()=>{SetUseKeyCode(!UseKeyCode); SetTargetDataItem(item)}} Icon={<Key weight='fill'/>} label={"Enter Key"}/>
                <UIButton Variant='Primary' onClick={()=>{SetOpenQRCodeReader(true); SetTargetDataItem(item)}} Icon={<Camera weight='fill'/>} label={"Scan Key"}/> 

                {/* Receive through key */}

              {UseKeyCode &&   <div className={`${classes.enter_key_order_group}`}>
                    <div className={`${classes.input_key_wrapper}`}>
                        <input onChange={(e)=>{OnKeyInput(e.target.value)}} type="text" placeholder='Please Enter Key..' />
                    </div>
                </div>}

            </div>}
        </li>
    </>
    )
}
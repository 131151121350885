import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateNewMerchGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/create_merch_group', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateMerchGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/update_merch_group', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMerchGroupAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/query-all-merch-group', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeMerchGroupByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/purge_merch_group_by_id', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////// MERCH PRODUCTS NOT GROUP
export const CreateMerchProducts = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/create-merch-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateMerchProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/update-merch-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMerchProductAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/fetch-merch-products-all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeMerchProductByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/purge-merch-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMerchProductByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'merch-cluster/fetch-merch-products-by-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const QueryVendorsOmni = async(token)=>
{
    try   
    {
        const response = await axios.post(getBaseURL() + 'vendors/get-vendor', {}, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeVendorSingle = async(data, token)=>
{
    try   
    {
        const response = await axios.post(getBaseURL() + 'vendors/purge-vendor', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateNewVendor = async(data, token)=>
{
    try   
    {
        const response = await axios.post(getBaseURL() + 'vendors/add-vendor', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
import React, { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { useAuth } from "./AuthProvider";

import { getBaseSocket } from "./SocketBase";
import { useGlobalNotification } from "./GlobalNotification";
import { getBaseURL } from "./SharedComp/BaseUrlConfig";
import { useToast } from "./ToastContext";

const GlobalNotificationProviderContext = createContext();

export const useGNP = () => useContext(GlobalNotificationProviderContext);


export const GNProvider = ({children})=>
{

    const auth = useAuth();
    const notify = useToast();
    const Gnotify = useGlobalNotification();
    const socket = getBaseSocket();

    const [EVENT_DailyOrderArrival, SetEVENT_DailyOrderArrival] = useState(null);
    const [EVENT_SpecialOrderArrival, SetEVENT_SpecialOrderArrival] = useState(null);
    const [EVENT_ChefIncomingDispatchedOrder, SetEVENT_ChefIncomingDispatchedOrder] = useState(null);
    const [EVENT_ChefStockRequest, SetEVENT_ChefStockRequest] = useState(null);
    const [EVENT_FinishPrepareProduct, SetEVENT_FinishPrepareProduct] = useState(null);
  

    // ev-chef-production-finish-init
    useEffect(()=>{

        const run_event_check = (data)=>
        {
            if(data.BranchID === auth.activeWorkShop && data.TargetUser === auth.user.UserAccountID) 
            {
                Gnotify.Disturb(`Chef Completed Product`, `The chef has finished preparing '${data.ProdName}'. Please check the distribution section for details.`, 'finish_order_task_sf');
                notify.ShowSuccess(`The chef has finished preparing '${data.ProdName}'. Please check the distribution section for details.`);
                SetEVENT_FinishPrepareProduct(Date.now());
            }
        }

        socket.on('ev-chef-production-finish-init', run_event_check);
        

        return () => {
            socket.off('ev-chef-production-finish-init'); 
        };
    }, [auth.activeWorkShop, auth.user])

    // ev-chef-stock-request-init
    useEffect(()=>{
        
        const run_event_check = (data)=>
        {
            if(data.BranchID === auth.activeWorkShop && data.TargetUser === auth.user.UserAccountID) 
            {
                Gnotify.Disturb(`You Have New Production Stock Request`, "A new Stock Request from Chef Initialized", 'incoming_order_sf');
                notify.ShowWarn(`You Have New Production Stock Request`);
                SetEVENT_ChefStockRequest(Date.now());
            }
        }

        socket.on('ev-chef-stock-request-init', run_event_check);

        return () => {
            socket.off('ev-chef-stock-request-init'); 
        };
    }, [auth.activeWorkShop, auth.user])

    // new-kicthen-supply-order
    useEffect(()=>{

        const run_event_check = (data)=>
        {
            if(data.Dest === auth.activeWorkShop) 
            {
                Gnotify.Disturb(`You Have New Daily Order from ${data.SourceName}`, "A new daily order has been added to your system. Please review it to ensure timely processing.", 'order_task_action_sf');
                notify.ShowWarn(`You Have New Daily Order from ${data.SourceName}`);
                SetEVENT_DailyOrderArrival(Date.now());
            }
        }
        socket.on('new-kicthen-supply-order', run_event_check);

        return () => {
            socket.off('new-kicthen-supply-order'); 
        };

    }, [auth.activeWorkShop, auth.user])

    // new-special-order
    useEffect(()=>
    {

        const run_event_check = (data)=>
        {
            if(data.Dest === auth.activeWorkShop) 
            {
                Gnotify.Disturb(`You Have New Special Order from ${data.SourceName}`, "A new special order has been added to your system. Please review it to ensure timely processing.", 'order_task_action_sf');
                notify.ShowWarn(`You Have New Special Order from ${data.SourceName}`);
                SetEVENT_SpecialOrderArrival(Date.now());
            }
        }
        socket.on('new-special-order', run_event_check)

        return () => {
            socket.off('new-special-order'); 
        };
    }, [auth.activeWorkShop, auth.user])

    // new-dispatch-product-to-chef
    useEffect(()=> 
    {
        const run_event_check = (data)=>
        {
            if(data.ChefID === auth.user?.WorkspaceID) 
            {
                Gnotify.Disturb(`New Incoming Order`, "A new order has been assigned to you.", 'incoming_order_sf');
                notify.ShowWarn(`A new order has been assigned to you.`);
                SetEVENT_ChefIncomingDispatchedOrder(Date.now());
            }
        }
        socket.on('new-dispatch-product-to-chef', run_event_check);

        return () => {
            socket.off('new-dispatch-product-to-chef'); 
        };
    }, [auth.activeWorkShop, auth.user])
    
    return (
        <GlobalNotificationProviderContext.Provider value={
            {
                EVENT_SpecialOrderArrival,
                EVENT_ChefIncomingDispatchedOrder,
                EVENT_ChefStockRequest,
                EVENT_DailyOrderArrival,
                EVENT_FinishPrepareProduct
            }
        }>
            {children}
        </GlobalNotificationProviderContext.Provider>
    )
}
import classes from './HROperationData.module.css'
import { useState } from 'react';

import DataTableWrapper from '../../SharedComp/DataTable/DataTableWrapper'
import HROperationDataCreator from './Component/HROperationDataCreator'
import NotificationPopUp from '../../SharedComp/NotificationPopUp/NotificationPopUp';


export default function HROperationData() 
{

    const [DataCreatorState, SetDataCreatorState] = useState(false);
    const [ActiveMasterData, SetActiveMasterData] = useState();

    const [NotificationData, SetNotificationData] = useState(null);

    function handleCreateWindowOpen(data) 
    {
        SetActiveMasterData(data);
        SetDataCreatorState(true);
    }

    function NotificationTrigger(data) 
    {
        SetNotificationData({
            Key: Math.random() * 1000,
            Msg: data.Msg,
            Status: data.MyStateCode
        })
    }
    return (
        <div className={`${classes.hr_operation_data_main_wrapper}`}>
            {DataCreatorState && <HROperationDataCreator ActiveState={DataCreatorState} MDType={ActiveMasterData} StateController={SetDataCreatorState} NotificationTrigger={NotificationTrigger}/> }

            { NotificationData && <NotificationPopUp key={NotificationData.Key} msg={NotificationData.Msg} state={NotificationData.Status}/> }


            <div className={`${classes.hr_operation_datatable_wrapper}`}>

                        {/* <DataTableWrapper
                            WrapperName={'Penality Type'}
                            ThemeColor={'var(--background-light)'}
                            WrapperWidth={'90%'}
                            WinName={'Penality'}
                            RouteAddress={'http://localhost:3333/master-data/get-penality'}
                            RegisterCallback={handleCreateWindowOpen}
                            NewEntryFlag={DataCreatorState}
                        />

                        <DataTableWrapper
                            WrapperName={'Allownace Type'}
                            ThemeColor={'var(--background-light)'}
                            WrapperWidth={'90%'}

                            WinName={'Allownace'}
                            RouteAddress={'http://localhost:3333/master-data/get-allowance-type/'}
                            NewEntryFlag={DataCreatorState}
                            RegisterCallback={handleCreateWindowOpen}
                        />

                        <DataTableWrapper
                            WrapperName={'Job Position'}
                            ThemeColor={'var(--background-light)'}
                            WrapperWidth={'90%'}
                            WinName={'JobPosition'}
                            RouteAddress={'http://localhost:3333/master-data/get-job-position'}
                            RegisterCallback={handleCreateWindowOpen}
                            NewEntryFlag={DataCreatorState}
                        />

                        
                        <DataTableWrapper
                            WrapperName={'Paid Time Off - (PTO)'}
                            ThemeColor={'var(--background-light)'}
                            WrapperWidth={'90%'}
                            WinName={'Pto'}
                            RouteAddress={'http://localhost:3333/master-data/get-pto'}
                            RegisterCallback={handleCreateWindowOpen}
                            NewEntryFlag={DataCreatorState}
                        /> */}
            </div>

                
        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const FetchWarehouseByBranch = async(data, token)=>
    {
        try 
        {
            const response = await axios.post(getBaseURL() + 'warehouse/get-branch-warehouse', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }
import { faBan, faCheckDouble, faCircleStop, faCodeBranch, faCopy, faInfoCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './OrderGroup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {FetchTargetOrderGroupSubProducts, RejectOrderSubProduct} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'

import FilterEmployeeList from '../../../FilterEmployeeList/FilterEmployeeList'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed';
import CountdownTimer from '../../../../../SharedComp/CountDown/CountDownTimer';
import { useToast } from '../../../../../ToastContext';
import { useDAControl } from '../../../../../DAControlContext';
import { useAuth } from '../../../../../AuthProvider';
import { Star } from '@phosphor-icons/react';

import OrderSubGroup from '../OrderSubGroup/OrderSubGroup';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';
import { useGNP } from '../../../../../GlobalNotificationProvider';


function getFormattedDate(dateValue) {
    return new Date(dateValue).toLocaleDateString('en-US', {
      weekday: 'long', 
      month: 'short',  
      day: '2-digit'
    //   year: 'numeric'  
    });
  }
function getFormattedTime(dateValue) {
    return new Date(dateValue).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true 
    });
}
function isDateInThePast(inputDate) {
    const now = new Date();
    const providedDate = new Date(inputDate);
    return now < providedDate;
}
export default function OrderGroup({Data, ChefsList,  MGPos}) 
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();
    const GNP = useGNP();

    const [GroupDataInfo, SetGroupDataInfo] = useState();
    const [SubProductList, SetSubProductList] = useState([]);
    const [OpenFilterMenu, SetOpenFilterMenu] = useState(false);
    const [RefreshView, SetRefreshView] = useState(false);

    const [OpenOrderTimeInfo, SetOpenOrderTimeInfo] = useState(false);
    const TimeModalRef = useRef(null);
    const [ValidOrderTime, SetValidOrderTime] = useState(isDateInThePast(Data.CKAOG_ArrivalDateTime));
    const [ProcessBuffer, SetProcessBuffer] = useState(new Map());
    const [BatchOp, SetBatchOp] = useState(false);
    
    // This is where the chef menu will be apear
    const [FilterBoxPos, SetFilterBoxPos] = useState();

    const [SubProductsGroupedList, SetSubProductsGroupedList] = useState(new Map());


    const OpenFilterBox = async() =>
    {
        SetOpenFilterMenu(true);
    }
    const ProcessGroupData = async(data) =>
    {
        // here we gone group them by there own group
        const map_temp_map = new Map();

        for(let i = 0; i < data.length; i++) 
        {
            if(!map_temp_map.has(data[i].KOPREOPD_OrderPkgWorkspaceID)) 
            {
                map_temp_map.set(data[i].KOPREOPD_OrderPkgWorkspaceID, [data[i]]);
            } else 
            {
                const _temp_array = map_temp_map.get(data[i].KOPREOPD_OrderPkgWorkspaceID);
                _temp_array.push(data[i]);
                map_temp_map.set(data[i].KOPREOPD_OrderPkgWorkspaceID, _temp_array);

            }
        }

        SetSubProductsGroupedList(new Map(map_temp_map));
    }
    const FetchProductList = async() =>
    {
        try 
        {
            const fd = new FormData();
            fd.append('package_query', JSON.stringify(Data));
            const main_res = await FetchTargetOrderGroupSubProducts(fd, auth.token);
            ProcessGroupData(main_res);
            SetSubProductList(main_res);
        }catch(err) 
        {

        }
    }
    const SetFilterBoxPositionManual = async(pos)=>
    {
        SetFilterBoxPos({
            x: pos.x,
            y: pos.y,
            });
    }
    const handleMouseMove = (event) => {
        if(OpenFilterMenu) 
        {
            return;
        }
        SetFilterBoxPos({
            x: event.clientX,
            y: event.clientY,
            });
    };
    const SingleDispatch = async(data, button_pos)=>
    {
        const _single = new Map();
        _single.set(data.CKP_ItemName, {DataPkg: data, Quantity: data.KOPREOPD_OrderedQuantity - data.KOPREOPD_DispatchedQuantity});
        SetProcessBuffer(_single);
        SetFilterBoxPositionManual(button_pos);
        OpenFilterBox();
    }
    const OminiDispatch = ()=>
    {
        const _omni = new Map();
        for(let i = 0; i < SubProductList.length; i++) 
        {
            if((SubProductList[i].KOPREOPD_DismissedQuantity + SubProductList[i].KOPREOPD_DispatchedQuantity) >= SubProductList[i].KOPREOPD_OrderedQuantity) 
            {
                continue;
            }
            _omni.set(SubProductList[i].CKP_ItemName, {DataPkg: SubProductList[i], Quantity: SubProductList[i].KOPREOPD_OrderedQuantity - SubProductList[i].KOPREOPD_DispatchedQuantity});
        }
        SetProcessBuffer(_omni);
        SetOpenFilterMenu(true);
    }
    const ToggelItemInOut = async(data)=>
    {
        if(!BatchOp)
        {
            return;
        }

        if((data.KOPREOPD_DismissedQuantity + data.KOPREOPD_DispatchedQuantity) >= data.KOPREOPD_OrderedQuantity) 
        {
            notify.ShowError(`You can't select '${data.CKP_ItemName}'! It is closed, Please have some manner.`);
            return;
        }

        const _exist = ProcessBuffer;
        if(_exist.has(data.CKP_ItemName)) 
        {
            _exist.delete(data.CKP_ItemName);
        } else 
        {
            // I know this is Stupid but it serve a puprose letter
            _exist.set(data.CKP_ItemName, {DataPkg: data, Quantity: data.KOPREOPD_OrderedQuantity - data.KOPREOPD_DispatchedQuantity});
        }

        SetProcessBuffer(_exist);
    }
    const CleanCloseMiniModal = async(__val)=>
    {
        SetBatchOp(false);
        SetOpenFilterMenu(false);
        SetProcessBuffer(new Map());
    }
    const IsMarked = (__key)=>
    {
        return ProcessBuffer.has(__key);
    }
    const HandleBatchOp = (__val)=>
    {
        SetBatchOp(__val);
        SetProcessBuffer(new Map());

        if(!__val)
        {
            CleanCloseMiniModal();
        }

    }

    const HandleRejectOrder = async(order_hash, prod_id, rej_aount)=>
    {

        notify.ShowLoad("Processing your request.....", 10);
        
        setTimeout(async()=>
        {
            try 
            {
                const fd = new FormData();
                fd.append("order_hash", order_hash);
                fd.append("order_id", prod_id);
                fd.append("reject_amount", rej_aount);

                const main_res = await RejectOrderSubProduct(fd, 'token');
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                SetRefreshView(!RefreshView);

            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }, 1500)
    }

    useEffect(()=>
    {
        if(Data.length > 0) 
        {
            FetchProductList();
            SetIntialDatas();
        }
    }, [Data, RefreshView, GNP.EVENT_SpecialOrderArrival, GNP.EVENT_DailyOrderArrival])


    const SetIntialDatas = async() => { SetGroupDataInfo(Data[0]); }


    return (
        <div className={`${classes.order_group_main_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.order_group_main_header_wrapper}`}>
                <h2>From #{GroupDataInfo?.BranchName}</h2>
            </div>

           {OpenFilterMenu && <FilterEmployeeList ActiveOrderGroup={Data} UpdateViewCallback={SetRefreshView} UpdateView={RefreshView} OrderSourceBranchID={Data.BranchID} ItemBuffer={ProcessBuffer} Chefs={ChefsList} CloseController={CleanCloseMiniModal} Mpos={FilterBoxPos}/>} 

            <div className={`${classes.group_body_wrapper}`}>

                {
                Array.from(SubProductsGroupedList.entries()).map(([map_key, map_items], key_idx)=>(
                    <div className={`${classes.data_inner_view}`} key={key_idx + map_key}>

                        <OrderSubGroup 
                            SingleItemDispatch={SingleDispatch}
                            SelectItem={ToggelItemInOut} 
                            IsItemSelected={IsMarked} 
                            ItemGroupBase={map_items[0]} 
                            GroupList={map_items}  
                        />

                    </div>  
                ))
                }


            </div>

            <div className={`${classes.dispatch_group_footer}`}>
                    <div className={`${classes.dispatch_group_action_button_wrapper}`}>
                        <button onClick={()=>{HandleBatchOp(!BatchOp)}}><span><FontAwesomeIcon icon={BatchOp ? faCircleStop : faCopy}></FontAwesomeIcon></span> <span>{BatchOp ? "Void" : "Select"}</span></button>
                        {ProcessBuffer.size > 0 && BatchOp && <button onMouseMove={(e)=>{handleMouseMove(e)}} onClick={()=>{OpenFilterBox()}} style={{background: `var(--color-theme)`, borderColor: `var(--color-theme)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Dispatch Marked</span></button>}
                    </div>

                    <div className={`${classes.right_side_dispatch_group_button_wrapper}`}>
                        <button onMouseMove={(e)=>{handleMouseMove(e);}} onClick={()=>{OminiDispatch()}} style={{background: `var(--color-theme)`, borderColor: `var(--color-theme)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Dispatch All</span></button>
                    </div>
            </div>

        </div>
    )
}
import classes from './DirectStockRequestInputPackage.module.css'

import { useAuth } from '../../../../../AuthProvider';
import Select from 'react-select'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'


export default function DirectStockRequestInputPackage({
        HandleInvItemSelect,
        HandlInvItemQuantity,
        idx
    }) 
{

    const auth = useAuth();
    const [InvtItem, SetInvtItem] = useState();
    const [InvtItemPackage, SetInvtItemPackage] = useState();
    const [InvItemQuantity, SetInvItemQuantity] = useState();
    const [InventoryItemSelectionList, SetInventoryItemSelectionList] = useState([]);
    const [InventoryList, SetInventoryList] = useState([]);


    const FetchIvItems = async () => 
    {

        try 
        {
        const response = await fetch(getBaseURL() +  'master-data/get-iv-item-branch-only', {  
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ID: auth.activeWorkShop})
            });
            const res = await response.json();
            SetInventoryList(res);
            PrepareDropDownList(res, SetInventoryItemSelectionList);
        }catch(err) 
        {

        }

    } 

    const SelectInventoryItemByID = (data)=>
    {
        const target_item = InventoryList.find((item)=>item.IvtID === data.value);
        SetInvtItem(data);
        SetInvtItemPackage(target_item)
    }

    useEffect(()=>{
        FetchIvItems();
    }, [])



        return (
        <div className={`${classes.input_list_wrapper}`}>

            <div className={`${classes.input_fold_wrapper}`}>
                
                <div className={`${classes.single_input_wrapper_stock_req}`}>
                <MasterDataRegInput input={
                    <Select
                        value={InvtItem}
                        onChange={(data)=>{SelectInventoryItemByID(data); HandleInvItemSelect(data, idx)}}
                        name={"dd_data"}
                        options={InventoryItemSelectionList}
                        placeholder={"Inventory..."}
                        isSearchable={true}
                        styles={Custom_DD_Style}
                        required ={true}
                    /> } /> 
                </div>

                <div className={`${classes.single_input_wrapper_stock_req}`}>
                <MasterDataRegInput 
                    input={<input 
                    value={ InvItemQuantity} 
                    onChange={(e)=>{SetInvItemQuantity(e.target.value); HandlInvItemQuantity(e.target.value, idx)}} 
                    name='item_quantity' min={0.1} 
                    type="number" 
                    step="any" 
                    placeholder='Quantity...' 
                    title='' 
                    autoComplete='off' 
                    required />}
                    
                    tip={`${InvtItem ? 'Use ' + InvtItemPackage.UomName : 'Select Inventory to see Unit of Measurment'}`}
                    />
                
                </div>

            </div>
        
    </div>
)}
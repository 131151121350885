import classes from './OtherOrderDetail.module.css'

import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'


//lib
import { useAuth } from '../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../ToastContext'

export default function OtherOrderDetail({OpenController, TargetData, AddPastry_Callback, ProductGroupID})
{

    const auth = useAuth();
    const notify = useToast();

    const [ProductChoice, SetProductChoice] = useState();
    const [ProductPrice, SetProductPrice] = useState(0);
    const [ProductQuantity, SetProductQuantity] = useState('');
    const [ProductColor, SetProductColor] = useState('');
    const [ProductKG, SetProductKG] = useState('');
    const [ProductScripture, SetProductScripture] = useState('');
    const [ProductNote, SetProductNote] = useState('');

    const [ResponsePackage, SetResponsePackage] = useState();

    function CloseModal() 
    {
        OpenController(false);
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();



        AddPastry_Callback({
            _ProductGroupID: ProductGroupID,
            _ProductPreviewName: TargetData.CMBP_Name,
            _ProductChoice: JSON.stringify(ProductChoice),
            _ProductPrice: ProductPrice,
            _ProductPriceMod: null,
            _ProductQuantity: ProductQuantity,
            _ProductColor: null,
            _ProductKG: null,
            _ProductScripture: null,
            _ProductNote: ProductNote
        });
        notify.ShowSuccess("Product Choice Queued for Processing.")
        CloseModal();
    }

    useEffect(()=>{

        if(TargetData) 
        {
            SetProductPrice(TargetData.CMBP_UnitPrice);
            SetProductChoice({ProductID: TargetData.CMBGID, ProductName: TargetData.CMBP_Name, ProductType: TargetData.CMBG_Name});
        }

    }, [TargetData])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Receive <span className={`${data_win.data_create_name}`}>Other Product</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            
                                            <MasterDataRegInput
                                                    input={
                                                        <input
                                                        value={TargetData?.CMBP_Name}  
                                                        onChange={(e)=>{}}
                                                        type="text" 
                                                        readOnly />
                                                    }
                                                />

                                            <div className={`${classes.product_count_price}`}>
                                                
                                                <div className={`${classes.inptu_inner_carry}`}>

                                                    <MasterDataRegInput
                                                            input={
                                                                <input
                                                                value={TargetData?.CMBP_UnitPrice}  
                                                                // onChange={(e)=>{SetProductPrice(e.target.value)}}
                                                                type="number"
                                                                step={"any"} 
                                                                readOnly
                                                                />
                                                            }
                                                        />

                                                </div>
                                                

                                                <div className={`${classes.inptu_inner_carry}`}>
                                                    <MasterDataRegInput
                                                        input={
                                                            <input
                                                                onChange={(e)=>{SetProductQuantity(e.target.value)}}
                                                                value={ProductQuantity}
                                                                placeholder='Quantity' 
                                                                type="number"
                                                                required
                                                            />
                                                        }
                                                        tip={"Product Quantity"}
                                                    />
                                                </div>
                                                
                                            </div>
                                            
                                            
                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                    onChange={(e)=>{SetProductNote(e.target.value)}}
                                                    value={ProductNote}
                                                    type="text"
                                                    placeholder='Order Detail if any..'
                                                    />
                                                }
                                                tip={"If you have any additional instructions or requests that might help, please specify here."}
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button className={`${data_win.form_action_button_save}`}>Add</button>
                                <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}
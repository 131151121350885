import classes from './OperationMDCluster_III.module.css'


import MasterEmployeeBenfitType from './MasterEmployeeBenfitType/MasterEmployeeBenfitType'
import MasterVehicleType from './MasterVehicleType/MasterVehicleType'

export default function OperationMDCluster_III() 
{


    return (
        <div className={`${classes.data_upper_wrapper_prox_table}`}>
            <div className={`${classes.core_master_data_wrapper}`}>
                
                <MasterEmployeeBenfitType/>
                <MasterVehicleType/>

            </div>
        </div>
    )
} 
import classes from './MainMenu.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import MainMenuTag from './Component/Separator/MainMenuTag';
import { faArrowDownUpAcrossLine, faBars, faBoxOpen, faBoxesStacked, faBuildingColumns, faCartFlatbed, faChartLine, faCircleHalfStroke, faCircleUser, faCogs, faCubesStacked, faDollarSign, faFlaskVial, faGears, faHandshakeAngle, faLayerGroup, faLink, faPizzaSlice, faSignsPost, faUserCheck, faUserTie, faUsers, faUtensils, faWrench } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import NavLinkButton from './Component/LinkButton/NavLinkButton';
import { faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from '../../../AuthProvider';
import { CaretCircleDoubleLeft, CaretCircleDoubleRight } from '@phosphor-icons/react';

export default function MainMenu() 
{

  const [subMenuState, setSubMenuState] = useState(new Array(50).fill(false));
  const [CurrentUsageIndex, SetCurrentUsageIndex] = useState(0);

  const [activeMainMenu, setActiveMainMenu] = useState(-1);
  const [sidebarExpanded, setsidebarExpanded] = useState(false);

  const [RoutePackage, SetRoutePackage] = useState(new Map());

  const auth = useAuth();

  const GetNextIndex = () => {
    const idx = CurrentUsageIndex;
    SetCurrentUsageIndex(CurrentUsageIndex + 1);
    return idx;

  }
  const IconMapShittyWay = useRef(new Map([
    ['faChartLine', faChartLine],
    ['faUserCheck', faUserCheck],
    ['faUsers', faUsers],
    ['faDollarSign', faDollarSign],
    ['faWrench', faWrench],
    ['faLink', faLink],
    ['faBoxesStacked', faBoxesStacked],
    ['faArrowDownUpAcrossLine', faArrowDownUpAcrossLine],
    ['faGears', faGears],
    ['faCircleUser', faCircleUser],
    ['faLayerGroup', faLayerGroup],
    ['faPizzaSlice', faPizzaSlice],
    ['faUtensils', faUtensils],
    ['faBoxOpen', faBoxOpen],
    ['faCogs', faCogs],
    ['faBuildingColumns', faBuildingColumns],
    ['faFlaskVial', faFlaskVial],
    ['faCubesStacked', faCubesStacked],
    ['faHandshakeAngle', faHandshakeAngle],
    ['faSignsPost', faSignsPost]

  ]))
  


  
  function FUNC_ToggleMenuBar() 
  {
      localStorage.setItem("is_side_bar_open", !sidebarExpanded);
      setsidebarExpanded(!sidebarExpanded);
      if(sidebarExpanded) 
        {
            const some = subMenuState.fill(false);
            setSubMenuState(some);
        }
    }
    const toggelSubMenu = (idx) => 
    {
        if(!sidebarExpanded) 
        {
            setsidebarExpanded(!sidebarExpanded);
        }
        
        localStorage.setItem("active_on_menu_idx", idx);
        setActiveMainMenu(idx);
        const some = subMenuState.fill(false);
        setSubMenuState(some);
        const updatedState = [...subMenuState];
        updatedState[idx] = !updatedState[idx];
        setSubMenuState(updatedState);
    }

    const ParseRouteInfo = async()=>
    {
        const reconstructedMap = new Map();
            
        Object.entries(JSON.parse(auth.user.UARI_PageList)).forEach(([groupName, groupData]) => {
            reconstructedMap.set(groupName, {
                ValidRouteGroup: groupData.ValidRouteGroup,
                Icon: groupData.Icon,
                Config: new Map(Object.entries(groupData.Config))
            });
        });

        SetRoutePackage(reconstructedMap);
    }

    useEffect(()=>{
        if(auth.user) 
        {
            const active_idx = localStorage.getItem("active_on_menu_idx");
            const side_bar_open = localStorage.getItem("is_side_bar_open");

            if(active_idx) { toggelSubMenu(active_idx); }
            // if(side_bar_open) {toggelSubMenu()}


            ParseRouteInfo();
        }
    }, [auth.user])
    
    return (
        <div className={`${sidebarExpanded ? classes.sidebar_expanded : classes.sidebar_collapsed}`}>



                <div className={`${classes.wrap_toggel_button}`}>

                    <button type='button' onClick={(e)=>{FUNC_ToggleMenuBar()}}>{sidebarExpanded ? <CaretCircleDoubleLeft /> : <CaretCircleDoubleRight/> }</button>
                
                </div>
                <ul className={`${classes.menu}`}>
                {
                    Array.from(RoutePackage.entries()).map(([group_name, group_data], idx)=>(
                        <React.Fragment key={idx+group_name}>
                        {group_name === "Product Line" && group_data.ValidRouteGroup &&  
                                    <div className={`${classes.menu_separator_tag} ${sidebarExpanded ? classes.menu_separator_tag_open : classes.menu_separator_tag_close}`}>
                                        <li>Configuration</li>
                                    </div>}
                        {group_data.ValidRouteGroup && <MainMenuTag Name={group_name} Idx={idx} IsSubMenuOpen={subMenuState[idx]} Icon={IconMapShittyWay.current.get(group_data.Icon)} FUNC_ToggelSubMenu={toggelSubMenu} ActiveMainMenuIdx={activeMainMenu} URL={"#"}  IconOnly={!sidebarExpanded} /> }
                             <ul className={`${classes.sub_menu} ${subMenuState[idx] ? classes.open_sub_menu : ''}`}>
                             {
                                Array.from(group_data.Config.entries()).map(([route, config], idx) => (
                                  config.HasAccess &&   <NavLinkButton key={config.Label+idx} Name={config.Label} Route={route} Icon={faCircleHalfStroke} />
                                ))
                            }
                            </ul>
                        </React.Fragment>
                    ))
                }

            </ul>

        </div>
    );
}
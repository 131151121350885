import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

//////////////////////////////////////// MASTER EMPLOYEE BENEFIT

export const CreateNewEmployeeBenfitType = async(data, token)=>
{
        try  
        {
            const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/create-hr-master-employee-benefit-type', data, GetRouteConfig(token));
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateEmployeeBenfitType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/update-hr-master-employee-benefit-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeEmployeeBenfitType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/purge-hr-master-employee-benefit-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterEmployeeBenfit = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/query-all-hr-master-employee-benefit-type', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}



//////////////////////////////////////// MASTER VEHICLE Vehicle

export const CreateNewVehicleType = async(data, token)=>
{
        try  
        {
            const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/create-hr-master-vehicle-type', data, GetRouteConfig(token));
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateVehicleType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/update-hr-master-vehicle-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeVehicleType = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/purge-hr-master-vehicle-type', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterVehicle = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'operation-data-cluster-gamma/query-all-hr-master-vehicle-type', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
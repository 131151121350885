import classes from './EmployeeDocumentsProfile.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'
import { useEffect, useState } from 'react';
import { useToast } from '../../../../ToastContext';
import { QueryAllMasterDataBank, QueryAllMasterJobPosition } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I';
import { QueryAllMasterShift } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_II';

export default function EmployeeDocumentsProfile({UpdateEmployeeData, Employee}) 
{

    const UpdateEmployeeInput = (e) =>
    {
        UpdateEmployeeData(prev => ({
            ...prev,
            [e.target.name]: e.target.files[0]
        })); 
    }


    return (
        <div className={`${classes.master_product_type_inner_config_wrapper}`}>

            <div className={`${classes.personal_employee_info_input_col_group}`}>
                <MasterDataRegInput 
                TickLable={Employee['emp_profile_pic'] !== undefined}
                LableText={"Employee Profile Picture " + `[#] ${Employee['emp_profile_pic'] ? Employee['emp_profile_pic'].name : ''}`} 
                tip={"Make sure it is less than 2MB"} 
                    input={ 
                        <input 
                            onChange={(e)=>{UpdateEmployeeInput(e)}} 
                            name='emp_profile_pic' 
                            placeholder='Gross Salary' 
                            type="file" accept='image/png, image/jpeg, image/jpg' />}/>

                <MasterDataRegInput
                TickLable={Employee['emp_certification'] !== undefined} 
                LableText={"Employee Certification " + `[#] ${Employee['emp_certification'] ? Employee['emp_certification'].name : ''}`} 
                    input={ 
                        <input 
                            onChange={(e)=>{UpdateEmployeeInput(e)}} 
                            name='emp_certification' 
                            accept='application/pdf'
                            type="file" />}/>

                
                <MasterDataRegInput 
                    TickLable={Employee['emp_identification_card'] !== undefined} 
                    LableText={"Employee Identification Card " + `[#] ${Employee['emp_identification_card'] ? Employee['emp_identification_card'].name : ''}`} 
                    input={ 
                        <input 
                            onChange={(e)=>{UpdateEmployeeInput(e)}} 
                            name='emp_identification_card' 
                            accept='application/pdf'
                            type="file" />}/>


                <MasterDataRegInput 
                    TickLable={Employee['emp_ref_identification_card'] !== undefined} 
                    LableText={"Reference Person Identification Card " + `[#] ${Employee['emp_ref_identification_card'] ? Employee['emp_ref_identification_card'].name : ''}`}
                    input={ 
                        <input 
                            onChange={(e)=>{UpdateEmployeeInput(e)}} 
                            name='emp_ref_identification_card' 
                             accept='application/pdf'
                            type="file" 
                             />}/>
                
                <MasterDataRegInput
                    TickLable={Employee['emp_ref_signed_doc'] !== undefined}  
                    LableText={"Reference Signed Document " + `[#] ${Employee['emp_ref_signed_doc'] ? Employee['emp_ref_signed_doc'].name : ''}`}
                    input={ 
                        <input 
                            onChange={(e)=>{UpdateEmployeeInput(e)}} 
                            name='emp_ref_signed_doc' 
                            accept='application/pdf'
                            type="file" 
                             />}/>
            </div>

        </div>
    )
}
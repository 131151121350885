import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const CreateNewPayrollSchedule = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/create-new-payroll-schedule-man', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const PurgePayrollSchedule = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/purge-payroll-schedule-man', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPayrollScheduleByBranchByDate = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/query-payroll-schedule-by-date-branch', data, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPayrollScheduleByDate = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/query-payroll-schedule-by-date', data, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPayrollScheduleAllResolved = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'payroll/query-payroll-schedule-all-resolved', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
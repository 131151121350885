import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateNewBeverageGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/create_baverage_group', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBeverageGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/update_baverage_group', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBeverageGroupAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/query-all-baverage-group', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBeverageGroupByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/purge_baverage_group_by_id', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////// BAVERAGE PRODUCTS NOT GROUP
export const CreateBeverageProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/create-baverage-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBeverageProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/update-baverage-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBeverageProductAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/fetch-baverage-products-all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBeverageProductByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/purge-baverage-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBeverageProductByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'baverage-cluster/fetch-baverage-products-by-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
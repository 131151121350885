import classes from './EmployeeFinalPreview.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'
import { useEffect, useState } from 'react';
import { useToast } from '../../../../ToastContext';
import { QueryAllMasterDataBank, QueryAllMasterJobPosition } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I';
import { QueryAllMasterShift } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_II';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function EmployeeFinalPreview({UpdateEmployeeData, Employee})
{

    const [EmployeeProfilePicPreviewURL, SetEmployeeProfilePicPreviewURL] = useState('');
    const CreatePreviewURL = (e) => {
    const file = Employee['emp_profile_pic'];
        if (file) {
            const url = URL.createObjectURL(file); 
            SetEmployeeProfilePicPreviewURL(url); 
        }else 
        {
            if(Employee['emp_gender'].value === "Male") 
            {
                SetEmployeeProfilePicPreviewURL(getBaseURL() + 'images_public/placeholder-man.jpg'); 
            } else {
                SetEmployeeProfilePicPreviewURL(getBaseURL() + 'images_public/placeholder-chick.jpg'); 
            }
        }
    };

    useEffect(()=>{
        CreatePreviewURL();
    }, [])


    return (
        <div className={`${classes.master_product_type_inner_config_wrapper}`}>

            <div className={`${classes.personal_employee_info_input_col_group}`}>
                    <div className={`${classes.new_employee_pp_warpper}`}>
                        <img src={EmployeeProfilePicPreviewURL} alt="" />
                    </div>
                    <div className={`${classes.new_employee_preview_not_warpper}`}>
                        <li>{Employee['emp_first_name']} {Employee['emp_midd_name']} {Employee['emp_late_name']}</li>
                        <li>{Employee['emp_job_position'].label}</li>
                    </div>
                    
            </div>

        </div>
    )
}
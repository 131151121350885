import { Empty } from "@phosphor-icons/react"

export default function EmptyProxyDataView({text="Empty"})
{


    return (
        <div 
            style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    alignContent: 'center',
                    flexWrap: 'wrap'
                }}> <h2 
                        style={{
                                width: '100%', 
                                textAlign: 'center', 
                                color: `var(--background-light)`, 
                                textTransform: 'capitalize', 
                                fontSize: '2em'}}><Empty weight="bold"/></h2> 
                <h2 
                style={{
                    width: '100%', 
                    textAlign: 'center', 
                    color: `var(--background-light)`, 
                    textTransform: 'capitalize', 
                    fontSize: '1em'}}>{text}</h2> 
        </div>
    )
}
import { formatDistanceToNow } from 'date-fns';



export default function TimeAgo({ date, show_suffix = false }) {
  const utcDate = new Date(date);

  return (
    <span>{formatDistanceToNow(utcDate, { addSuffix: show_suffix })}</span>
  );
}
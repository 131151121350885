import classes from './StockRequestGroup.module.css'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'
import { useToast } from '../../../../../ToastContext'
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'

import UIButton from '../../../../Components/UIButton/UIButton'
import { CheckCircle, TrashSimple, Warning, XCircle } from '@phosphor-icons/react'

import {QueryChefsStockRequestItem, AcceptChefsStockRequestItem} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { useEffect, useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'


export default function StockRequestGroup({TargetData, CloseCallback})
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [CanBeAccepted, SetCanBeAccepted] = useState(true);

    const [SubProductRequestList, SetSubProductRequestList] = useState([]);


    const CheckAccepance = async(data) =>
    {
        for(let i = 0; i < data.length; i++) 
        {
            if(parseFloat(data[i].AvailQuantity) < parseFloat(data[i].KOCSII_InventoryAmount)) 
            {
                SetCanBeAccepted(false);
                return;
            }
        }
        SetCanBeAccepted(true);
    }
    const QueryRequestItems = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("req_group_id", TargetData.KOCSIG_ID);
            fd.append("branch_id", TargetData.KOCSIG_DomainWorkspace);
            const main_res = await QueryChefsStockRequestItem(fd, 'token');
            CheckAccepance(main_res);
            SetSubProductRequestList(main_res);
        }catch(err) 
        {
            notify.ShowError("Falied to fetch Product List: " + err.message);
        }
    }

    const HandleAcceptStockRequest = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("package_list", JSON.stringify(SubProductRequestList));
            fd.append("handler_id", auth.user.UserAccountID);
            fd.append("handler_workshop", auth.activeWorkShop);
            fd.append("req_hash", TargetData.CKCSR_RequestGroupHash);
            fd.append("dispatch_hash", TargetData.CKCSR_DispatcheHash);
            fd.append("req_owner", TargetData.CKCSR_ReqOwner);


            fd.append("dispatch_id", TargetData.KOCSIG_DispatchID);
            fd.append("batch_count", TargetData.KOCSIG_ProcessingBatchCount);
            fd.append("req_group_id", TargetData.KOCSIG_ID);
            fd.append("assigned_chef_id", TargetData.KODI_AssignedChefID);
            fd.append("workspace_id", TargetData.KOCSIG_DomainWorkspace);
            fd.append("linked_prod", TargetData.KOCSIG_LinkedProductID);
            fd.append("direct_stock_request", TargetData.KOCSIG_DS_Request)
            
            fd.append("is_recipe", TargetData.CKCSR_FromRecipe);
            fd.append("track_back_hash", TargetData.CKCSR_RequestGroupHash);
            fd.append("prod_order_hash", TargetData.CKCSR_ProdOrderHash);
            fd.append("auto_trigger", TargetData.CKCSR_RecipeAutoTrigger);

            const main_res = await AcceptChefsStockRequestItem(fd, 'token');
            notify.ShowSuccess(main_res.Msg);
            CloseCallback();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        QueryRequestItems();
    }, [])


    return (
        <div className={`${classes.main_order_group_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.main_order_group_header_wrapper}`}>
                <h3>Stock Request <sub>{<TimeElapsed Time={TargetData.KOCSIG_RequestTimeStamp} />}</sub></h3>

                <div className={`${classes.right_size_header_action_wrapper}`}>
                    {/* <UIButton Icon={<TrashSimple weight='bold' />} label={"Reject"} Variant='Danger'/> */}

                 {CanBeAccepted && <UIButton onClick={()=>{
                        DAControl.Trigger('Accept Chef Stock Request',
                            `You are about to approve the chef’s stock request. By confirming, the requested items will be deducted from your store inventory, affecting your stock balance.`,
                            true,
                            7,
                            (data)=>{
                                if(data) 
                                {
                                    HandleAcceptStockRequest();
                                }
                            }
                        )
                        }} Icon={<CheckCircle weight='bold' />} label={"Accpet"} />}
                {!CanBeAccepted && <UIButton Variant='Secondary' label={"Approval restricted"} Icon={<Warning weight='fill'/>} onClick={()=>{notify.ShowWarn("Acceptance is blocked due to insufficient inventory. Please Notify Inventory Manager, or discuss with the chef to adjust the requested amount.")}}/>}
                </div>
            </div>

            <div className={`${classes.request_body_wrapper}`}>

                <div className={`${classes.order_info_wrapper}`}>

                    <div className={`${classes.order_owner_wrapper}`}>

                        <img src={getBaseURL() + TargetData.JAKBED_Photo} alt="" />

                        <div className={`${classes.owner_name_and_stuff}`}>
                            <li>{TargetData.FirstName} {TargetData.LastName}</li>
                            <li>{TargetData.RoleNameLazy}</li>
                        </div>

                    </div> 
                    <div className={`${classes.order_prod_list_wrapper}`}>

                        {
                            SubProductRequestList.map((item, idx)=>(
                                    <li  key={item.IVItemName}>
                                        <span>{item.IVItemName}</span>
                                        <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': `As reported by (${item.WHName})`})} className={`${parseFloat(item.AvailQuantity) >= parseFloat(item.KOCSII_InventoryAmount) ? classes.check_stock_validity_good : classes.check_stock_validity_bad}`}>
                                            {item.KOCSII_InventoryAmount} {item.UomDescription}  {parseFloat(item.AvailQuantity) >= parseFloat(item.KOCSII_InventoryAmount) ? <CheckCircle weight='bold' size={13}/> : <Warning weight='fill' size={13}/> }   </span>
                                    </li>
                            ))
                        }
                        
                        
                    </div>


                </div>

            </div>

        </div>
    )
}
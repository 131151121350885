export const LU_PermissionsScheme = [
    {
        category: "General",
        options: [
          { label: "Suspend Core Interaction", stateKey: "LC_SuspendCoreInteraction" }
        ],
      },
      {
        category: "Account Management",
        options: [
          { label: "Create User", stateKey: "LC_CreateUser" },
          { label: "Manage User Account", stateKey: "LC_ManageUserAccount" },
          { label: "Create and Manage Roles", stateKey: "LC_ManageRoles" },
        ],
      },
      {
        category: "Human Resource",
        options: [
          { label: "Create Employee", stateKey: "LC_CreateEmployee" },
          { label: "Inspect Employee Profile", stateKey: "LC_InspectEmployeeProfile" },
          { label: "Terminate Employee", stateKey: "LC_TerminateEmployee" },
          { label: "Inspect Employee Penalty", stateKey: "LC_InspectEmployeePenalty" },
          { label: "Inspect Employee Allowance", stateKey: "LC_InspectEmployeeAllowance" },
          { label: "Inspect Employee Benefit", stateKey: "LC_InspectEmployeeBenefit" },
          { label: "Inspect Employee Credit Line", stateKey: "LC_InspectEmployeeCreditLine" },
          { label: "Inspect Employee Bank", stateKey: "LC_InspectEmployeeBank" },
          { label: "Inspect Employee Document", stateKey: "LC_InspectEmployeeDocument" },
          { label: "Direct Employee Purge", stateKey: "LC_DirectEmployeePurge" },
        ],
      },
      {
        category: "Device Integration",
        options: [
          { label: "View Tracked Device", stateKey: "LC_ViewTrackedDevice" },
          { label: "Break Device Link", stateKey: "LC_BreakDeviceLink" },
          { label: "Create Device Link", stateKey: "LC_CreateDeviceLink" },
        ],
      },
      {
        category: "Stock Managment",
        options: [
          { label: "Receive Stock", stateKey: "LC_ReceiveStock" },
          { label: "Create Stock Request", stateKey: "LC_CreateStockRequest" },
          { label: "Create Direct Stock", stateKey: "LC_CreateDirectStock" },
          { label: "Inspect Stock", stateKey: "LC_InspectStock" },
          { label: "Print Stock Entry Invoice", stateKey: "LC_PrintStockEntryInvoice" },
        ],
      },
      {
        category: "Stock Transfer & Request",
        options: [
          { label: "Approve Transfer Request", stateKey: "LC_ApproveTransferRequest" },
          { label: "Inspect Transfer Document", stateKey: "LC_InspectTransferDocument" },
          { label: "Print Transfer Document", stateKey: "LC_PrintTransferDocument" },
          { label: "Terminate Transfer Request", stateKey: "LC_TerminateTransferRequest" },
          { label: "Approve Stock Request", stateKey: "LC_ApproveStockRequest" },
          { label: "Reject Stock Request", stateKey: "LC_RejectStockRequest" },
          { label: "Preview Stock Archive Document", stateKey: "LC_PreviewStockArchiveDocument" }, 
          { label: "Unlock Suspended Request", stateKey: "LC_CanUnlockTransferRequest" },
        ],
      },
      {
        category: "Petty Cash",
        options: [
          { label: "Allow Release Cash", stateKey: "LC_AllowReleaseCash" },
          { label: "Allocate Budget", stateKey: "LC_AllocateBudget" },
          { label: "Inspect Branch Cash Usage", stateKey: "LC_InspectBranchCashUsage" },
          { label: "Approve Petty Cash Budget", stateKey: "LC_ApprovePettyCashBudget" },
          { label: "Preview Branch Petty Cash Utilization", stateKey: "LC_PreviewBranchPettyCashUtilization" }
        ],
      },
      {
        category: "Production Order",
        options: [
          { label: "Initiate Production Order", stateKey: "LC_InitiateProductionOrder" },
          { label: "Inspect Shared PO Config", stateKey: "LC_InspectSharedPOConfig" }
        ],
      },
      {
        category: "Production Stack",
        options: [
          { label: "Reverse Order To Stock", stateKey: "LC_ReverseOrderToStock" },
          { label: "View Expired Order", stateKey: "LC_ViewExpiredOrder" },
          { label: "Perform Production Log Entry", stateKey: "LC_PerformProductionLogEntry" }
        ],
      },
      {
        category: "Front Desk",
        options: [
          { label: "Perform PreOrder", stateKey: "LC_PerformPreOrder" },
          { label: "Inspect Active Order", stateKey: "LC_InspectActiveOrder" },
          { label: "Inspect Pending Order", stateKey: "LC_InspectPendingOrder" },
          { label: "Perform Cash Collection", stateKey: "LC_PerformCashCollection" }
        ],
      },
      {
        category: "Order Managment",
        options: [
          { label: "Perform Kicthen Supply Order", stateKey: "LC_PerformKicthenSupplyOrder" },
          { label: "Receive/Authorize Order", stateKey: "LC_ReceiveAuthorizeOrder" }
        ],
      },
      {
        category: "Shelf Managment",
        options: [
          { label: "Perform Merchandise Shelf Entry", stateKey: "LC_PerformMerchandiseShelfEntry" },
          { label: "Receive/Authorize Incoming Order", stateKey: "LC_ReceiveAuthorizeIncomingOrder" },
          { label: "Reverse Merchandise Invoice", stateKey: "LC_ReverseMerchandiseInvoice" },
          { label: "Inspect Merchandise Invoice", stateKey: "LC_InspectMerchandiseInvoice" }
        ],
      },
      {
        category: "Centeral Kitchen",
        options: [
          { label: "Modify Product Availability Status", stateKey: "LC_ModifyProductAvailabilityStatus" },
          { label: "Authorize / Reject Chef Stock Request", stateKey: "LC_AuthorizeRejectChefStockRequest" },
          { label: "Authorize Distribution", stateKey: "LC_AuthorizeDistribution" },
          { label: "Inspect Distribution Document", stateKey: "LC_InspectDistributionDocument" },
          { label: "Print Distribution Document", stateKey: "LC_PrintDistributionDocument" },
          { label: "Dispatch Active Order To Chefs", stateKey: "LC_DispatchActiveOrderToChefs" },
          { label: "Configure Chef Line Up", stateKey: "LC_ConfigureChefLineUp" }
        ],
      },
      {
        category: "Operation Data",
        options: [
          { label: "Manage Operational Data ", stateKey: "LC_ManageOperationalData" },
          { label: "Purge Operational Data", stateKey: "LC_PurgeOperationalData" },
        ],
      }
]
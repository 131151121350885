import { useEffect } from 'react'
import classes from './ConfigMasterProduct.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import WorkspaceAccessList from '../../../../../SharedComp/WorkspaceAccessList/WorkspaceAccessList'
import { FetchBranchAll, FetchProductLineMasterGroup, UpdateProductLineMasterGroup } from '../../../../../api';
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import { useAuth } from '../../../../../AuthProvider';
import { useToast } from '../../../../../ToastContext';

export default function ConfigMasterProduct({TargetData, OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [Loading, SetLoading] = useState(false);
    const [WorkspacePkg, SetWorkspacePkg] = useState();
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [IncludePreorder, SetIncludePreorder] = useState(false);

    useEffect(()=>{
        // Always make sure this data is valid before use
        if(TargetData) 
        {
            SetWorkspacePkg(TargetData.ProdCatAvailBranch);
        }
    }, [TargetData])

    const WorkspaceDataUpdate = async(data)=>
    {
        SetWorkspaceList(data)
    }

    const HandleSubmition = async(e)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        e.preventDefault();

        try 
        {
            const fd = new FormData();

            fd.append("avial_workspace", JSON.stringify(Object.fromEntries(WorkspaceList)));
            fd.append("include_in_preorder", IncludePreorder);
            fd.append("original_id", TargetData.ProdCatID);

            const main_res = await UpdateProductLineMasterGroup(fd, auth.token);

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            CloseModal();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    const CloseModal = async()=>
    {
        OpenController(false);
    }


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Configure <span className={`${data_win.data_create_name}`}> {TargetData?.ProdCatName}</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
                <form onSubmit={HandleSubmition} action="POST">
                    <div className={`${classes.form_wrapper}`}> 

                            <div  className={`${classes.input_list_wrapper}`}>
                                
                                <MasterDataRegInput input={<input name='master_product_group' value={TargetData?.ProdCatName} type="text"  readOnly />} tip={"Name of the master group. (Readonly)"} />
                               
                                <div onClick={()=>{SetIncludePreorder(!IncludePreorder)}} className={`${classes.prodution_group_type}`}>
                                    <span>Include In Pre-Order</span>
                                    <input onChange={()=>{}} checked={IncludePreorder} type="checkbox" />
                                </div>
                                
                            </div>

                            <div  className={`${classes.input_list_wrapper}`}>

                            <WorkspaceAccessList SerializeData={WorkspacePkg} DataCallback={WorkspaceDataUpdate}/>

                            </div>
                            
                    </div>
                    <div className={`${data_win.form_action_button_wrapper}`}>
                        <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                        <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                    </div>
                </form>

            </div>
        </div>

    </div>
    )
}
import { faCheckCircle, faFilter, faPlus, faTriangleExclamation, faUserSecret, faUserTie } from '@fortawesome/free-solid-svg-icons';
import classes from './UserManagment.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import data_win from '../../SharedComp/CommonStyle/DataCreator.module.css'
import ClassicUserCreate from './Component/ClassicUserCreate/ClassicUserCreate';
import StandardUserCreate from './Component/StandardUserCreate/StandardUserCreate';
import ManageUser from './Component/View/ManageUser';
import { useEffect, useState } from 'react';

import NotificationPopUp from '../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';

import { formatDistanceToNow } from 'date-fns';
import { Tooltip } from 'react-tooltip'
import UIButton from '../Components/UIButton/UIButton';
import { PencilCircle, WifiHigh, WifiSlash } from '@phosphor-icons/react';
import { useAuth } from '../../AuthProvider';
import { getBaseSocket } from '../../SocketBase';



const getRelativeTime = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
};

export default function UserManagment() 
{
    
    const socket = getBaseSocket();
    const auth = useAuth();
    const [OpenClassicUserCreate, SetOpenClassicUserCreate] = useState(false);
    const [OpenStandardUserCreate, SetOpenStandardUserCreate] = useState(false);
    const [OpenManageUser, SetOpenManageUser] = useState(false);

    const [ActiveUser, SetActiveUser] = useState();


    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);


    const [UserList, SetUserList] = useState([]);


    function SelectUser(item) 
    {
        SetActiveUser(item);
        SetOpenManageUser(true);
    }


    function MsgCallback(res) 
    {
        SetOpenClassicUserCreate(false);
        SetOpenStandardUserCreate(false);
        SetOpenManageUser(false);

        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }

    function UpdateList()
    {
        SetRefeshList(!RefeshList);
    }
 
    function LocalMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true); 
    }

    function handleUserUpdate() 
    {
        SetRefeshList(new Date().getMilliseconds());
    }

    const fetchUsers = async () =>{
        try 
        {
            // SetUserList([]);
            const response = await fetch(getBaseURL() + 'query_usr_omni', {
                method: 'POST'
            });

            const res = await response.json();
            SetUserList(res);

        }catch(err) 
        {
            console.log("Falied to fetch user OMINI");
            throw new Error("Falied to fetch user OMINI");
        }
    }

    useEffect(() => {
        const handleOnline = (data) => handleUserUpdate();
        const handleOffline = (data) => handleUserUpdate();

        socket.on("user-back-online", handleOnline);
        socket.on("user-back-offline", handleOffline);

        return () => {
            socket.off("user-back-online", handleOnline);
            socket.off("user-back-offline", handleOffline);
        };
    }, []);

    useEffect(()=>{
        fetchUsers();
    }, [RefeshList, auth.activeWorkShop, auth.user])



    return (
       <div className={`${classes.user_managment_main_wrapper}`}>
        {/* <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" /> */}
        {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}

        {OpenClassicUserCreate      && <ClassicUserCreate Close_CALLBACK={SetOpenClassicUserCreate} MsgTrigger_CALLBACK={MsgCallback} /> }
        {OpenStandardUserCreate     && <StandardUserCreate Close_CALLBACK={SetOpenStandardUserCreate} MsgTrigger_CALLBACK={MsgCallback} /> }
        {OpenManageUser             && <ManageUser RefCallBack={UpdateList} UsrData={ActiveUser} Close_CALLBACK={SetOpenManageUser} MsgTrigger_CALLBACK={MsgCallback} /> }


        <div className={`${classes.user_data_wrapper}`}>

            <div className={`${classes.user_box_wrapper}`}>

                <div className={`${classes.user_header_wrapper}`}>
                    <div className={`${classes.reg_action_button_wrapper}`}>
                     {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateUser && <button onClick={(e)=>{SetOpenClassicUserCreate(true)}}><FontAwesomeIcon icon={faUserSecret}></FontAwesomeIcon> Classic User</button> }
                     {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateUser &&   <button onClick={(e)=>{SetOpenStandardUserCreate(true)}}><FontAwesomeIcon icon={faUserTie}></FontAwesomeIcon> Standard User</button> }
                    </div>

                    <div className={`${classes.filter_header_wrapper}`}>
                        {/* <div className={`${classes.filter_action_button_wrapper}`}>
                            <button>Online</button>
                            <button>Offline</button>
                            <button>Suspended</button>
                            <button>Active</button>
                        </div> */}

                        <div className={`${classes.filter_input_wrapper}`}>
                            <span><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span>
                            <input type="text" placeholder='Username...' autoComplete='off' />
                        </div>
                    </div>

                </div>

                <div className={`${classes.user_list_wrapper}`}>

                    {
                        UserList.map((item, idx)=>(
                            <li key={idx}>
                                <span className={`${classes.user_name}`}>{item.FirstName} {item.LastName} / {item.Username}</span>
                                <span className={`${ item.AccountState ===  'Active' ? classes.account_state_active : classes.account_state_suspend}`}> <FontAwesomeIcon icon={item.AccountState ===  'Active' ? faCheckCircle : faTriangleExclamation}></FontAwesomeIcon> {item.AccountState}</span>
                                <span className={`${classes.user_role}`}>{item.UARI_Name}</span>
                               {/* {!item.IsActive && <span className={`${classes.user_last_time}`}>{'Active ' + getRelativeTime(item.LastTime)}</span> } */}
                                <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.IsActive ? '' : 'Active ' + getRelativeTime(item.LastTime)})} className={`${item.IsActive ? classes.online_state : classes.offline_state}`}>{item.IsActive ? <WifiHigh weight='bold'/> : <WifiSlash weight='bold'/> }</span>
     
                                <UIButton onClick={(e)=>{SelectUser(item)}} Variant='Secondary' label={"Manage"} Icon={<PencilCircle/>}/>
                            </li>
                        ))
                    }
                    
                </div>

            </div>
        </div>

       </div>
    );
}
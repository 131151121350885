import classes from './ProductionStackCheckOutPreview.module.css'

import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {CreateNewMerchGroup} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'

 

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import { FetchSavedProductionProductOrder } from '../../../../api'

import {FetchMixSaved} from '../../../../api'

import { FetchBakeryProductByID } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';

import UIButton from '../../../Components/UIButton/UIButton'
import { SignOut } from '@phosphor-icons/react'

export default function ProductionStackCheckOutPreview({PendingOrderPackage, OpenController})
{

    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(false);
    const [RemainOrderPackage, SetRemainOrderPackage] = useState(new Map());
    const [ItemBatchMoveTargetList, SetItemBatchMoveTargetList] = useState([]);

    function CloseModal() 
    {
        OpenController(false);
    }

    const FetchAndGroupByOrderGroup = async()=>
    {
        const __collection_temp_buffer = new Map();
        // notify.ShowLoad("Processing, your request....", 10);
        try 
        {

            const _temp_move_target_buffer = [];
            for(let i = 0; i < PendingOrderPackage.length; i++) 
            {
                const __buffer_array_data = [];
                const __order_list =  await FetchSavedProductionProductOrder(PendingOrderPackage[i].PO_Hash);

                for(let k = 0; k < __order_list.length; k++) 
                {
                    const fd = new FormData();
                    fd.append("targte_id", __order_list[k].POP_ProdID);
                    // Fetch Detail Product Entry
                    const __detail_product = __order_list[k].POP_IsMix === 1 ? await FetchMixSaved(__order_list[k].POP_ProdID) : await FetchBakeryProductByID(fd, auth.token);
                    __buffer_array_data.push({...__detail_product[0],  ...__order_list[k]});


                    _temp_move_target_buffer.push({...__detail_product[0],  ...__order_list[k], Action: "Transfer"});
                }

                __collection_temp_buffer.set(PendingOrderPackage[i].PO_Hash, __buffer_array_data);
            }
            SetItemBatchMoveTargetList(_temp_move_target_buffer);
            SetRemainOrderPackage(__collection_temp_buffer);

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }


    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();


            // notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    const UpdateTransferState = (pop_hash) => {
        SetItemBatchMoveTargetList((prev) => {
            // Create a deep copy of the previous state to avoid direct mutation
            let __update = [...prev];
            for (let i = 0; i < __update.length; i++) {
                if (__update[i].POP_Hash === pop_hash) {
                    // Safely toggle the Action property
                    __update[i] = {
                        ...__update[i],
                        Action: __update[i].Action === "Transfer" ? "Reallocate" : "Transfer",
                    };
                }
            }
            return __update; // Return the updated state
        });
    };

    useEffect(()=>{
        FetchAndGroupByOrderGroup();
    }, [])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Finish <span className={`${data_win.data_create_name}`}>Client Shift</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                    <div className={`${classes.group_wrapper_for_list}`}>
                                       {
                                        ItemBatchMoveTargetList.map((val_item, val_idx)=>(
                                            <li key={val_idx}>
                                                <span>{val_item.POP_IsMix ? val_item.MixName : val_item.CMBP_Name}</span>
                                                <span className={`${classes.gourp_item_left_sided_}`}>{val_item.POP_BatchRemain} Remain Batch</span>
                                                <span className={`${classes.group_item_action_View_wrapper}`}>Action: <UIButton Variant={val_item.Action === "Transfer" ? "Primary" : "Secondary"} Type='button' onClick={()=>{UpdateTransferState(val_item.POP_Hash)}} label={val_item.Action === "Transfer" ? "Transition to the upcoming batch" : "Reallocate batch stock to inventory"} Icon={<SignOut/>}/></span>
                                            </li>
                                        ))
                                       } 
                                        
                                    </div>
                                
                                </div>
                                    {/* <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetGroupName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Group Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                    onChange={(e)=>{SetGroupDescritpion(e.target.value)}}
                                                    type="text"
                                                    placeholder='Description'
                                                    />
                                                }
                                            />

                                    </div> */}
                                    <h2></h2>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                {/* <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Finish'}</button> */}
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}
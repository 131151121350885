import classes from './MasterVehicleType.module.css'
import DataViewTableProxy from '../../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import { useEffect, useState } from 'react'


import MVT_Create from './MVT_Create/MVT_Create'
import MVT_Edit from './MVT_Edit/MVT_Edit'


import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'
import { faBan, faPenNib } from '@fortawesome/free-solid-svg-icons'
import { useDAControl } from '../../../../DAControlContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PurgeVehicleType, QueryAllMasterVehicle } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_III'

export default function MasterVehicleType()
{
    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl();

    const [RefreshView, SetRefreshView] = useState(false);
    const [OpenMasterDataCreate, SetOpenMasterDataCreate] = useState(false);
    const [OpenMasterdataUpdate, SetOpenMasterdataUpdate] = useState(false);
    const [TargetEditData, SetTargetEditData] = useState(null);
    const [DataList, SetDataList] = useState([]);
    const [DataListCopy, SetDataListCopy] = useState([]);

    const FetchData = async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterVehicle(auth.token);
            SetDataList(main_res);
            SetDataListCopy(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to fetch vehicle!");
        }
    }
    const HandleUpdateView = ()=>
    {
        SetRefreshView(!RefreshView);
    }
    useEffect(()=>{
        FetchData();
    }, [RefreshView])

    const FilterByName = (key_name)=>
    {
        const valid_list = [];
        for(let i = 0; i < DataList.length; i++) 
        {
            if(DataList[i].HRMVT_Name.toLowerCase().includes(key_name.toLowerCase())) 
            {
                valid_list.push(DataList[i]);
            }
        }
        SetDataListCopy(valid_list);
    }

    return (
        <div className={`${classes.data_pro_table_wrapper}`}>

            {OpenMasterDataCreate && <MVT_Create UpdateView={HandleUpdateView} OpenController={SetOpenMasterDataCreate}/>}
            {OpenMasterdataUpdate && <MVT_Edit UpdateView={HandleUpdateView} OpenController={SetOpenMasterdataUpdate} TargetData={TargetEditData}/>}



            <div className={`${classes.md_inner_data_table_wrap}`}>
                        <DataViewTableProxy 
                            IsEmpty={DataList.length === 0}
                            OnFilter={FilterByName}
                            OpenDataCreate={SetOpenMasterDataCreate}
                            HeaderName={
                                <div>
                                    <h2><b>Master Vehicle</b> Type</h2>
                                </div>
                            }
                            DataArray={
                                <div>
                                    {
                                        DataListCopy.map((item, idx)=>(
                                            <li key={item.HRMVT_Name + idx}>
                                                <span>{item.HRMVT_Name}</span>
                                                <span>{item.HRMVT_Description}</span>
                                                <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                                {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                                    onClick={()=>{DAControl.Trigger(`Purge Vehicle type`, 
                                                        `You are about to Vehicle Type '${item.HRMVT_Name}'.
                                                        This action is IRREVERSIBLE!`, 
                                                        item.HRMVT_ID,
                                                        10,
                                                        async (data)=>{
                                                            if(!data)
                                                                return;

                                                                try 
                                                                {
                                                                    const fd = new FormData();
                                                                    fd.append('target_id', data);
                                                                    const main_res = await PurgeVehicleType(fd, auth.token);
                                                                    notify.ShowSuccess(main_res.Msg);
                                                                    SetRefreshView(!RefreshView);
                                                                } catch(err) {
                                                                   notify.ShowError(err.message);
                                                                }

                                                        })}}
                                                    >
                                                        <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                        <span>Purge</span>
                                                    </button>}
                                                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{SetTargetEditData(item); SetOpenMasterdataUpdate(true)}}>
                                                        <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                        <span>Manage</span>
                                                    </button>}
                                                </span>
                                            </li>
                                        ))
                                    }
                                    
                                </div>
                            }
                        />
            </div>
        </div>
    )
}
import { useState } from 'react'
import classes from './EditableInputPreviewComponent.module.css'
import { FloppyDisk, MarkerCircle, NotePencil } from '@phosphor-icons/react';


export default function EditableInputPreviewComponent({Label, PreviewValue, DataType = "text"}) 
{

    const [InitValue, SetInitValue] = useState(JSON.stringify(PreviewValue));



    return (
        <div className={`${classes.editable_input_var_emp_view_master_wrapper}`}>
            <span className={`${classes.unlock_edit_field}`}>
                <span className={`${classes.mini_manipulate_button}`} style={{display: 'inline-flex', margin: '3px'}}><FloppyDisk weight='fill'/></span>
                <span className={`${classes.mini_manipulate_button}`} style={{display: 'inline-flex', margin: '3px'}}><NotePencil weight='fill'/></span>
            </span>
            <div className={`${classes.editable_input_var_emp_view_label_wrapper}`}>
                <li>{Label}</li>
            </div>
            <div className={`${classes.editable_input_var_emp_view_input_wrapper}`}>
                <input onChange={(e)=>{SetInitValue(e.target.value)}} value={PreviewValue} type={DataType} />
            </div>
        </div>
    )
}
import classes from './ChemiclaCollectionPreviewDoc.module.css'


export default function ChemiclaCollectionPreviewDoc({ChemicalPreviewPayload}) 
{



    return (
        <div className={`${classes.single_order_data_view_master_wrapper}`}>
            <div className={`${classes.packge_data_wrapper}`}>

                <div className={`${classes.main_prod_info_wrapper}`}>
                    <li>
                        <span className={`${classes.prod_name_PO}`}>Chemical Request</span>
                    </li>
                </div>

                <div className={`${classes.ing_list_data_wrapper}`}>
                    <div className={`${classes.data_marging_wrapper}`}>
                            <li style={{paddingBottom: '5px', fontWeight: 'bold'}}>
                                <span>Production Mix</span>
                                <span>Linked Product</span> 
                                <span>Batch Count</span>
                            </li>
                        {
                            ChemicalPreviewPayload.map((item, idx)=>(
                                <li key={item.CH_Name + idx}>
                                    <span>{item.CH_Name}</span>
                                    <span>{item.PROD_Name}</span> 
                                    <span>x{item.ORDER_BatchCount}</span>
                                </li>
                            ))
                           
                        }
                    </div>
                </div>


            </div> 

        </div>
    )
}
import classes from './PrintProductionLogView.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'
import { objectToMap, ToEthiopianCalander } from '../../../../SharedComp/Utility'

const formatter_currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB', 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
});
const formatter = new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0
})
const formatter_Frack = new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
})


export default function PrintProductionLogView({DataList, TargetDate, Close_CALLBACK})
{

    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const [ProcessedList, SetProcessedList] = useState([]);

    const [TotalUsedKG, SetTotalUsedKG] = useState(0);
    const [SummeryPrice, SetSummeryPrice] = useState(0)
    const [SummeryQuantity, SetSummeryQuantity] = useState(0)
    const [SummeryResource, SetSummeryResource] = useState(0)

    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Production Log" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    });

    const SumUpPriceQuantity = async()=> 
    {
        let __price = 0;
        let __quantity = 0;
        let __resource = 0;

        for(let i = 0; i < DataList.length; i++) 
        {
            const _expected_batch_product_output = DataList[i].ProductCountPerBatch ? parseFloat(DataList[i].ProductCountPerBatch) : 1;
            const _killogram_usage_per_batch = DataList[i].FlourPerBatch ? parseFloat(DataList[i].FlourPerBatch) : 1;
            const __unit_price = DataList[i].Price ? parseFloat(DataList[i].Price) :  parseFloat(DataList[i].CMBP_UnitPrice);
            __quantity += parseInt(DataList[i].Quantity);
            __price += __unit_price * DataList[i].Quantity;
            __resource += DataList[i].Quantity * (_killogram_usage_per_batch / _expected_batch_product_output);
        }

        SetSummeryQuantity(__quantity);
        SetSummeryPrice(__price);
        SetSummeryResource(__resource);
    }

    
    const GroupItems = async(data_list)=> 
    {
        const _data_buffer = new Map();
        for (let i = 0; i < data_list.length; i++) {
            const currentItem = {...data_list[i]};

            if(_data_buffer.has(currentItem.ItemName)) 
            {
                const _exist =_data_buffer.get(currentItem.ItemName);
                _exist.ProductionQuantity += parseFloat(currentItem.ProductionQuantity);
                _exist.FlourPerBatch += parseFloat(currentItem.MS_FlourPerBatch);
                _exist.ProductCountDifference += currentItem.ProductCountDifference;
                _exist.BatchCount += 1;
            } else 
            {
                _data_buffer.set(currentItem.ItemName, {...currentItem});
            }

        }
        const __array_val = Array.from(_data_buffer.values()); 
        return __array_val;
    }
    const SortByName = async(data_list)=> 
    {
        const sortedData = [...data_list].sort((a, b) => a.ItemName.localeCompare(b.ItemName));
        return  sortedData;
    }


     const ParseDataForView = async (data_list)=>
    {

        let __total_kg = 0;
        const __readable_data_set = [];
       for(let i = 0; i < data_list.length; i++) 
        {
            const _branch_spec_data = objectToMap(JSON.parse(data_list[i].CMBP_WorkspaceSpec));
            const __my_profile = _branch_spec_data.get(auth.activeWorkShop);

            const _expected_batch_product_output = data_list[i].ProductCountPerBatch ? parseFloat(data_list[i].ProductCountPerBatch) : 0;
            const _killogram_usage_per_batch = data_list[i].FlourPerBatch ? parseFloat(data_list[i].FlourPerBatch) : 0;
            __total_kg += _killogram_usage_per_batch;
            const __unit_price = data_list[i].Price ? parseFloat(data_list[i].Price) :  parseFloat(data_list[i].CMBP_UnitPrice);
            const __product_diff = parseInt(data_list[i].Quantity) - _expected_batch_product_output;
            __readable_data_set.push({
                ItemName: data_list[i].Alias ? data_list[i].Alias : data_list[i].CMBP_Name,
                UnitPrice: __unit_price,
                ProductionQuantity: parseInt(data_list[i].Quantity),
                FlourPerBatch: _killogram_usage_per_batch,
                MS_FlourPerBatch: _killogram_usage_per_batch,
                ProductCountPerBatch: _expected_batch_product_output,
                MS_ProductCountPerBatch: _expected_batch_product_output,
                ExpectedGram: formatter.format((_killogram_usage_per_batch / _expected_batch_product_output) * 1000),
                CurrentGram: formatter.format((_killogram_usage_per_batch / parseInt(data_list[i].Quantity)) * 1000),
                ProductCountDifference: __product_diff,
                BatchCount: 1,
                ProductionDifferenceCashValue: __product_diff < 0 ? __product_diff * __unit_price : 0,
                EP_ProductionDifferenceCashValue: __product_diff < 0 ? __product_diff * __unit_price : 0
            })

            SetTotalUsedKG(__total_kg);
        }

        SetProcessedList(await SortByName(await GroupItems(__readable_data_set)));

    }


    useEffect(() => {
        if (DataList.length > 0) {
            ParseDataForView(DataList)
            SumUpPriceQuantity();
            // SortByName();
        }

    }, [DataList]);

    


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        {auth.activeWorkShopName} <span className={`${data_win.data_create_name}`}>Production Log</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>[#] {auth.activeWorkShopName} </h2>
                            <h2>[#] Production Log </h2>
                        </div>

                        <div className={`${classes.transcation_owner_wrapper}`}>
                            <div className={`${classes.transcation_owner}`}>
                                <div className={`${classes.transcation_recive}`}>
                                    <h2>Processed By</h2>
                                    <li>{auth.employeeData?.JAKSBE_FullName}</li>
                                    <li>{TargetDate ? ToEthiopianCalander(TargetDate) : '-'}</li>
                                    <li>[Day - Night] - Shift</li>
                                </div>
 
                            </div>
                        </div>

                        <div className={`${classes.entry_detail}`}>
                            <li><span className={`${classes.entry_deatil_tag}`}>Production Date</span> <span className={`${classes.entry_deatil_val}`}>{new Date(TargetDate).toDateString()}</span></li>
                            <li><span className={`${classes.entry_deatil_tag}`}>Date Of Print</span> <span className={`${classes.entry_deatil_val}`}>{new Date().toDateString()}</span></li>
                        </div>  
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>

                            <div className={`${classes.paper_list_wrapper}`}>

                                        <li className={`${classes.paper_list_wrapper_header_tit}`}>
                                            <span>Product Name</span>
                                            <span className={`${classes.list_item_alignt_right}`}>Production Volume</span>
                                            <span className={`${classes.list_item_alignt_right}`}>Flour Usage</span>
                                            <span className={`${classes.list_item_alignt_right}`}>Summarized Price</span>
                                        </li>

                                {
                                    ProcessedList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon> {item.ItemName}</span>
                                            <span className={`${classes.list_item_alignt_right}`}>{formatter.format(item.ProductionQuantity)} Pcs - {item.BatchCount} Batch</span>
                                            <span className={`${classes.list_item_alignt_right}`}>{formatter_Frack.format(item.FlourPerBatch)} Kg</span>
                                            <span className={`${classes.list_item_alignt_right}`}>{formatter_currency.format(item.UnitPrice * item.ProductionQuantity)}</span>
                                        </li>
                                    ))
                                }
                               
                            </div>

                            <div className={`${classes.paper_list_wrapper_summery}`}>
                                <li className={`${classes.paper_list_wrapper_header_bot}`}>
                                    <span>Grand Total</span>
                                    <span className={`${classes.list_item_alignt_right}`}>{formatter.format(SummeryQuantity)} Pcs - {DataList.length} Batch</span>
                                    <span className={`${classes.list_item_alignt_right}`}>{formatter_Frack.format(TotalUsedKG)} kg</span>
                                    <span className={`${classes.list_item_alignt_right}`}>{formatter_currency.format(SummeryPrice)}</span>
                                </li>
                            </div>
                            
                        </div>

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Production Manager</h2>
                                <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                <li className={`${classes.print_date}`}>Date:________________________</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                        </div>

                        {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                        <div className={`${classes.final_note_po_stock_out}`}>
                            <li>Invalid if not signed.</li>
                        </div>

                    </div>
                </div>



                </div>


                <div className={`${classes.action_button_wrapper}`}>

                    <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}
import classes from './EmployeeBudleHandler.module.css'
import EmployeeMiniCardMaster from '../EmployeeMiniCardMaster/EmployeeMiniCardMaster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import { QueryBaseEmployeeByBranch } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee';
import { useAuth } from '../../../../AuthProvider';

import UIButton from '../../../Components/UIButton/UIButton';
import { SortAscending, SortDescending, UserCirclePlus } from '@phosphor-icons/react';
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';

export default function EmployeeBundleHandler({BranchData, EmployeeCreatorWinController, EmpViewController}) 
{

    const auth = useAuth();
    const [EmployeeListStatic, SetEmployeeListStatic] = useState([]);
    const [EmployeeListDynamic, SetEmployeeListDynamic] = useState([]);
    const [IncomingLoading, SetIncomingLoading] = useState(false)


    const QueryBaseEmployee = async() =>
    {
        SetIncomingLoading(true);
        try 
        {
            let __branch_id = -1;
            if(BranchData) 
            {
              __branch_id = BranchData.BranchID;
            }

            const fd = new FormData();
            fd.append("branch_id", __branch_id)
            const main_res = await QueryBaseEmployeeByBranch(fd, auth.token);
            SetEmployeeListStatic(main_res);
            SetEmployeeListDynamic(main_res);
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }
    useEffect(() => 
    {
        QueryBaseEmployee()

    }, [])

    const FilterEmployeeByKey = (key_val)=>
    {
      const valid_list = [];

      for(let i = 0; i < EmployeeListStatic.length; i++) 
      {
        if(
          EmployeeListStatic[i].JAKSBE_FullName.toLowerCase().includes(key_val.toLowerCase()) ||
          EmployeeListStatic[i].JAKSBE_PhoneNumber.includes(key_val) 
        ) 
        {
          valid_list.push(EmployeeListStatic[i]);
        }
      }

      SetEmployeeListDynamic(valid_list);
    }

    const SortEmployeeByName = (asc = true) =>
    {
      const valid_list = [...EmployeeListStatic].sort((a, b) => {
        const nameA = a.JAKSBE_FullName.toLowerCase();
        const nameB = b.JAKSBE_FullName.toLowerCase();
        
        if(asc) 
        {
          if (nameA < nameB) return -1; 
          if (nameA > nameB) return 1;
        } else 
        {
          if (nameA > nameB) return -1; 
          if (nameA < nameB) return 1;
        }
          
        return 0;                     
      });

      SetEmployeeListDynamic(valid_list);
    }

    return (
        <div className={`${classes.employee_bundle_handler_wrapper}`}>

            <div className={`${classes.employee_master_list_wrapper}`}>

                <div className={`${classes.employee_master_list_header}`}>
                    <div className={`${classes.header_info}`}>

                       <h3>Workspace <strong>#{BranchData ? BranchData.BranchName : "Cross-Branch"}</strong></h3>

                    </div>

                    <div className={`${classes.header_info}`}>
            
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateEmployee && <UIButton onClick={()=>{EmployeeCreatorWinController(BranchData)}} label={"Enroll Staff Member"} Icon={<UserCirclePlus weight='bold'/>}/> }
              
                    </div>
                </div>
                
                <div className={`${classes.emp_list_wrapper}`}>

                  {!IncomingLoading &&
                  EmployeeListDynamic.map((item, index) => (
                      <EmployeeMiniCardMaster   
                          key={index + item.JAKSBE_EmployeeHash}   
                          EmpData={item}  
                          EmpViewController={EmpViewController}

                        />
                )) }
               
                {IncomingLoading && <LoadingPlaceholder/> }

                </div>
                
                <div className={`${classes.data_filter_action_wrapper}`}>
                    <div className={`${classes.data_employee_input_field_wapper}`}>
                        <input onChange={(e)=>{FilterEmployeeByKey(e.target.value)}} type="text" placeholder='Employee Name / Telephone' />
                    </div>
                    <div className={`${classes.action_button_wrapper}`}>
                      <button onClick={()=>{SortEmployeeByName()}}><SortAscending weight='bold'/></button>
                    </div>
                    <div className={`${classes.action_button_wrapper}`}>
                      <button onClick={()=>{SortEmployeeByName(false)}}><SortDescending weight='bold'/></button>
                    </div>
                </div>

            </div>
    </div>
    );
}
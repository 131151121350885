import classes from './ProductionStockOrderProcessorV2.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'

import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { nanoid } from 'nanoid'

import {SingleProductPackageOrderTemplate} from '../../../../ProductionOrder/Component/InitProductionOrder/ProductOrderUtils'

import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'

import {FetchSavedProductionProductOrder, ApprovePoRequest} from '../../../../../api'
import {FetchSavedProductionOrderConfig, FetchProductionOrderOWner} from '../../../../../SharedComp/API/Production/API_ProductionOrder'

import SingleOrderDataViewWrapper from '../Component/SingleOrderDataViewWrapper/SingleOrderDataViewWrapper'
import GrandTotalDataViewWrapper from '../Component/GrandTotalOrderDataViewWrapper/GrandTotalOrderDataViewWrapper'
import { useToast } from '../../../../../ToastContext'

import { FetchProductionOrderReqMaterial, FetchMixIngredientJoined, FetchMixSaved } from '../../../../../api'
import { FetchBakeryProductByID } from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';

import ChemiclaCollectionPreviewDoc from './ChemiclaCollectionPreviewDoc/ChemiclaCollectionPreviewDoc'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import { nextMonday } from 'date-fns'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'


export default function ProductionStockOrderProcessorV2({Preview = false, Close_CALLBACK, DataRefersh, TargetData}) 
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();
    const hasFetched = useRef(false);

    const [EC_Orderdate, SetEC_Orderdate] = useState();
    const [OrderedProductList, SetOrderedProductList] = useState([]);
    const [OrderOwner, SetOrderOwner] = useState();
    const [IncomingLoading, SetIncomingLoading] = useState(false);

    const [DataReadBackMapBuffer, SetDataReadBackMapBuffer] = useState(new Map());
    const [GrandTotalArray, SetGrandTotalArray] = useState([]);

    // Collect all chemical List
    const [OrderChemicalRequest, SetOrderChemicalRequest] = useState([]);


    const FetchTragetSavedOrder = async()=>
    {
        const TargetHash = TargetData.PO_Hash;
        const order_prod_list  = await FetchSavedProductionProductOrder(TargetHash, 'token');
        SetOrderedProductList(order_prod_list);

        const chemical_list = [];
        for(let i = 0; i < order_prod_list.length; i++) 
        {
            const _is_mix = order_prod_list[i].POP_IsMix === 1;
            let prod_data = []
            if(_is_mix) 
            {
                prod_data = await FetchMixSaved(order_prod_list[i].POP_ProdID, auth.token);
            } else 
            {
                const fd = new FormData();
                fd.append("targte_id", order_prod_list[i].POP_ProdID);
                prod_data = await FetchBakeryProductByID(fd, auth.token);
            }
            // console.log(prod_data);
            chemical_list.push({
                CH_Name: prod_data[0].MixName,
                PROD_Name: prod_data[0].CMBP_Name,
                ORDER_BatchCount: order_prod_list[i].POP_Batch
            }); 

            if(prod_data.length > 0) 
            {
                //  Now the next step to fetch the Ing list AKA YAM-MIX 
                if(_is_mix) 
                {
                    await QueryChemicalDataForView(prod_data[0].MID, order_prod_list[i]);
                }else 
                {
                    await QueryChemicalDataForView(prod_data[0].CMBP_RecipeID, order_prod_list[i]);
                }
            }else 
            {
                notify.ShowError("Failed to fetch linked mix for target product.");
            }
            FetchComplimentaryStock(order_prod_list[i]);
        }

        SetOrderChemicalRequest(chemical_list);
    }


    const QueryChemicalDataForView = async(ing_id, DataPoint)=>
    {
        try 
        {
            const res = await FetchMixIngredientJoined(ing_id, auth.token);
            
            for(let i = 0; i < res.length; i++) 
            {
                UpdateReadbackBuffer(res[i].IVItemName, {ID: res[i].IvtID, Amount: res[i].Quantity * DataPoint.POP_Batch, UOM: res[i].UomDescription})
            }
            
        }catch(err) 
        {
            
        }
    }
    const FetchComplimentaryStock = async(DataPoint)=>
    {
        try
        {
            
            const res = await FetchProductionOrderReqMaterial(DataPoint.POP_Hash, auth.token);
            // Update read back for each
            for(let i = 0; i < res.length; i++) 
            {
                UpdateReadbackBuffer(res[i].IVItemName, {ID: res[i].IvtID, Amount: res[i].POM_IvQuantity * DataPoint.POP_Batch, UOM: res[i].UomDescription})
            }

        }catch(err) 
        {

        }
    }

    const FetchOrderOwner = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("order_hash", TargetData.PO_Hash);
            fd.append("target_branch", auth.activeWorkShop);
            const res = await FetchProductionOrderOWner(fd, auth.token);
            SetOrderOwner(res[0]);
        }catch(err) 
        {
            
        }
    }


    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_PO-Requested-Stock" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })
    const handleApproval = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("source_branch", auth.activeWorkShop);
            fd.append("user_id", auth.user.UserAccountID);
            fd.append("data_pkg", JSON.stringify(GrandTotalArray));
            fd.append("store_id", TargetData.PO_SourceWarehouse);
            fd.append("order_hash", TargetData.PO_Hash);

            const res = await ApprovePoRequest(fd, 'token');
            
            if(res.MyStateCode !== 1)
            {
                throw new Error(res.Msg);
            }

            notify.ShowSuccess("Production Stock Request was Successfull.");
            DataRefersh();
            Close_CALLBACK(false);

        }catch(err) 
        {
            notify.ShowError(<div dangerouslySetInnerHTML={{ __html: err.message }} />);
        }
    }
    const UpdateReadbackBuffer = async (name, Data) => {

        SetDataReadBackMapBuffer((prev)=>
        {
            const __update = new Map(prev);
            if(__update.has(name)) 
            {
                const existingData = __update.get(name);
                __update.set(name, {
                    ID: existingData.ID,
                    Amount: existingData.Amount + Data.Amount,
                    UOM: Data.UOM
                });
            } else 
            {
                __update.set(name, Data);
            }

            return __update;
        })

        // if (DataReadBackMapBuffer.current.has(name)) {
        //     // If the name exists, update the amount
        //     const existingData = DataReadBackMapBuffer.current.get(name);
            
        //     DataReadBackMapBuffer.current.set(name, {
        //         ID: existingData.ID,
        //         Amount: existingData.Amount + Data.Amount,
        //         UOM: Data.UOM
        //     });
        // } else {

        //     DataReadBackMapBuffer.current.set(name, Data);
        // }
        
    };

    useEffect(()=>{
        SetGrandTotalArray(Array.from(DataReadBackMapBuffer.entries()));
    }, [DataReadBackMapBuffer])

    const FetchTargetDataPackay = async()=>
    {
        SetIncomingLoading(true);
        try 
        {
            await FetchTragetSavedOrder();
            await FetchOrderOwner();
            SetIncomingLoading(false);
        } catch(err) 
        {
            notify.ShowError(err.message);
            SetIncomingLoading(false);
        }
    }
    
    useEffect(()=>{
        if (!hasFetched.current) {
            FetchTargetDataPackay();
            hasFetched.current = true; 
        }
    }, [auth.user])
    
    useEffect(()=>{
        // console.log(TargetData);
        if(OrderOwner) 
        {
            SetEC_Orderdate(ToEthiopianCalander(new Date(OrderOwner.PO_OrderDate)));
        }
    }, [OrderOwner])


    



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>PO Stock Process V2</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

               {!IncomingLoading && <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        
                        <div className={`${classes.print_doc_non_repeat_content}`}>

                            <PaperHash text={Preview ? "copy not original" : null}/>
                            <div className={`${classes.meta_data_wrapper}`}>
                                <h2>[#] {auth.activeWorkShopName} </h2>
                                <h2>[#] PO Stock Processing</h2>
                            </div>

                            <div className={`${classes.transcation_owner_wrapper}`}>
                                <div className={`${classes.transcation_owner}`}>

                                    <div className={`${classes.transcation_init}`}>
                                        <h2>Stock Request Info</h2>
                                        <li><span>Request Owner</span> <span style={{fontWeight: 'bold'}}>{OrderOwner?.FirstName} {OrderOwner?.LastName}</span></li>
                                        <li><span>Target Shift</span> <span style={{fontWeight: 'bold'}}>{OrderOwner?.PO_TargetShift}</span></li>
                                        <li><span>Ordered Date</span>  <span style={{fontWeight: 'bold'}}>{EC_Orderdate}</span></li>
                                        <li><span>Clock</span> <span style={{fontWeight: 'bold'}}>{OrderOwner ? new Date(OrderOwner.PO_OrderDate).toLocaleTimeString() : "--"}</span></li>
                                    
                                    </div>
                                    {/* <div className={`${classes.transcation_recive}`}>
                                        <h2>Processed By</h2>
                                        <li>{auth.employeeData?.JAKSBE_FullName}</li>
                                    </div> */}

                                </div>
                            </div>

                            <div className={`${classes.entry_detail}`}>
                                <li><span className={`${classes.entry_deatil_tag}`}>Process ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData.PO_Hash}</span></li>
                            </div>  
                            
                            
                            <div className={`${classes.main_paper_data_wrapper}`}>

                                <div className={`${classes.paper_list_wrapper}`}>
                                    <ChemiclaCollectionPreviewDoc ChemicalPreviewPayload={OrderChemicalRequest}/>
                                    <GrandTotalDataViewWrapper DataList={GrandTotalArray} />
                                </div>
                                
                            </div>

                            <div className={`${classes.special_footer_local_wrapper}`}>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Digital Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>{auth.employeeData?.JAKSBE_FullName}</li>
                                    <li className={`${classes.print_date}`}>{new Date().toDateString()}</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Physical Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.print_date}`}>Date:________________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Stock checked & approved by</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.print_date}`}>Date:________________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                            </div>

                            {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                            <div className={`${classes.final_note_po_stock_out}`}>
                                <li>This document must have dual signatures to be considered valid.</li>
                            </div>

                        </div>
                    </div>
                </div>



                </div>}
                {
                    IncomingLoading &&
                    <LoadingPlaceholder preview_text='Preparing Document'/>
                }


                <div className={`${classes.action_button_wrapper}`}>

                 {Preview &&    <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>}
                  {!Preview && !IncomingLoading && <button onClick={()=>{
                                        DAControl.Trigger(`Approve Stock Out`, 
                                        `This action will affect Inventory Balance. In other word the "Grand Total" Amount you see will be deducted form your store.`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                handleApproval();
                                            }
                                        }   
                                    );}} className={`${classes.button_approve}`}>Approve</button> }
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}
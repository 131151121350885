import classes from './EditMerchProduct.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle, faObjectUngroup, faObjectGroup } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import Select from 'react-select';

import { FetchBranchAll } from '../../../../../../api'


import {FetchMerchGroupAll, UpdateMerchProduct, FetchMerchProductByID} from '../../../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'

import {Custom_DD_Style, PrepareDropDownList, mapToObject, objectToMap} from '../../../../../../SharedComp/Utility'
 

//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'

export default function EditMerchProduct({OpenController, TargetID})
{
    const formRef = useRef();
    const auth = useAuth();
    const notify = useToast();

    // Dropdown Option
    const [SubMasterGroup, SetSubMasterGroup] = useState([]);

    const [Loading, SetLoading] = useState(false);
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

    // form data
    const [SubMasterProduct, SetSubMasterProduct] = useState();
    const [ProductName, SetProductName] = useState();
    const [ProductPrice, SetProductPrice] = useState();
    const [ProductDetail, SetProductDetail] = useState();

    // Edit Target Data
    const [TargetData, SetTargetData] = useState();

    
    const handleToggle = (branchID) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("Active", !update_state_inner.get("Active"));

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecPrice = (branchID, price) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("Price", price);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecAlias = (branchID, alias) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("Alias", alias);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            const updated = mp_val;
            updated.set("Active", val);
            updated_list.set(mp_key, updated);
        }
        SetWorkspaceChecked(updated_list);    
    };
    const QueryWorkspace = async () => {

        try {
            const res = await FetchBranchAll('token');

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchID)) 
                    init_check_list.set(res[i].BranchID, new Map([
                        ["Active", false],
                        ["Price", null],
                        ["Alias", null]
                      ])); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }
    const QueryProductGroup = async () => {
        try 
        {
            const res = await FetchMerchGroupAll(auth.token);
            PrepareDropDownList(res, SetSubMasterGroup);
        }catch(err) 
        {

        }
    }
    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }
    const QueryTarget = async()=> 
    {
        try 
        {
            const fd = new FormData();
            fd.append("targte_id", TargetID);
            const res = await FetchMerchProductByID(fd, auth.token);
            SetTargetData(res[0]);

            // pharse
            SetSubMasterProduct({value: res[0].CMMP_ParentID, label: res[0].CMMG_Name});
            SetProductName(res[0].CMMP_Name);
            SetProductPrice(res[0].CMMP_Price);
            SetProductDetail(res[0].CMMP_Description);

            const p_res = objectToMap(JSON.parse(res[0].CMMP_WorkspaceConfig));;
            SetWorkspaceChecked(p_res);

        }catch(err) 
        {

        }
    }
    const HandleSubmition = async(e) =>
    {
        e.preventDefault();
        notify.ShowLoad("Processing your request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("sub_group", JSON.stringify(SubMasterProduct));
            fd.append("product_name", ProductName);
            fd.append("product_price", ProductPrice);
            fd.append("product_detail", ProductDetail);
            fd.append('access_workspace', JSON.stringify(mapToObject(WorkspaceChecked)));
            fd.append("user_id", auth.user.UserAccountID);
            fd.append('target_id', TargetID);

            const res = await UpdateMerchProduct(fd, 'token');
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            SetLoading(false);
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    useEffect(()=>{
        QueryTarget();
    }, [WorkspaceList])

    useEffect(() => 
    {
        QueryWorkspace();
        QueryProductGroup();
    }, [])
        


    return (
         <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Update <span className={`${data_win.data_create_name}`}>Merchandise Products</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                { WorkspaceChecked ? <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                                <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            value={SubMasterProduct}
                                                            onChange={(data)=>{SetSubMasterProduct(data)}}
                                                            name={"sub_master_group"}
                                                            options={SubMasterGroup}
                                                            placeholder={"Select Group..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        />
                                                        
                                                    }  />
                                                <MasterDataRegInput
                                                    input={
                                                        <input  
                                                        onChange={(e)=>{SetProductName(e.target.value)}}
                                                        value={ProductName}
                                                        type="text" 
                                                        placeholder='Product Name'
                                                        required />
                                                    }
                                                />
                                                <MasterDataRegInput
                                                    input={
                                                        <input  
                                                        onChange={(e)=>{SetProductPrice(e.target.value)}}
                                                        value={ProductPrice}
                                                        type="number"
                                                        step='any'
                                                        placeholder='Price'
                                                        required />
                                                    }
                                                />


                                                 <MasterDataRegInput
                                                    input={
                                                        <textarea  
                                                        onChange={(e)=>{SetProductDetail(e.target.value)}}
                                                        value={ProductDetail}
                                                        name="detail" 
                                                        placeholder='Discription'
                                                        ></textarea>
                                                    }
                                                />
                                        </div>

                                        <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                            <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                <h1>Branch Accessibility</h1>
                                                <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                            </div>
                                            <div className={`${classes.master_prod_avail_list}`}>
                                                {
                                                    WorkspaceList.map((item, idx)=>(
                                                        <div key={idx} className={`${classes.master_prod_avail_branch}`}>
                                                            <div onClick={() => handleToggle(item.BranchID)} className={`${classes.workspace_name_tick}`}>
                                                                <input 
                                                                    checked={WorkspaceChecked.get(item.BranchID)?.get("Active")} 
                                                                    name={item.BranchName} 
                                                                    type="checkbox"
                                                                    onChange={() => handleToggle(item.BranchID)}
                                                                />
                                                                <li>{item.BranchName}</li>
                                                            
                                                            </div>
                                                            <div className={`${classes.data_per_workspace}`}>
                                                                <li>
                                                                    <input
                                                                    value={WorkspaceChecked.get(item.BranchID)?.get("Price")} 
                                                                    onChange={(e)=>{handleBranchSpecPrice(item.BranchID, e.target.value)}}
                                                                    
                                                                    step='any' 
                                                                    type="number" 
                                                                    placeholder='Price?' />
                                                                </li>
                                                                <li>
                                                                    <input 
                                                                        value={WorkspaceChecked.get(item.BranchID)?.get("Alias")} 
                                                                        onChange={(e)=>{handleBranchSpecAlias(item.BranchID, e.target.value)}}
                                                                    
                                                                        type="text" 
                                                                        placeholder='Alias?' />
                                                                </li>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                        
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>
                </div> : '' }
            </div>

        </div>
    )
}
import { useState } from 'react'
import classes from './MiniPittyCashBranchCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../../AuthProvider';


export default function MiniPittyCashBranchCard({Item, OpenAnalysis}) 
{
    const auth = useAuth();
    const [HelperText, SetHelperText] = useState('');
    const [hasAction, setHasAction] = useState(Item.NeedAction);



    return (
        <div onClick={()=>{
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PreviewBranchPettyCashUtilization)
            {
                OpenAnalysis(Item)
            }
            }} className={`${classes.mini_card_main_wrapper}`}>
            <div className={`${classes.mini_card_header}`}>
                <h2>
                    {Item.BranchName}
                </h2>
                <li className={`${hasAction ? classes.noti_wrapper : classes.noti_wrapper_hide}`}  onMouseOver={()=>{SetHelperText("Action required")}} onMouseLeave={()=>{SetHelperText('')}}>
                    {Item.ReqActionCount} {HelperText}
                </li>
            </div>

            <div className={`${classes.mini_card_body_wrapper}`}>
                <li>Card Holder:  <span className={`${classes.card_min_val}`}>{Item.PittyCashHolder}</span></li>
                <li>Budget Limit: <span className={`${classes.card_min_val}`}>{Item.PittyCashLimit ? Item.PittyCashLimit : <FontAwesomeIcon icon={faInfinity}/>}</span></li>
                <li></li>
            </div>
        </div>
    )
}
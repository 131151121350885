import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const UpdateEmployeeBankInfo = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'base-st-employee-update/update-base-employee-bank-info', data, GetRouteConfig(token));
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
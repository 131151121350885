import classes from './CreateMerchShelves.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import { QueryVendorsOmni } from '../../../../../SharedComp/API/VendorAPI/API_FE_Vendor'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import Select from 'react-select'

import { AddMerchShelvesProduct } from '../../../../../SharedComp/API/ShopShelf/API_FE_MerchShelves'

import MerchBatchInputUnit from '../MerchBatchInputUnit/MerchBatchInputUnit'
import UIButton from '../../../../Components/UIButton/UIButton'

//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { PlusCircle } from '@phosphor-icons/react'

export default function CreateMerchShelves({OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [Loading, SetLoading] = useState(false);

    const [VendorsList, SetVendorsList] = useState([]);

    const [EntryList_RC, SetEntryList_RC] = useState(new Map());
    const [EntryList_DATA, SetEntryList_DATA] = useState(new Map());
    

    const [SelectedVendor, SetSelectedVendor] = useState('');
    const [Attachment, SetAttachment] = useState();

    const HandleMerchItemUpdate = async(ID, _val)=>
    {

        SetEntryList_DATA((prev)=>{
            if(prev.has(ID)) 
            {
                const updated_list = new Map(prev);
                let _copy = updated_list.get(ID);
                _copy.Merch = JSON.stringify(_val);
                updated_list.set(ID, _copy);
                return updated_list;
            } else 
            {
                const updated_list = new Map(prev);
                updated_list.set(
                    ID,
                    {
                        Merch: JSON.stringify(_val),
                        UnitPrice: 0,
                        Quantity: 0
                    } 
                );

                return updated_list;
            }
        })

    }
    const HandleMerchItemQuantityUpdate = async(ID, _val)=>
    {

        SetEntryList_DATA((prev)=>{
            if(prev.has(ID)) 
            {
                const updated_list = new Map(prev);
                let _copy = updated_list.get(ID);
                _copy.Quantity = _val;
                updated_list.set(ID, _copy);
                return updated_list;
            } else 
            {
                const updated_list = new Map(prev);
                updated_list.set(
                    ID,
                    {
                        Merch: '',
                        UnitPrice: 0,
                        Quantity: _val
                    } 
                );

                return updated_list;
            }
        })
    }
    const HandleMerchItemPriceUpdate = async(ID, _val)=>
    {
        SetEntryList_DATA((prev)=>{
            if(prev.has(ID)) 
            {
                const updated_list = new Map(prev);
                let _copy = updated_list.get(ID);
                _copy.UnitPrice = _val;
                updated_list.set(ID, _copy);
                return updated_list;
            } else 
            {
                const updated_list = new Map(prev);
                updated_list.set(
                    ID,
                    {
                        Merch: '',
                        UnitPrice: _val,
                        Quantity: 0
                    } 
                );

                return updated_list;
            }
        })
    }

    const HandleAddNewEntry = async()=>
    {
        // get id
        const _unique_id = getRandomID();
        const updatedList = new Map(EntryList_RC); 
        updatedList.set(_unique_id,
            <MerchBatchInputUnit
                ID={_unique_id}
                EntryPurge={HandleEntryPurge}
                UpdateActiveMerch={HandleMerchItemUpdate}
                UpdateQuantity={HandleMerchItemQuantityUpdate}
                UpdateUnitPrice={HandleMerchItemPriceUpdate}
            />
        );
        SetEntryList_RC(updatedList);
        
    }
    const PurgeEntryData = async(ID)=>
    {
        SetEntryList_DATA((prev)=>{
            if(prev?.has(ID))
            {
                const updated_res = new Map(prev);
                updated_res.delete(ID);
                return updated_res;
            }
        })
    }
    const HandleEntryPurge = async(id)=> 
    {
        
        SetEntryList_RC((prevList) => {
            if (!prevList?.has(id)) return prevList; 

            PurgeEntryData(id);
            const updatedList = new Map(prevList); 
            updatedList.delete(id); 
            return updatedList; 
        });

    }
    
    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }
    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("data_package", JSON.stringify(Array.from(EntryList_DATA.entries())));
            fd.append('file_attachment', Attachment);
            fd.append('vendors', SelectedVendor);
            fd.append("workspace_id", auth.activeWorkShop);
            fd.append("user_id", auth.user.UserAccountID);

            const res = await AddMerchShelvesProduct(fd, auth.token);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    const FetchVendorsList  = async()=>
    {
        try 
        {
            const main_res = await QueryVendorsOmni(auth.token);
            PrepareDropDownList(main_res, SetVendorsList);

        }catch(err) 
        {
            notify.ShowError("Failed to query Vendors list!");
        }
    }
    useEffect(()=>{

        FetchVendorsList();
        HandleAddNewEntry();

    }, [])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Add <span className={`${data_win.data_create_name}`}>Merchandise Shelves Product</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form enctype='multipart/form-data' onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>

                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                    {
                                        Array.from(EntryList_RC.entries()).map((item, idx) => (
                                            <div key={item[0]}>
                                                {item[1]}
                                            </div>
                                        ))
                                    }
                                    {
                                        EntryList_RC.size === 0 && 
                                        <div className={`${classes.empty_entry}`}>
                                            You are not suppose to remove the last entry, I think.
                                        </div>
                                    }

                                            

                                    </div>
                                        
                                        <div className={`${classes.batch_wrapper_input}`}>
                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                        type="file"
                                                        accept="image/*, application/pdf"
                                                        onChange={(e)=>{SetAttachment(e.target.files[0])}}
                                                        required
                                                    />
                                                }
                                                tip={"Receipt attachment"}
                                            />
                                            <MasterDataRegInput input={
                                                <Select
                                                    onChange={(data)=>{SetSelectedVendor(JSON.stringify(data))}}
                                                    name={"dd_data"}
                                                    options={VendorsList}
                                                    placeholder={"Vendor..."}
                                                    isSearchable={true}
                                                    styles={Custom_DD_Style}
                                                    required ={true}
                                                /> } /> 
                                        </div>

                                    </div>
                                
                            </div>

                            <div className={`${classes.action_button_for_dy_list}`}>

                                <div className={`${classes.action_button_for_dy_inner_list}`}>
                                    
                                    <UIButton Type='button' Variant='Secondary' label={"New Entry"} Icon={<PlusCircle weight='fill'/>} onClick={()=>{HandleAddNewEntry()}} />

                                </div>
                            </div>



                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}
import classes from './PayrollScheduler.module.css'
import { useEffect, useState } from 'react'
import TimeAgo from '../../../SharedComp/TimeElapsed/TimeAgo'
import { ArrowFatLinesDown, Tray } from '@phosphor-icons/react';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../AuthProvider';
import PayrollBranchScheduleCard from './PayrollBranchScheduleCard/PayrollBranchScheduleCard';
import { async } from 'q';
import { QueryPayrollScheduleAllResolved } from '../../../SharedComp/API/Payroll/API_FE_PayrollSchedule';
const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function PayrollScheduler() 
{


    const auth = useAuth();
    const [PastPayrollList, SetPastPayrollList] = useState([]);
    const [DataCopy, SetDataCopy] = useState([]);


    const QueryPastScehduledPayroll = async()=>
    {
        try 
        {
            const main_res = await QueryPayrollScheduleAllResolved(auth.token);
            SetPastPayrollList(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{

            QueryPastScehduledPayroll();

    }, [])


    const [BranchOriginal, SetBranchOriginal] = useState([]);
    


    const GetAvailBranchForPayrollDataFeed = async()=>
    {
        fetch(getBaseURL() + 'master-data/get-company-branch')
            .then(res => res.json())
            .then((data) => {SetBranchOriginal(data);});
    }

    useEffect(()=>{

        GetAvailBranchForPayrollDataFeed();

    }, [])


    
    return (
        <div className={`${classes.payroll_scheduler_master_wrapper}`}>

            <div className={`${classes.branch_payroll_preview_wrapper}`}>

                <div className={`${classes.manual_schdule_action_take_Wrapper}`}>
                        <ul>
                            <li>
                                {auth.GlobalConfig.GC_EnableAutoSchedule 
                                ? "Automatic scheduling is currently enabled." 
                                : "Automatic scheduling is currently disabled."}
                            </li>
                        </ul>
                </div>

                <div className={`${classes.payroll_scheduler_coming_up_schedules_wrapper}`}>
                    {
                        BranchOriginal.map((item, idx)=>(
                            <PayrollBranchScheduleCard TargetItem={item}/>
                        ))
                    }
                </div>

            </div>

            <div className={`${classes.tabel_box_connector}`}>
                    <ul><ArrowFatLinesDown/></ul>
            </div>
            

            <div className={`${classes.active_order_list_master_wrapper}`}>


                

                <div className={`${classes.list_box_wrapper}`}>
                    <div className={`${classes.active_order_main_list_wrapper}`}>
                        <li className={`${classes.header_list}`}>
                            <span>Branch</span>
                            <span>Day</span>
                            <span>Month</span>
                            <span>Year</span>
                            <span>CPU Override</span>
                        </li>
                        <div className={`${classes.list_data_wrapper}`}>
                            {
                                PastPayrollList.map((item, idx)=>(
                                    <li key={idx}>
                                        <span>{item.BranchName}</span>
                                        <span>{item.PAYROLLBMS_TargetPayrollECDay}</span>
                                        <span>{item.PAYROLLBMS_TargetPayrollECMonth}</span>
                                        <span>{item.PAYROLLBMS_TargetPayrollECYear}</span>
                                    <span className={`${classes.pre_payment_remain_amount}`}>{item.PAYROLLBMS_CpuOverride}</span>
                                    </li>
                                ))
                            }
                            {
                                PastPayrollList.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                    <ul><Tray fontSize={30}/></ul>
                                    <ul>No Schedule Data</ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
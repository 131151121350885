import classes from './OrderSubGroup.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckDouble, faCircleStop, faCodeBranch, faCopy, faInfoCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {FetchTargetOrderGroupSubProducts, RejectOrderSubProduct} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed';
import CountdownTimer from '../../../../../SharedComp/CountDown/CountDownTimer';
import { useState } from 'react';
import { useToast } from '../../../../../ToastContext';
import { useDAControl } from '../../../../../DAControlContext';
import { useAuth } from '../../../../../AuthProvider';
import { Star } from '@phosphor-icons/react';
import { useRef } from 'react';

function getFormattedDate(dateValue) {
    return new Date(dateValue).toLocaleDateString('en-US', {
      weekday: 'long', 
      month: 'short',  
      day: '2-digit'
    //   year: 'numeric'  
    });
  }
function getFormattedTime(dateValue) {
    return new Date(dateValue).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true 
    });
}
function isDateInThePast(inputDate) {
    const now = new Date();
    const providedDate = new Date(inputDate);
    return now < providedDate;
}

export default function OrderSubGroup({SingleItemDispatch, IsItemSelected, SelectItem,  ItemGroupBase, GroupList})
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();
    const TimeModalRef = useRef(null);

    const [ValidOrderTime, SetValidOrderTime] = useState(isDateInThePast(ItemGroupBase.KOPREOPKWS_ExpectedTimeStamp));
    const [OpenOrderTimeInfo, SetOpenOrderTimeInfo] = useState(false);
    const [MyButtonPosition, SetMyButtonPosition] = useState();


    const HandleTimeModalInfoOpen = (val)=>
    {
        if(!OpenOrderTimeInfo && TimeModalRef.current) 
        {
            TimeModalRef.current.focus();
        }
        SetOpenOrderTimeInfo(val);
    }
    const handleMouseMove = (event) => 
    {
        SetMyButtonPosition({
            x: event.clientX,
            y: event.clientY,
            });
    };

    const HandleRejectOrder = async(order_hash, prod_id, rej_aount)=>
        {
    
            notify.ShowLoad("Processing your request.....", 10);
            
            setTimeout(async()=>
            {
                try 
                {
                    const fd = new FormData();
                    fd.append("order_hash", order_hash);
                    fd.append("order_id", prod_id);
                    fd.append("reject_amount", rej_aount);
    
                    const main_res = await RejectOrderSubProduct(fd, 'token');
                    notify.ShowSuccessUpdate(main_res.Msg, 10);
                    // SetRefreshView(!RefreshView);
    
                }catch(err) 
                {
                    notify.ShowErrorUpdate(err.message, 10);
                }
            }, 1500)
    }

    return (
            <div className={`${classes.order_group_list_singular}`}>
                <div className={`${classes.group_header}`}>
                    <h2 className={`${ItemGroupBase.KOPREOPKWS_IsSpecial === "Yes" ? classes.special_order_wrap : ''}`}>{ItemGroupBase.KOPREOPKWS_IsSpecial === "Yes" ? "Special Order" : 'Daily Order'}</h2>
                    <button onClick={()=>{HandleTimeModalInfoOpen(!OpenOrderTimeInfo);}}><FontAwesomeIcon icon={OpenOrderTimeInfo ? faTimesCircle : faInfoCircle}></FontAwesomeIcon></button>
                    <div 
                    ref={TimeModalRef} 
                    tabIndex={0} 
                    onBlur={()=>{SetOpenOrderTimeInfo(false)}} 
                    className={`${OpenOrderTimeInfo ? classes.order_info_critical : classes.order_info_critical_hide}`}>
                        <li>Ordered Date: <TimeElapsed Time={ItemGroupBase.KOPREOPKWS_OrdereTimeStamp}/> </li>
                        <li>Expected Date: {getFormattedDate(ItemGroupBase.KOPREOPKWS_ExpectedTimeStamp)} @ {getFormattedTime(ItemGroupBase.KOPREOPKWS_ExpectedTimeStamp)}</li>
                        <li>Available Time: <CountdownTimer targetTime={ItemGroupBase.KOPREOPKWS_ExpectedTimeStamp} CountSec={true}/></li>
                        <li>Order Status: <span className={`${ValidOrderTime ? classes.order_validity : classes.order_invalidity}`}>{ValidOrderTime ? "In Effect" : "No Longer Active"}</span></li>
                    </div>
                </div>
                <div className={`${classes.sub_group_list_data}`}>
                    {
                        GroupList.map((item, idx)=>(
                            <li key={item.CKP_ItemName + idx} className={`${IsItemSelected(item.CKP_ItemName) ? classes.selected_group_prod : ''}`} onClick={()=>{SelectItem(item)}}>
                                <span>{item.CKP_ItemName} {item.KOPREOPKWS_IsSpecial === "Yes" ? <Star weight='fill'/> : ''}</span>
                                <span className={`${classes.quantity_data_wrapper}`}>
                                    <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Ordered Quantity'})} className={`${classes.total_amount_wrapper}`}><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon> {item.KOPREOPD_OrderedQuantity} </span>
                                    <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Dispatched Amount'})}><FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon> {item.KOPREOPD_DispatchedQuantity} </span>
                                    {item.KOPREOPKWS_IsSpecial === "No" &&<span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Rejected Amount'})} className={`${classes.rejected_amount_wrapper}`}><FontAwesomeIcon icon={faBan}></FontAwesomeIcon> {item.KOPREOPD_DismissedQuantity} </span>}
                                </span>
                            <span className={`${classes.action_button_wrapper_group}`}>
                                   {item.KOPREOPKWS_IsSpecial === "No" &&  <button disabled={((item.KOPREOPD_DispatchedQuantity + item.KOPREOPD_DismissedQuantity) >= item.KOPREOPD_OrderedQuantity)} onClick={()=>{
                                            DAControl.Trigger(`Reject Order Productt`, 
                                            `You are about to dismiss the remaining products which is [ -${item.KOPREOPD_OrderedQuantity - item.KOPREOPD_DispatchedQuantity}- ] the dispatched amount won't be affected.`,
                                            true,
                                            5, (val)=>{
                                                if(val !== null) 
                                                {
                                                    HandleRejectOrder(item.CKAOPID_GroupHash, item.CKAOPID_ProductID, item.KOPREOPD_OrderedQuantity - item.KOPREOPD_DispatchedQuantity);
                                                }
                                            }   
                                        );}}
                                    className={`${classes.reject_order_item} ${((item.KOPREOPD_DispatchedQuantity + item.KOPREOPD_DismissedQuantity) >= item.KOPREOPD_OrderedQuantity) ? classes.button_disabled : ''}`}  >
                                        <span>
                                            <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span> <span>Reject</span>
                                        </span>
                                    </button>}
                                    <button 
                                        disabled={((item.KOPREOPD_DispatchedQuantity + item.KOPREOPD_DismissedQuantity) >= item.KOPREOPD_OrderedQuantity)} 
                                        className={`${((item.KOPREOPD_DispatchedQuantity + item.KOPREOPD_DismissedQuantity) >= item.KOPREOPD_OrderedQuantity) ? classes.button_disabled : ''}`} 
                                        onMouseMove={(e)=>{handleMouseMove(e)}}  
                                        onClick={()=>{SingleItemDispatch(item, MyButtonPosition);}}
                                        >
                                        <span>
                                            <span><FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon></span> <span>Dispatch</span>
                                        </span>
                                    </button>
                                </span> 
                            </li>
                        ))
                    }
                </div>
            </div>
    )
}
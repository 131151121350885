 
 
function getBaseURL() {
    if (process.env.REACT_APP_ENV === 'production') {
        return process.env.REACT_APP_API_URL_PROD; 
    }
    return process.env.REACT_APP_API_DEV_URL 
}

export { getBaseURL }; 

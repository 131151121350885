import classes from './DynamicPoInput.module.css'

import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'

import Select from 'react-select'
import { useState } from 'react';
import { useEffect } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function DynamicPoInput({
        UID, 
        RootName, 
        HandleMaterialQantity, 
        ReadBack_Quantity, 
        ReadBack_Item, 
        HandleMaterialType
    }) 
{
    const [ProductionCat, SetProductionCat] = useState([]);
    const [SelectedDD, SetSelectedDD] = useState();
    const [SelectedQantity, SetSelectedQantity] = useState('');

    useEffect(() => 
    {
        if(ReadBack_Quantity) 
        {
            SetSelectedQantity(ReadBack_Quantity.get(UID));
        }
        if(ReadBack_Item) 
        {
            SetSelectedDD(ReadBack_Item.get(UID));
        }
       
        QueryOption();
    }, [])

    function QueryOption() 
    {

      fetch(getBaseURL() + 'master-data/get-iv-item', {  
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ID: "Yes" }),
      })
          .then(res => res.json())
          .then((data) => {PrepareDropDownList(data, SetProductionCat); });

    } 

    return (
        <div className={`${classes.input_list_wrapper}`}>

            <div className={`${classes.input_min}`}>
                <MasterDataRegInput input={
                        <Select
                            onChange={(data)=>{SetSelectedDD(data);HandleMaterialType(RootName, data, UID)}}
                            name={"dd_data"}
                            value={SelectedDD}
                            options={ProductionCat}
                            placeholder={"Raw Material..."}
                            isSearchable={true}
                            styles={Custom_DD_Style}
                            required ={true}
                    />
                    }  /> 
            </div>
            <div className={`${classes.input_min}`}>
                <MasterDataRegInput 
                    input={<input 
                        value={ SelectedQantity} 
                        onChange={(e)=>{SetSelectedQantity(e.target.value); HandleMaterialQantity(RootName, e.target.value, UID)}} 
                        name='item_quantity' min={0.00001} 
                        type="number" 
                        step="0.000001" 
                        placeholder='Quantity...' 
                        title='' 
                        autoComplete='off' 
                        required />} />
            </div>
            
        

        </div>
    )
}
import { useState } from 'react';
import classes from './ApChartMini.module.css'
import { useEffect } from 'react';
import Chart from 'react-apexcharts'
import { useAuth } from '../../AuthProvider';

const getCssVariable = (variableName) =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();


export default function ApChartMini({Data, Height, Tag, Type}) 
{
  const colorTheme = getCssVariable('--color-theme');
  const auth = useAuth();
   const [ChartData, SetChartData] = useState(Object.entries(Data).map(([date, value]) => ({ x: date, y: value })));

    const chart_option = {
        
        chart: {
          background: 'var(--background-dark_plus)', // Set the background color
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,

            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        }
      },
      noData: {
        text: "No Data",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '16px',
          fontFamily: undefined
        }
      },
      theme: !auth.DarkMode ? { 
        monochrome: {
          enabled: true,
          color: colorTheme,
          shadeTo: 'light',
          shadeIntensity: 0.65
        } 
      } : {},
        xaxis: {
            type: 'category',
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set x-axis text color
              },
            },
          },
          yaxis: {
            show: true, // Hide y-axis
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set y-axis text color
              },
            },
          },
          grid: {
            show: false, // Hide grid lines
            
          },
          dataLabels: {
            enabled: false, // Hide data labels inside the graph
          },
          tooltip: {
            theme: auth.DarkMode ? "dark" : 'light', // Set the theme to dark
          },
        colors: ['var(--color-theme)'], // Set the text color
      };



    return (
        <Chart
            options={chart_option}
            series={[{ name: Tag, data: ChartData }]}
            type={Type}
            height={Height}
            
        />
    )
}
import classes from './PayrollAccountingPass.module.css'
import SingleAccoutingReadyDataBundle from './SingleAccoutingReadyDataBundle/SingleAccoutingReadyDataBundle'
import { QueryHRPreparedData } from '../../../SharedComp/API/Payroll/API_FE_PayrollAccounting'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'
export default function PayrollAccountingPass() 
{

    const notify = useToast();
    const auth = useAuth();
    const [PackageList, SetPackageList] = useState([]);


    const GetDataPackage = async()=>
    {
        try 
        {
            const main_res = await QueryHRPreparedData(auth.token);
            SetPackageList(main_res);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    useEffect(()=>{
        GetDataPackage();
    }, [])



    return (
        <div>

            <div className={`${classes.payroll_acc_ready_data_process_inner_bundle_Wrapper}`}>
                {
                    PackageList.map((item, idx)=>(
                        <SingleAccoutingReadyDataBundle key={idx} DataPackage={item} />
                    ))
                }
            </div>
        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const CreateNewBaseEmployee = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'base-st-employee/create-new-base-employee', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryBaseEmployeeByBranch = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'base-st-employee/query-base-employee-by-branch', data, GetRouteConfig(token));
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryBaseEmployeeByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'base-st-employee/query-base-employee-by-id', data, GetRouteConfig(token));
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryBaseEmployeeBySyntID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'base-st-employee/query-base-employee-by-synt-id', data, GetRouteConfig(token));
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const QueryAllDispatchedTransaction = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-all-dispatched-transaction-v2', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllIncomingTransaction = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-all-incoming-transaction-v2', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestPayloadV2 = async(data, token)=>
{ 
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-payload-v2', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestSenderInfo = async(data, token)=>
{ 
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-sender-info-v2', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestReceiverInfo = async(data, token)=>
{ 
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-receiver-info-v2', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestDriverInfo = async(data, token)=>
{ 
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/fetch-request-driver-info-v2', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessStockRequestV2 = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/process-stock-tr-request-v2', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessStockRequestShipingState = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/process-stock-tr-request-shiping-state-v2', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReceiveStockRequestV2 = async(data, token)=>{
    try 
    {
        const response = await axios.post(getBaseURL() + 'stock-transfer/process-request-receive-transfer-v2', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
import { useState } from 'react'
import classes from './OrderSingluarProd.module.css'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchFinalProductSaved, FetchMixSaved, FetchYamMixIngID} from '../../../../api'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { FetchBakeryProductByID } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { useAuth } from '../../../../AuthProvider';

export default function OrderSingluarProd({Target, Selected_CALLBACK})
{
    const [TargetData, SetTargetData] = useState();
    const [IsMix, SetIsMix] = useState(Target.POP_IsMix === 1);
    const [LoadingRec, SetLoadingRec] = useState(false);
    const [IngList, SetIngList] = useState([]);
    const auth = useAuth();


    const FetchIngList = async(Data) =>
    {
        SetLoadingRec(true);
        try 
        {
            const recipe_id = IsMix ? Data.MID : Data.CMBP_RecipeID;
            const res = await FetchYamMixIngID(recipe_id, 'token');
            SetLoadingRec(false);
            SetIngList(res);
        }catch(err) 
        {
            console.log(err);
            SetLoadingRec(false);
        }
    }
    const FetchTargetData = async()=>{
        try 
        {
            const fd = new FormData();
            fd.append("targte_id", Target.POP_ProdID);
            const res = IsMix ? await FetchMixSaved(Target.POP_ProdID) : await FetchBakeryProductByID(fd, auth.token);
            SetTargetData(res[0]);
            await FetchIngList(res[0]);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchTargetData();
    }, [])

    return (
        <div className={`${classes.main_product_shelf_card_wrapper}`}>
            <div className={`${classes.prod_item_wrapper}`}>
                <div className={`${classes.prod_name_info_wrapper}`}>
                    <h2>{IsMix ? TargetData?.MixName: TargetData?.CMBP_Name} <sup className={`${classes.batch_preview}`}>^({Target.POP_Batch})</sup></h2>
                </div>
                <div className={`${classes.prod_other_info_wrpper}`}>
                   {
                    !LoadingRec && IngList.map((item, idx)=>(
                        <li key={idx}><span>{item.IVItemName}</span> <span className={`${classes.ing_amount_c}`}>#{(item.Quantity * 1000).toLocaleString()} G</span></li>
                    ))
                   } 
                   {LoadingRec && <div className={`${classes.load_recep}`}> <FontAwesomeIcon spin icon={faSpinner}></FontAwesomeIcon> </div> }
                </div>
            </div>
        </div>
    )
}
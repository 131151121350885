import { useEffect, useState } from 'react'
import classes from './ChefsStockRequest.module.css'

import StockRequestGroup from './Component/StockRequestGroup/StockRequestGroup'
import { useAuth } from '../../../AuthProvider';
import { useToast } from '../../../ToastContext';

import {QueryChefsStockRequestGroup} from '../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { BoxArrowDown } from '@phosphor-icons/react';
import { useGNP } from '../../../GlobalNotificationProvider';


export default function ChefsStockRequest() 
{

    const [RequestGroupList, SetRequestGroupList] = useState([]);
    const [RefreshList, SetRefreshList] = useState(false);
    const auth = useAuth();
    const notify = useToast();
    const GNP = useGNP();



    const QueryActiveRequestGroup = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);

            const main_res  = await QueryChefsStockRequestGroup(fd, 'token');
            SetRequestGroupList(main_res);

        }catch(err) 
        {
            notify.ShowError(err.messsage);
        }
    }

    useEffect(()=>{
        
        QueryActiveRequestGroup();

    }, [auth.activeWorkShop, RefreshList, GNP.EVENT_ChefStockRequest]);

    const RefershViewList = async()=>
    {
        SetRefreshList(!RefreshList);
    }


    return (
        <div className={`${classes.chefs_stock_request_wrapper}`}>

            <div className={`${classes.stock_req_inner_wrapper}`}>


                <div className={`${classes.group_item_list_wrapper}`}>
                        {
                        RequestGroupList.map((item, idx)=>(

                            <StockRequestGroup key={item.KOCSIG_ID} CloseCallback={RefershViewList} TargetData={item}/>

                        ))
                        }

                    {
                        RequestGroupList.length === 0 && <div className={`${classes.request_queue_halo}`}>
                                <h1><BoxArrowDown /></h1>
                                <h2>If you don't see Stock Request list, it means there isn't any!</h2>
                        </div>
                    }
                </div>
            </div>


        </div>
    )
}
import classes from './TrackRealtimeVhState.module.css'

import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { faTimesCircle, faCircleInfo, faTruckMoving, faParking } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine';
// import './CustomRoutingStyles.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import { useRef } from 'react'
import {BreakDriverDeviceLinkInfo, FetchWhatsgpsCarInfo} from '../../../../SharedComp/API/GPS/API_FE_WhatsGPS'
import { useEffect } from 'react'
import { useGlobalNotification } from '../../../../GlobalNotification'
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'
import UIButton from '../../../Components/UIButton/UIButton'
import { CarProfile, CubeFocus, LetterCircleP, LinkBreak, Park } from '@phosphor-icons/react'
import { useAuth } from '../../../../AuthProvider'
import { useDAControl } from '../../../../DAControlContext'
import { useToast } from '../../../../ToastContext'


const mapStyle = { height: '25em', width: "100%", borderRadius: '10px' };
export default function TrackRealtimeVhState({VHData, OpenController}) 
{

    const notify = useToast();
    const DAcontrol = useDAControl();
    const auth = useAuth();
    const Gnotify = useGlobalNotification();
    const [CurrentLoc, SetCurrentLoc] = useState(null)
    const [LeafletReady, SetLeafletReady] = useState(false);
    const [StaticTime, SetStaticTime] = useState(1);
    const [DriverID, SetDriverID] = useState();
    const [Speed, SetSpeed] = useState(0);
    const [DisplayName, SetDisplayName] = useState();
    const [LocationInfo, SetLocationInfo] = useState(null);
    const [ShowDetailLocationInfo, SetShowDetailLocationInfo] = useState(true);
    const [SateliteView, SetSateliteView] = useState(true);
    const [EstimatedDistance, SetEstimatedDistance] = useState(0);
    const [ETA, SetETA] = useState(0);

    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const GeoReverseIntervalRef = useRef(null); 
    const CarInfoIntervalRef = useRef(null);
    const ETARef = useRef(null);

    // I think this is global option .....
    let DefaultIcon = L.icon({
        iconUrl: icon,
        iconSize: [15, 20],
        className: `${StaticTime === '5' ? classes.map_marker : ''}`
    });
    
    L.Marker.prototype.options.icon = DefaultIcon;


    

    // This function calaculate distance from Point A to Point B by making API calls
    // The <<destination>> is the Sefera Coordinate hard coded // but it will be replaced on demand
    const CalculateDistance = async()=> 
    {
        if(CurrentLoc) 
        {
            try 
            {
                const origin = `${CurrentLoc.lon},${CurrentLoc.lat}`;
                const destination = '38.734898,8.919581';
                const res = await axios.get(`http://router.project-osrm.org/route/v1/driving/${origin};${destination}?overview=full&geometries=geojson&alternatives=true`);
                const route = res.data.routes[0];
                const distance = route.distance / 1000; // Convert to kilometers
                const duration = route.duration / 60; // Convert to minutes
                SetEstimatedDistance(distance.toFixed(2));
                SetETA(duration.toFixed(2)); 
            }catch(err) 
            {
                console.log(err);
            }
        }
       
    }
    
    // This function Extract the location info like the name
    // it take the current target usuall the car coordinate and return the name of the location city and something like that
    // in human readable format 
    function ReverseLoc() 
    {
        if(CurrentLoc)
        {
            axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${CurrentLoc.lat}&lon=${CurrentLoc.lon}&format=json`)
            .then(response => {
                // Handle the response here
                SetDisplayName(response.data.display_name);
                SetLocationInfo(response.data);
            })
            .catch(error => {
                console.error(error.message);
            });
        }
    }


    // This function As the name suggest get the token and fetch car information the speed, location, status all that jazz
    const FetchCarInfo = async (gps_token, car_id) => {
        try {
            const res = await FetchWhatsgpsCarInfo(car_id, gps_token, auth.token);

            if(res.data)
            {
                const { lat, lon } = res.data[0];
                SetCurrentLoc({ lat: lat, lon: lon });
                SetSpeed(res.data[0].speed);
                SetStaticTime(res.data[0].status);
                SetDriverID(res.data[0].carId);
            }
    
        } catch (err) {
            console.log(err);
        }
    };



    // This is is Interval which re trigger every 5 seconde 
    // which is hard coded not good I know, 
    // First the distance calculation is expensive and is not free
    // there are afew provided which they are willing to do for free with some basic limitation like only 2 call every sec
    // so I tought I get the updated distance every 5 seconde, which is not bad and ... beside how far can a car go in 5 sec i mean think about it
    useEffect(()=>{
        ETARef.current = setInterval(()=>{

            CalculateDistance();
            
        }, 5 * 1000);

        return () => {
            if (ETARef.current) {
                clearInterval(ETARef.current);
                ETARef.current = null; // Reset the ref after clearing
            }
        };
    }, [])


    // This is the other kind of loop, it get called every 15 seconde, You though the upper was bad huh,
    // It has a few reason the first one is as always not free and if it is with limitation, 
    // In order to reverse a location we have limited calls per seconde but we don't need it
    // I thought a location does not change for a at least 15 sec, meaning if the car is JEMO and on Route to Mekial and if I make 5 API call every 5 seconde I propably get the same location
    // I update the location name every 15 sec it wil be suffient, but this will be a problem if the car move fomr JEMO -> Mekial -> German in 20 seconde
    // but I bet that woudn't happen i guess....
    useEffect(()=>{
        GeoReverseIntervalRef.current = setInterval(()=>{

            if (CurrentLoc && StaticTime === '5') 
            {
                ReverseLoc();
            }
            
        }, 15 * 1000);

        return () => {
            if (GeoReverseIntervalRef.current) {
                clearInterval(GeoReverseIntervalRef.current);
                GeoReverseIntervalRef.current = null; // Reset the ref after clearing
            }
        };
    }, [])



    // This is real time car location, so basically what happening here is
    // we ask the car "whare are you?", "whare are you?", 
    // every two seconde and card replie "Am Here","Am Here","Am Here" with the coordinate
    useEffect(()=>{
        const _gps_token = localStorage.getItem('GPS-TOKEN');
        if(_gps_token) 
            {
                CarInfoIntervalRef.current = setInterval(async () => {
                    FetchCarInfo(_gps_token, VHData.VINTKD_CarID, auth.token);
                    
                }, 1000);
            }

            return () => {
            if (CarInfoIntervalRef.current) {
                clearInterval(CarInfoIntervalRef.current);
                CarInfoIntervalRef.current = null; // Reset the ref after clearing
            }
            };
            
        }, [])



    // Now this guy depend on the car location update meaning
    // this code make sure the pin on the map is visiable when the car moves
    // I tryed to make it at center but I couldn't my bad dwag
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setView([CurrentLoc.lat, CurrentLoc.lon], mapRef.current.getZoom(), {
                animate: true, 
                pan: {
                    duration: 1,
                }
            });
            if (markerRef.current) {
                markerRef.current.openPopup();
            }
        }
    }, [CurrentLoc]);



    useEffect(()=>{
        ReverseLoc();
        CalculateDistance();
    }, [LeafletReady]); 


    const BreakDriverLink = async(id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);
           const main_res = await BreakDriverDeviceLinkInfo(fd, auth.token);
           CloseModal();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    function CloseModal() 
    {
        OpenController(false);
    }


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Track <span className={`${data_win.data_create_name}`}>Vehicle</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${classes.data_body}`}>

                    <div className={`${classes.map_route_view_wrapper}`}>
                            {CurrentLoc && <MapContainer 
                                    ref={mapRef}
                                    zoomControl={false} 
                                    attributionControl={false} 
                                    center={CurrentLoc} 
                                    zoom={12} 
                                    style={mapStyle}
                                    whenCreated={mapInstance => { mapRef.current = mapInstance; }}
                                    whenReady={()=>{SetLeafletReady(true)}}
                                    >
                                        { 
                                            LeafletReady && 
                                            <>
                                                 {SateliteView && <>    <TileLayer
                                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                                attribution='&copy; <a href="https://www.esri.com">Esri</a>'
                                            />
                                                <TileLayer
                                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                                        attribution='&copy; <a href="https://www.esri.com">Esri</a>'
                                                        opacity={1}
                                                /> </> }
                                            {
                                                !SateliteView && 
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    
                                                />
                                            }
                                            {/* <RoutingMachine ActiveCurrent={CurrentLoc} /> */}
                                            <Marker ref={markerRef} position={[CurrentLoc.lat, CurrentLoc.lon]}>
                                                <Popup>
                                                    {/* {DriverID} */}
                                                    {LocationInfo?.address.suburb}, {LocationInfo?.address.county} - {LocationInfo?.address.state}
                                                </Popup>
                                            </Marker>
                                                
                                            </>
                                        }
                            
                            </MapContainer> }
                            <div>
                                {!LeafletReady && 
                                <div className={`${classes.view_loading}`}>
                                    <div className={`${classes.locating_in_action_note}`}>
                                        Trying to locate device 
                                    </div>
                                    <div>
                                        <DotsGif/>
                                    </div>
                                </div> }
                            </div>  
                            <div className={`${classes.map_layer_swicth}`}>
                                <UIButton Variant='Secondary' Icon={<CubeFocus weight='fill' size={16}/>} label={"Satellite Layer"} onClick={()=>{SetSateliteView(true)}}/>
                                <UIButton Variant='Secondary' Icon={<CubeFocus size={16}/>} label={"Base Layer"} onClick={()=>{SetSateliteView(false)}}/>
                            </div>
                        </div>

                        <div className={`${classes.pre_readable_data}`}>
                                    <div className={`${classes.known_location}`}>

                                        {LeafletReady &&   <div className={`${classes.info_wrapper}`}>
                                            <UIButton onClick={()=>{}} 
                                            label={
                                                StaticTime === '5' ? 'In Motion' : 'Stationary'
                                            } 
                                            Icon={
                                                StaticTime === '5' ? <CarProfile weight='fill'/>: 
                                                <LetterCircleP weight='fill'/>}
                                            Variant={StaticTime === '5' ? "Primary" : "Secondary"}
                                            />
                                    
                                        {StaticTime === '5' && <UIButton onClick={()=>{}} Variant='Secondary' label={Speed + " km/h"} />}
                                        {StaticTime === '5' && <UIButton onClick={()=>{}} Variant='Secondary' label={EstimatedDistance + " km"}/>}
                                        {StaticTime === '5' && <UIButton onClick={()=>{}} Variant='Secondary' label={ETA + " ETA"} />}
                                    
                                    </div> }
                                        
                                        <div className={`${classes.known_location_left}`}>
                                                <li className={`${classes.name_short}`}>
                                                    {LocationInfo ? 
                                                        <span className={`${classes.loc_text_main}`}><span>{LocationInfo?.address.suburb}, {LocationInfo?.address.county} - {LocationInfo?.address.state}</span> </span>
                                                        : 'Location Unknown'}
                                                        </li>
                                        </div>
                                        <div className={`${classes.known_location_right}`}>
                                            <li>
                                                <span className={`${classes.loc_tag}`}>Type</span>
                                                <span  className={`${classes.loc_val}`}>{LocationInfo?.type}</span>
                                            </li>
                                            <li>
                                                <span className={`${classes.loc_tag}`}>Class</span>
                                                <span  className={`${classes.loc_val}`}>{LocationInfo?.class}</span>
                                            </li>
                                            <li>
                                                <span className={`${classes.loc_tag}`}>Suburb</span>
                                                <span  className={`${classes.loc_val}`}>{LocationInfo?.address.suburb}</span>
                                            </li>
                                            <li>
                                                <span className={`${classes.loc_tag}`}>County</span>
                                                <span  className={`${classes.loc_val}`}>{LocationInfo?.address.county}</span>
                                            </li>
                                            <li>
                                                <span className={`${classes.loc_tag}`}>State</span>
                                                <span  className={`${classes.loc_val}`}>{LocationInfo?.address.state}</span>
                                            </li>
                                            <li>
                                                <span className={`${classes.loc_tag}`}>Country</span>
                                                <span  className={`${classes.loc_val}`}>{LocationInfo?.address.country}</span>
                                            </li>
                                        </div>

                                        <div className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_BreakDeviceLink &&   <UIButton Icon={<LinkBreak/>} onClick={()=>{
                                                DAcontrol.Trigger("Break Driver and Vehicle Link", 
                                                `This action will break the link between the driver and vehicle, this means this device can not be used in operation.`,
                                                true,
                                                7,
                                                async (data)=>{
                                                    if(data) 
                                                    {
                                                        await BreakDriverLink(VHData.VINTKD_ID);
                                                    }
                                                }
                                                )
                                            }}  Variant='Danger' label={"Break Link"}  /> }
                                        </div>

                                    </div>
                                   
                        </div>


            </div>
        </div>

    </div>
    )
}

import classes from './EmployeeCompanyProfile.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'
import { useEffect, useState } from 'react';
import { useToast } from '../../../../ToastContext';
import { QueryAllMasterDataBank, QueryAllMasterJobPosition } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I';
import { QueryAllMasterShift } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_II';


export default function EmployeeCompanyProfile({UpdateEmployeeData, Employee})
{

    const notify = useToast();
    const [JobPositionList, SetJobPositionList] = useState([]);
    const [WorkspaceShiftList, SetWorkspaceShiftList] = useState([]);
    const [ActiveBankList, SetActiveBankList] = useState([]);

    const EmploymentType = [{ value: 'Permanent', label: 'Permanent'  }, { value: 'Contract', label: 'Contract'  }];


    const UpdateEmployeeInput = (e) =>
    {
        UpdateEmployeeData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        })); 
    }
    const UpdateEmployeeInputDropDown = (_name, _value) =>
    {
        UpdateEmployeeData(prev => ({
            ...prev,
            [_name]: _value
        })); 
    }

    const GetAvialJobPosition = async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterJobPosition();
            PrepareDropDownList(main_res, SetJobPositionList);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const GetAvialShifts = async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterShift();
            PrepareDropDownList(main_res, SetWorkspaceShiftList);
        } catch(err) 
        {
            notify.ShowError(err.message); 
        }
    }
    const GetAvialBanks = async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterDataBank();
            PrepareDropDownList(main_res, SetActiveBankList);

        }catch(err) 
        {
            notify.ShowError(err.message); 
        }
    }

    useEffect(()=>{
        GetAvialJobPosition();
        GetAvialShifts();
        GetAvialBanks();
    }, [])
    

 


    return (
        <div className={`${classes.master_product_type_inner_config_wrapper}`}>

            <div className={`${classes.personal_employee_info_input_col_group}`}>
            <MasterDataRegInput input={
                                <Select value={Employee['emp_job_position'] ? Employee['emp_job_position'] : ''} onChange={(data)=>{UpdateEmployeeInputDropDown('emp_job_position', data)}} name={"emp_job_position"} options={JobPositionList} placeholder={"Job Position"} isSearchable={true} styles={Custom_DD_Style} required ={true} /> } />
            
            <MasterDataRegInput input={
                                <Select value={Employee['emp_job_type'] ? Employee['emp_job_type'] : ''} onChange={(data)=>{UpdateEmployeeInputDropDown('emp_job_type', data)}} name={"emp_job_type"} options={EmploymentType} placeholder={"Employment Type"} isSearchable={true} styles={Custom_DD_Style} required ={true} /> } />
            {Employee['emp_job_type'] && Employee['emp_job_type'].value === "Contract" && 
                <MasterDataRegInput tip={"Day Count/ How Long this contract agreement sustain number of day"} input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} name='emp_contract_day_count' placeholder='Contract Day Count' type="number" min={1} autoComplete='off' required />}/>
            }
            <MasterDataRegInput tip={"Employment Start Date"} input={ <input value={Employee['emp_start'] ? Employee['emp_start'] : ''} onChange={(e)=>{UpdateEmployeeInput(e)}} name='emp_start' type="date" autoComplete='off' required />}/>

            <MasterDataRegInput input={
                                <Select value={Employee['emp_job_shift'] ? Employee['emp_job_shift'] : ''} onChange={(data)=>{UpdateEmployeeInputDropDown('emp_job_shift', data)}} name={"emp_job_shift"} options={WorkspaceShiftList} placeholder={"Job Shift"} isSearchable={true} styles={Custom_DD_Style} required ={true} /> } />

            </div>

            <div className={`${classes.personal_employee_info_input_col_group}`}>
            <MasterDataRegInput tip={"Maximum Number of Annual PTO - 16 is usual"} input={ <input value={Employee['emp_max_pto'] ? Employee['emp_max_pto'] : ''} onChange={(e)=>{UpdateEmployeeInput(e)}} name='emp_max_pto' placeholder='Annual PTO' type="number" min={0} autoComplete='off' required />}/>
            <MasterDataRegInput tip={"Max Cash Credit in percent in reference to salary"} input={ <input value={Employee['emp_max_credit'] ? Employee['emp_max_credit'] : ''} onChange={(e)=>{UpdateEmployeeInput(e)}} name='emp_max_credit' placeholder='Max Cash Credit in %' type="number" min={0} max={100} autoComplete='off' required />}/>
            <MasterDataRegInput input={
                                <Select value={Employee['emp_job_bank'] ? Employee['emp_job_bank'] : ''} onChange={(data)=>{UpdateEmployeeInputDropDown('emp_job_bank', data)}} name={"emp_job_bank"} options={ActiveBankList} placeholder={"Service Bank"} isSearchable={true} styles={Custom_DD_Style} required ={true} /> } />
            <MasterDataRegInput input={ <input value={Employee['emp_account_number'] ? Employee['emp_account_number'] : ''} onChange={(e)=>{UpdateEmployeeInput(e)}} name='emp_account_number' placeholder='Account Number' type="text" autoComplete='off' required />}/>
            <MasterDataRegInput tip={"Make sure it is (Gross-Salary) NOT (Net-Salary)"} input={ <input value={Employee['emp_salary'] ? Employee['emp_salary'] : ''} onChange={(e)=>{UpdateEmployeeInput(e)}} name='emp_salary' placeholder='Gross Salary' type="number" min={0} step={'any'} autoComplete='off' required />}/>
            </div>

        </div>
    )
}
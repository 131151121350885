import classes from './StockApprovedArchive.module.css'

import { useEffect } from 'react'
import { useState } from 'react'

import StockApprovedBranchBundle from './StockApprovedBranchBundle/StockApprovedBranchBundle'
import { FetchWorkspaceBranchAll } from '../../SharedComp/API/Workspace/API_FE_Workspace';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { useAuth } from '../../AuthProvider'

export default function StockApprovedArchive() 
{

    const [CompanyBranchs, SetCompanyBranchs] = useState([]);
    const [IncomingLoading, SetIncomingLoading] = useState(false);
    const [SelectedBranch, SetSelectedBranch] = useState(null);
    const auth = useAuth();

    const FetchCompanyBranch = async()=>
    {
        SetIncomingLoading(true);
        try 
        {
            const main_res = await FetchWorkspaceBranchAll(auth.token);
            SetCompanyBranchs(main_res)
            const __target_branch = main_res.find((item)=>item.BranchID === auth.activeWorkShop);
            SetSelectedBranch(__target_branch)
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }

    useEffect(()=>{
        FetchCompanyBranch();
    }, [auth.activeWorkShop])



    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>


            <div className={`${classes.avial_workspace_data_budle}`}>
                {!IncomingLoading && SelectedBranch ? <StockApprovedBranchBundle WorkspaceData={SelectedBranch} /> : <LoadingPlaceholder/>}
            </div>

        </div>
    )
}
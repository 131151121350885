import classes from './SpecialOrderDetailView.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { useRef } from 'react'
import { useState } from 'react'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'

import { QuerySpecialOrderDetail } from '../../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'

const human_readable_format = (val)=> 
{
    if(!val) { return ''; }
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function SpecialOrderDetailView({OpenController, IsProdCard = false, DV_TargetData}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [OrderDetail, SetOrderDetail] = useState();

    const CloseModal = () => {OpenController(false)};

    const FetchOrderDetail = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append('ck_product_id', IsProdCard ? DV_TargetData.CKPRBUF_CkProdID : DV_TargetData.CKDPC_ProdCKID);
            fd.append('order_pord_hash', IsProdCard ? DV_TargetData.CKPRBUF_OrderProdHash : DV_TargetData.CKDPC_OrderProdHash);

            const main_res = await QuerySpecialOrderDetail(fd, auth.token);
            SetOrderDetail(main_res[0]);
        }catch(err) {

        }
    }

    

    useEffect(()=>{
        if(DV_TargetData) 
        {
            if(DV_TargetData.KOPREOPKWS_IsSpecial !== "Yes") 
            {
                notify.ShowError("Invalid data parameter!");
                CloseModal();
            }
            // FetchOrderDetail();
        }
    }, [DV_TargetData])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Special Order <span className={`${data_win.data_create_name}`}>{DV_TargetData.CKP_ItemName}</span> Preview
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form action="POST">
                                    <div className={`${classes.form_wrapper}`}> 

                                        <div className={`${classes.master_prod_type_config_wrapper}`}>

                                            <div className={`${classes.deatil_patch_wrapper}`}>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Order Received By</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.FirstName} {DV_TargetData?.LastName}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Customer Name</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROCI_Name === '' ? "Unknown" : DV_TargetData?.PROCI_Name}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Customer Phone</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROCI_Phone === '' ? "Unknown" : DV_TargetData?.PROCI_Phone}</span></li>
                                            </div>
                                            
                                            <div className={`${classes.deatil_patch_wrapper}`}>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Scripture</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROPPI_Scripture === ''? "Unknown" : DV_TargetData?.PROPPI_Scripture}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Cashier Note</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROPPI_OrderDetail === ''? "Unknown" : DV_TargetData?.PROPPI_OrderDetail}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Custom Product Color</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROPPI_Color === ''? "Unknown" : DV_TargetData?.PROPPI_Color}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Custom Kg Request</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROPPI_KGRequest === null ? "Unknown" : human_readable_format(DV_TargetData?.PROPPI_KGRequest)} KG</span></li>
                                            </div>
                                            

                                                
                                        </div>
                                            
                                    </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_save}`}>Okay</button>
                                </div>
                            </form>
                </div>
            </div>

        </div>
    )
}
import classes from './EmpPenality.module.css'
import { CheckCircle, Money, SpinnerGap, UserCircleGear } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import UIButton from '../../../../Components/UIButton/UIButton';


import PerformCashPenality from './PerformCashPenality/PerformCashPenality';
import PerformOtherPenality from './PerformOtherPenality/PerformOtherPenality';

import { useTargetEmployee } from '../SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import { QueryAllEmployeeCashPenality, QueryEmployeeAllNonCashPenality } from '../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeePenality';
import { useAuth } from '../../../../../AuthProvider';

import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'; 

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function EmpPenality() 
{

    const sharedData = useTargetEmployee();
    const auth = useAuth();
    // Penality Filter
    const [ViewCashPenaltiy, SetViewCashPenaltiy] = useState(true);

    const [OpenPerformCashPenalityView, SetOpenPerformCashPenalityView] = useState(false);
    const [OpenPerformOtherPenalityView, SetOpenPerformOtherPenalityView] = useState(false);

    const [EmployeeCashPenalityList, SetEmployeeCashPenalityList] = useState([]);
    const [EmployeeNonCashPenalityList, SetEmployeeNonCashPenalityList] = useState([]);


    const GetEmployeeCashPenality = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_ID);
            const main_res = await QueryAllEmployeeCashPenality(fd, auth.token);
            SetEmployeeCashPenalityList(main_res);
        }catch(err) 
        {

        }
    }
    const GetEmployeeNonCashPenality = async()=>
    {
        try
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_ID);
            const main_res = await QueryEmployeeAllNonCashPenality(fd, auth.token);
            SetEmployeeNonCashPenalityList(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(() => { 
           
        GetEmployeeCashPenality();

    }, [OpenPerformCashPenalityView])

    useEffect(() => { 
           
        GetEmployeeNonCashPenality();

    }, [OpenPerformOtherPenalityView])







    return (
        <div className={`${classes.emp_penality_main_wrapper}`}>

            {OpenPerformCashPenalityView && <PerformCashPenality OpenController={SetOpenPerformCashPenalityView} />}
            {OpenPerformOtherPenalityView && <PerformOtherPenality OpenController={SetOpenPerformOtherPenalityView} />}

            <div className={`${classes.emp_local_penality_nav}`}>
                <button onClick={() => {SetOpenPerformCashPenalityView(true)}}>Cash Penality</button>
                <button onClick={() => {SetOpenPerformOtherPenalityView(true)}} >Other Penality</button>
            </div>

            <div className={`${classes.emp_penality_inner_wrapper}`}>

                <div className={`${classes.emp_penality_inner_content}`}>
                    <div className={`${classes.emp_penality_inner_content_wrapper}`}>
                        <div className={`${classes.emp_cash_penality_history}`}>

                            <div className={`${classes.emp_penality_demotion_header}`}>
                                <div>
                                    <h2>Penality Archive for <span>{sharedData.Core.JAKSBE_FullName}</span></h2>
                                </div>
                                <div className={`${classes.emp_penality_demotion_header_action}`}>
                                    <UIButton Icon={<Money weight='bold'/>} Variant={ViewCashPenaltiy ? "Primary" : "Secondary"} onClick={() => {SetViewCashPenaltiy(true)}} label={ViewCashPenaltiy ? "Cash Related" : "Show Cash Related"}/>
                                    <UIButton Icon={<UserCircleGear weight='bold'/>} Variant={!ViewCashPenaltiy ? "Primary" : "Secondary"} onClick={() => {SetViewCashPenaltiy(false)}} label={!ViewCashPenaltiy ? "Other Penality" : "Show Other Penality"}/>
                                </div>
                            </div>

                            <div className={`${classes.emp_penality_list}`}>

                                {ViewCashPenaltiy &&
                                    EmployeeCashPenalityList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span><TimeAgo show_suffix={true} date={item.JAKSEOPPC_PenalityDate}/></span>
                                            <span>{currency_format(item.JAKSEOPPC_PenalityAmount)}</span>
                                            <span>{item.JAKSEOPPC_Reason}</span>
                                            <span className={`${classes.action_preview_wrapper}`}>{item.JAKSEOPPC_IsApproved === "No" ? <UIButton Variant='Danger' onClick={()=>{}} Icon={<SpinnerGap weight='bold'/>} label={"Not yet approved"}/> : <UIButton onClick={()=>{}} Icon={<CheckCircle weight='bold'/>} label={"Approved"}/>}</span>
                                        </li>
                                    ))
                                }

                                {!ViewCashPenaltiy &&
                                    EmployeeNonCashPenalityList.map((item, idx)=>(
                                        <li key={idx}>
                                            <span><TimeAgo show_suffix={true} date={item.JSKSEOPPNC_PenalityDate}/></span>
                                            <span>{item.HRMPT_Name}</span>
                                            <span><strong>{item.JSKSEOPPNC_Reason}</strong></span>
                                            <span className={`${classes.action_preview_wrapper}`}> <UIButton Icon={<CheckCircle weight='bold'/>} onClick={()=>{}} label={"Action Taken"}/> </span>
                                        </li>
                                    ))
                                }
                               
                            </div>

                        </div>
                    </div>
                </div> 

            </div>

        </div>
    );
}
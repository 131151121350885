import classes from './ViewManageInvoice.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {ReverseProductEntry} from '../../../../../SharedComp/API/ShopShelf/API_FE_MerchShelves'

import UIButton from '../../../../Components/UIButton/UIButton'



//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import { ArrowCounterClockwise } from '@phosphor-icons/react'
import { useDAControl } from '../../../../../DAControlContext'


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function ViewManageInvoice({OpenController, InvoicePackage})
{
    const auth = useAuth();
    const notify = useToast();

    const DAControl = useDAControl();

    const [Loading, SetLoading] = useState(false);
    const [InternalInvoicePackage, SetInternalInvoicePackage] = useState([]);

    function CloseModal() { OpenController(false); }

    useEffect(()=>{

        if(InvoicePackage.length > 0) 
        {
            SetInternalInvoicePackage(InvoicePackage);
        } else 
        {
            console.log(InvoicePackage);
        }

    }, [InvoicePackage])

    const OpenImage = (url) => {
        window.open(url, '_blank');
      };

    const HandleEntryReverse = async(hash_id, product_id, workspace_id, _id)=>
    {
        if(InternalInvoicePackage.length === 1) 
        {
            notify.ShowWarn("Since you only have one transcation inside this invoice, revresing the whole invoice is the only option!");
            return;
        }
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("hash_id", hash_id);
            fd.append("product_id", product_id);
            fd.append("branch_id", workspace_id);
            fd.append("entry_id", _id);
            
            const main_res = await ReverseProductEntry(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            CloseModal();
        }catch(err)
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    <span className={`${data_win.data_create_name}`}>Merchandise Invoices</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>

                <div className={`${classes.invoice_view_body_wrapper}`}>

                    <div className={`${classes.main_data_entry_view}`}>

                        <div className={`${classes.entry_header}`}>

                            <li><span>Vender:</span> <span>{InternalInvoicePackage[0]?.Vname}</span></li>
                            <li><span>Vender Tin:</span> <span>{InternalInvoicePackage[0]?.Vtin}</span></li>
                            <li><span>Transcation ID:</span> <span>{InternalInvoicePackage[0]?.SMPI_Hash}</span></li>

                        </div>

                        <div className={`${classes.entry_list}`}>
                            {
                                InternalInvoicePackage.map((item, idx)=>(
                                    <li key={idx}>
                                        <span>{item.CMMP_Name}</span>
                                        <span>In: <span className={`${classes.price_teller}`}>{currency_format(item.SMPI_UnitPrice)}</span></span>
                                        <span>Sp: <span className={`${classes.price_teller}`}>{currency_format(item.CMMP_Price)}</span></span>
                                        <span>Qt:   {human_readable_format(item.SMPI_Quantity)}</span>
                                        <span><UIButton Variant='Danger' Icon={<ArrowCounterClockwise weight='bold'/>} label={"Reverse"} onClick={()=>{
                                            DAControl.Trigger(
                                                `Reverse Single Entry`,
                                                `You are about to reverse merchandise entry, this will deduct '${item.SMPI_Quantity}' from  branch shelf...`,
                                                true,
                                                8,
                                                async (data)=>
                                                {
                                                    if(data)
                                                    {
                                                        await HandleEntryReverse(item.SMPI_Hash, item.SMPI_ProductID, item.SMPI_WorkshopID, item.SMPIID);
                                                    }
                                                }
                                            )
                                        }}/></span>
                                    </li>
                                ))
                            }
                        </div>

                        

                    </div>

                    <div className={`${classes.invoice_preview}`}>
                            <img onClick={()=>{OpenImage(getBaseURL() + InternalInvoicePackage[0]?.SMPI_Attachment)}} src={getBaseURL() + InternalInvoicePackage[0]?.SMPI_Attachment} alt="Attachment" />
                    </div>

                </div>
                    
                    
                <div className={`${data_win.form_action_button_wrapper}`}>
                    {/* <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button> */}
                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                </div>
            </div>
        </div>

    </div>
    )
}


import classes from './NotificationConfig.module.css'



export default function NotificationConfig({UpdateReq, ConfigData}) 
{


    return (
        <div className={`${classes.payroll_config_wrapper}`}>

            <div className={`${classes.part_of_option}`}>
                <ul>Cash Collection - Receiver</ul>
                <div className={`${classes.setting_items_config}`}>
                    
                </div>  
            </div>

        </div>
    )
}
import classes from './ManageMerchInvoices.module.css'
import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenNib, faBan } from '@fortawesome/free-solid-svg-icons';
import { QueryAvialMerchInvoiceByBranch, ReverseMerchInvoice } from '../../../SharedComp/API/ShopShelf/API_FE_MerchShelves'
import ViewManageInvoice from './Component/ViewManageInvoice/ViewManageInvoice'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'

import TimeAgo from '../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../Components/UIButton/UIButton'
import { useDAControl } from '../../../DAControlContext';
 


export default function ManageMerchInvoices() 
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    const [RefreshView, SetRefreshView] = useState(false);

    const [InvoiceItem, SetInvoiceItem] = useState([]);
    const [ShelfItemsOriginal, SetShelfItemsOriginal] = useState([]);

    const [OpenViewManageInvoice, SetOpenViewManageInvoice] = useState(false);

    const [InvoicePackageData, SetInvoicePackageData] = useState(new Map());
    const [ProcessInvoicePackage, SetProcessInvoicePackage] = useState([]);



    const ProcessInputInvoice = async(data)=>
    {
        const list_map = new Map();
        const RC_Array = [];
        for(let i = 0; i < data.length; i++) 
        {
            const exists = RC_Array.some(item => item.SMPI_Hash === data[i].SMPI_Hash);
            if(!exists) 
            {
                RC_Array.push(data[i]);
            }
            if(list_map.has(data[i].SMPI_Hash)) 
            {
                const _update_list = list_map.get(data[i].SMPI_Hash); 
                _update_list.push(data[i]);
                list_map.set(data[i].SMPI_Hash, _update_list);
            } else 
            {
                const init_array = [];
                init_array.push(data[i]);
                list_map.set(data[i].SMPI_Hash, init_array);
            }
        }

        SetInvoiceItem(RC_Array);
        SetShelfItemsOriginal(RC_Array);
        SetInvoicePackageData(new Map(list_map));
    }

    const QueryMerchInvoice = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);

            const main_res = await QueryAvialMerchInvoiceByBranch(fd, auth.token);
            ProcessInputInvoice(main_res);

        }catch(err) 
        {
            notify.ShowError("Failed to query Merch Invoices!")
        }
    }
    function truncateString(str, length) {
        if (str.length > length) {
          return str.slice(0, length) + '...'; 
        } else {
          return str;
        }
      }
      


    useEffect(()=>{
        QueryMerchInvoice();
    }, [OpenViewManageInvoice, RefreshView]);


    const HandleFilterByHash = async(key)=>
    {
        const valid_list = [];
        for(let i = 0; i < ShelfItemsOriginal.length; i++) 
        {
            if(ShelfItemsOriginal[i].SMPI_Hash.includes(key.toLowerCase()))
            {
                valid_list.push(ShelfItemsOriginal[i]);
            }
        }
        SetInvoiceItem(valid_list);
    }


    const HandleReverseInvoiceEntry = async(hash, workspace_id)=>
    {
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("hash_id", hash);
            fd.append("branch_id", workspace_id);
            
            const main_res = await ReverseMerchInvoice(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshView(!RefreshView);
        }catch(err)
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    const HandleManageInvoiceClick = async(_hash)=>
    {
        if(!InvoicePackageData.has(_hash))
        {
            notify.ShowError("Failed to locate data!")
            return;
        }
        SetProcessInvoicePackage(InvoicePackageData.get(_hash));
        SetOpenViewManageInvoice(true);
    }


    return(
        <div className={`${classes.shelves_master_wrapper}`}>

            {OpenViewManageInvoice && <ViewManageInvoice InvoicePackage={ProcessInvoicePackage} OpenController={SetOpenViewManageInvoice}/>}

            <div className={`${classes.data_inner}`}>
                
                    <div className={`${classes.data_table_wrapper}`}>

                        <DataViewTableProxy 
                            ShowNewButton={false}
                            IsEmpty={InvoiceItem.length === 0}
                            OnFilter={HandleFilterByHash}
                            HeaderName={
                                <div>
                                    <h2><b>Merchandise</b> Invoices</h2>
                                </div>
                            }
                            DataArray={
                                <div>
                                    {
                                        InvoiceItem.map((item, idx)=>(
                                            <li key={item.CMMP_Name + idx}>
                                                <span>{truncateString(item.SMPI_Hash, 10)}</span>
                                                <span>VENDOR: {item.Vname}</span>
                                                <span><TimeAgo show_suffix={true} date={new Date(item.SMPI_Date)}/></span>
                                                <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                            <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Reverse Merchandise Product Entry`, 
                                                `You are about to reverse merchandise Invoice entry '${item.SMPI_Hash}'. 
                                                Any active Order That are linked with this Item will be Terminated! This action is IRREVERSIBLE!`, 
                                                item.SMPI_Hash,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;

                                                     await HandleReverseInvoiceEntry(item.SMPI_Hash, item.SMPI_WorkshopID);
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Reverse</span>
                                            </button>
                                            <button onClick={()=>{HandleManageInvoiceClick(item.SMPI_Hash)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>
                                        </span>
                                            </li>
                                        ))
                                    }
                                    
                                </div>
                            }
                        />

                    </div>
            </div>

        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const ReturnProductionOrderToStock = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'po/return-production-stack-item-to-inventory', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
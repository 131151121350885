import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const CreateEmployeeAllowance = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/create-new-allowance', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryEmployeeAllowanceByEmployeeID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/query-all-allowance-by-employee-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeEmployeeAllowance = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/purge-allowance-by-employee-id', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
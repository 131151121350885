import classes from './ActiveOrder.module.css'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../../../Components/UIButton/UIButton'
import { BoxArrowDown, CheckCircle, Empty, MagnifyingGlass, SealCheck, SpinnerGap, Tray } from '@phosphor-icons/react'

import CloseActiveOrder from './Component/CloseActiveOrder/CloseActiveOrder'
import { useState } from 'react'

export default function ActiveOrder({DataList}) 
{

    const [OpenCloseActiveOrderDiag, SetOpenCloseActiveOrderDiag] = useState(false);
    const [SelectedCustomerInfo, SetSelectedCustomerInfo] = useState(null);

    const [DataCopy, SetDataCopy] = useState(DataList);

    const HandleOpenActiveOrderDiag = async(data)=>
    {
        SetSelectedCustomerInfo(data);
        SetOpenCloseActiveOrderDiag(true);
    }

    const FilyerByName = (key_name)=>
    {
        const valid_list = [];
        for(let i = 0; i < DataList.length; i++) 
        {
            if(
                DataList[i].PROCI_Name.toLowerCase().includes(key_name.toLowerCase()) || 
                DataList[i].PROCI_Phone.includes(key_name)) 
            {
                valid_list.push(DataList[i]); 
            }
        }

        SetDataCopy(valid_list);
    }
    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>

            {OpenCloseActiveOrderDiag && <CloseActiveOrder CustomerInfo={SelectedCustomerInfo} OpenController={SetOpenCloseActiveOrderDiag}/>}


            <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilyerByName(e.target.value)}} autoComplete='off' placeholder='Name, Telephone' type="text" /> 
                        <span><MagnifyingGlass weight='bold'/></span>
                    </div>
            </div>
            
            <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>Customer Name</span>
                        <span>Ordered Date</span>
                        <span>Expected</span>
                        <span>Cashier</span>
                        <span>Order State</span>
                        <span>Action</span>
                    </li>

                    <div className={`${classes.list_data_wrapper}`}>
                        {
                            DataCopy.map((item, idx)=>(
                                <li key={idx + item.PROCI_Name}>
                                    <span>{item.PROCI_Name}</span>
                                    <span><TimeAgo show_suffix={true} date={item.PROCI_OrderDate}/></span>
                                    <span><TimeAgo show_suffix={true} date={item.PROCI_OrderArrivalDate}/></span>
                                    <span>{item.FirstName} {item.LastName}</span>
                                    <span className={`${classes.action_button_wrapper}`}>
                                        <UIButton 
                                            onClick={()=>{}} 
                                            Variant={item.PROPPI_ProductionState === "Ready" ? "Primary" : "Secondary"} 
                                            label={item.PROPPI_ProductionState} 
                                            Icon={item.PROPPI_ProductionState === "Ready" ? <SealCheck weight='bold'/> : <SpinnerGap weight='bold'/> } />
                                    </span>
                                    
                                    <span className={`${classes.action_button_wrapper}`}>
                                        {/* <UIButton onClick={()=>{}} Icon={<Empty weight='fill'/>} Variant='Danger'  label={"Abort"}/> */}
                                     {item.PROPPI_ProductionState === "Ready" ?  <UIButton onClick={()=>{HandleOpenActiveOrderDiag(item)}} Icon={<CheckCircle weight='fill'/>} label={"Process"}/> : '--'}  
                                    </span>
                                </li>
                            ))
                        }
                        {
                            DataCopy.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><Tray fontSize={30}/></ul>
                                <ul>No Data</ul>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
import classes from './OrderGroupManager.module.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrderSingluarProd from '../OrderSingluarProd/OrderSingluarProd'
import { faBan, faCircleCheck } from '@fortawesome/free-solid-svg-icons'



import {FetchSavedProductionProductOrder, RejectPoRequest} from '../../../../api'
import { useDAControl } from '../../../../DAControlContext'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

export default function OrderGroupManager({OrderData, DataAcceptCallback, DataRefersh}) 
{
    const [OrderProducts, SetOrderProducts] = useState([]);
    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(false);

    function TerminateString(input) {
        if (input.length > 15) {
            return input.slice(0, 15) + '...';
        }
        return input;
    }

    const FetchOrderProducts = async()=>{
        SetLoading(true);
        try 
        {
            const res = await FetchSavedProductionProductOrder(OrderData.PO_Hash);
            SetOrderProducts(res);
            SetLoading(false);

        }catch(err) 
        {
            SetLoading(false);
        }
    }
    const HandleRejection = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("order_hash", OrderData.PO_Hash);
            fd.append("branch_id", auth.activeWorkShop);
            const res = await RejectPoRequest(fd, 'token');
            notify.ShowSuccess(res.Msg);
            DataRefersh();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    useEffect(()=>{
        FetchOrderProducts();
    }, [])
    

    return (
        <div className={`${classes.group_batch_manager_wrapper}`}>

            <div className={`${classes.group_batch_header_wrapper}`}>
                    <div className={`${classes.order_has_wrapper}`}>
                        <h2>POSR-Hash <span className={`${classes.order_hash_value}`}>{TerminateString(OrderData.PO_Hash)}</span></h2>
                    </div>
                    <div className={`${classes.order_action_wrapper}`}>
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_RejectStockRequest && <button onClick={()=>{
                                        DAControl.Trigger(`Reject PO Stock Request`, 
                                        `You are about to reject stock item request for "Production Order", If you do this the Production Manager need to Re-order. And it gone be all you fault so with that in mind...`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                HandleRejection();
                                            }
                                        }   
                                    );}} className={`${classes.reject_request}`}><span><FontAwesomeIcon icon={faBan}/></span> <span>Reject</span></button> }
                       {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ApproveStockRequest && <button onClick={()=>{DataAcceptCallback(OrderData)}} className={`${classes.accept_request}`}><span><FontAwesomeIcon icon={faCircleCheck}/></span> <span>Accept</span></button>}
                    </div>
            </div>

            <div className={`${classes.group_manager_list_wrapper}`}>
                {
                    OrderProducts.map((item, idx)=>(
                        <OrderSingluarProd Target={item}/>
                    ))
                }
            </div>

            <div className={`${classes.batch_group_footer_wrapper}`}>
                <div className={`${classes.footer_action_wrapper}`}>
                    <li>{OrderData.PO_TargetShift} Shift</li>
                    <li>Total {OrderProducts.length} Orders</li>
                </div>
            </div>
        </div>
    )
}
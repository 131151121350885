import { useState } from 'react';
import { useAuth } from '../../../AuthProvider'
import classes from './ChefsDashboard.module.css'
import RecipeCard from './Component/RecipeCard/RecipeCard';

import {QueryChefsOrderDashboard, QueryLocalStockBuffer, QueryProductionReadyItems} from '../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { useEffect } from 'react';
import { useToast } from '../../../ToastContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import IngStockRequestProcessor from './Component/IngStockRequestProcessor/IngStockRequestProcessor';
import ChefsProductionStackCard from './Component/ChefsProductionStackCard/ChefsProductionStackCard';
import { ArrowCounterClockwise, ArrowLineRight, CheckCircle, Gear, StackMinus, Warning } from '@phosphor-icons/react';



import UIButton from '../../Components/UIButton/UIButton';
import { useGNP } from '../../../GlobalNotificationProvider';

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function ChefsDashboard() 
{

    const auth = useAuth();
    const notify = useToast();
    const GNP = useGNP();
    const [OrderList, SetOrderList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);

    const [OpenStockBuffer, SetOpenStockBuffer] = useState(false);
    const [OpenIngStockProcess, SetOpenIngStockProcess] = useState(false);
    const [LocalBufferStock, SetLocalBufferStock] = useState([]);
    const [LocalBufferCount, SetLocalBufferCount] = useState(0);

    const [ProdReadyItems, SetProdReadyItems] = useState([]);

    const [LocalNav, SetLocalNav] = useState([0, 1, 2]);
    const [ActiveNav, SetActiveNav] = useState(0);

    const HandleNavSwitch = async(idx)=>
    {
        SetActiveNav(idx);
    }
    const QueryMyOrder = async()=>
    {
        try 
        {
            const fd = new FormData();

            fd.append("chef_id", auth.user.WorkspaceID);
            fd.append("account_id", auth.user.UserAccountID);
            fd.append("workspace_id", auth.activeWorkShop);
 
            const main_res = await QueryChefsOrderDashboard(fd, auth.token);
            SetOrderList(main_res);

        }catch(err) 
        {
            notify.ShowError("Failed to query active orders.");
        }
    }
    const QueryLocalSrockBuffer = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.user.SourceWorkspace)
            fd.append("chef_id", auth.user.UserAccountID)

            const main_res = await QueryLocalStockBuffer(fd, auth.token);
            SetLocalBufferCount(main_res.length);
            SetLocalBufferStock(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const FetchProductionReadyItems = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.user.SourceWorkspace)
            fd.append("chef_id", auth.user.WorkspaceID);

            const main_res = await QueryProductionReadyItems(fd, auth.token);
            SetProdReadyItems(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{

        if(auth.user) 
        {
            QueryLocalSrockBuffer();
            QueryMyOrder();
            FetchProductionReadyItems();
        }
     
    }, [RefreshView, auth.user, ActiveNav, GNP.EVENT_ChefIncomingDispatchedOrder])

    const DataRefersh = ()=>
    {
        SetRefreshView(!RefreshView);
    }


    return (
        <div className={`${classes.chefs_dashboard_main_wrapper}`}>

           {OpenIngStockProcess &&  <IngStockRequestProcessor OpenModal={SetOpenIngStockProcess}/> }

{/* Kicthen Stock Buffer */}

            <div className={`${OpenStockBuffer ? classes.kicthen_stock_buffer_wrapper : classes.kicthen_stock_buffer_wrapper_hide}`}>

                <div className={`${classes.kicthen_stock_buffer_header}`}>
                    <h3>Kitchen Virtual Store</h3>
                    <button onClick={()=>{SetOpenStockBuffer(false)}}><FontAwesomeIcon icon={faTimesCircle}/></button>
                </div>

                <div className={`${classes.kicthen_store_buffer_list}`}>
                    {
                        LocalBufferStock.map((item, idx)=>(
                            <li key={item.IVItemName + idx}>
                                <span className={`${classes.recipe_card_tag}`}>{item.IVItemName}</span>
                                <span className={`${classes.recipe_card_val}`}>
                                    {
                                    item.UomDescription === "KG" ?  currency_format((item.KSBID_Quantity * 1000)) 
                                        : currency_format(item.KSBID_Quantity)
                                    } {item.UomDescription === "KG" ? "Gram" : item.UomDescription} </span>

                                    <span className={`${classes.action_button_wrapper}`}>
                                       {item.KSBID_Quantity > 1 ? 
                                       <UIButton onClick={()=>{}} label={"Stable"} Icon={<CheckCircle weight='bold'/>}/> 
                                    : <UIButton onClick={()=>{}} Variant='Danger' label={"Re-Order"} Icon={<Warning weight='fill'/>}/>} 
                                    </span>
                            </li>
                        ))
                    }
                </div>

            </div>

{/* End Kicthen Stock Buffer */}


            <div className={`${classes.chefs_dashboard_header_wrapper}`}>
                <div className={`${classes.chefs_dashboard_wrapper_header_nav}`}>
                    
                    <div className={`${classes.action_button_nav_wrapper}`}>
                        <button onClick={()=>{HandleNavSwitch(0)}} className={`${LocalNav[0] === ActiveNav ? classes.active_nav : '' }`}>Request Queue</button>
                        <button onClick={()=>{HandleNavSwitch(1)}} className={`${LocalNav[1] === ActiveNav ? classes.active_nav : '' }`}>Production Stack</button>
                        <button onClick={()=>{SetOpenIngStockProcess(true)}}>Direct Stock Request</button>
                        <button onClick={()=>{SetOpenStockBuffer(!OpenStockBuffer)}}>
                            {(!OpenStockBuffer && (LocalBufferCount > 0)) && <span className={`${classes.local_buffer_count}`}>{LocalBufferCount}</span> }
                           Virtual Store</button>
                        {/* <button onClick={()=>{HandleNavSwitch(2)}} className={`${LocalNav[2] === ActiveNav ? classes.active_nav : '' }`}>Options</button> */}
                    </div>

                    <div className={`${classes.chef_setting_action_button}`}>
                        {/* <button><Gear/></button> */}
                    </div>
                </div>
            </div>

            
            <div className={`${classes.chefs_dashboard_inner_main_wrapper}`}>

              {ActiveNav === 0 &&   <div className={`${classes.chefs_dashboard_recipe_card_list}`}>
                    {
                        OrderList.map((item, idx)=>(
                            <RecipeCard TempStockStorage={LocalBufferStock}  DataRef={DataRefersh} TargetData={item} key={item.CKP_ItemName + idx} />
                        ))
                    }
                    {
                        OrderList.length === 0 && <div className={`${classes.request_queue_halo}`}>
                                <h1><ArrowLineRight /></h1>
                                <h2>No Production Request At the Moment!</h2>
                        </div>
                    }
                </div> }

                {
                    ActiveNav === 1 && <div className={`${classes.chefs_dashboard_production_card_list}`}>
                            {
                                ProdReadyItems.map((item, idx)=>(
                                    <ChefsProductionStackCard DataRef={DataRefersh} TargetData={item} key={item.CKP_ItemName + item.KOPRI_ID + idx}/>
                                ))
                            }

                            {
                                ProdReadyItems.length === 0 && <div className={`${classes.request_queue_halo}`}>
                                        <h1><StackMinus /></h1>
                                        <h2>Your Production stack is empty, Enjoy!</h2>
                                </div>
                            }
                    </div>
                }


            </div>


        </div>
    )
}
import { ChefHat } from '@phosphor-icons/react'
import classes from './ChefViewCard.module.css'

import { useDAControl } from '../../../../DAControlContext';
import { useToast } from '../../../../ToastContext';
import {PurgeAssignedChef} from '../../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

export default function ChefViewCard({ChefData, UpdateView}) 
{

    const DAControl = useDAControl();
    const notify = useToast();

    const HandlePurge = async()=>
    {
        notify.ShowLoad("Processing your request....", 10);
        setTimeout(async()=>{
            try
            {

                const fd = new FormData();
                fd.append("emp_data", JSON.stringify(ChefData));
                fd.append("account_user_id", ChefData.UserAccountID);

                const main_res = await PurgeAssignedChef(fd, 'token');
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                UpdateView();

            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }, 1500)

    }


    return (
        <div className={`${classes.chef_card_view_wrapper}`}
        onDoubleClick={()=>{
            DAControl.Trigger(
                `Purge Assign Chef`,
                `You are about Purge Assigned Chef ${ChefData.JAKSBE_FullName}. after this action the chef will not receive order form the dipstacher... so with this in mind `,
                true,
                8,
                (data)=>{
                    if(data) 
                    {
                        HandlePurge();
                    }
                }
            )
        }}
        >
            <div className={`${classes.chaef_image_wrapper}`}>
                <img src={getBaseURL() + ChefData.JAKBED_Photo} alt="" />
            </div>
            <div className={`${classes.chef_name_and_info}`}>
                <li className={`${classes.chef_name}`}> <ChefHat/> {ChefData.JAKSBE_FullName}</li>
                <li className={`${classes.chef_email}`}>{ChefData.JAKSBE_Email}</li>
                <li className={`${classes.chef_phone}`}>{ChefData.JAKSBE_PhoneNumber}</li>
            </div>
        </div>
    ) 
}
import classes from './PreviewEmployeeSupportingDocument.module.css'
import data_win from '../../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { getBaseURL } from '../../../../../../../../SharedComp/BaseUrlConfig'

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function PreviewEmployeeSupportingDocument({data_link, OpenController})
{
    const docs = [
        { uri: getBaseURL() + data_link }
      ];
    function CloseModal()
    {
        OpenController(false);
    }
    return(
        <div className={`${data_win.main_data_creator_wrapper} ${classes.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    <span className={`${data_win.data_create_name}`}>Document Preview @ JAK-ERP/Beta</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body} ${classes.data_body}`}>
                <DocViewer 
                documents={docs} 
                style={{ width: '100%', height: 400, overflow: 'scroll' }}
                config={{
                    header: {
                      disableHeader: false,
                      disableFileName: false,
                      retainURLParams: false,
                    },
                    csvDelimiter: ",", // "," as default,
                    pdfZoom: {
                      defaultZoom: 1, // 1 as default,
                      zoomJump: 0.1, // 0.1 as default,
                    },
                    pdfVerticalScrollByDefault: false, // false as default
                  }} 
                pluginRenderers={DocViewerRenderers} />
            
            </div>
        </div>

    </div>)
}
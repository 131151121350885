import { faPenNib, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './RoleManagment.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import CreateRole from './Component/CreateRole/CreateRole'
import EditRole from './Component/EditRole/EditRole'

import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp'
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig'

import UIButton from '../../Components/UIButton/UIButton'
import { ArrowClockwise, Pen, PlusCircle, Trash } from '@phosphor-icons/react'

import { UpdateMainMenuPackage } from '../../../SharedComp/API/UserAccess/API_FE_UserAccess'
import { MasterMenu } from '../../../SharedComp/MasterMenu'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'


export default function RoleManagment() 
{

    const [OpenEditRole, SetOpenEditRole] = useState(false);
    const [OpenCreateRole, SetOpenCreateRole] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);

    const [RoleList, SetRoleList] = useState([]);
    const [EditTargetRole, SetEditTargetRole] = useState();

    const auth = useAuth();
    const notify = useToast();


    function MsgCallback(res) 
    {
        SetOpenCreateRole(false);
        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }

    function LocalMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true);
    }

    useEffect(()=>{

        fetch(getBaseURL() + 'master-data/query_usr_roles', {
            method: 'POST'
        })
        .then(res => res.json())
        .then((data)=>{SetRoleList(data)})
        .catch(err => console.log(err));

    }, [RefeshList, OpenEditRole, OpenCreateRole])

    const HandleRoleManage = async(data)=>
    {
        SetEditTargetRole(data);
        SetOpenEditRole(true);
    }

    const SyncMenu = async()=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {

            const routePackageObject = {};

            MasterMenu.forEach((groupData, groupName) => {
                routePackageObject[groupName] = {
                    ValidRouteGroup: groupData.ValidRouteGroup,
                    Icon: groupData.Icon,
                    Config: {}
                };
    
                groupData.Config.forEach((config, route) => {
                    routePackageObject[groupName].Config[route] = {
                        HasAccess: config.HasAccess,
                        Route: config.Route,
                        Label: config.Label
                    };
                });
            });
    
            const serializedData = JSON.stringify(routePackageObject);

            const fd = new FormData();
            fd.append("package_menu", serializedData);

            const main_res = await UpdateMainMenuPackage(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);;

        }catch(err) 
        {
            notify.ShowErrorUpdate("Failed to Sync " + err.message, 10);
        }
    }


    return (
        <div className={`${classes.role_managmanet_main_wrapper}`}>

            {OpenEditRole && <EditRole TargetData={EditTargetRole} Close_CALLBACK={SetOpenEditRole}/>}
            {OpenCreateRole && <CreateRole Close_CALLBACK={SetOpenCreateRole} />}
            {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
            <div className={`${classes.role_managmanet_inner_wrapper}`}>

                <div className={`${classes.list_parent}`}>

                    <div className={`${classes.list_wrapper}`}>
                        
                        <div className={`${classes.list_action_but_wrapper}`}>
                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageRoles && <UIButton Icon={<PlusCircle/>} label={"Create"} onClick={(e)=>{SetOpenCreateRole(true)}} /> }
                        {/* {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageRoles &&    <UIButton Icon={<ArrowClockwise/>} label={"Sync"} onClick={()=>{SyncMenu()}}/> } */}
                                {/* <button onClick={(e)=>{SetOpenCreateRole(true)}}> <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> Create</button> */}
                                {/* <button onClick={(e)=>{SetOpenCreateRole(true)}}> <ArrowsClockwise/> Sync</button> */}
                        </div>

                        <div className={`${classes.list_col}`}>

                            {
                                RoleList.map((item, idx)=>(

                                    <li>
                                            <div className={`${classes.role_name}`}>
                                                {item.UARI_Name}
                                            </div>
                                            

                                            <div className={`${classes.bar_wrap}`}>

                                                <div className={`${classes.bar_wrap_out}`}>
                                                    <div className={`${classes.bar_wrap_in}`} style={{
                                                        width: `${item.UARI_AccessLevel}%`
                                                    }}>
                                                    </div>
                                                </div>

                                                <div>
                                                    <span className={`${classes.bar_value}`}>{item.UARI_AccessLevel}%</span>
                                                </div>


                                            </div>

                                            <div className={`${classes.role_action}`}>
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageUserAccount && <UIButton onClick={()=>{HandleRoleManage(item)}} label={"Manage"} Icon={<Pen/>} /> }
                                                {/* <UIButton onClick={()=>{  }} label={"Purge"} Variant='Danger' Icon={<Trash/>} /> */}
                                                
                                            </div>
                                    </li>

                                ))
                            }

                            

                           


                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    )
}
import classes from './TransferArchiveBranchBundle.module.css'
import TimeAgo from '../../../SharedComp/TimeElapsed/TimeAgo';
import { ArrowDown, ArrowDownRight, ArrowUp, FilePdf, MagnifyingGlass, SpinnerGap, Tray } from '@phosphor-icons/react'
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../AuthProvider';
import { useToast } from '../../../ToastContext';
import { QueryFinalBakeryProductRequestIncomingAll, QueryFinalBakeryProductRequestOutgoingAll } from '../../../SharedComp/API/FinalBakeryTransfer/FinalBakeryTransfer';
import UIButton from '../../Components/UIButton/UIButton';
import { ToEthiopianCalander } from '../../../SharedComp/Utility';

import TransferBakeryProductPrintPreview from './TransferBakeryProductPrintPreview/TransferBakeryProductPrintPreview';
import ProcessBakeryProductTransfer from './ProcessBakeryProductTransfer/ProcessBakeryProductTransfer';

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function TransferArchiveBranchBundle({TargetData}) 
{

    const auth = useAuth();
    const notify = useToast();


    const [DataPackage, SetDataPackage] = useState([]);
    const [Loading, SetLoading] = useState(false);
    const [InspectIncoming, SetInspectIncoming] = useState(true);
    const [OpenPrintPreview, SetOpenPrintPreview] = useState(false);
    const [TargetPreviewPackage, SetTargetPreviewPackage] = useState();
    const [ReceiveRequestKey, SetReceiveRequestKey] = useState(false);
    const [OpenProcessBakeryProductTranfer, SetOpenProcessBakeryProductTranfer] = useState(false);
    const [TranferredData, SetTranferredData] = useState();
    

    
    const QueryTransferPackageData_Incoming = async()=>
    {
        try 
        {
            SetLoading(true);
            const fd = new FormData();
            fd.append("target_branch_id", auth.activeWorkShop);
            const main_res = await QueryFinalBakeryProductRequestIncomingAll(fd, auth.token);
            SetDataPackage(main_res);
            SetInspectIncoming(true);
            SetLoading(false);
        }catch(err) 
        {
            notify.ShowError(err.message);
            SetLoading(false);
        }
    }
    const QueryTransferPackageData_Outgoing = async()=>
    {
        try 
        {
            SetLoading(true);
            const fd = new FormData();
            fd.append("target_branch_id", auth.activeWorkShop);
            const main_res = await QueryFinalBakeryProductRequestOutgoingAll(fd, auth.token);
            SetDataPackage(main_res);
            SetInspectIncoming(false);
            SetLoading(false);
        }catch(err) 
        {
            notify.ShowError(err.message);
            SetLoading(false);
        }
    }

    useEffect(()=>{
        
        QueryTransferPackageData_Outgoing();

    }, [auth.activeWorkShop, auth.user])


    const FilyerByName = (key_name)=>
    {
        const valid_list = [];
       

    }

    const OpenTargetItem = (data)=>
    {
        SetTargetPreviewPackage(data);
        SetOpenPrintPreview(true);
    }

    const FilterReceiveKeyInput = (input_data, ref_data)=>
    {
        const __ref_array = ref_data.TWFP_TransactionID.split('-');
        const __rec_key = __ref_array[0] + '-' + __ref_array[1];
        if(input_data === __rec_key) 
        {
            SetOpenProcessBakeryProductTranfer(true);
            SetTranferredData(ref_data);
        }
    }



    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>
            {OpenPrintPreview && <TransferBakeryProductPrintPreview TargetData={TargetPreviewPackage} OpenModal={SetOpenPrintPreview}/>}
            {OpenProcessBakeryProductTranfer && <ProcessBakeryProductTransfer TargetData={TranferredData} OpenController={SetOpenProcessBakeryProductTranfer}/>}

            <div className={`${classes.filter_workshop_by_name}`}>
                    {/* <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilyerByName(e.target.value)}} autoComplete='off' placeholder='Something' type="text" /> 
                        <span><MagnifyingGlass weight='bold'/></span>
                    </div> */}
                    <div className={`${classes.doc_view_action_button_wrapper}`}>
                        <UIButton 
                            Variant={InspectIncoming ? 'Primary' : 'Secondary'}
                            Icon={<ArrowDown weight='bold'/>} 
                            onClick={()=>{QueryTransferPackageData_Incoming()}} 
                            label={"Show Incoming Request"}/>
                        <UIButton 
                            Variant={!InspectIncoming ? 'Primary' : 'Secondary'}
                            Icon={<ArrowUp weight='bold'/>} 
                            onClick={()=>{QueryTransferPackageData_Outgoing()}} 
                            label={"Show Outgoing Request"}/>
                    </div>
            </div>

            <div className={`${classes.list_box_wrapper}`}>
                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>{InspectIncoming  ? 'Source Branch' : 'Destination Branch'}</span>
                        <span>Request Made By</span>
                        <span>Request Intaited Date</span>
                        <span>Expected Arrival Date</span>
                        <span>Approved Date</span>
                        <span>Request State</span>
                        <span>Action</span>
                    </li>
                    <div className={`${classes.list_data_wrapper}`}>
                        {!Loading && 
                            DataPackage.map((item, idx)=>(
                                <li key={idx}>
                                    <span>{item.BranchName}</span>
                                    <span>{item.FirstName} {item.LastName}</span>
                                    <span>{<TimeAgo show_suffix={true} date={new Date(item.TWFP_RequestDateTIme)}/>}</span>
                                    <span>{ToEthiopianCalander(new Date(item.TWFP_TargetArrivalDate))}</span>
                                    <span>
                                        {
                                            item.TWFP_RequestState === "Completed" ? 
                                            <TimeAgo show_suffix={true} date={new Date(item.TWFP_RequestHandelDateTime)}/> : '--'
                                        }
                                    </span>
                                    <span>
                                        <span className={`${
                                            item.TWFP_RequestState === "Pending" ? 
                                            classes.pending_status_View : 
                                            item.TWFP_RequestState === "Completed" || item.TWFP_RequestState === "Received"  ?  classes.completed_status_View
                                            : classes.reject_status_View
                                            }`}>
                                            {item.TWFP_RequestState}
                                        </span>
                                    </span>
                                   
                                    <span className={`${classes.center_content_button}`}>
                                        {
                                            item.TWFP_RequestState === "Completed" || item.TWFP_RequestState === "Received"?
                                            InspectIncoming ? <UIButton Icon={<FilePdf/>} onClick={()=>{OpenTargetItem(item)}} label={"Document Preview"}/> 
                                            :
                                            <>
                                            {
                                              item.TWFP_RequestState !== "Received" &&
                                             <UIButton Icon={<ArrowDownRight/>} onClick={()=>{SetReceiveRequestKey(!ReceiveRequestKey)}} label={"Receive Product"}/>
                                            }
                                             {
                                                ReceiveRequestKey && 
                                                <div className={`${classes.aquaire_request_key}`}>
                                                    <input onChange={(e)=>{FilterReceiveKeyInput(e.target.value, item)}}  type="text" placeholder='Enter Key' />
                                                </div>
                                             }
                                            </> 
                                            : '--'
                                        }
                                    </span>
                                    
                                </li>
                            ))
                        }
                        { !Loading && 
                            DataPackage.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><Tray fontSize={30}/></ul>
                                <ul>
                                    {
                                        InspectIncoming ? 'No Incoming Request' : 'No Outgoing Request'
                                    }
                                </ul>
                            </div>
                        }
                        {
                            Loading &&
                            <LoadingPlaceholder/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
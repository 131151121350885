import { useEffect } from 'react'
import classes from './GrandTotalOrderDataViewWrapper.module.css'

export default function GrandTotalDataViewWrapper({DataList}) 
{

    useEffect(()=>{
    }, [DataList])
    return (
        <div className={`${classes.single_order_data_view_master_wrapper}`}>
            <div className={`${classes.packge_data_wrapper}`}>

                <div className={`${classes.main_prod_info_wrapper}`}>
                    <li><span className={`${classes.prod_name_PO}`}>Grand Total</span></li>
                </div>

                <div className={`${classes.ing_list_data_wrapper}`}>
                    <div className={`${classes.data_marging_wrapper}`}>
                        {
                            DataList.map((item, idx)=>(
                                <li key={item[1].Amount + idx}>
                                    <span>{item[0]}</span> 
                                    <span className={`${classes.right_align}`}>{(item[1].Amount).toFixed(2)} {item[1].UOM}</span>
                                </li>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
import classes from './FinalProductChoiceInputPackage.module.css'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { useEffect, useState } from 'react'
import { CheckCircle, Checks, Warning } from '@phosphor-icons/react'


export default function FinalProductChoiceInputPackage(
    {   
        Sender = false, 
        ID, 
        Update_CALLBACK, 
        DropDownProductList,
        ReadDataPackage = null
    }) 
{

    const [ProductQuantity, SetProductQuantity] = useState('');
    const [SelectedProductDetail, SetSelectedProductDetail] = useState(0);
    const [SelectedProduct, SetSelectedProduct] = useState();
    const [AvialProductList, SetAvialProductList] = useState([]);

    const SelectedProductItem = (data)=>
    {
        const __target_res = DropDownProductList.find(((item)=>item.CMBPID === data.value));
        if(__target_res) 
        {
            SetSelectedProductDetail(__target_res.Quantity)
        } else 
        {
            SetSelectedProductDetail(0);
        }
    }

    useEffect(()=>{
        if(DropDownProductList.length > 0) 
        {
            PrepareDropDownList(DropDownProductList, SetAvialProductList);
        }
    }, [DropDownProductList])

    useEffect(()=>{

        if(ReadDataPackage) 
        {
            const __read_data_parse = JSON.parse(ReadDataPackage.ProductItem);
            SetProductQuantity(ReadDataPackage.Quantity);
            SetSelectedProduct(__read_data_parse);

            const __target_res = DropDownProductList.find((item)=>item.CMBPID === __read_data_parse.value);
            if(__target_res) 
            {
                SetSelectedProductDetail(__target_res.Quantity)
            } else 
            {
                SetSelectedProductDetail(0);
            }
        }

    }, [ReadDataPackage])


    return (
        <div className={`${classes.final_product_choice_input_package_wrapper}`}>

            <div className={`${classes.final_product_input_package_input_wrapper_single}`}>
                <MasterDataRegInput input={
                        <Select
                            value={SelectedProduct}
                            onChange={(data)=>{SelectedProductItem(data);SetSelectedProduct(data);Update_CALLBACK(ID, "ProductItem", JSON.stringify(data))}}
                            name={"dd_data"}
                            options={AvialProductList}
                            placeholder={"Product Item"}
                            isSearchable={true}
                            styles={Custom_DD_Style}
                            required ={true}
                        /> } 
                />
            </div>

            {Sender && <div className={`${classes.final_product_input_package_input_wrapper_single}`}>
                <MasterDataRegInput
                    input={
                        <input
                        value={SelectedProductDetail}  
                        type="number" 
                        readOnly />
                    }
                    tip={"Available Quantity"}
                />
            </div>}

            <div className={`${classes.final_product_input_package_input_wrapper_single}`}>
                <MasterDataRegInput
                    input={
                        <input 
                        value={ProductQuantity} 
                        onChange={(e)=>{SetProductQuantity(e.target.value);Update_CALLBACK(ID, "Quantity", e.target.value)}}
                        type="number" 
                        placeholder='Quantity'
                        required />
                    }
                />
            </div>
           {Sender &&  <div style={{width: '5em'}} className={`${parseInt(SelectedProductDetail) >= parseInt(ProductQuantity) ? classes.possibel_production_volume : classes.lack_production_volume}`} >
            {
                parseInt(SelectedProductDetail) >= parseInt(ProductQuantity) ? <Checks weight='bold'/> : <Warning weight='fill'/>
            }
            </div>}
            
            
            

        </div>
    )
}
import classes from './TerminationArchive.module.css'


export default function TerminationArchive() 
{



    return (
        <div>
            <h1>Termination Archive</h1>
        </div>
    )
}
import { useEffect, useState } from 'react';
import { useAuth } from '../../AuthProvider'
import classes from './ProductionTransferArchive.module.css'

import TransferArchiveBranchBundle from './TransferArchiveBranchBundle/TransferArchiveBranchBundle'
import { useToast } from '../../ToastContext';
import UIButton from '../Components/UIButton/UIButton';
import { QuestionMark } from '@phosphor-icons/react';

import TransferFinishedProduct from '../StockCard/Component/TransferFinishedProduct/TransferFinishedProduct';

export default function ProductionTransferArchive() 
{

    const auth = useAuth();
    const [OpenFinalProductTransferDiag, SetOpenFinalProductTransferDiag] = useState(false);

    return (
        <div className={`${classes.production_transfer_archive_master_wrapper}`}>

            {OpenFinalProductTransferDiag && <TransferFinishedProduct OpenController={SetOpenFinalProductTransferDiag}/>}

            <div className={`${classes.production_trasnfer_action_button_wrapper}`}>
                <UIButton onClick={()=>{SetOpenFinalProductTransferDiag(true)}} Icon={<QuestionMark weight='bold'/>} label={"Create Production Request"}/>
            </div>
            
            <div className={`${classes.production_trasnfer_bundle_wrapper}`}>
                <TransferArchiveBranchBundle />
            </div>

        </div>
    )
}
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import classes from './Offline.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {WifiSlash} from "@phosphor-icons/react";


export default function OfflinePage() 
{
    return (
        <div className={`${classes.offline_page_main_wrapper}`}>
            <div className={`${classes.main_icon_wrap}`}><WifiSlash size={30} weight="bold" /></div>
            <h1>You're offline</h1>
            <li className={`${classes.relax_text}`}><b>Relax!</b> we’ll inform you as soon as your <b>connexion</b> is restored.</li>
        </div>
    )
}
import classes from './TransferFinishedBakeryProductActionTake.module.css'

import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

import { v4 as uuidv4 } from 'uuid'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {CreateNewMerchGroup} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'
import {FetchBranchExcludedCurrent} from '../../../../api'
 
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import FinalProductChoiceInputPackage from '../TransferFinishedProduct/FinalProductChoiceInputPackage/FinalProductChoiceInputPackage'
import UIButton from '../../../Components/UIButton/UIButton'
import { CirclesThreePlus, TrashSimple } from '@phosphor-icons/react'
import { ApproveFinalBakeryProductRequest, RejectFinalBakeryProductRequest } from '../../../../SharedComp/API/FinalBakeryTransfer/FinalBakeryTransfer'
import { useDAControl } from '../../../../DAControlContext'



export default function TransferFinishedBakeryProductActionTake({ RequestPackage, OpenController, ProductList, SelectedDate}) 
{

    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();

    const [ParsedRequestPackage, SetParsedRequestPackage] = useState(JSON.parse(RequestPackage.TWFP_RequestPackage));
    const [SelectedDestDate, SetSelectedDestDate] = useState();
    const [Loading, SetLoading] = useState(false);
    const [SummerizedProductionList, SetSummerizedProductionList] = useState([]);

    const [ValidBranchList, SetValidBranchList] = useState([]);
    const [SelectedBranch, SetSelectedBranch] = useState();

    const [DynamicInputList, SetDynamicInputList] = useState([]);

    const [DynamicInputListMainData, SetDynamicInputListMainData] = useState(new Map());



    const GroupItems = ()=> 
    {
        const _data_buffer = new Map();
        for (let i = 0; i < ProductList.length; i++) {
            const currentItem = {...ProductList[i]};

            if(_data_buffer.has(currentItem.CMBP_Name)) 
            {
                const _exist =_data_buffer.get(currentItem.CMBP_Name);
                _exist.SCM_Quantity += currentItem.SCM_Quantity;
            } else 
            {
                _data_buffer.set(currentItem.CMBP_Name, {...currentItem});
            }

        }
        const __array_val = Array.from(_data_buffer.values()); 
        SetSummerizedProductionList(__array_val);
    }
    const AddNewDynamicInput = ()=>
    {
        const __id = uuidv4();

        SetDynamicInputList((prev)=>{
            const __current = [...prev];
            __current.push(__id);

            return __current;
        })

    }
    const RemoveDynamicInputEntry = (__id)=>
    {
        const _idx_to_purge = DynamicInputList.indexOf(__id);
        if(_idx_to_purge !== -1) 
        {
            SetDynamicInputList((prev)=>{
                const __current = [...prev];
                __current.splice(_idx_to_purge, 1)
                return __current;
            })
        }

        // Remove the data also
        SetDynamicInputListMainData((prev)=>
        {
            const __update = new Map(prev);
            if(__update.has(__id)) 
            {
                __update.delete(__id);
            }

            return __update;
        })
        
    }
    const DyanamicInputListUpdateCallback = (update_key, update_value_ref, update_value)=>
    {
        SetDynamicInputListMainData((prev)=>{
            const __update = new Map(prev);
            if(__update.has(update_key)) 
            {
                const _target_data ={... __update.get(update_key)};
                _target_data[update_value_ref] = update_value;

                __update.set(update_key, _target_data);
            }else 
            {
                __update.set(update_key, {
                    [update_value_ref]: update_value
                })
            }
            
            return __update;
        })
    }
    const FetchValidBranchList = async() =>
    {
        try 
        {
            const res = await FetchBranchExcludedCurrent(auth.activeWorkShop, auth.token);
            PrepareDropDownList(res, SetValidBranchList);
        }catch(err) 
        {
            notify.ShowError("Falied to Fetch valid Workspace");
        }
    }
    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async()=> 
    {
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);
        try 
        {
            let __invalid_count = 0;
            const __total_entry_count = DynamicInputListMainData.size;
            for(const [key, obj] of DynamicInputListMainData.entries())
            {
                if(parseInt(obj.AvailQuantity) < parseInt(obj.Quantity))
                {
                    __invalid_count++;
                }
            }
            if(__total_entry_count === __invalid_count) 
            {
                throw new Error("You can't approve this request becouse your production log can't handle this reqquest, not even one!");
            }
            const fd = new FormData();
            fd.append("data_request_package", JSON.stringify(Array.from(DynamicInputListMainData.entries())));
            fd.append("request_id", RequestPackage.TWFP_ID);
            fd.append("request_handled_by", auth.user.UserAccountID);
            fd.append("target_date", SelectedDate);
            fd.append("sender_branch", auth.activeWorkShop);
            fd.append("receiver_branch", RequestPackage.TWFP_RequestOwnerWorkspace);
            const main_res = await ApproveFinalBakeryProductRequest(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetLoading(false);
            OpenController(false);
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }
    const HandleRejection = async()=>
    {
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);
        try {
            const fd = new FormData();
            fd.append("request_id", RequestPackage.TWFP_ID);
            const main_res = await RejectFinalBakeryProductRequest(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetLoading(false);
            OpenController(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }

    useEffect(()=>{
        FetchValidBranchList();
    }, [])
    useEffect(()=>{


        if(ParsedRequestPackage.length > 0) 
        {
            const __let_temp_map = new Map();
            const __let_data_array = [];
            for(let i = 0; i < ParsedRequestPackage.length; i++) 
            {
                const __new_key = uuidv4();
                let __temp_obj = ParsedRequestPackage[i][1];
                __temp_obj["key"] = __new_key;

                // Provide Avail Quantity
                const __target_Avial_item = SummerizedProductionList.find((item)=>item.CMBPID === JSON.parse(__temp_obj.ProductItem).value);
                if(__target_Avial_item) 
                {
                    __temp_obj["AvailQuantity"] = __target_Avial_item.Quantity;
                } else 
                {
                    __temp_obj["AvailQuantity"] = 0;
                }


                __let_data_array.push(__temp_obj);
                __let_temp_map.set(__new_key, __temp_obj);
            }
            SetDynamicInputListMainData(new Map(__let_temp_map));
            SetDynamicInputList(__let_data_array);
        }
        

    }, [ParsedRequestPackage, SummerizedProductionList])

    useEffect(()=>{
        if(ProductList.length > 0) 
        {
            GroupItems();
        }
    }, [ProductList])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Approve <span className={`${data_win.data_create_name}`}>Product Transfer</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        {/* <div  className={`${classes.input_list_wrapper}`}>
                                                
                                                <div className={`${classes.input_header_single_wrapper_st_req}`}>
                                                    <MasterDataRegInput input={
                                                        <Select
                                                            onChange={(data)=>{SetSelectedBranch(data);}}
                                                            name={"dd_data"}
                                                            options={ValidBranchList}
                                                            placeholder={"Recipient..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        /> } 
                                                      />
                                                </div>
                                                
                                                <div className={`${classes.input_header_single_wrapper_st_req}`}>
                                                    <MasterDataRegInput input={<input onChange={(e)=>{SetSelectedDestDate(e.target.value)}} name='need_date' type="date" required />} />
                                                </div>

                                        </div> */}
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <div className={`${classes.final_product_ship_wrapper}`}>
                                                
                                                    {
                                                        DynamicInputList.map((item, idx)=>(
                                                            <li key={item.key}>
                                                                <span className={`${classes.main_input_final_product_ship_wrapper}`}>
                                                                    <FinalProductChoiceInputPackage
                                                                        ReadDataPackage={item}
                                                                        Sender={true}
                                                                        ID={item.key}
                                                                        DropDownProductList={SummerizedProductionList}
                                                                        Update_CALLBACK={DyanamicInputListUpdateCallback}
                                                                    />
                                                                </span>
                                                                {/* <span><UIButton Type='button' Icon={<TrashSimple/>} Variant='Danger' onClick={()=>{RemoveDynamicInputEntry(item_key)}} label={"Remove"}/></span> */}
                                                            </li>
                                                        ))
                                                    }
                                            </div>

                                    </div>

                                    

                                    
                                </div>
                                {/* <div className={`${classes.finsla_product_trasnfer_package_input_action_button}`}>
                                        <UIButton Type='button' onClick={()=>{AddNewDynamicInput()}} label={"Add More"} Icon={<CirclesThreePlus/>}/>
                                </div> */}
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button 
                                    type='button' 
                                    disabled={Loading} 
                                    className={`${data_win.form_action_button_save}`}
                                    onClick={()=>{
                                        DAControl.Trigger(
                                            "Apporve Product Transfer",
                                            `You are about to approve a product transfer. Please note that this action will impact your production log. If there are any red warnings displayed, it indicates that the product transfer has been declined. Ensure that only entries that have been thoroughly double-checked are approved.`,
                                            true,
                                            6,
                                            (data)=>
                                            {
                                                if(data)
                                                {
                                                    HandleSubmition();
                                                }
                                            }
                                        )
                                    }}
                                    >{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Approve Request'}</button>
                                <button 
                                    type='button' 
                                    disabled={Loading} 
                                    className={`${data_win.form_action_button_save}`}
                                    onClick={()=>{
                                        DAControl.Trigger(
                                            "Reject Product Transfer",
                                            `You are about to reject a product transfer. Please note that if you do this who every made the request won't get anything.`,
                                            true,
                                            6,
                                            (data)=>
                                            {
                                                if(data)
                                                {
                                                    HandleRejection();
                                                }
                                            }
                                        )
                                    }}
                                    >{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Reject Request'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div> 
    )
}
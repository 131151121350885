import classes from './BranchCardStyle.module.css'
import { faLocation, faLocationPin, faMapLocationDot, faPen, faPersonDigging, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BuildingOffice, Storefront } from '@phosphor-icons/react';
import GoogleMapReact from 'google-map-react';
import { useEffect } from 'react';
import UIButton from '../../../Components/UIButton/UIButton';
import { QueryBaseEmployeeByBranch } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee';
import { useAuth } from '../../../../AuthProvider';
import { useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';



export default function BranchCard({BranchData, DataViewPkgSet, DetailViewController, Data_CALLBK}) 
{

    const auth = useAuth();
    const [EmployeeList, SetEmployeeList] = useState([]);
    const [StoreList, SetStoreList] = useState([]);

    const GetBranchStore = async()=>
    {
        fetch(getBaseURL() + 'warehouse/get-branch-store', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({BranchID: BranchData.BranchID })
        })
        .then((data) => data.json())
        .then(res => SetStoreList(res));
    }

    const GetBranchEmployee = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", BranchData.BranchID);
            const main_res = await QueryBaseEmployeeByBranch(fd, auth.token);
            SetEmployeeList(main_res);
            GetBranchStore();
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        GetBranchEmployee();
    }, [])

    return (
        <div onClick={()=>{Data_CALLBK(BranchData)}} className={`${classes.brach_card_main_wrapper}`}>
            <div className={`${classes.workspace_icon_preview}`}>
                <li><BuildingOffice/></li>
            </div>
                <div className={`${classes.card_header_wrapper}`}>
                    <h1>{BranchData.BranchName}</h1> 
                </div>

                <div className={`${classes.cadr_body_wrapper}`}>
                    <li>{BranchData.Type} Service</li>
                    <li className={`${BranchData.BranchState === "Active" ? '' : classes.suspeded_branch_note}`}>{BranchData.BranchState}</li>
                    <li>{EmployeeList.length} Employee</li>
                    <li>{StoreList.length} Warehouse</li>
                </div>
        </div>
    );
}
import classes from './ProductionLogGroupTransfer.module.css'
import { faBars, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { objectToMap } from '../../../../SharedComp/Utility'
import { useAuth } from '../../../../AuthProvider'
import { Checks, Warning } from '@phosphor-icons/react'
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
const formatter_currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB', 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2
});
const formatter = new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 0, 
    maximumFractionDigits: 2
})

export default function ProductionLogGroupTransfer({isLoading, DataList, ShiftName})
{

    const auth = useAuth();
        const messagesEndRef = useRef(null);
        const scrollToBottom = () => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          };
        const [ShowMinFilterBox, SetShowMinFilterBox] = useState(false);
    
    
        const [ActiveDataViewList, SetActiveDataViewList] = useState([]);
        const [CoreDataViewList, SetCoreDataViewList] = useState([]);
        const [CoreDataViewGroupedList, SetCoreDataViewGroupedList] = useState([]);
    
        const [TotalBatchCount, SetTotalBatchCount] = useState(0);
        const [IsGroupView, SetIsGroupView] = useState(false);
        const [SumQuantity, SetSumQuantity] = useState(0);
        const [SumPrice, SetSumPrice] = useState(0);
        const [TotalUsedKG, SetTotalUsedKG] = useState(0);
        const [Loading, SetLoading] = useState(false);
        const [IsEmpty, SetIsEmpty] = useState(true);
        const [TotalCashDiffValue, SetTotalCashDiffValue] = useState(false);
    
        const SumUpPriceQuantity = async(data_array)=> 
        {
            let _total_batch = 0;
            let __price = 0;
            let __quantity = 0;
    
            for(let i = 0; i < data_array.length; i++) 
            {
                __quantity += parseInt(ShiftName === "Outgoing" ? data_array[i].TO_Quantity : data_array[i].TI_Quantity);
                __price += data_array[i].CMBP_UnitPrice * parseInt(ShiftName === "Outgoing" ? data_array[i].TO_Quantity : data_array[i].TI_Quantity);
                
            }
    
            SetSumQuantity(__quantity);
            SetSumPrice(__price);
        }
        const GroupItems = async()=> 
        {
            let __total_cash_loss = 0;
            let __toal_batch = 0;
            const _data_buffer = new Map();
            for (let i = 0; i < CoreDataViewList.length; i++) {
                const currentItem = {...CoreDataViewList[i]};
    
                if(_data_buffer.has(currentItem.ItemName)) 
                {
                    const _exist =_data_buffer.get(currentItem.ItemName);
                    _exist.ProductionQuantity += currentItem.ProductionQuantity;
                    _exist.ProductCountPerBatch += currentItem.MS_ProductCountPerBatch;
                    _exist.ProductCountDifference += currentItem.ProductCountDifference;
                    _exist.ProductionDifferenceCashValue += currentItem.EP_ProductionDifferenceCashValue;
                    _exist.BatchCount += 1;
                    // _exist.CMBP_Gram += currentItem.CMBP_Gram;
                } else 
                {
                    _data_buffer.set(currentItem.ItemName, {...currentItem});
                }
                
            }
            for(const [key, value] of _data_buffer.entries()) 
            {
                __toal_batch += value.BatchCount;
                __total_cash_loss +=  value.ProductionDifferenceCashValue;
            }
    
            SetTotalCashDiffValue(__total_cash_loss);
            SetCoreDataViewGroupedList(Array.from(_data_buffer.values()));
            SetActiveDataViewList(Array.from(_data_buffer.values()));
            SetIsGroupView(true);
            SetTotalBatchCount(__toal_batch);
        }
    
        const SortByName = async()=> 
        {
            const sortedData = [...ActiveDataViewList].sort((a, b) => a.ItemName.localeCompare(b.ItemName));
            SetActiveDataViewList(sortedData); 
        }
        const sortByQuantity = () => {
            const sortedData = [...ActiveDataViewList].sort((a, b) => b.ProductionQuantity - a.ProductionQuantity);
            SetActiveDataViewList(sortedData);
          };
       
          const generateAnimationDelay = (index) => {
            return `${index * 0.06}s`
          };
    
          const ParseDataForView = async (data_list)=>
          {
            let __total_kg = 0;
            const __readable_data_set = [];
            for(let i = 0; i < data_list.length; i++) 
            {
                const _branch_spec_data = objectToMap(JSON.parse(data_list[i].CMBP_WorkspaceSpec));
                const __my_profile = _branch_spec_data.get(auth.activeWorkShop);
    
                const _expected_batch_product_output = data_list[i].ProductCountPerBatch ? parseFloat(data_list[i].ProductCountPerBatch) : 1;
                const _killogram_usage_per_batch = data_list[i].FlourPerBatch ? parseFloat(data_list[i].FlourPerBatch) : 1;
                __total_kg += _killogram_usage_per_batch;
                const __unit_price = data_list[i].Price ? parseFloat(data_list[i].Price) :  parseFloat(data_list[i].CMBP_UnitPrice);
                const __product_diff = parseInt(data_list[i].Quantity) - _expected_batch_product_output;
                __readable_data_set.push({
                    ItemName: data_list[i].Alias ? data_list[i].Alias : data_list[i].CMBP_Name,
                    UnitPrice: __unit_price,
                    ProductionQuantity: parseInt(data_list[i].Quantity),
                    FlourPerBatch: _killogram_usage_per_batch,
                    ProductCountPerBatch: _expected_batch_product_output,
                    MS_ProductCountPerBatch: _expected_batch_product_output,
                    ExpectedGram: formatter.format((_killogram_usage_per_batch / _expected_batch_product_output) * 1000),
                    CurrentGram: formatter.format((_killogram_usage_per_batch / parseInt(data_list[i].Quantity)) * 1000),
                    ProductCountDifference: __product_diff,
                    BatchCount: 1,
                    ProductionDifferenceCashValue: __product_diff < 0 ? __product_diff * __unit_price : 0,
                    EP_ProductionDifferenceCashValue: __product_diff < 0 ? __product_diff * __unit_price : 0
                })
    
                SetTotalUsedKG(__total_kg);
            }
    
            SetCoreDataViewList(__readable_data_set);
            SetActiveDataViewList(__readable_data_set);
          }
    
          
          
          useEffect(()=>{GroupItems()}, [CoreDataViewList])
    
          const PrepareDataView = async() =>
          {
    
            SetLoading(true);
            SetIsEmpty(DataList.length <= 0);
            if (DataList.length > 0) {
                // await ParseDataForView(DataList);
                // await SumUpPriceQuantity();
            }
            
            SetLoading(false);
          }
    
        useEffect(() => {
    
            // PrepareDataView();
            if(DataList.length > 0) 
            {
                if(DataList[0].ProductPackageList.length > 0) 
                {
                    SumUpPriceQuantity(DataList[0].ProductPackageList);
                    SetActiveDataViewList(DataList[0].ProductPackageList);
                    SetIsEmpty(DataList[0].ProductPackageList <= 0);
                }
            }
            
        }, [DataList]);



    return (
        <div className={`${classes.production_log_group}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="production-log-preview" place="top" />
            <div className={`${classes.production_log_group_header}`}>
                <div className={`${classes.prodution_log_group_header_inner}`}>
                    <h2>Production - {ShiftName}</h2>
                </div>

                <div className={`${classes.prodution_log_group_header_inner}`}>
                    <button onClick={()=>{SetShowMinFilterBox(!ShowMinFilterBox)}}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></button>

                    <div tabindex="0" onBlur={()=>{SetShowMinFilterBox(false)}} className={`${classes.production_log_group_filter_wrapper} ${ShowMinFilterBox ? '' : classes.production_log_group_filter_wrapper_hide}`}>
                        <li onClick={()=>{GroupItems(); SetShowMinFilterBox(false)}}>Group Items</li>
                        <li onClick={()=>{SetActiveDataViewList(CoreDataViewList); SetIsGroupView(false); SetShowMinFilterBox(false)}}>Detail Items</li>
                        <li onClick={()=>{SortByName(); SetShowMinFilterBox(false)}}>Sort A - Z</li>
                        <li onClick={()=>{sortByQuantity(); SetShowMinFilterBox(false)}}>Sort 1 - 9</li>
                    </div>

                </div>

            </div>

            <div className={`${classes.production_log_group_body}`}>

               {(!Loading && !isLoading) && <div className={`${classes.production_log_group_data_list_wrapper}`}>

                    {!IsEmpty &&
                        ActiveDataViewList.map((item, idx)=>(
                            <li key={idx} style={{animationDelay: generateAnimationDelay(idx) }}>
                                <span>{item.CMBP_Name}</span>
                                {/* <span className={`${item.ProductionDifferenceCashValue < 0 ? classes.has_defect : ''}`} style={{textAlign: 'center'}}>
                                    {item.ProductionDifferenceCashValue < 0 ? formatter.format(item.ProductionDifferenceCashValue) : '-'}
                                </span> */}
                                <span className={`${classes.span_p_log_right_}`}>
                                    
                                    {formatter.format(
                                        ShiftName === "Outgoing" ?
                                        item.TO_Quantity : item.TI_Quantity
                                        )} Pcs  
                                </span>
                                
                                {/* <span className={`${classes.span_p_log_right_}`}>{isNaN(item.ExpectedGram) ? <span {...({'data-tooltip-id': 'production-log-preview', 'data-tooltip-content': "There is no configured master data available to evaluate this product."})}><Warning weight='fill' size={14}/></span> : item.ExpectedGram + ' G'}</span> */}
                            </li>
                        ))
                    }
                  {!IsEmpty && <li style={{animationDelay: generateAnimationDelay(ActiveDataViewList.length) }} className={`${classes.grand_total_val}`}>
                        <span>Summery</span>
                        <span className={`${classes.span_p_log_right_}`}>{formatter.format(SumQuantity)} pcs</span>
                    </li> }
                    {!IsEmpty && <li style={{animationDelay: generateAnimationDelay(ActiveDataViewList.length) }} className={`${classes.grand_total_val}`}>
                        <span>Grand Total</span>
                        <span style={{textAlign: 'center'}}>-</span>
                        <span style={{textAlign: 'center'}}>-</span>
                        <span className={`${classes.span_p_log_right_}`}>{formatter_currency.format(SumPrice)}</span>
                    </li> }

                    {!isLoading && IsEmpty && 
                      <div className={`${classes.empty_log}`}>
                        <ul><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></ul>
                        <ul>No {ShiftName}</ul>
                      </div> }

                    {/* <div ref={messagesEndRef} /> */}
                </div> }

                {(isLoading || Loading) && 
                    <div className={`${classes.loader_gif_wrapper}`}>
                        <LoadingPlaceholder/>
                    </div>
                }

            </div>

        </div>
    )
}
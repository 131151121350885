import classes from './OperationMDCluster_II.module.css'


import MasterPenalityType from './MasterPenalityType/MasterPenalityType'
import MasterPTOType from './MasterPTOType/MasterPTOType'
import MasterShiftType from './MasterShiftType/MasterShiftType'
import MasterUtilityExpense from './MasterUtilityExpense/MasterUtilityExpense'

export default function OperationMDCluster_II() 
{


    return (
        <div className={`${classes.data_upper_wrapper_prox_table}`}>
            <div className={`${classes.core_master_data_wrapper}`}>
                
                <MasterPenalityType/>
                <MasterPTOType/>
                <MasterShiftType/>
                <MasterUtilityExpense/>

            </div>
        </div>
    )
}
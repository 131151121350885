import { useEffect, useState } from 'react'
import classes from './KitchenVariants.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPen, faPenNib, faPlusCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

import CreateKitchenVar from './Component/CreateKitchenVar/CreateKitchenVar';
import EditKitchenVariants from './Component/EditKitchenVar/EditKitchenVariants';

import {FetchKVType, PurgeKVType} from '../../../SharedComp/API/KV/API_kv'
import { useAuth } from '../../../AuthProvider';
import { useDAControl } from '../../../DAControlContext'
import { useToast } from '../../../ToastContext'


import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy';
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';

export default function KitchenVariants() 
{

    const [OpenCreateKitchenVar, SetOpenCreateKitchenVar] = useState(false);
    const [EditKitchenVar, SetEditKitchenVar] = useState(false);
    const [KVDataArray, SetKVDataArray] = useState([]);
    const [KVDataArrayOriginal, SetKVDataArrayOriginal] = useState([]);
    const [EditVarIdx, SetEditVarIdx] = useState();
    const [ReloadQuery, SetReloadQuery] = useState(false);

    const auth =  useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    // Loading
    const [LoadingData, SetLoadingData] = useState(false);



    const HandleEditVar = async(__idx)=>
    {
        SetEditVarIdx(__idx);
        SetEditKitchenVar(true);
    }

    const FetchKVTypeFront = async()=>
    {
        SetLoadingData(true);
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const res = await FetchKVType(fd, auth.token);
            SetKVDataArray(res);
            SetKVDataArrayOriginal(res);
            SetLoadingData(false);
        }catch(err) 
        {
            SetLoadingData(false);
        }
    }

    useEffect(()=>{
        FetchKVTypeFront();

    }, [OpenCreateKitchenVar, auth.activeWorkShop, EditKitchenVar, ReloadQuery]);

    const FilterByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < KVDataArrayOriginal.length; i++) 
        {
            const check = KVDataArrayOriginal[i].KV_TypeName.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(KVDataArrayOriginal[i])
        }

        SetKVDataArray(temp_list);
    }

    return (
        <div className={`${classes.kitchen_var_main_wrapper}`}>

            {OpenCreateKitchenVar && <CreateKitchenVar OpenController={SetOpenCreateKitchenVar}/>}
            {EditKitchenVar && <EditKitchenVariants EditIdx={EditVarIdx} OpenController={SetEditKitchenVar} />}

            <div className={`${classes.kitchen_var_bundle_outer}`}>
                <DataViewTableProxy 
                    IsEmpty={KVDataArray.length === 0 && !LoadingData}
                    OnFilter={FilterByName}
                    OpenDataCreate={SetOpenCreateKitchenVar}
                    HeaderName={
                        <div>
                            <h2>Master <b>Kitchen Variance </b> Manager</h2>
                        </div>
                    }

                    DataArray={
                        <div>

                            {
                                KVDataArray.map((item, idx)=>(
                                    <li key={item.KV_TypeName + idx}>
                                        <span>{item.KV_TypeName}</span>
                                        <span className={`${classes.mix_ing_count}`}> {item.KV_SliceCount} Pcs </span> 
                                        <span>{item.KV_IsSolo ? "Solo" : "Multi Pcs"}</span>
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger("Purge Kitchen Variance", 
                                                `You are about to purge the Kitchen Variance '${item.KV_TypeName}'. This action will trigger severe consequences:
                                                Any active Product Item That are linked with this Recipe will be Hidden!This action is IRREVERSIBLE! Make sure no product is linked with this Variance.`, 
                                                item.KVTID,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;
                            
                                                        notify.ShowLoad(`Purging Variance ${item.KV_TypeName}.....`, 10);
                            
                                                        try 
                                                        {
                                                            const res = await PurgeKVType(data, 'token');
                                                            notify.ShowSuccessUpdate(res.Msg, 10);
                                                            SetReloadQuery(!ReloadQuery);
                                                        }catch(err) 
                                                        {
                                                            notify.ShowErrorUpdate(err.message, 10);
                                                        }
                            
                            
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleEditVar(item.KVTID)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingData && 
                                <LoadingPlaceholder/>
                            }
                            
                        </div>
                    }
                />
                
            </div>

        </div>
    )
}


// <div className={`${classes.kitchen_var_bundle_wrapper}`}>

// <div className={`${classes.kitchen_var_head_wrapper}`}>
//     <h1>Kitchen Variance</h1>
// </div>

// <div className={`${classes.kitchn_var_list_body}`}>
//     {
//     KVDataArray.map((item, idx)=>(
//         <li>
//             <span>{item.KV_TypeName}</span>
//             <span>{item.KV_SliceCount}</span>
//             <span>{item.KV_IsSolo ? "Solo" : "Multi Pcs"}</span>
//             <span className={`${classes.action_button_wrapper}`}>
//             {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button className={`${classes.purge_kv}`}
//                 onClick={()=>{DAControl.Trigger("Purge Kitchen Variance", 
//                     `You are about to purge the Kitchen Variance '${item.KV_TypeName}'. This action will trigger severe consequences:
//                     Any active Product Item That are linked with this Recipe will be Hidden!This action is IRREVERSIBLE! Make sure no product is linked with this Variance.`, 
//                     item.KVTID,
//                     10,
//                     async (data)=>{
//                         if(!data)
//                             return;

//                             notify.ShowLoad(`Purging Variance ${item.KV_TypeName}.....`, 10);

//                             setTimeout(async()=>{

//                                 try 
//                                 {
//                                     const res = await PurgeKVType(data, 'token');
//                                     notify.ShowSuccessUpdate(res.Msg, 10);
//                                     SetReloadQuery(!ReloadQuery);
//                                 }catch(err) 
//                                 {
//                                     notify.ShowErrorUpdate(err.message, 10);
//                                 }

//                             }, 500)


//                     })}}
//                 >
//                     <span>
//                         <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
//                     </span>
//                     <span>
//                         Purge
//                     </span>
//                 </button>}
//                 {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleEditVar(item.KVTID)}} className={`${classes.manage_kv}`}>
//                     <span>
//                         <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
//                     </span>
//                     <span>
//                         Manage
//                     </span>
//                 </button>}
//             </span>
//         </li>
//     ))
//     }
// </div>

// <div className={`${classes.kitchen_var_footer_wrapper}`}>

//     <div className={`${classes.kicthen_var_footer_action_button_wrapper}`}>
//         <button onClick={()=>{SetOpenCreateKitchenVar(true)}}><span><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></span>New</button>
//     </div>

// </div>

// </div>
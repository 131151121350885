import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig';
import { useTargetEmployee } from '../../../SingleEmployeeContextProvider/SingleEmployeeContextProvider'
import classes from './EmployeeSupportDocument.module.css'
import { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import UIButton from '../../../../../../Components/UIButton/UIButton';

import PreviewEmployeeSupportingDocument from './PreviewEmployeeSupportingDocument/PreviewEmployeeSupportingDocument';

import SupportDocMiniCard from './SupportDocMiniCard/SupportDocMiniCard';

export default function EmployeeSupportDocument() 
{

    const SharedData = useTargetEmployee();
    const [TargetDataList, SetTargetDataList] = useState();
    const [OpenDocumentPreview, SetOpenDocumentPreview] = useState(false);

    const SetDataTargetDataLink = (data_link)=>
    {
        SetTargetDataList(data_link);
        SetOpenDocumentPreview(true);
    }

    useEffect(()=>{

    }, [])
    

    return (
        <div>
           {OpenDocumentPreview && <PreviewEmployeeSupportingDocument OpenController={SetOpenDocumentPreview} data_link={TargetDataList} /> } 

                <div className={`${classes.document_card_wrapper}`}>

                    <SupportDocMiniCard 
                        ClickCallback={SetDataTargetDataLink}
                        SharedDoc={SharedData.Core.JAKBED_Certification}
                        CardName={SharedData.Core.JAKSBE_FullName.split(' ')[0] + " Certification Card"}/>
                    <SupportDocMiniCard
                        ClickCallback={SetDataTargetDataLink} 
                        SharedDoc={SharedData.Core.JAKBED_IdentificationCard}
                        CardName={SharedData.Core.JAKSBE_FullName.split(' ')[0] + " Identification Card"}/>
                    <SupportDocMiniCard
                        ClickCallback={SetDataTargetDataLink} 
                        SharedDoc={SharedData.Core.JAKBED_ReferencePersonIdentificationCard}
                        CardName={"RP Identification Card"}/>
                    <SupportDocMiniCard 
                        ClickCallback={SetDataTargetDataLink}
                        SharedDoc={SharedData.Core.JAKBED_ReferenceSignedDocument}
                        CardName={"RP Signed Document"}/>


                </div>

            
        </div>
    )
}
import classes from './ProductShelfCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchYamMixIngID} from '../../../../api';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../AuthProvider';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ProductShelfCard({IsMix, Data, Selected_CALLBACK, BatchMode, OnClick}) 
{

    const auth = useAuth();
    const [IngList, SetIngList] = useState([]);
    const [Selected, SetSelected] = useState(false);
    const [Hash, SetHash] = useState(getRandomID());
    const [LoadingRec, SetLoadingRec] = useState(false);

    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }

    const FetchIngList = async() =>
    {
        SetLoadingRec(true);
        try 
        {
            const recipe_id = IsMix ? Data.MID : Data.CMBP_RecipeID;
            const res = await FetchYamMixIngID(recipe_id, auth.token);
            SetLoadingRec(false);
            SetIngList(res);
        }catch(err) 
        {
            console.log(err);
            SetLoadingRec(false);
        }
    }
    
    useEffect(()=>{
        FetchIngList();
        SetSelected(Selected_CALLBACK);
    }, [BatchMode]);
    useEffect(()=>{
        // SetSelected(Selected_CALLBACK(IsMix ? Data.MixName: Data.CMBP_Name, IsMix));
    }, []);





    return (
        <div onClick={()=>{
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InitiateProductionOrder) 
            {
                SetSelected(OnClick(Data, Hash)); 
            }
            }} className={`${classes.main_product_shelf_card_wrapper} ${Selected ? classes.main_product_shelf_card_wrapper_selected : ''}`}>
            <div className={`${classes.image_wrapper}`}>
                {/* I was hoping to add image here but.... */}
            </div>
            <div className={`${classes.prod_item_wrapper}`}>
                <div className={`${classes.prod_name_info_wrapper} ${Selected ? classes.prod_name_info_wrapper_selected : ''}`}>
                    <h2>{IsMix ? Data.MixName: Data.CMBP_Name}</h2>
                </div>
                <div className={`${classes.prod_other_info_wrpper}`}>
                   {
                    !LoadingRec && IngList.map((item, idx)=>(
                        <li key={idx}><span>{item.IVItemName}</span> <span className={`${classes.ing_amount_c}`}>#{(item.Quantity * 1000).toLocaleString()} G</span></li>
                    ))
                   } 
                   {LoadingRec && <div className={`${classes.load_recep}`}> <FontAwesomeIcon spin icon={faSpinner}></FontAwesomeIcon> </div> }
                </div>

            </div>
        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}
export const CreateDirectStockRequest = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'direct-stock-request/create-direct-stock-request',  data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryDirectStockRequestGroupByBranch = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'direct-stock-request/query-direct-stock-request-group-by-branch',  data, GetRouteConfig(token));
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryDirectStockRequestItemsByGroup = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'direct-stock-request/query-direct-stock-request-item-by-group',  data, GetRouteConfig(token));
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ApproveDirectStockRequestItemsByGroup = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'direct-stock-request/approve-direct-stock-request',  data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const RejectDirectStockRequestRequest = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'direct-stock-request/reject-direct-stock-request',  data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
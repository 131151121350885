import classes from './MasterSetting.module.css'

import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {CreateNewMerchGroup} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'


import PayrollConfig from './PayrollConfig/PayrollConfig'
import GeneralConfig from './GeneralConfig/GeneralConfig'
import InteractionConfig from './InteractionConfig/InteractionConfig'
import NotificationConfig from './NotificationConfig/NotificationConfig'
import CreditSystemConfig from './CreditSystem/CreditSystemConfig'
import AccoutingConfig from './AccoutingConfig/AccoutingConfig'

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import UIButton from '../../../../pages/Components/UIButton/UIButton'
import { ArrowCircleDownLeft, FloppyDisk } from '@phosphor-icons/react'
import { CreateNewGlobalConfig, QueryGlobalObjectConfig, UpdateGlobalConfig } from '../../../API/GlobalConfig/PI_FE_GlobalConfig'

export default function MasterSetting({OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();
    const [GlobalConfig, SetGlobalConfig] = useState({})
    const [ActiveTab, SetActiveTab] = useState('General');

    const IsActive = (name) =>
    {
        return name === ActiveTab;
    }

    const RequestGlobalConfigUpdate = async()=>
    {
        try {

            const fd =  new FormData();
            fd.append("config_data", JSON.stringify(GlobalConfig));
            fd.append("processed_by", auth.user.UserAccountID);
            fd.append("target_id", 1);

            const main_res = await UpdateGlobalConfig(fd, auth.token);
            notify.ShowSuccess(main_res.Msg);
            auth.UpdateGlobalConfig();

        }catch(err) 
        {
            notify.ShowError(err.message);

        }
    }

    const UpdateConfigProp = (prop_name, value)=>
    {
        SetGlobalConfig((prev) => {
            const _updated = { ...prev }; 
            _updated[prop_name] = value; 
            return _updated;
        });
        
    }
    const GetGlobalConfig = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", 1);
            const main_res = await QueryGlobalObjectConfig(fd, auth.token);
            SetGlobalConfig(JSON.parse(main_res[0].CONFIGGLOBAL_Config));
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        GetGlobalConfig();

    }, [])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    <span className={`${data_win.data_create_name}`}>System Configuration</span>
                </h3>
                <button onClick={()=>{OpenController(false)}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body} ${classes.data_body}`}>
                    
                    <div className={`${classes.tab_left_menu}`}>
                      <div className={`${classes.button_action_menu_choice_list_wapper}`}>
                            <div className={`${classes.left_action_button_wrapper}`}>
                                <button className={`${IsActive('General') ? classes.active_side_left_action_tab : ''}`} onClick={()=>{SetActiveTab('General')}}>General</button>
                            </div>
                            <div className={`${classes.left_action_button_wrapper}`}>
                                <button className={`${IsActive('Payroll') ? classes.active_side_left_action_tab : ''}`} onClick={()=>{SetActiveTab('Payroll')}}>Payroll</button>
                            </div>
                            <div className={`${classes.left_action_button_wrapper}`}>
                                <button className={`${IsActive('CreditConfig') ? classes.active_side_left_action_tab : ''}`} onClick={()=>{SetActiveTab('CreditConfig')}}>Credit System</button>
                            </div>
                            <div className={`${classes.left_action_button_wrapper}`}>
                                <button className={`${IsActive('AccoutingConfig') ? classes.active_side_left_action_tab : ''}`} onClick={()=>{SetActiveTab('AccoutingConfig')}}>Accouting</button>
                            </div>
                            <div className={`${classes.left_action_button_wrapper}`}>
                                <button className={`${IsActive('Notification') ? classes.active_side_left_action_tab : ''}`} onClick={()=>{SetActiveTab('Notification')}}>Notification</button>
                            </div>
                            <div className={`${classes.left_action_button_wrapper}`}>
                                <button className={`${IsActive('Interaction') ? classes.active_side_left_action_tab : ''}`} onClick={()=>{SetActiveTab('Interaction')}}>Interaction</button>
                            </div>
                      </div>
                     
                    </div>

                    <div className={`${classes.master_setting_right_contaient_wrapper}`}>
                            {ActiveTab === 'General' && <GeneralConfig UpdateReq={UpdateConfigProp} ConfigData={GlobalConfig}/>}
                            {ActiveTab === 'Payroll' && <PayrollConfig UpdateReq={UpdateConfigProp} ConfigData={GlobalConfig}/>}
                            {ActiveTab === "Interaction" && <InteractionConfig UpdateReq={UpdateConfigProp} ConfigData={GlobalConfig}/>}
                            {ActiveTab === "Notification" && <NotificationConfig  UpdateReq={UpdateConfigProp} ConfigData={GlobalConfig}/>}
                            {ActiveTab === "CreditConfig" && <CreditSystemConfig UpdateReq={UpdateConfigProp} ConfigData={GlobalConfig}/>}
                            {ActiveTab === "AccoutingConfig" && <AccoutingConfig UpdateReq={UpdateConfigProp} ConfigData={GlobalConfig}/>}
                    </div>

                    
            </div>

            <div className={`${classes.footer_action_buttons}`}>
                <UIButton Icon={<FloppyDisk weight='bold'/>} label={"Save Preference"} onClick={()=>{RequestGlobalConfigUpdate()}}/>
                <UIButton Variant='Secondary' Icon={<ArrowCircleDownLeft weight='bold'/>} label={"Reset to default"} onClick={()=>{}}/>
            </div>
        </div>

    </div>
    )
}
import classes from './CreateWarehouse.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Custom_DD_Style } from '../../../../../../SharedComp/Utility'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput';
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useRef } from 'react';
import Select from 'react-select'
import { getBaseURL } from '../../../../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../../../../AuthProvider';

export default function CreateWarehouse({Close_CALLBACK, WorkspaceCore, Res_Callback}) 
{
    const formRef = useRef();
    const [Loading, SetLoading] = useState(false);
    const [StoreType, SetStoreType] = useState();

    const [StoreName, SetStoreName] = useState();
    const [ShareLocal, SetShareLocal] = useState(false);
    const [PrimaryStore, SetPrimaryStore] = useState(false);

    const auth = useAuth();

    const store_type = [
        { value: 'Local', label: 'Local' },
        { value: 'Shared', label: 'Shared' }
      ];

    function HandleSubmition(e) 
    {
        e.preventDefault();
        
        const fd = new FormData();

        fd.append("Name", StoreName);
        fd.append("BranchID", WorkspaceCore.BranchID); 
        fd.append("ShareLocal", ShareLocal);
        fd.append("IsPrimary", PrimaryStore);
        fd.append("workspace_name", WorkspaceCore.BranchName);

        axios.post(getBaseURL() + 'warehouse/reg-branch-store',fd)
        .then((res) => {
            Res_Callback(res)
        });
    }




    return (
        <div className={`${data_win.main_data_creator_wrapper} `}>


            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Initialize Warehouse</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_body}`}>


                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                            <div className={`${classes.form_wrapper}`}> 



                                    <div  className={`${classes.input_list_wrapper}`}>
                                        
                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput 
                                            input={
                                                <input 
                                                    onChange={(e)=>{SetStoreName(e.target.value)}}
                                                    name='store_name' 
                                                    type="text" 
                                                    placeholder='Store Name' 
                                                    title='' 
                                                    autoComplete='off' 
                                                    required />} 
                                            forceInput={true} />
                                        </div>
                                        <div onClick={(e)=>{SetShareLocal(!ShareLocal)}} className={`${classes.input_wrap}`}>
                                            <div className={`${classes.quick_check_wrapper}`}>

                                                <div className={`${classes.input_wrap_check}`}>
                                                    <span>Is Shared store</span>
                                                    <input checked={ShareLocal} onChange={(e)=>{SetShareLocal(e.target.checked)}} type="checkbox" />
                                                </div>

                                            </div>
                                        </div>
                                        <div onClick={(e)=>{SetPrimaryStore(!PrimaryStore)}} className={`${classes.input_wrap}`}>
                                            <div className={`${classes.quick_check_wrapper}`}>

                                                <div className={`${classes.input_wrap_check}`}>
                                                    <span>Is Primary store</span>
                                                    <input checked={PrimaryStore} onChange={(e)=>{SetPrimaryStore(e.target.checked)}} type="checkbox" />
                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={Loading}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>

                </div>
            </div>

        </div>

    )
}
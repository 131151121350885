import classes from '../PayrollConfig/PayrollConfig.module.css'


export default function GeneralConfig({UpdateReq, ConfigData}) 
{


    return (
        <div className={`${classes.payroll_config_wrapper}`}>
            <div className={`${classes.part_of_option}`}>
                <ul>General</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Require Email Verification Before Account Activation</span> <span><input onChange={(e)=>{UpdateReq("GC_RequireEmailVerificationBeforeAccountActivation", e.target.checked)}} checked={ConfigData.GC_RequireEmailVerificationBeforeAccountActivation} type="checkbox" /></span></li>
                    <li><span>Shipped Stock Can Be Received Before</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_ShippedStockCanBeReceivedBefore",  parseInt(e.target.value)); }} value={String(ConfigData.GC_ShippedStockCanBeReceivedBefore)}>
                                <option value="1">1 Hour</option>
                                <option value="3">3 Hour</option>
                                <option value="6">6 Hour</option>
                                <option value="12">12 Hour</option>
                                <option value="24">1 Day</option>
                            </select>
                        </span>
                    </li>
                </div>  
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { createContext, useContext } from "react";

import jak_logo from './asset/images/favicon.png'
import jak_not from './asset/images/notifi_view.png'

import { Howl } from "howler";

import order_task_action_sf from './asset/audio/task-urgent.wav'
import incoming_order_sf from './asset/audio/incoming-order.mp3'
import finish_order_task_sf from './asset/audio/finish-production.mp3'
import announcement_sf from './asset/audio/announcement.mp3'




const GlobalNotificationContext = createContext();
export const useGlobalNotification = () => useContext(GlobalNotificationContext);

export const GlobalNotificationProvider = ({children}) => 
{

    const [SoundEffectMap, SetSoundEffectMap] = useState(new Map([
        ['order_task_action_sf', order_task_action_sf],
        ['incoming_order_sf', incoming_order_sf],
        ['finish_order_task_sf', finish_order_task_sf],
        ['announcement_sf', announcement_sf]
        ]));

    function requestNotificationPermission() {
        
        if (Notification.permission === 'granted') {
            // Permission already granted
            return;
        }
    
        if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                } else {
                    console.log('Notification permission denied.');
                }
            });
        }
    }
 
    function Disturb(title_, body_, noti_sf = 'announcement_sf') {

        const title = title_;
        const options = {
            body: body_,
            icon: jak_logo,
            badge: jak_logo,
            image: jak_not,
            timestamp: Date.now(),
            vibrate: [200, 100, 200],
        };

        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.');
            return;
        }
      
        if (Notification.permission === 'granted') {

            new Notification(title, options);

            const sound = new Howl({
                src: [SoundEffectMap.get(noti_sf)],
                volume: 1,
            });

            sound.play();

        } else {
            console.log('Notification permission not granted.');
        }
    }

    useEffect(()=>{
        requestNotificationPermission();
    }, []);

    return (
        <GlobalNotificationContext.Provider value={{
            Disturb
        }}>
            {children}
        </GlobalNotificationContext.Provider>
    )
}
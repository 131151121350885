import { useEffect, useState } from 'react'
import classes from './XChefProductionStackGroup.module.css'

import {CreateChefNotifMsg} from '../../../../../SharedComp/API/Message/API_FE_Message'

import { useToast } from '../../../../../ToastContext'
import { useAuth } from '../../../../../AuthProvider';

import UIButton from '../../../../Components/UIButton/UIButton'
import { CheckCircle, Spinner, Warning, WarningDiamond } from '@phosphor-icons/react';


import BarLoader from '../../../../../SharedComp/BarLoader/BarLoader';
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo';
import { useDAControl } from '../../../../../DAControlContext'

export default function XChefProductionStackGroup({TargetData}) 
{

    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl(); 


    return (
        <div className={`${classes.x_chef_production_stack_group_wrapper}`}>
                <h2>Chef Task Manager</h2>
        </div>
    )
}
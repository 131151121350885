import classes from './DomesticProduction.module.css'

import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPenNib, faTrash, faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';

// Bakery Product
import CreateDomProduct from './Component/CreateDomProduct/CreateDomProduct';
import EditDemoProduct from './Component/EditDomProduct/EditDomProduct';

// Bakery Group
import CreateBakeryProductGroup from './Component/CreateBakeryProductGroup/CreateBakeryProductGroup';
import EditBakeryProductGroup from './Component/EditBakeryProductGroup/EditBakeryProductGroup';

import { FetchDomestickProductAll, PurgeDomesticProduct } from '../../../api';

// API
import {FetchBakeryGroupAll, PurgeBakeryGroupByID, FetchBakeryProductAll, PurgeBakeryProductByID} from '../../../SharedComp/API/CoreDataClusters/API_FE_Bakery'

import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy';

import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';
import { useDAControl } from '../../../DAControlContext'; 
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function DomesticProduction() 
{
    // Bakery Product Config Open Controller
    const [OpenProdItemData, SetOpenProdItemData] = useState(false);
    const [OpenEditProdItem, SetOpenEditProdItem] = useState(false);

    // Bakery Product Group Config OpenController
    const [OpenBakeryGroupCreator, SetOpenBakeryGroupCreator] = useState(false);
    const [OpenBakeryGroupEditor, SetOpenBakeryGroupEditor] = useState(false);

    // Bakery Group
    const [BakeryGroupList, SetBakeryGroupList] = useState([]);
    const [BakeryGroupListOriginal, SetBakeryGroupListOriginal] = useState([]);
    const [TargetBakeryGroupEdit, SetTargetBakeryGroupEdit] = useState();
    const [RefreshbakeryGroupList, SetRefreshbakeryGroupList] = useState(false);


    // Bakery Product
    const [BakeryProductList, SetBakeryProductList] = useState([]);
    const [BakeryProductListOriginal, SetBakeryProductListOriginal] = useState([]);
    const [TargetBakeryProductEdit, SetTargetBakeryProductEdit] = useState();
    const [RefreshBakeryProductList, SetRefreshBakeryProductList] = useState(false);

    // Loading
    const [LoadingBakeryGroup, SetLoadingBakeryGroup] = useState(false);
    const [LoadingBakeryItem, SetLoadingBakeryItem] = useState(false);



    const DAControl = useDAControl();
    const notify = useToast();
    const auth = useAuth();

    const FetchBakeryGroup = async()=>
    {
        SetLoadingBakeryGroup(true);
        try 
        {
            const main_res = await FetchBakeryGroupAll(auth.token);
            SetBakeryGroupList(main_res);
            SetBakeryGroupListOriginal(main_res);
            SetLoadingBakeryGroup(false);
        }catch(err) 
        {
            SetLoadingBakeryGroup(false);
            notify.ShowError("Failed to Fetch Bakery Group! Please Refresh The Page!");
        }
    }
    const FetchbakeryProducts = async()=>
    {
        SetLoadingBakeryItem(true);
        try 
        {
            const main_res = await FetchBakeryProductAll(auth.token);
            SetBakeryProductList(main_res);
            SetBakeryProductListOriginal(main_res);
            SetLoadingBakeryItem(false);
        }catch(err) 
        {
            SetLoadingBakeryItem(false);
            notify.ShowError(err.message);
        }
    }
    const HandleBakeryGroupPurg = async(id)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);

            const main_res = await PurgeBakeryGroupByID(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const handleBakeryProductPurge = async(id)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);

            const main_res = await PurgeBakeryProductByID(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshBakeryProductList(!RefreshBakeryProductList);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const HandleBakeryGroupManage = async(data) =>
    {
        SetTargetBakeryGroupEdit(data);
        SetOpenBakeryGroupEditor(true);

    }
    const HandleBakeryProductManage = async(data)=>
    {
        SetTargetBakeryProductEdit(data);
        SetOpenEditProdItem(true);
    }

    const FilterBakeryGroupByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < BakeryGroupListOriginal.length; i++) 
        {
            const check = BakeryGroupListOriginal[i].CMBG_Name.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(BakeryGroupListOriginal[i])
        }

        SetBakeryGroupList(temp_list);
    }
    const FilterBakeryProductByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < BakeryProductListOriginal.length; i++) 
        {
            const check = BakeryProductListOriginal[i].CMBP_Name.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(BakeryProductListOriginal[i])
        }

        SetBakeryProductList(temp_list);
    }

    const FilterBakeryByGroup = async(group_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < BakeryProductListOriginal.length; i++) 
        {
            if(BakeryProductListOriginal[i].CMBP_ParentID === group_key)
                temp_list.push(BakeryProductListOriginal[i])
        }

        SetBakeryProductList(temp_list);
    }


    useEffect(()=>
    {
        FetchBakeryGroup();
    }, [RefreshbakeryGroupList, OpenBakeryGroupCreator, OpenBakeryGroupEditor])

    useEffect(()=>{
        FetchbakeryProducts();
    }, [RefreshBakeryProductList, OpenEditProdItem, OpenProdItemData])

    




    return (
        <div className={`${classes.domestic_prod_main_wrapper}`}>

            {OpenProdItemData && <CreateDomProduct OpenController={SetOpenProdItemData} />}
            {OpenEditProdItem && <EditDemoProduct OpenController={SetOpenEditProdItem} TargetID={TargetBakeryProductEdit.CMBPID}/>}

{/* Bakery Group */}
            {OpenBakeryGroupCreator &&  <CreateBakeryProductGroup  OpenController={SetOpenBakeryGroupCreator}/>}
            {OpenBakeryGroupEditor  && <EditBakeryProductGroup TargetData={TargetBakeryGroupEdit} OpenController={SetOpenBakeryGroupEditor} />}


            <div className={`${classes.data_wrapper_upper}`}>

                <DataViewTableProxy 
                    IsEmpty={BakeryGroupList.length === 0 && !LoadingBakeryGroup}
                    OnFilter={FilterBakeryGroupByName}
                    OpenDataCreate={SetOpenBakeryGroupCreator}
                    HeaderName={
                        <div>
                            <h2>Master <b>Bakery</b> Group</h2>
                        </div>
                    }
                    DataArray={
                        <div>

                            {
                                BakeryGroupList.map((item, idx)=>(
                                    <li onDoubleClick={()=>{FilterBakeryByGroup(item.CMBGID)}} key={item.CMBG_Name + idx}>
                                        <span>{item.CMBG_Name}</span>
                                        <span>{item.CMBG_Description}</span>
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Purge Bakery Group`, 
                                                `You are about to purge bakery Products Group '${item.CMBG_Name}'. (MAKE SURE THERE ARE NO LINKED SUB-PRODUCT TO THIS GROUP)
                                                Any active Product That are linked with this Group will be Hidden! This action is IRREVERSIBLE!`, 
                                                item.CMBGID ,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;

                                                    await HandleBakeryGroupPurg(item.CMBGID);
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleBakeryGroupManage(item)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingBakeryGroup && 
                                <LoadingPlaceholder/>
                            }
                            
                        </div>
                    }
                />

            <DataViewTableProxy 
                    IsEmpty={BakeryProductList.length === 0 && !LoadingBakeryItem}
                    OnFilter={FilterBakeryProductByName}
                    OpenDataCreate={SetOpenProdItemData}
                    HeaderName={
                        <div>
                            <h2>Master <b>Bakery</b> Product</h2>
                        </div>
                    }
                    DataArray={
                        <div>

                            {
                                BakeryProductList.map((item, idx)=>(
                                    <li key={item.CMBP_Name + idx}>
                                        <span>{item.CMBP_Name}</span>
                                        <span><span className={`${classes.cash_view_value}`}>{currency_format(item.CMBP_UnitPrice)}</span></span>
                                        <span><span className={`${classes.gram_view_amount}`}>{human_readable_format(item.CMBP_Gram)} Gram</span></span>
                                        <span>{item.MixName} / {item.CMBG_Name}</span>
                                        {/* <span>{item.CMBG_Name}</span> */}
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Purge Bakery Product Config`, 
                                                `You are about to purge bakery Products '${item.CMBP_Name}'. 
                                                Any active Order That are linked with this Item will be Terminated! This action is IRREVERSIBLE!`, 
                                                item.CMBPID  ,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;

                                                    await handleBakeryProductPurge(item.CMBPID );
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleBakeryProductManage(item)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingBakeryItem && 
                                <LoadingPlaceholder/>
                            }
                            
                        </div>
                    }
                />
                


            </div>

        </div>
    )
}
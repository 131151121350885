import classes from './DistributionArchive.module.css'
import { useState } from 'react';
import {FetchWorkspaceAllOutlet} from '../../../SharedComp/API/Workspace/API_FE_Workspace'
import { useEffect } from 'react';
import { useAuth } from '../../../AuthProvider';

import ArchiveBranchGroup from './ArchiveBranchGroup/ArchiveBranchGroup';



export default function DistributionArchive() 
{

    const auth = useAuth();
    const [OrderGroupList, SetOrderGroupList] = useState([]);

    const FetchGroupList = async() =>
    {
        try 
        {
            const main_res = await FetchWorkspaceAllOutlet(auth.token);
            SetOrderGroupList(main_res);
        } catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchGroupList();
    }, [auth.activeWorkShop])


    return (
        <div className={`${classes.distribution_archive}`}>
            
                <div className={`${classes.distro_branch_list_wrapper}`}>
                    {
                        OrderGroupList.map((item, idx)=>(
                            <ArchiveBranchGroup TargetData={item}/>
                        ))
                    }
                </div>

        </div>
    )
}
import { useState } from 'react'
import classes from './StockApprovedBranchBundle.module.css'
import { Paperclip, Tray } from '@phosphor-icons/react'
import { FetchProductionOrderBranchStockedOut } from '../../../api'
import { useEffect } from 'react'
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import UIButton from '../../Components/UIButton/UIButton'
import { ToEthiopianCalander } from '../../../SharedComp/Utility'
import ProductionStockOrderProcessorV2 from '../../StockOut/Component/OrderStockProcessor/ProductionStockOrderProcessorV2/ProductionStockOrderProcessorV2'
import { useAuth } from '../../../AuthProvider'

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}


export default function StockApprovedBranchBundle({WorkspaceData}) 
{

    const auth = useAuth();
    const [DataPackage, SetDataPackage] = useState([]);
    const [DataPackageOrigin, SetDataPackageOrigin] = useState([]);
    const [IncomingLoading, SetIncomingLoading] = useState(false);
    const [SelectedDate, SetSelectedDate] = useState(new Date());


    const [TargetPreviewData, SetTargetPreviewData] = useState();
    const [OpenPreview, SetOpenPreview] = useState(false);


    const QueryActiveProductionOrders = async()=>{
        SetIncomingLoading(true);
        try 
        {
            const res = await FetchProductionOrderBranchStockedOut(WorkspaceData.BranchID, auth.token);
            SetDataPackage(res);
            SetDataPackageOrigin(res);
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }

    useEffect(()=>
    {
        QueryActiveProductionOrders();
    }, [])

    const OpenDataPreview = async(data)=>
    {
        SetTargetPreviewData(data);
        SetOpenPreview(true);
    }

    useEffect(()=>{
        FilterByDate(new Date());
    }, [DataPackageOrigin])

    const FilterByDate = (date)=>
    {
        const _list_data = [];
        for(let i = 0; i < DataPackageOrigin.length; i++) 
        {
            const _target_date = new Date(DataPackageOrigin[i].PO_OrderDate);

            if(
                _target_date.getDay() === date.getDay() &&
                _target_date.getFullYear() === date.getFullYear() && 
                _target_date.getMonth() === date.getMonth()
            ) 
            {
                _list_data.push(DataPackageOrigin[i]);
            }
        }

        SetDataPackage(_list_data);
    }


    return (
        <div className={`${classes.order_detail_man_wrapper}`}>
            {OpenPreview && <ProductionStockOrderProcessorV2 Preview={true} Close_CALLBACK={SetOpenPreview} TargetData={TargetPreviewData}/>}

                    <div className={`${classes.filter_workshop_by_name}`}>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <li>Approved Stock Request For Workshop <strong style={{padding: '1px 4px', borderRadius: '5px', background: `var(--color-theme)`, color: `var(--background-dark_plus)`}}>{WorkspaceData.BranchName}</strong></li>
                        </div>
                    </div>
                    <div className={`${classes.filter_workshop_by_name}`}>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <li>{ToEthiopianCalander(SelectedDate) }</li>
                            <input onChange={(e)=>{SetSelectedDate(new Date(e.target.value)); FilterByDate(new Date(e.target.value))}} type="date" />
                        </div>
                    </div>

                <div className={`${classes.list_box_wrapper}`}>
                    <div className={`${classes.active_order_main_list_wrapper}`}>
                        <li className={`${classes.header_list}`}>
                            <span>Order Date</span>
                            <span>Order Created By</span>
                            <span>Target Shift</span>
                            <span>Action</span>
                        </li>
                        <div className={`${classes.list_data_wrapper}`}>
                            { !IncomingLoading &&
                                DataPackage.map((item, idx)=>(
                                    <li key={idx}>
                                        <span>{ToEthiopianCalander(new Date(item.PO_OrderDate))} @ {new Date(item.PO_OrderDate).toLocaleTimeString()}</span>
                                        <span>{item.FirstName} {item.LastName}</span>
                                        <span>{item.PO_TargetShift}</span>
                                        <span style={{display: 'flex', justifyContent: 'center'}}><UIButton label={"Document Preview"} Icon={<Paperclip weight='bold'/>} onClick={()=>{OpenDataPreview(item)}}/></span>
                                    
                                    </li>
                                ))
                            }
                            {IncomingLoading && <LoadingPlaceholder/>}
                            {
                                !IncomingLoading &&
                                DataPackage.length === 0 && 
                                <div className={`${classes.empty_box_wrapper}`}>
                                    <ul><Tray fontSize={30}/></ul>
                                    <ul>No Data</ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
    )
}
import classes from './DirectStockOrderApprovalDocument.module.css'
import data_win from '../../../SharedComp/CommonStyle/DataCreator.module.css'
import {FetchSavedProductionOrderConfig, FetchProductionOrderOWner} from '../../../SharedComp/API/Production/API_ProductionOrder'
import { useToast } from '../../../ToastContext'

import { FetchProductionOrderReqMaterial, FetchMixIngredientJoined, FetchMixSaved } from '../../../api'
import { FetchBakeryProductByID } from '../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToEthiopianCalander } from '../../../SharedComp/Utility'
import { useState } from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import PaperHeader from '../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../SharedComp/PaperHash/PaperHash'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../AuthProvider'
import { useDAControl } from '../../../DAControlContext'
import { useRef } from 'react';
import { useEffect } from 'react';
import { ApproveDirectStockRequestItemsByGroup, QueryDirectStockRequestItemsByGroup, RejectDirectStockRequestRequest } from '../../../SharedComp/API/DirectStockRequest/API_FE_DirectStockRequest'
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

export default function DirectStockOrderApprovalDocument({Preview = false, Close_CALLBACK, DataRefersh, TargetData}) 
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [EC_Orderdate, SetEC_Orderdate] = useState();
    const [UtilizedPackageIvtItem, SetUtilizedPackageIvtItem] = useState([]);
    const [IncomingLoading, SetIncomingLoading] = useState(false);
    const [ApproveLoading, SetApproveLoading] = useState(false);


    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Direct-Requested-Stock" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const ParseData = (data)=>
    {
        const valid_data = [];
        for(let i = 0; i < data.length; i++) 
        {
            valid_data.push({
                ItemName: data[i].IVItemName,
                ItemQuantity: data[i].DSRITMS_Quantity,
                ItemID: data[i].DSRITMS_ItemInvID,
                UOM: data[i].UomDescription
            })
        };

        console.log(data);

        SetUtilizedPackageIvtItem(valid_data);
    }
    const QuerySelectedGroupItems = async()=>{
        SetIncomingLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("group_id", TargetData.DSRG_ID);
            const res = await QueryDirectStockRequestItemsByGroup(fd, auth.token);
            ParseData(res);
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }
    const handleApproval = async()=>
    {
        SetApproveLoading(true);
        notify.ShowLoad("Processsing your request....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("requesst_id", TargetData.DSRG_ID);
            fd.append("processed_by", auth.user.UserAccountID);
            fd.append("input_data_package", JSON.stringify(UtilizedPackageIvtItem));
            fd.append("workspace_id", TargetData.BranchID);

            const main_res = await ApproveDirectStockRequestItemsByGroup(fd, auth.token);
            SetApproveLoading(false);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            DataRefersh();
            Close_CALLBACK(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetApproveLoading(false);
        }
        
    }

    const FetchTargetDataPackage = async()=>
    {
        try 
        {
            await QuerySelectedGroupItems();

        } catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    
    useEffect(()=>{
        FetchTargetDataPackage();
    }, [auth.employeeData])
    
    useEffect(()=>{
        if(TargetData) 
        {
            SetEC_Orderdate(ToEthiopianCalander(new Date(TargetData.DSRG_RequestDateTime)));
        }
    }, [TargetData])


    const RejectDirectStockRequest = async(target_item)=>
    {
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("group_id", target_item.DSRG_ID);

            const main_res = await RejectDirectStockRequestRequest(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            Close_CALLBACK(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Approve <span className={`${data_win.data_create_name}`}>Direct Stock Request</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

            {!IncomingLoading && <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        
                        <div className={`${classes.print_doc_non_repeat_content}`}>

                            <PaperHash text={Preview ? "copy not original" : null}/>
                            <div className={`${classes.meta_data_wrapper}`}>
                                <h2>[#] {auth.activeWorkShopName} </h2>
                                <h2>[#] Direct Stock Processing</h2>
                            </div>

                            <div className={`${classes.transcation_owner_wrapper}`}>
                                <div className={`${classes.transcation_owner}`}>

                                    <div className={`${classes.transcation_init}`}>
                                        <h2>Direct Stock Request Info</h2>
                                        <li><span>Request By</span> <span style={{fontWeight: 'bold'}}>{TargetData?.FirstName} {TargetData?.LastName}</span></li>
                                        <li><span>Request Date</span>  <span style={{fontWeight: 'bold'}}>{EC_Orderdate}</span></li>
                                        <li><span>Clock</span> <span style={{fontWeight: 'bold'}}>{TargetData ? new Date(TargetData.DSRG_RequestDateTime).toLocaleTimeString() : "--"}</span></li>
                                    </div>
                                    {/* <div className={`${classes.transcation_recive}`}>
                                        <h2>Processed By</h2>
                                        <li>{auth.employeeData?.JAKSBE_FullName}</li>
                                    </div> */}

                                </div>
                            </div>

                            <div className={`${classes.entry_detail}`}>
                                <li><span className={`${classes.entry_deatil_tag}`}>Process ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData.PO_Hash}</span></li>
                            </div>  
                            
                            
                            <div className={`${classes.main_paper_data_wrapper}`}>

                                <div className={`${classes.paper_list_wrapper}`}>

                                    <div className={`${classes.single_order_data_view_master_wrapper}`}>
                                        <div className={`${classes.packge_data_wrapper}`}>

                                            <div className={`${classes.main_prod_info_wrapper}`}>
                                                <li><span className={`${classes.prod_name_PO}`}>Requested Items</span></li>
                                            </div>

                                            <div className={`${classes.ing_list_data_wrapper}`}>
                                                <div className={`${classes.data_marging_wrapper}`}>
                                                    <li>
                                                        <span>Inventory Item Name</span>
                                                        <span className={`${classes.right_align}`}>Quantity</span>
                                                    </li>
                                                    {
                                                        UtilizedPackageIvtItem.map((item, idx)=>(
                                                            <li style={{fontWeight: 'lighter'}} key={idx}>
                                                                <span>{item.ItemName}</span> 
                                                                <span className={`${classes.right_align}`}>{(item.ItemQuantity).toFixed(2)} <strong>{item.UOM}</strong></span>
                                                            </li>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <div className={`${classes.packge_data_wrapper}`}>

                                            <div className={`${classes.main_prod_info_wrapper}`}>
                                                <li><span className={`${classes.prod_name_PO}`}>Request Reason</span></li>
                                            </div>

                                            <div className={`${classes.ing_list_data_wrapper}`}>
                                                <div className={`${classes.data_marging_wrapper}`}>
                                                    <li>{TargetData.DSRG_RequestReason}</li>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className={`${classes.special_footer_local_wrapper}`}>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Digital Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>{auth.employeeData?.JAKSBE_FullName}</li>
                                    <li className={`${classes.print_date}`}>{new Date().toDateString()}</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Physical Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.print_date}`}>Date:________________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Stock checked & approved by</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.print_date}`}>Date:________________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                            </div>

                            {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                            <div className={`${classes.final_note_po_stock_out}`}>
                                <li>This document must have all signatures to be considered valid.</li>
                            </div>

                        </div>
                    </div>
                </div>



                </div> }
                {
                    IncomingLoading && 
                    <LoadingPlaceholder preview_text='Preparing Document'/>
                }


                <div className={`${classes.action_button_wrapper}`}>

                   {Preview && <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>} 
                  {!Preview && !IncomingLoading &&   <button onClick={()=>{
                                        DAControl.Trigger(`Approve Stock Out`, 
                                        `This action will affect Inventory Balance. In other word the "Grand Total" Amount you see will be deducted form your store.`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                handleApproval();
                                            }
                                        }   
                                    );}} className={`${classes.button_approve}`}>Approve</button> }
                   {!Preview && <button 
                        className={`${classes.form_action_button_dismiss}`}
                        onClick={()=>{
                            DAControl.Trigger(
                                'Reject User Direct Stock Requesst',
                                "You are about to reject direct stock requesst from user, if you do this the user need to re-order the request",
                                true,
                                7,
                                (data)=>
                                {
                                    if(data)
                                    {
                                        RejectDirectStockRequest(TargetData)
                                    }
                                }
                            )
                        }}>Reject</button>} 
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}
import { useState } from 'react';
import classes from './HRPassSingleWorkshopeBundlePreview.module.css'
import { useEffect } from 'react';
import { ArrowCircleDown, Export, MagnifyingGlass } from '@phosphor-icons/react';
import UIButton from '../../../Components/UIButton/UIButton';
import { QueryBaseEmployeeBySyntID } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee';
import { useAuth } from '../../../../AuthProvider';
import { ToEthiopianCalander } from '../../../../SharedComp/Utility';
import { QueryEmployeeAllowanceByEmployeeID } from '../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeeAllowance';
import { QueryEmployeeCreditByEmployeeID } from '../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeeCredit';
import { QueryAllEmployeeCashPenality } from '../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeePenality';

import DotsGif from '../../../../SharedComp/CssGif/DotsGif';
import { CreateHRPassDataReadyEntry } from '../../../../SharedComp/API/Payroll/API_FE_PayrollHRPass';
import { useToast } from '../../../../ToastContext';
const currency_format = (val)=> 
    {
        return val.toLocaleString('en-US', {
            style: 'currency',
            currency: 'ETB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

export default function HRPassSingleWorkshopeBundlePreview({DataRefreshView_CALLBACK, TargetData}) 
{
    const notify = useToast();
    const auth = useAuth();
    const [Loading, SetLoading] = useState(false);
    const [OriginalDataFeedPass, SetOriginalDataFeedPass] = useState([]);


    const [EmployeeData, SetEmployeeData] = useState([]);
    const [EmployeeDataOriginal, SetEmployeeDataOriginal] = useState([]);


    const [EmployeePenalityMap, SetEmployeePenalityMap] = useState(new Map());
    const [EmployeeAllowaceMap, SetEmployeeAllowaceMap] = useState(new Map());
    const [EmployeeCreditMap, SetEmployeeCreditMap] = useState(new Map());

    const QueryEmployeeAllowance = async(emp_id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", emp_id);
            const main_res = await QueryEmployeeAllowanceByEmployeeID(fd, auth.token);

            return main_res;
        }catch(err) 
        {

        }
    }
    const QueryEmployeeCredit = async(emp_id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", emp_id);
            const main_res = await QueryEmployeeCreditByEmployeeID(fd, auth.token);
            return main_res;
        }catch(err) 
        {

        }
    }
    const QueryEmployeePenality = async(emp_id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", emp_id);
            const main_res = await QueryAllEmployeeCashPenality(fd, auth.token);
            return main_res;
        }catch(err) 
        {

        }
    }

    const ProcessEmployeeCredit = ()=>
    {

    }

    const ParsePrepareData = async()=>
    {
        const __package = JSON.parse(TargetData.PYROLLDF_DataPackage);


        let data_employee_synt = [];

        const emp_allowance_map = new Map();
        const emp_credit_map = new Map();
        const emp_penality_map = new Map();
        for(let i = 0; i < __package.length; i++) 
        {
            //Process Employee Allowance
            const fd = new FormData();
            fd.append("target_id", __package[i].EmployeeID);
            const main_res = await QueryBaseEmployeeBySyntID(fd, auth);
            const __valid_employee_found = main_res.length > 0;


            // Process Employee Allowance
            if(__valid_employee_found) 
            {
                const __allowance_list = await QueryEmployeeAllowance(main_res[0].JAKSBE_ID);
                let _total_allowance_amount = 0;
                for(let i = 0; i < __allowance_list.length; i++) 
                {
                    // compute if this allowance is taxable
                    if(__allowance_list[i].HRMCAT_TaxPercentage) 
                    {
                        const _precentage_amount = parseFloat(__allowance_list[i].HRMCAT_TaxPercentage);
                        const _taxable_amount = (_precentage_amount / 100.0) * parseFloat(__allowance_list[i].JAKSEOPALLOW_Amount);
                        const _payabel_amount = parseFloat(__allowance_list[i].JAKSEOPALLOW_Amount) - _taxable_amount;
                        _total_allowance_amount += _payabel_amount;
                    } else 
                    {
                        _total_allowance_amount += parseFloat(__allowance_list[i].JAKSEOPALLOW_Amount);
                    }
                }
                emp_allowance_map.set(main_res[0].JAKSBE_ID, parseFloat(_total_allowance_amount));
                
            }
            

            // Process Employee Credit
            if(__valid_employee_found) 
            {
                const __employee_credit = await QueryEmployeeCredit(main_res[0].JAKSBE_ID);
                if(__employee_credit.length > 0) 
                {
                    emp_credit_map.set(main_res[0].JAKSBE_ID, __employee_credit);
                }
                let __total_Credit = 0;
                const __payed_credit_entry = [];
                // make sure this employee has any credit
                const _max_payble = parseInt(auth.GlobalConfig.GC_DistributedCashPenaltyAcrossMultiplePayrollCyclesIfExceed);
                if(emp_credit_map.has(main_res[0].JAKSBE_ID)) 
                {
                    const __credit_package = emp_credit_map.get(main_res[0].JAKSBE_ID);
                    __credit_package.sort((a, b) => a.JAKSEOPC_CreditAmount - b.JAKSEOPC_CreditAmount);

                    for(let i = 0; i < __credit_package.length; i++)
                    {
                        const _target_payable_amount = parseFloat(__credit_package[i].JAKSEOPC_CreditAmount);
                        if((_target_payable_amount + __total_Credit) <= _max_payble) 
                        {
                            
                            __total_Credit += _target_payable_amount;
                            __payed_credit_entry.push({
                                FullyPaid: true,
                                CreditID: __credit_package[i].JAKSEOPC_ID,
                                PaidAmount: _target_payable_amount,
                                RemainAmount: 0
                            });
                        } else 
                        {
                            const __next_payable =  parseFloat(_target_payable_amount) + parseFloat(__total_Credit);
                            const __total_credit_we_can_afford = parseInt(_max_payble) - __total_Credit;
                            if(__total_credit_we_can_afford < 0)
                            {
                                break;
                            }

                            __total_Credit += __total_credit_we_can_afford;
                            __payed_credit_entry.push({
                                FullyPaid: __total_credit_we_can_afford === _target_payable_amount,
                                CreditID: __credit_package[i].JAKSEOPC_ID,
                                PaidAmount: __total_credit_we_can_afford,
                                RemainAmount: _target_payable_amount - __total_credit_we_can_afford
                            });

                        }
                    }
                }

                // Employee Penality
                // /////////// Employee Penality
                const __employee_penality = await QueryEmployeePenality(main_res[0].JAKSBE_ID);
                if(__employee_penality.length > 0) 
                {
                    emp_penality_map.set(main_res[0].JAKSBE_ID, __employee_penality)
                }
                let __toal_penality = 0;
                const __paid_penality_entry = [];

                if(emp_penality_map.has(main_res[0].JAKSBE_ID)) 
                {
                    const _target_penlity_list = emp_penality_map.get(main_res[0].JAKSBE_ID);

                    for(let i = 0; i < _target_penlity_list.length; i++) 
                    {
                        __toal_penality += parseFloat(_target_penlity_list[i].JAKSEOPPC_PenalityAmount);
                        __paid_penality_entry.push({
                            FullyPaid: true,
                            CreditID: _target_penlity_list[i].JAKSEOPPC_ID ,
                            PaidAmount: parseFloat(_target_penlity_list[i].JAKSEOPPC_PenalityAmount),
                            RemainAmount: 0
                        });
                    }
                }
                

                const __name_Array =  main_res[0].JAKSBE_FullName.split(' ');
                const __full_name_mod = __name_Array[0] + ' ' + __name_Array[1];
                const _init_data = {
                    RawID:  main_res[0].JAKSBE_ID,
                    SyntID: main_res[0].JAKSBE_SyntheticID,
                    FullName: __full_name_mod,
                    JobPosition: main_res[0].HRMJPT_Name,
                    DateOfEmployementReadableET: ToEthiopianCalander(new Date(main_res[0].JAKBECP_EmploymentStartDate)),
                    DateOfEmployement: main_res[0].JAKBECP_EmploymentStartDate,
                    GrossSalary: (main_res[0].JAKBECP_CurrentGrossSalary / 30) * __package[i].TotalDay,
                    DutyDateCount: __package[i].TotalDay,
                    TotalAllowance: emp_allowance_map.get(main_res[0].JAKSBE_ID),
                    Credit: __total_Credit,
                    PayedData: __payed_credit_entry,
                    Penality: __toal_penality,
                    TotalPenalityEntry: __paid_penality_entry,
                    BankAccount: main_res[0].JAKBECP_BankAccount,
                    BankCode: main_res[0].HRMBNKT_Code,
                    ApplyDeductionByDefault: auth.GlobalConfig.GC_ApplyDeductionByDefault,
                    ApplyPenalityDeduction: auth.GlobalConfig.GC_ApplyDeductionByDefault
                }

                data_employee_synt.push(_init_data);
            }
            
            
        }

        SetEmployeeCreditMap(new Map(emp_credit_map));
        SetEmployeeAllowaceMap(new Map(emp_allowance_map));

        SetEmployeeData(data_employee_synt);
        SetEmployeeDataOriginal(data_employee_synt);

        SetOriginalDataFeedPass(__package);
        SetLoading(false);
    }




    useEffect(()=>{
         SetLoading(true);
            setTimeout(()=>{
                ParsePrepareData();
            }, 1000)
    }, [])




    const UpdateMasterAttendyData = (prop_name, data, value)=>
        {

            SetEmployeeDataOriginal((prev) => {
    
                const _update = prev.map((item) => {
                    let _dynamic_data = {...item};
                    if (item.SyntID === data.SyntID) {
    
                        if(auth.GlobalConfig.GC_EnableAutomaticUpdatesForLinkedEntries) 
                        {

                        }
    
                        return { ..._dynamic_data, [prop_name]: value };
                    }
                    return item;
                });
                return _update; 
            });
    
            SetEmployeeData((prev) => {
    
                const _update = prev.map((item) => {
                    let _dynamic_data = {...item};
                    if (item.SyntID === data.SyntID) {
    
                        if(auth.GlobalConfig.GC_EnableAutomaticUpdatesForLinkedEntries) 
                        {

                        }
    
                        return { ..._dynamic_data, [prop_name]: value };
                    }
                    return item;
                });
                return _update; 
            });
    }
    const FilterEmployee = (filter_key)=>
    {
        const valid_list = [];
        for(let i = 0; i < EmployeeDataOriginal.length; i++) 
        {
            if(
                EmployeeDataOriginal[i].FullName.toLowerCase().includes(filter_key.toLowerCase()) ||
                EmployeeDataOriginal[i].SyntID.toLowerCase().includes(filter_key.toLowerCase()) || 
                EmployeeDataOriginal[i].JobPosition.toLowerCase().includes(filter_key.toLowerCase())
            ) 
            {
                valid_list.push(EmployeeDataOriginal[i]);
            }
        }

        SetEmployeeData(valid_list);
    }

    const ProcessHrDataEntry = async() =>
    {
        try 
        {
            const fd = new FormData();
            fd.append("processed_by", auth.user.UserAccountID);
            fd.append("package_data", JSON.stringify(EmployeeData));
            fd.append("parent_data_feed_id", TargetData.PYROLLDF_ID);
            const main_res = await CreateHRPassDataReadyEntry(fd, auth.token);
            notify.ShowSuccess(main_res.Msg);
            DataRefreshView_CALLBACK();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>

            <div className={`${classes.hr_data_feed_header}`}>

                <h2>[HR-PP] Payroll</h2>
                <div className={`${classes.top_hr_data_feed_pass_right_wrapper}`}>
                    <UIButton Icon={<Export weight='bold'/>} label={"Process"} onClick={()=>{ProcessHrDataEntry()}}/>
                </div>  

            </div>

            <div className={`${classes.hr_data_process_pass_inner_tbl_wrapper}`}>

                <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilterEmployee(e.target.value)}} autoComplete='off' placeholder='Eg. Employee Name, Job Position, Employee ID' type="text" /> 
                        <span><MagnifyingGlass weight='bold'/></span>
                    </div>
                </div>

                <div className={`${classes.list_box_wrapper}`}>

                    <div className={`${classes.active_order_main_list_wrapper}`}>
                        <li className={`${classes.header_tab_tabe}`}>
                            <span>Employee Full Name</span>
                            <span>Job Position</span>
                            <span>Date of Employment</span>
                            <span>Gross Monthly Salary</span>
                            <span>Total Allowances</span>
                            <span>Credit Deduction</span>
                            <span>Penality Deduction</span>
                            <span>Bank Account Number</span>
                        </li>
                        <div className={`${classes.list_data_wrapper}`}>
                            { Loading ? 
                            <div className={`${classes.dots_view_loading_hr_pass_wrapper}`}>
                                <li>Syncing your data</li>
                                <DotsGif/>
                            </div> : 
                                EmployeeData.map((item, idx)=>(
                                    <li key={item.FullName + item.SyntID + idx}>
                                        <span className={`${classes.employee_name_text}`}>{idx + 1}. {item.FullName}</span>
                                        <span>{item.JobPosition}</span>
                                        <span>{item.DateOfEmployementReadableET}</span>
                                        <span className={`${classes.employee_salary}`}>{currency_format(item.GrossSalary)} #{item.DutyDateCount}</span>
                                        <span>{currency_format(EmployeeAllowaceMap.get(item.RawID))}</span>
                                        <span> 
                                            <input onChange={(e)=>{UpdateMasterAttendyData('ApplyDeductionByDefault', item, e.target.checked)}} checked={item.ApplyDeductionByDefault}  className={`${classes.ot_adder_input_wrap}`}  type="checkbox" />
                                            {currency_format(item.Credit)}
                                        </span>
                                        <span>
                                            <input onChange={(e)=>{UpdateMasterAttendyData('ApplyPenalityDeduction', item, e.target.checked)}} checked={item.ApplyPenalityDeduction}  className={`${classes.ot_adder_input_wrap}`}  type="checkbox" />
                                            {auth.GlobalConfig.GC_AllowToModifyPenalityAmount ?
                                                <input disabled={!auth.GlobalConfig.GC_AllowToModifyPenalityAmount} title={'If you modify this value, it will be considered an adjustment to the overall penalty amount for the current month.'} onChange={(e)=>{UpdateMasterAttendyData('Penality', item, e.target.value)}} className={`${classes.excel_input_like}`} type="text" value={item.Penality} />
                                            :  currency_format(item.Penality)
                                            }
                                        </span>
                                        <span className={`${classes.employee_bank_account_wrapper_item}`}>{item.BankCode}-{item.BankAccount}</span>
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}
import classes from './EmployeePersonalData.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import ReactFlagsSelect from "react-flags-select";
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'
import { useState } from 'react';
import { EnvelopeSimpleOpen, Phone, User } from '@phosphor-icons/react';


export default function EmployeePersonalData({UpdateEmployeeData, Employee})
{

    const GenderSelectionCoice = [{ value: 'Male', label: 'Male'  }, { value: 'Female', label: 'Female'  }];
    const EduStatusSelectionCoice = [
        { value: 'Under 8th Grade', label: 'Under 8th Grade'  }, 
        { value: 'Under 10th Grade', label: 'Under 10th Grade' },
        { value: 'Certificate', label: 'Certificate' },
        { value: 'Diploma', label: 'Diploma' },
        { value: 'Degree', label: 'Degree' },
        { value: 'Masters', label: 'Masters' },
        { value: 'PHD', label: 'PHD' },
        { value: 'Doctoral ', label: 'Doctoral' },
        { value: 'Other ', label: 'Other' }
    ];

    const UpdateEmployeeInput = (e) =>
    {
        UpdateEmployeeData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        })); 
    }
    const UpdateEmployeeInputDropDown = (_name, _value) =>
    {
        UpdateEmployeeData(prev => ({
            ...prev,
            [_name]: _value
        })); 
    }
    




    return (
        <div className={`${classes.master_product_type_inner_config_wrapper}`}>

            <div className={`${classes.personal_employee_info_input_col_group}`}>
                <MasterDataRegInput Icon={<User weight='bold'/>} input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_first_name'] ? Employee['emp_first_name'] : ''} name='emp_first_name' type="text" placeholder='First Name' autoComplete='off' required />}/>
                <MasterDataRegInput Icon={<User weight='bold'/>}input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_midd_name'] ? Employee['emp_midd_name'] : ''} name='emp_midd_name' type="text" placeholder='Middle Name' autoComplete='off' required />}/>
                <MasterDataRegInput Icon={<User weight='bold'/>} input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_late_name'] ? Employee['emp_late_name'] : ''} name='emp_late_name' type="text" placeholder='Last Name' autoComplete='off' required />}/>
                <MasterDataRegInput Icon={<EnvelopeSimpleOpen weight='bold'/>} input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_email'] ? Employee['emp_email'] : ''} name='emp_email' type="email" placeholder='Email' autoComplete='off' />}/>
                <MasterDataRegInput Icon={<Phone weight='bold'/>} input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_phone'] ? Employee['emp_phone'] : ''} name='emp_phone' type="number" placeholder='Telephone' autoComplete='off' required />}/>

                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_age'] ? Employee['emp_age'] : ''} name='emp_age' type="number" placeholder='Age' autoComplete='off' required />}/>
                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_birth_date'] ? Employee['emp_birth_date'] : ''} name='emp_birth_date' type="date"   required />} tip={"Birth Date"}/>
                <MasterDataRegInput input={
                                <Select value={Employee['emp_gender'] ? Employee['emp_gender'] : ''} onChange={(data)=>{UpdateEmployeeInputDropDown('emp_gender', data)}} name={"emp_gender"} options={GenderSelectionCoice} placeholder={"Gender"} isSearchable={true} styles={Custom_DD_Style} required ={true} /> } />
            </div>

            <div className={`${classes.personal_employee_info_input_col_group}`}>
                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_address'] ? Employee['emp_address'] : ''} name='emp_address' type="text" placeholder='Address' autoComplete='off' required />}/>
                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_emerge_name'] ? Employee['emp_emerge_name'] : ''} name='emp_emerge_name' type="text" placeholder='Emergency Contact Full Name' autoComplete='off' required />}/>
                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_emerge_phone'] ? Employee['emp_emerge_phone'] : ''} name='emp_emerge_phone' type="text" placeholder='Emergency Contact Phone' autoComplete='off' required />}/>
                <MasterDataRegInput input={
                                <Select value={Employee['emp_edu_stat'] ? Employee['emp_edu_stat'] : ''} onChange={(data)=>{UpdateEmployeeInputDropDown('emp_edu_stat', data)}} name={"emp_edu_stat"} options={EduStatusSelectionCoice} placeholder={"Education Status"} isSearchable={true} styles={Custom_DD_Style} required ={true} /> } />
                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_field_of_study'] ? Employee['emp_field_of_study'] : ''} name='emp_field_of_study' type="text" placeholder='Field of Study' autoComplete='off' required />}/>
                <MasterDataRegInput input={ <input onChange={(e)=>{UpdateEmployeeInput(e)}} value={Employee['emp_institution'] ? Employee['emp_institution'] : ''} name='emp_institution' type="text" placeholder='Institution' autoComplete='off' required />}/>
                <MasterDataRegInput input={
                                <ReactFlagsSelect
                                selected={Employee['emp_friend_nation'] ? Employee['emp_friend_nation'] : null}
                                selectedSize= '0.7em'
                                optionsSize = '0.7em'
                                onSelect={(code)=>{UpdateEmployeeInputDropDown('emp_friend_nation', code)}}
                                searchable={true}
                            /> } />
            </div>

        </div>
    )
}
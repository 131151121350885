import classes from './BaverageDataPackageRC.module.css'
import { FetchBeverageProductAll } from '../../../../../../../SharedComp/API/CoreDataClusters/API_FE_Baverage';
import { useAuth } from '../../../../../../../AuthProvider';
import { useToast } from '../../../../../../../ToastContext';
import { useEffect, useState } from 'react';
import { ArrowBendUpRight, Lock, LockOpen, SealCheck, Warning } from '@phosphor-icons/react';
import UIButton from '../../../../../../Components/UIButton/UIButton';



export default function BaverageDataPackageRC({Interaction, BaverageDataPackage, SetBaverageDataPackage}) 
{
    
    const auth = useAuth();
    const notify = useToast();

    // ///////////////////////////// Baverage START 
    const [LockBaverageInteraction, SetLockBaverageInteraction] = useState(false);
    const [BaverageProductList, SetBaverageProductList] = useState([]);
    const [BaverageProductListOriginal, SetBaverageProductListOriginal] = useState([]);

    const InitBaverageDataPackage = async(data)=>
    {
        const _init_list = new Map();
        for(let i = 0; i < data.length; i++) 
        {
            _init_list.set(data[i].CMBVP_Name,
                {
                    Name: data[i].CMBVP_Name,
                    ProductID: data[i].CMBVPID,
                    Price: data[i].CMBVP_Price,
                    Sold: 0
                }
            )
        }

        SetBaverageDataPackage(new Map(_init_list));
    }
    const UpdateBaverageSoldAmount = async(name, amount)=>
    {
        SetBaverageDataPackage((prevList) => {
            const newList = new Map(prevList);
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Sold: amount });  
            }
            return newList; 
        });
    }
    const HandleBaverageFilter = async(_key)=>
        {
            const valid_list = [];
    
            for(let  i = 0; i < BaverageProductListOriginal.length; i++) 
            {
                const _seach_val = BaverageProductListOriginal[i].CMBVP_Name;
                if(_seach_val.toLowerCase().includes(_key.toLowerCase())) 
                {
                    valid_list.push(BaverageProductListOriginal[i]);
                }
            }
    
            SortAndSetBakertList(valid_list);
    }
    const SortAndSetBakertList = async(data)=>
    {
        const sortedProducts = data.sort((a, b) => {
            if (a.CMBVP_Name < b.CMBVP_Name) return -1; 
            if (a.CMBVP_Name > b.CMBVP_Name) return 1; 
            return 0;                         
        });

        SetBaverageProductList(sortedProducts);
    }
    const QueryBaverageProductList = async()=>
    {
        try 
        {
            const main_res = await FetchBeverageProductAll(auth.token);
            InitBaverageDataPackage(main_res);
            SortAndSetBakertList(main_res);
            SetBaverageProductListOriginal(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Baverage Product List: " + err.message);
        }
    }

    // /////////////////////////////// Baverage END
    

    useEffect(()=>{
        QueryBaverageProductList();
    }, [])

    return (
            <div className={`${classes.list_box_wrapper}`}>

            <div className={`${classes.heder_type_main_wrapper}`}>
                <h3>Baverage</h3>
                <div className={`${classes.group_filter_and_action}`}>
                    <input onChange={(e)=>{HandleBaverageFilter(e.target.value)}} type="text" placeholder='Filter' autoComplete='off' />
                </div>
            </div>

            <div className={`${classes.list_content_section_wrapper}`}>
                <div style={{pointerEvents: LockBaverageInteraction ? 'none' : '', filter: LockBaverageInteraction ? 'brightness(50%)' : 'brightness(100%)' }} className={`${classes.items_list_wrapper}`}>
                    {
                        BaverageProductList.map((item, idx)=>(
                            <div key={item.CMBVP_Name} className={`${classes.list_single_item}`}>
                                <div className={`${classes.produc_singluar_name} ${classes.data_inner_wrapper}`}>
                                    {item.CMBVP_Name}
                                </div>
                                <div className={`${classes.data_left_side_input_wrapper}`}>
                                    <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                        <li className={`${classes.unsold_prod_report}`}> <span><SealCheck weight='fill'/></span> Sold Quantity</li>
                                        <input
                                            key={item.CMBVP_Name + "_sold"}
                                            value={BaverageDataPackage.get(item.CMBVP_Name)?.Sold || ''}
                                            onChange={(e) => UpdateBaverageSoldAmount(item.CMBVP_Name, e.target.value)}
                                            name={item.CMBVP_Name + '_'+'sold'}
                                            type="number" 
                                            min={0} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={`${classes.bottom_action_wrapper}`}>
                <UIButton 
                    Variant={LockBaverageInteraction ? "Danger" : "Primary"} 
                    Icon={LockBaverageInteraction ? <Lock weight='fill'/> : <LockOpen weight='fill' />} 
                    label={LockBaverageInteraction ? "Unlock Interaction" : "Lock Interaction"} 
                    onClick={
                        ()=>{
                            SetLockBaverageInteraction(!LockBaverageInteraction);
                            Interaction(!LockBaverageInteraction);
                            }
                            }/>
            </div>
            </div>
    )
}
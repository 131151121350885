import classes from './ManageUser.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNodes, faCircleNotch, faEye, faEyeSlash, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import { PrepareDropDownList } from '../../../../SharedComp/Utility';
import LocalNotification from '../../../../SharedComp/LocalNotification/LocalNotification'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import axios from 'axios';
import man_placeholder from '../../../../asset/images/placeholder-man.jpg'
import chick_placeholder from '../../../../asset/images/placeholder-chick.jpg'
import { useEffect, useRef, useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'

import DecisionAssurance from '../../../../SharedComp/DecisionAssurance/DecisionAssurance'
import { useDAControl } from '../../../../DAControlContext'
import { useAuth } from '../../../../AuthProvider'

import {TerminateUserSession} from '../../../../SharedComp/API/UserAccess/API_FE_UserManage'
import { useToast } from '../../../../ToastContext'
import UIButton from '../../../Components/UIButton/UIButton'
import { Prohibit, SealCheck, Skull, StopCircle } from '@phosphor-icons/react'
import PasswordVerfiyPage from './PasswordVerfiyPage/PasswordVerfiyPage'


// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     iconSize: [20, 20],
//     className: `${classes.map_marker}`
// });

// L.Marker.prototype.options.icon = DefaultIcon;


const mapStyle = { height: "200px", width: "100%", borderRadius: '10px' };

export default function ManageUser({UsrData,RefCallBack, Close_CALLBACK, MsgTrigger_CALLBACK})
{
    let position = [1.024307, 1.866125];
    const [Placeholder, SetPlaceholder] = useState(chick_placeholder);
    const [SiteList, SetSiteList] = useState([]);
    const [WorkspaceAccessList, setWorkspaceAccessList] = useState(null);
    const [WorkspaceAccessListBK, SetWorkspaceAccessListBK] = useState(null);
    const [EmployeeDetail, SetEmployeeDetail] = useState();
    const [NewUserKey, SetNewUserKey] = useState();
    const [HideKey, SetHideKey] = useState(false);
    const [RoleList, SetRoleList] = useState([]);
    const [ActiveRole, SetActiveRole] = useState();
    const [LeafletReady, SetLeafletReady] = useState(false);
    const [UsrLoc, SetUsrLoc] = useState({lat: 0.0, lon: 0.0});
    const [verifiyingEmail, SetverifiyingEmail] = useState(false);
    const [TargetUser, SetTargetUser] = useState();


    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);

    const [LocalUserData, SetLocalUserData] = useState(null);
    const [InitialLoaded, SetInitialLoaded] = useState(false);

    // Ref
    const NewKeyInputFormRef = useRef();
    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();


    // Decision Assurance
    const [daState, SetDaState] = useState(false);
    const [agreement, SetAgreement] = useState();
    const [reqCallbackData, SetReqCallbackData] = useState();
    const [ActionName, setActionName] = useState(null);

    const HandleEmailVerification = async(_item)=>
    {
        SetverifiyingEmail(true);
        SetTargetUser(_item);
    }

    // fetch core
    useEffect(()=>{

        setTimeout(()=>{
            fetch(getBaseURL() + 'query_usr', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ID: UsrData.UserAccountID
                })
            }).then(res => res.json())
            .then((data)=>{
                SetLocalUserData(data[0]);
                SetUsrLoc(JSON.parse(data[0].Address));
                const lox = JSON.parse(data[0].Address);
                position[0] = lox.lat;
                position[1] = lox.lon;
            })
            .catch(err => {console.log(err)})
        }, 1000)

    }, [InitialLoaded]);

    const Custom_DD_Style = {
        control: (provided, state) => ({
            ...provided,
            width: 160,
            minHeight: 20,
            height: 35,
            fontWeight: 'bold',
            fontSize: '0.8em',
            background: 'var(--background-dark_plus)',
            borderColor: 'var(--background-dark)',
            color: 'var(--secondary-color)',
            borderRadius: 1,
            borderColor: 'none',
            boxShadow: 'none',
            '&:hover': {
            borderColor: 'none',
            boxShadow: 'none',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--body-background)' : 'var(--background-dark_plus)', // Change background color on hover
            color:'var(--text_light_white)',
            transition: 'background-color 0.2s ease-in-out',
            cursor: 'pointer'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--text_light_white)', // Set the text color for the selected option in the dropdown
          }),
          input: (provided) => ({
            ...provided,
            color: 'var(--text_light_white)', //input text color when searching
          }),
        menu: (provided) => ({
            ...provided,
            zIndex: 100, // Increase the z-index if options are not displayed properly
            backgroundColor: 'var(--background-light)',
            fontSize: '0.9em',
            borderRadius: 1,
            fontWeight: 500,
        }),
      };

    function GenerateNewRandomKey(pass_leng = 10) 
    {
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
        const allchars = upperCase + lowerCase + numbers + specialChars;
        const char_count = allchars.length;

        let pass = '';
        for(let  i = 0; i < pass_leng; i++)
            pass += (allchars[Math.floor(Math.random() * char_count)]);

        SetNewUserKey(pass);
    }
    function HandleWorkshopeSelection(e, _id) 
    {
        const { name, checked } = e.target;

        setWorkspaceAccessList(prevState => ({
            ...prevState,
            [name]: checked
        }));
        SetWorkspaceAccessListBK(prevState => ({
            ...prevState,
            [name]: checked ? _id : -1
        }));
    
    }
    function InitState(name, val, _id) 
    {
        setWorkspaceAccessList(prevState => ({
            ...prevState,
            [name]: val
        }));

        SetWorkspaceAccessListBK(prevState => ({
            ...prevState,
            [name]: _id
        }));
    }

    function QueryUserRole() 
    {
        fetch(getBaseURL() + 'master-data/query_usr_roles', {
            method: 'POST'
        })
        .then(res => res.json())
        .then((data)=>{PrepareDropDownList(data, SetRoleList)})
        .catch(err => console.log(err));

        if(LocalUserData.Type === 'Standard') 
        {
            fetch(getBaseURL() + 'employee/query_employee_id/', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ EmpID: LocalUserData.WorkspaceID }),
                })
                .then(res => res.json())
                .then((data) => {
                    SetEmployeeDetail(data[0]);
                    console.log(data);
                });
        }
    }

    function SetPlaceholderPic() 
    {
        SetPlaceholder(man_placeholder);
        if(LocalUserData.Type === 'Standard' && EmployeeDetail)  
        {
            if(EmployeeDetail.Gender === 'Female')
                SetPlaceholder(chick_placeholder);
        }
    }

    function PrepAvailAccess() 
    {
        const _current_state = JSON.parse(LocalUserData.WorkspacePrivilege);

        for(let i = 0; i < SiteList.length; i++) 
        {
             const __id = parseInt(SiteList[i].BranchID);
             const __branch_name = SiteList[i].BranchName;
 
             InitState(__branch_name, false, -1);
 
             for(let key in _current_state) 
             {
                 const local_id = parseInt(_current_state[key]);
                 if(local_id === __id)
                 {
                     InitState(__branch_name, true, __id);
                     break;
                 }
             }
        }
    }


    useEffect(()=>{
        
        if(LocalUserData) 
        {
            QueryUserRole();
            SetPlaceholderPic();
            PrepAvailAccess();
        }

    }, [LocalUserData, SiteList])


    function HandleAccessUpdate(e) 
    {
        e.preventDefault();
        LocalLoadTrigger();

        setTimeout(()=>{
            const fd = new FormData();
            fd.append("UsrID", LocalUserData.UserAccountID);
            fd.append("AccessPack", JSON.stringify(WorkspaceAccessListBK));
            axios.post(getBaseURL() + `update_usr_access_workspace`, fd)
            .then(data => LocalMsgCallback(data))
            .catch(err => console.log(err));
        }, 2000)
    }

    function HandlePassKeyUpdate(e) 
    {
        e.preventDefault();
        
        LocalLoadTrigger();
        setTimeout(()=>{

            const fd = new FormData();
            fd.append("NewUserKey", NewUserKey);
            fd.append("UsrID", LocalUserData.UserAccountID);
            axios.post(getBaseURL() + `update_usr_access_passkey`, fd)
            .then(data => {
                LocalMsgCallback(data);
                SetNewUserKey('');
                this.NewKeyInputFormRef.reset();
            })
            .catch(err => console.log(err));
        }, 3000)

       
    }

    function UpdateUserRole(e) 
    {
        e.preventDefault();
        LocalLoadTrigger();

        setTimeout(()=>{
            const fd = new FormData();
            fd.append("NewRole", JSON.stringify(ActiveRole));
            fd.append("UsrID", LocalUserData.UserAccountID);
            axios.post(getBaseURL() + `update_usr_role`, fd)
            .then(data => {
                LocalMsgCallback(data);
                SetActiveRole('');
            })
            .catch(err => console.log(err));
        }, 2000)
    }

    function UpdateAccountState() 
    {
        LocalLoadTrigger();
        
        setTimeout(() => {

            const new_state = LocalUserData.AccountState === 'Active' ? 'Suspended' : 'Active';
            const fd = new FormData();
            fd.append("NewState", new_state);
            fd.append("UsrID", LocalUserData.UserAccountID);
            axios.post(getBaseURL() + `update_usr_state`, fd)
            .then(data => LocalMsgCallback(data))
            .catch(err => console.log(err));
            
        }, 2000);
    }

    useEffect(() => 
    {
        fetch(getBaseURL() +  'master-data/get-company-branch')
            .then(res => res.json())
            .then((data) => SetSiteList(data));
    }, []) 


    function LocalLoadTrigger() 
    {
        SetMsgPkg({Key : Math.random() * 10000, Msg: '##', State: 0})
        SetOpenMyNoti(true); 
    }
    function LocalMsgCallback(res) 
    {
        SetInitialLoaded(!InitialLoaded);
        RefCallBack();
        MyMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }

    function MyMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true); 
    }

    function InitRevockAction(id, name) 
    {
        SetAgreement(`You are about to Revock Access to '${LocalUserData.Username}'. Are you sure you want to do this?`);
        SetReqCallbackData(LocalUserData.UserAccountID);
        SetDaState(true);
        setActionName("Revock Access")
    }

    const HandleUserSessionTermination = async (user_data)=>
    {

        if(!auth.user) 
        {
            notify.ShowError("Failed to determine current user, please try again!");
            return;
        }

        if(auth.user.UserAccountID === user_data.UserAccountID) 
        {
            notify.ShowError("You cannot terminate your own session. Please sign out if you want to end your session.");
            return;
        }

        if(user_data.IsActive !== 1) 
        {
            notify.ShowError("You cannot terminate an inactive session.");
            return;
        }

        notify.ShowLoad("Processing your request....", 10);
        try 
        {

            const fd = new FormData();
            fd.append("user_id", user_data.UserAccountID);
            const main_res = await TerminateUserSession(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    function RemoveItem(data) 
    {
        if(!data)
        {
            return;
        }
        const fd = new FormData();
        fd.append("ID", data);
        fd.append("Name", LocalUserData.FirstName + ' ' + LocalUserData.LastName);
        axios.post(getBaseURL() + 'purge_account_usr', fd)
        .then((res)=>{MsgTrigger_CALLBACK(res)})
        .catch(err => {console.log(err)});
    }

// Add this (ValidEmail) to user account after Email
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            {daState && 
                <DecisionAssurance  
                    ActionName={ActionName}
                    CallbackData={reqCallbackData} 
                    Msg={agreement} 
                    openController={SetDaState} 
                    HandleResponse={RemoveItem}
                />
            }

            {verifiyingEmail && <PasswordVerfiyPage XUser={TargetUser} CloseModal={SetverifiyingEmail} />}

        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Manage User</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            {OpenMyNoti && <LocalNotification key={MsgPkg.Key} Msg={MsgPkg.Msg} State={MsgPkg.State}/>}


            {LocalUserData && 
            <div className={`${classes.data_body}`}>

            <div className={`${classes.inner_wrapper}`}>

                <div className={`${classes.header_wrap}`}>

                    <div className={`${classes.top_level_user_detail}`}>
                        <div className={`${classes.user_pic_wrapper}`}>
                            <img src={Placeholder} alt="" />
                        </div>
                        <div className={`${classes.user_name_wrapper}`}>
                            <li>{LocalUserData.Username} / {LocalUserData.RoleNameLazy}</li>
                        </div>
                    </div>

                    <div className={`${classes.session_detail}`}>
                        <div className={`${classes.state_wrapper}`}>
                            <button className={`${LocalUserData.AccountState ===  'Active' ? classes.account_state_active : classes.account_state_suspend}`}>{LocalUserData.AccountState}</button>
                            <button className={`${LocalUserData.IsActive ? classes.online_state : classes.offline_state}`}>{LocalUserData.IsActive ? 'Online' : 'Offline'}</button>
                        </div>

                        <div className={`${classes.info_wrap}`}>

                            <div className={`${classes.raw_info_wrap}`}>

                                <li>
                                {LocalUserData.FirstName} {LocalUserData.LastName}
                                </li>
                                <li className={`${classes.user_email_preview_check}`}>
                                    {LocalUserData.Email} {LocalUserData.ValidEmail ? <span className={`${classes.verified_email}`}><SealCheck size={16} weight='bold'/></span> : <span onClick={()=>{HandleEmailVerification(LocalUserData)}} className={`${classes.unverified_email}`}>Unverified Email</span> }
                                </li> 
                                <li>
                                    {LocalUserData.Type} - User
                                </li>

                                    <form ref={()=>{return NewKeyInputFormRef}} onSubmit={HandlePassKeyUpdate} method="post">
                                        <div className={`${classes.pass_update_wrapper}`}>
                                            <input value={NewUserKey} onChange={(e)=>{SetNewUserKey(e.target.value)}} type={HideKey ? 'password' : 'text'} placeholder='New Password' required />
                                            <div className={`${classes.pass_field_action}`}>
                                                    <button type='button' onClick={(e)=>{SetHideKey(!HideKey)}}><FontAwesomeIcon icon={HideKey ? faEye : faEyeSlash}></FontAwesomeIcon></button>
                                                    <button type='button' onClick={(e)=>{GenerateNewRandomKey()}}><FontAwesomeIcon icon={faCircleNodes}></FontAwesomeIcon></button>
                                                    <button type='submit'>Save</button>
                                            </div>
                                        </div>
                                    </form>
                                    <form onSubmit={UpdateUserRole} method="post">
                                        <div className={`${classes.role_update_wrapper}`}>
                                            <Select
                                                onChange={(data)=>{SetActiveRole(data)}}
                                                name={"dd_data"}
                                                options={RoleList}
                                                placeholder={"Update Role ..."}
                                                isSearchable={true}
                                                styles={Custom_DD_Style}
                                                required ={true}
                                            />
                                            <button type='submit'>Save</button>
                                        </div>
                                    </form>

                            </div>
                            <div className={`${classes.access_wrap}`}>
                                <form onSubmit={HandleAccessUpdate} method="post">
                                    <div className={`${classes.batch_input_wrap}`}>


                                        {
                                          WorkspaceAccessList && SiteList.map((item, idx)=>(
                                                <div key={idx} className={`${classes.single_input_wrap}`}>
                                                    <input 
                                                        onChange={(e)=>{HandleWorkshopeSelection(e, item.BranchID)}} 
                                                        name={item.BranchName} 
                                                        checked={WorkspaceAccessList[item.BranchName]} 
                                                        type="checkbox" /> 
                                                    <span className={`${classes.workspace_access_tag}`}>[Access]-{item.BranchName}</span>

                                                </div>
                                            ))
                                        }

                                    </div>
                                    
                                    <div className={`${classes.action_wrap}`}>
                                        <button>Update</button>
                                    </div>
                                    
                                </form>
                            </div>

                        </div>
                        
                    </div>

                </div>

                <div className={`${classes.map_frame_wrapper}`}>

                    <div className={`${classes.leaftlet_map}`}>
                        <MapContainer 
                                zoomControl={false} 
                                attributionControl={false} 
                                center={[UsrLoc.lat, UsrLoc.lon]} 
                                zoom={16} 
                                style={mapStyle}
                                whenReady={()=>{SetLeafletReady(true)}}
                                >
                                    {
                                        LeafletReady && 
                                        <>
                                         <TileLayer
                                                url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                                                attribution=''
                                            />
                                            <Marker position={[UsrLoc.lat, UsrLoc.lon]}>
                                                <Popup>
                                                    <div className={`${classes.user_location_info_tip}`}>
                                                        <li>{LocalUserData?.SignalOriginCity} / {LocalUserData?.SignalOriginCountry}</li>
                                                        <li>{LocalUserData?.CarrierProvider}</li>
                                                        <li>{LocalUserData.SignalOriginCity ? 'This is an approximation based on the IP address and may not reflect the exact location.' : 'The user may not be logged in yet, or we were unable to determine their location'}</li>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        </>
                                    }
                           


                        </MapContainer>
                    </div>

                    <div className={`${classes.usr_info_pers}`}>
                        <li> <span className={`${classes.ac_tag}`}>[IP-Address]</span> <span className={`${classes.ac_val}`}>{LocalUserData.LastIP}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Session-Count]</span> <span className={`${classes.ac_val}`}>{LocalUserData.SessionCount}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Last Session Duration]</span> <span className={`${classes.ac_val}`}>{LocalUserData.SessionDuration}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Latitude]</span> <span className={`${classes.ac_val}`}>{UsrLoc.lat}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Longitude]</span>  <span className={`${classes.ac_val}`}>{UsrLoc.lon}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Target-Device]</span> <span className={`${classes.ac_val}`}>{LocalUserData.DeviceName}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[OS]</span> <span className={`${classes.ac_val}`}>{LocalUserData.DeviceOS}</span></li>
                    </div>

                </div>

                <div className={`${classes.user_action}`}>
                    <UIButton onClick={(e)=>{InitRevockAction(LocalUserData.UserAccountID, LocalUserData.Username)}} Variant='Danger' Icon={<Prohibit weight='bold'/>} label={"Revoke"} />
                    <UIButton onClick={(e)=>{UpdateAccountState()}} Variant='Secondary' Icon={<StopCircle  weight='bold'/>} label={LocalUserData.AccountState === 'Active'? 'Restrict' : 'Reactivate'} />
                    <UIButton onClick={(e)=>{
                            DAControl.Trigger(`Terminate User Session`, 
                                `You are about to terminate a user session. This action will immediately affect the user's current activity.`,
                                true,
                                8,
                                async (data)=>
                                {
                                    if(data) 
                                    {
                                        await HandleUserSessionTermination(LocalUserData);
                                    }
                                }
                            )
                        }} Variant='Secondary' Icon={<Skull/>} label={"Terminate Session"} />
                        
                </div>

            </div>

        </div>
            }

            {!LocalUserData && <div className={`${classes.loading_placeholder}`}>
                  <DotsGif/>
             </div>}

        </div>

    </div>
    )
}
import classes from './PreviewRequestOrderDetail.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {CreateNewMerchGroup} from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'

import { FetchTransferRequestPayloadV2 } from '../../../../../SharedComp/API/InventoryStockTransfer/API_FE_InventoryStockTransfer'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'

export default function PreviewRequestOrderDetail({OpenController, TargetData}) 
{

    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(false);
    const [RequestPayload, SetRequestPayload] = useState([]);
    const [TargetRequest, SetTargetRequest] = useState();

    function CloseModal() 
    {
        OpenController(false);
    }

    const FetchRequestPayload = async(target_id)=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_request_id", target_id);
            const res = await FetchTransferRequestPayloadV2(fd, auth.token);

            SetRequestPayload(res.REQUEST_DETAIL_ARRAY);
            SetTargetRequest(res.INIT_REQUEST);
            SetLoading(false);
            return res.INIT_REQUEST;
        }catch(err) 
        {
            SetLoading(false);
            throw err;
        }
    }
    useEffect(()=>{
        console.log();
        if(TargetData) 
        {
            FetchRequestPayload(TargetData.ISTRV2_ID)
        }
    }, [TargetData])




    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Preview <span className={`${data_win.data_create_name}`}>Request Detail</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                <div className={`${classes.form_wrapper}`}> 

                <div className={`${classes.master_prod_type_config_wrapper}`}>
                            

                            <div className={`${classes.request_info_upper}`}>
                                {/* <li><span>Reason</span> <span>{TargetRequest?.ISTRV2_RequestReason}</span></li> */}
                                <li><span>Request Made By</span> <span>{TargetData?.FirstName} {TargetData?.LastName}</span></li>
                                <li><span>Request Date and Time</span> <span>{TargetRequest ? ToEthiopianCalander(new Date(TargetRequest?.ISTRV2_RequestInitDate)) : '-'} @ {TargetRequest ? new Date(TargetRequest?.ISTRV2_RequestInitDate).toLocaleTimeString() : '-'}</span></li>
                                <li><span>Transaction ID</span> <span>{TargetRequest?.ISTRV2_TransactionID}</span></li>
                            </div>

                        <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                            {
                            !Loading && 
                                RequestPayload.map((item, idx)=>(
                                    <li key={idx}>
                                        <span>{item.IVItemName}</span>
                                        <span>{item.SR_RequstedQuantity} {item.UomDescription}</span>
                                    </li>
                                ))
                            }
                            

                            {
                                Loading && 
                                <LoadingPlaceholder/>
                            }

                        </div>

                        
                    </div>
                        
                </div>
                <div className={`${data_win.form_action_button_wrapper}`}>
                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Close</button>
                </div>
            </div>
        </div>

    </div>
    )
}
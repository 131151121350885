import { useRef } from 'react';
import classes from './EmployeeIDCard.module.css'
import QRCode from 'react-qr-code'; 
import { useReactToPrint } from 'react-to-print';
export default function EmpStat() 
{

    const IDCardRef = useRef();
    
    const handlePrint = useReactToPrint({
        content: () => IDCardRef.current,
    })


    return (
        <div className={`${classes.emp_id_card_design_main_wrapper}`}>

            <h2>Printable ID</h2>

            

        </div>
    )
}
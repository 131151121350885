// import classes from './CreditSystemConfig.module.css'
import classes from '../PayrollConfig/PayrollConfig.module.css'


export default function CreditSystemConfig({UpdateReq, ConfigData}) 
{



// <input onChange={(e)=>{UpdateReq("GC_EnableAutomaticUpdatesForLinkedEntries", e.target.checked)}} checked={ConfigData.GC_EnableAutomaticUpdatesForLinkedEntries} type="checkbox" />
    return (
        <div className={`${classes.credit_system_wrapper}`}>
            <div className={`${classes.part_of_option}`}>
                <ul>Credit Configuration</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Override Default Credit Rate</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_OverrideDefaultCreditRate",  e.target.value === "true")}} value={String(ConfigData.GC_OverrideDefaultCreditRate)}>
                                <option value="true">Override Setting</option>
                                <option value="false">Do Not Override</option>
                            </select>
                        </span>
                    </li>
                    <li><span>Apply Deduction by Default</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_ApplyDeductionByDefault",  e.target.value === "true")}} value={String(ConfigData.GC_ApplyDeductionByDefault)}>
                                <option value="true">Automatically Apply Deduction</option>
                                <option value="false">Let User Decide</option>
                            </select>
                        </span>
                    </li>
                    <li><span>Allow Employees to Receive Credit Before Settling Existing Debt</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_AllowEmployeesToReceiveCreditBeforeSettlingExistingDebt",  e.target.value === "true")}} value={String(ConfigData.GC_AllowEmployeesToReceiveCreditBeforeSettlingExistingDebt)}>
                                <option value="true">Allow</option>
                                <option value="false">Block</option>
                            </select>
                        </span>
                    </li>
                    <li><span>Distribute Credit By Month</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_DistributionCreditByMonth",  e.target.value === "true")}} value={String(ConfigData.GC_DistributionCreditByMonth)}>
                                <option value="true">Distribute</option>
                                <option value="false">Make a full payment</option>
                            </select>
                        </span>
                    </li>
                    <li><span>Allow to Modify Penality Amount</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_AllowToModifyPenalityAmount",  e.target.value === "true")}} value={String(ConfigData.GC_AllowToModifyPenalityAmount)}>
                                <option value="true">Allow</option>
                                <option value="false">Block</option>
                            </select>
                        </span>
                    </li>
                    {/* <li><span>Credit Repayment Percentage</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_CreditRepaymentPercentage",  e.target.value)}} value={ConfigData.GC_CreditRepaymentPercentage}>
                                <option value={5}>5%</option>
                                <option value={10}>10%</option>
                                <option value={15}>15%</option>
                                <option value={25}>25%</option>
                                <option value={35}>35%</option>
                                <option value={50}>50%</option>
                            </select>
                        </span>
                    </li> */}
                    <li><span>Distributed Cash Deduction across multiple payroll cycles if exceed</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_DistributedCashPenaltyAcrossMultiplePayrollCyclesIfExceed",  e.target.value)}} value={ConfigData.GC_DistributedCashPenaltyAcrossMultiplePayrollCyclesIfExceed}>
                                <option value={500}>500 Birr</option>
                                <option value={1000}>1,000 Birr</option>
                                <option value={1500}>1,500 Birr</option>
                                <option value={2000}>2,000 Birr</option>
                            </select>
                        </span>
                    </li>
                    
                </div>  
            </div>
        </div>
    )
}
import classes from './ProductionStackOrderGroupWrapper.module.css'
import ProductionStackOrderSingleCardWrapper from '../ProductionStackOrderSingleCardWrapper/ProductionStackOrderSingleCardWrapper'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FetchSavedProductionProductOrder, RefreshProdcutionOrder} from '../../../../api'
import { useEffect } from 'react';
import { faBan, faCheckToSlot, faCircleChevronRight, faCirclePause } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-tooltip';

import ProductionStackProcessor from '../ProductionStackProcessor/ProductionStackProcessor'
import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility'
import { Warning, WarningDiamond } from '@phosphor-icons/react'
import UIButton from '../../../Components/UIButton/UIButton'
import { useDAControl } from '../../../../DAControlContext'
import { ReturnProductionOrderToStock } from '../../../../SharedComp/API/ProductionStack/API_FE_ProductionStack'

export default function ProductionStackOrderGroupWrapper({DataPoint, DataRefresh_Callback}) 
{
    const [OrderProducts, SetOrderProducts] = useState([]);
    const [OpenPOProcessor, SetOpenPOProcessor] = useState(false);
    const [SelectedDataList, SetSelectedDataList] = useState(new Map());
    const [BatchMode, SetBatchMode] = useState(false);
    const [DataRefersh, SetDataRefersh] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [DateDiffCount, SetDateDiffCount] = useState(0);
    const DAControl = useDAControl();
    const [PurgingActiveOrder, SetPurgingActiveOrder] = useState(false);

    const notify = useToast();
    const auth = useAuth();

    const FetchOrderProducts = async()=>{
        SetLoading(true);
        try 
        {
            const res = await FetchSavedProductionProductOrder(DataPoint.PO_Hash);
            SetOrderProducts(res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }
    const SingleProductCallback = async(data, is_mix, batch_count, pop_hash)=>
    {
        const __name = is_mix ? data.MixName : data.CMBP_Name;

        if(!BatchMode) 
        {
            const __new_map = new Map();
            __new_map.set(__name, {POP_Hash: pop_hash, IsMix: is_mix, Pkg: data, CycleCount: batch_count});
            SetSelectedDataList(__new_map);
            SetOpenPOProcessor(true);
        }
        const existing_data = new Map(SelectedDataList);

        if(existing_data.has(__name)) 
        {
            existing_data.delete(__name);
        }else 
        {
            existing_data.set(__name, {POP_Hash: pop_hash, IsMix: is_mix, Pkg: data, CycleCount: batch_count});
        }

        SetSelectedDataList(existing_data);
    }
    const ProcessBatchSelection = async()=>{
        SetOpenPOProcessor(true);
    }
    const ApplyBatchVoid = async(val)=>
    {
        SetBatchMode(val);
        if(!val) 
        {
            SetSelectedDataList(new Map());
        }
    }
    const CheckOrderPackageState = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("order_hash", DataPoint.PO_Hash);
            fd.append("branch_id", DataPoint.PO_SourceBranch);

            const res = await RefreshProdcutionOrder(fd, auth.token);

            if(res.CleanHouse) 
            {
                // Global Parent Refresh
                if(res.MyStateCode === -1) 
                {
                    notify.ShowError(res.Msg);
                } else 
                {
                    notify.ShowSuccess(res.Msg);
                }
                DataRefresh_Callback(DataPoint.PO_Hash);
            } else if(res.CleanCount > 0) 
            {
                // Local Refresh
                SetDataRefersh(!DataRefersh);
            }

            if(res.MyStateCode === -1) 
            {
                notify.ShowError(res.Msg);
            }

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const CleanCloseCallback = async(val)=>
    {
        SetBatchMode(false);
        SetOpenPOProcessor(false);
        SetDataRefersh(!DataRefersh);
        SetSelectedDataList(new Map());
        CheckOrderPackageState();
    }

    const ReturnStockToInventory = async()=>
    {
        SetPurgingActiveOrder(true);
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("return_package_data", JSON.stringify(OrderProducts));
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("action_taken_by", auth.user.UserAccountID);
            const main_res = await ReturnProductionOrderToStock(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            DataRefresh_Callback();
            SetPurgingActiveOrder(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetPurgingActiveOrder(false);
        }
    }

    const CalculateDateDifference = ()=>
    {
        const start = new Date(DataPoint.PO_OrderDate);
        const end = new Date();
    
    
        const differenceInMilliseconds = Math.abs(end - start);
    
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    
        SetDateDiffCount(differenceInDays);
    }

    useEffect(()=>{
        FetchOrderProducts();
    }, [DataRefersh])

    useEffect(()=>{
        if(DataPoint) 
        {
            CalculateDateDifference();
        }
    }, [DataPoint])


    return (
        <div className={`${classes.prodcution_stack_order_group_wrapper_main}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            {OpenPOProcessor && <ProductionStackProcessor Target={DataPoint} DataList={SelectedDataList} Close_CALLBACK={CleanCloseCallback}/>}

            <div className={`${classes.pro_stck_group_pkg_wrapper}`}>
              {DataPoint &&  <div className={`${classes.order_info_header}`}>

                    <div>
                        <li><span>Order Initiated By: </span> <span className={`${classes.order_main_value_data}`}> {DataPoint.FirstName} {DataPoint.LastName}</span> </li>
                        <li><span>Shift Target: </span> <span className={`${classes.order_main_value_data}`}>{DataPoint.PO_TargetShift}</span> </li>
                    </div>
                   {!Loading && DateDiffCount > 1  && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ViewExpiredOrder && <div className={`${classes.expired_stock_order}`}>
                        <li><Warning weight='fill'/></li>
                    </div>}
                    <div>
                        <li><span>Ordered Date: </span> <span className={`${classes.order_main_value_data}`}>{ToEthiopianCalander(new Date(DataPoint.PO_OrderDate))}</span></li>
                        <li><span>Clock: </span> <span className={`${classes.order_main_value_data}`}>{new Date(DataPoint.PO_OrderDate).toLocaleTimeString()}</span></li>
                    </div>

                </div>}

                <div className={`${classes.production_stack_group_wrapper}`}>

                        <div className={`${classes.prodution_stock_single_card_list_wrapper}`}>

                            {!Loading &&
                                OrderProducts.map((item, idx)=>(
                                    <ProductionStackOrderSingleCardWrapper 
                                            key={idx + item.POP_Hashitem + item.POP_ProdID + item.POP_BatchRemain + item.POP_Hash} 
                                            PackageList={SelectedDataList} 
                                            SingleCard={SingleProductCallback} 
                                            Target={item}/>
                                ))
                            }

                        </div>
                        {Loading && <LoadingPlaceholder placeholder_height={10}/>}

                    
                </div>

                <div className={`${classes.production_stack_group_footer}`}>
                    <div className={`${classes.footer_wrap_inner}`}>
                        <button onClick={()=>{ApplyBatchVoid(!BatchMode)}}><span><FontAwesomeIcon icon={BatchMode ? faCirclePause : faCheckToSlot}></FontAwesomeIcon></span><span>{BatchMode ? "Void" : "Select"}</span></button>
                        {BatchMode && SelectedDataList.size > 0 &&  <button onClick={()=>{ProcessBatchSelection()}} className={`${classes.batch_process}`}><span><FontAwesomeIcon icon={faCircleChevronRight}></FontAwesomeIcon></span><span>Process</span></button>}
                    </div>
                    <div className={`${classes.action_taker}`}>
                        {
                           !Loading && DateDiffCount > 0 && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ReverseOrderToStock && <UIButton  Variant={PurgingActiveOrder ? 'Disabled' : 'Danger'} Icon={<Warning weight='bold'/>} label={"Reverse Stock"} onClick={()=>{
                                DAControl.Trigger(
                                    'Return Stock to Inventory',
                                    "You are about to return the order to stock. This will result in the remaining batch being split into separate components and added to your inventory. If you wish to retain these items, you will need to place a new order",
                                    true,
                                    8,
                                    (data)=>{
                                        if(data) 
                                        {
                                            ReturnStockToInventory();
                                        }
                                    }
                                )
                            }}/>
                        }
                       {/* {OrderProducts.length === 0 && 
                                    <button  
                                        onClick={()=>{CheckOrderPackageState()}} 
                                        {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "You shoudn't not see this button, If you are you have permission or things go south somewhere!"})} 
                                        className={`${classes.fail_save_purge}`}>
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span><span>Purge</span>
                                    </button>
                        } */}
                    </div>
                </div>
            </div>
            

            

        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const CreateNewGlobalConfig = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'global-config/create-new-gloabal-config', data, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const UpdateGlobalConfig = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'global-config/update-gloabal-config', data, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryGlobalObjectConfig = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'global-config/query-gloabal-config', data, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
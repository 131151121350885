import classes from './OperationMDCluster_I.module.css'

import MasterBankType from './MasterBankType/MasterBankType'
import MasterCashAllowanceType from './MasterCashAllowanceType/MasterCashAllowanceType'
import MasterDepartmentType from './MasterDepartmentType/MasterDepartmentType'
import MasterJobPositionType from './MasterJobPositionType/MasterJobPositionType'
export default function OperationMDCluster_I() 
{


    return (
        <div className={`${classes.data_upper_wrapper_prox_table}`}>
            <div className={`${classes.core_master_data_wrapper}`}>
                <MasterBankType/>
                <MasterCashAllowanceType/>
                <MasterDepartmentType/>
                <MasterJobPositionType/>
            </div>
        </div>
    )
}
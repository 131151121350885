import classes from './ProcessTRequest.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import ProcessTransferRequestPackageInput from './ProcessRequestPackageInput/ProcessTransferRequestPackageInput'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList, PrepareDropDownList_KV} from '../../../../SharedComp/Utility'

import {FetchBranchExcludedCurrent, ProcessStockRequest, FetchTransferRequestPayload} from '../../../../api'
import { FetchTransferRequestPayloadV2, ProcessStockRequestV2 } from '../../../../SharedComp/API/InventoryStockTransfer/API_FE_InventoryStockTransfer'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { useDAControl } from '../../../../DAControlContext'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { QueryAllVHDriverLink } from '../../../../SharedComp/API/GPS/API_FE_WhatsGPS'

export default function ProcessTRequest({OpenModal, TargetRequest, DataRefresh}) 
{

    const [Loading, SetLoading] = useState(false);

    const da_control = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const scrollRef = useRef(null);
    // Transfere Detail
    const [InventoryItemType, SetInventoryItemType] = useState(null);
    const [InventoryItemStore, SetInventoryItemStore] = useState(null);
    const [InventoryItemQuantity, SetInventoryItemQuantity] = useState(null);
    const [InventoryItemBrand, SetInventoryItemBrand] = useState(null);
    const [InventoryItemRequestHash, SetInventoryItemRequestHash] = useState(null);
    const [InventoryTransferApprovalState, SetInventoryTransferApprovalState] = useState(null);
    const [LockToSourceBranch, SetLockToSourceBranch] = useState(true);
    const [IncomingLoading, SetIncomingLoading] = useState(false);
    const [SelectedDriver, SetSelectedDriver] = useState(null);
    const [DriverList, SetDriverList] = useState([]);

    const [RequestPayloadList, SetRequestPayloadList] = useState([]);


    const GetAllAvailDriver = async()=>
    {
        try 
        {

            const _driver_list = await QueryAllVHDriverLink(auth.token);
            // PrepareDropDownList(_driver_list, SetDriverList);
            PrepareDropDownList_KV(_driver_list, SetDriverList, 'JAKSBE_FullName', 'VINTKD_ID');

        }catch(err) 
        {

        }
    }
    const FetchRequestPayload = async()=>{
        SetIncomingLoading(true);
        try 
        {
            await GetAllAvailDriver();
            const fd = new FormData();
            fd.append("target_request_id", TargetRequest.ISTRV2_ID);
            const res = await FetchTransferRequestPayloadV2(fd, auth.token);
            SetRequestPayloadList(res);

            SetInventoryItemType(null);
            SetInventoryItemStore(null);
            SetInventoryItemQuantity(null);
            SetInventoryItemBrand(null);
            SetInventoryItemRequestHash(null);
            SetInventoryTransferApprovalState(null);
            const existing = [];
            for(let i = 0; i < res.REQUEST_DETAIL_ARRAY.length; i++) 
            {
                const _focued_entry = res.REQUEST_DETAIL_ARRAY[i];
                const unique_idx = getRandomID();

                HandleIvTransferApprovalState(false, unique_idx);
                HandleIvnItemBrand(_focued_entry.SR_RequstedBrand ? _focued_entry.SR_RequstedBrand : '-', unique_idx);
                HandleItemQuantitySelection(_focued_entry.SR_RequstedQuantity, unique_idx);
                HandleIvItemSelection({value: _focued_entry.IvtID, label: _focued_entry.IVItemName}, unique_idx);
                HandleIvItemRequestHash(getRandomID(), unique_idx);
                existing.push(
                    <ProcessTransferRequestPackageInput 
                    I_Quantity={_focued_entry.SR_RequstedQuantity}
                    I_Brand={_focued_entry.SR_RequstedBrand ? _focued_entry.SR_RequstedBrand : '-'}
                    I_Inv_Item={{value: _focued_entry.IvtID, label: _focued_entry.IVItemName}}
                        HandleInvItemSelect={HandleIvItemSelection} 
                        HandleInvItemStore={HandleIvItemStore}
                        HandlInvItemQuantity={HandleItemQuantitySelection} 
                        HandleIvnItemBrand={HandleIvnItemBrand}
                        HandleTransferState={HandleIvTransferApprovalState}
                        idx={unique_idx}/>
                );
            }
                SetStockItem(existing);
                SetIncomingLoading(false);
        }catch(err) 
        {
            notify.ShowError("Faild To Fetch Request Payload, Please try again in a moment");
            SetIncomingLoading(false);
        }
    }

    function CheckValidTransfereCount() 
    {
        let data_count = 0;
        let valid_count = 0;
        for (const key in InventoryTransferApprovalState)  
        {
            data_count++;
            if(InventoryTransferApprovalState[key])
            {
                valid_count++;
            }
        }
        return valid_count;
    }

    const [StockItem, SetStockItem] = useState([]);
    function HandleIvItemSelection(data, idx) 
    {
        SetInventoryItemType(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemRequestHash(data, idx) 
    {
        SetInventoryItemRequestHash(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemStore(data, idx) 
    {
        SetInventoryItemStore(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemQuantitySelection(data, idx) 
    {
        SetInventoryItemQuantity(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvTransferApprovalState(data, idx) 
    {
        SetInventoryTransferApprovalState(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvnItemBrand(data, idx) 
    {
        SetInventoryItemBrand(prev => ({
            ...prev,
            [idx]: data
        }))
    }

    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }

    function CloseModal() 
    {
        OpenModal();
    }
    const HandleSubmition = async(e, declined = false)=> 
    {
        if(!e)
        {
            return;
        }
        notify.ShowLoad("Processing Request...", 10);
        SetLoading(true);
        try 
        {
        
            const fd = new FormData();
            fd.append("selected_Driver", SelectedDriver);
            fd.append("is_declined", declined);
            fd.append("receiver_user", auth.user.UserAccountID);
            fd.append("receiver_workspace", auth.activeWorkShop);
            fd.append("lock_to_source_branch", LockToSourceBranch);
            fd.append("request_payload_ID", TargetRequest.ISTRV2_ID);
            fd.append("request_item_hash", JSON.stringify(InventoryItemRequestHash));
            fd.append("request_payload_IVItem", JSON.stringify(InventoryItemType));
            fd.append("request_payload_IVItemQuantity", JSON.stringify(InventoryItemQuantity));
            fd.append("request_payload_IVItemBrand", JSON.stringify(InventoryItemBrand));
            fd.append("request_payload_store", JSON.stringify(InventoryItemStore));
            fd.append("request_payload_approval_state", JSON.stringify(InventoryTransferApprovalState));

            const res = await ProcessStockRequestV2(fd, auth.token);
            notify.ShowSuccessUpdate(res.Msg, 10); 
            SetLoading(false);
            DataRefresh(new Date().getUTCMilliseconds());
            CloseModal();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }
    function HandleDecline(val) 
    {
        if(!val) 
        {
            return;
        }
        HandleSubmition("sup", true);
    }
    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    useEffect(()=>{
        FetchRequestPayload();

    }, [])
    
    useEffect(()=>{
        scrollToBottom();
    }, [StockItem])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main} ${classes.data_main}`} >
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Processing <span className={`${data_win.data_create_name}`}>Stock Request</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={(e)=>{
                                e.preventDefault();
                                const valid_count = CheckValidTransfereCount();
                                if(valid_count > 0) 
                                {
                                    da_control.Trigger(
                                        'Authorize Stock Transfer',
                                        `You are about to perform a stock transfer. This action will impact your current stock balance. Please note, you won't be able to view this dialog again after proceeding.`,
                                        e, 
                                        2,
                                        HandleSubmition)
                                } else 
                                {
                                    notify.ShowError("At least one valid stock is required to perform this action!")
                                }
                              
                            }} method='POST'>
                             {!IncomingLoading &&    <div className={`${classes.form_wrapper}`}> 
                                        
                                        <div className={`${classes.input_list_main_wrapper}`}>
                                            <div  className={`${classes.input_list_wrapper}`}>
                                                
                                                    <div className={`${classes.input_header_single_wrapper_st_req_header}`}>
                                                        <h2>Warehouse Stock Transfer #{TargetRequest.ISTRV2_ID}</h2>
                                                    </div>
                                                    <div className={`${classes.input_header_single_wrapper_st_req_header}`}>
                                                        <div className={`${classes.input_single_wrap}`}>
                                                            <MasterDataRegInput input={
                                                                <Select
                                                                    isSearchable={true}
                                                                    styles={Custom_DD_Style}
                                                                    placeholder={"Select Driver"}
                                                                    options={DriverList}
                                                                    onChange={(data)=>{
                                                                        SetSelectedDriver(data.value);
                                                                      }}
                                                                /> } /> 
                                                            </div>
                                                    </div>
                                                    
                                                    {/* <div onClick={()=>{SetLockToSourceBranch(!LockToSourceBranch)}} className={`${classes.input_header_single_wrapper_st_req}`}>
                                                        <span><input checked={LockToSourceBranch} type="checkbox" onChange={(e)=>{SetLockToSourceBranch(e.target.checked)}} /></span>
                                                        <span>Lock to Source Branch</span>
                                                    </div> */}

                                            </div>
                                        </div>

                                        <div className={`${classes.po_mat_list_wrapper}`}>
                                            <div className={`${classes.po_mat_list}`}>
                                                    {StockItem.map((item) => ( 
                                                        <div 
                                                            className={`${classes.input_fild_shild}`} 
                                                            key={item.props.idx}>{item}
                                                            <div ref={scrollRef}/>
                                                        </div>
                                                    ))}
                                            </div>
                                       </div>                        
                                            
                                </div>}
                                {IncomingLoading && <LoadingPlaceholder preview_text='Preparing Request Package'/>}
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                   {!IncomingLoading &&  <button 
                                        className={`${data_win.form_action_button_save}`}>
                                            {Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Authorize'}
                                    </button>}
                                    {!IncomingLoading &&   <button 
                                        type='button' 
                                        className={`${data_win.form_action_button_dismiss}`}
                                        onClick={()=>{
                                            da_control.Trigger(
                                                'Reject Stock Transfer', // header
                                                `You are about to decline this stock transfer request. This action is irreversible, and the sender will need to resubmit the request if necessary.`, // agreement
                                                true, 
                                                7,
                                                HandleDecline)
                                        }}
                                        >{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Reject'}</button> }
                                    <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Close</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}
import classes from './CreateNewEmployeeAllowance.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'
import { useTargetEmployee } from '../../SingleEmployeeContextProvider/SingleEmployeeContextProvider'
import { CreateNewEmployeeNonCashPenality } from '../../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeePenality'
import { QueryAllMasterPenality } from '../../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_II'
import { QueryAllMasterCashAllowance } from '../../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I'
import { CreateEmployeeAllowance } from '../../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeeAllowance'

export default function CreateNewEmployeeAllowance({OpenController}) 
{
    const sharedData = useTargetEmployee();
    const auth = useAuth();
    const notify = useToast();

    const [AllowanceType, SetAllowanceType] = useState();
    const [AllowanceAmount, SetAllowanceAmount] = useState();
    const [Loading, SetLoading] = useState(false);

    const [AllowanceTypeList, SetAllowanceTypeList] = useState([]);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_ID);
            fd.append("allowance_init_by", auth.user.UserAccountID);
            fd.append("allowance_type", JSON.stringify(AllowanceType));
            fd.append("allowance_amount", AllowanceAmount);
            fd.append("emp_fullname", sharedData.Core.JAKSBE_FullName);
            fd.append("branch_id",  sharedData.Core.JAKSBE_ActiveBranchID);


            const res = await CreateEmployeeAllowance(fd, auth.token);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            sharedData.Update();
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    const GetAllPenalityList = async() =>
    {
        try 
        {
            const main_res = await QueryAllMasterCashAllowance(auth.token);
            PrepareDropDownList(main_res, SetAllowanceTypeList);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{

        GetAllPenalityList();

    }, [])

    return(
        <div className={`${data_win.main_data_creator_wrapper} ${classes.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main} ${classes.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Initiate  <span className={`${data_win.data_create_name}`}>New Allowance</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput input={
                                                <Select 
                                                    value={AllowanceType}
                                                    onChange={(data)=>{SetAllowanceType(data)}} 
                                                    options={AllowanceTypeList} 
                                                    placeholder={"Allowance Type"} 
                                                    isSearchable={true} 
                                                    styles={Custom_DD_Style} 
                                                    required ={true} /> } 
                                                />


                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetAllowanceAmount(e.target.value)}}
                                                    type="number"
                                                    step={"any"}
                                                    max={sharedData.Core.JAKBECP_CurrentGrossSalary}
                                                    min={1}
                                                    placeholder='Allowance Amount'
                                                    required />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button style={{width: '13em'}} disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Approve Allowance'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}
import classes from './EmpPto.module.css'
import CircleMiniGraph from '../../../../../SharedComp/CircleMiniGraph/CircleMiniGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip'; 
import PtoAction from './Component/PtoAction';
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

export default function EmpPto() 
{
    


    return (
        <div className={`${classes.emp_pto_main_wrapper}`}>

            <div className={`${classes.emp_pto_action_adder}`}>
                <button onClick={()=>{}}>Request Time Off</button>
            </div>

            <h2>PTO</h2>
            
        </div>
    );
}
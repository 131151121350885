import classes from './PayrollHrPass.module.css'
import HRPassSingleWorkshopeBundlePreview from './HRPassSingleWorkshopeBundlePreview/HRPassSingleWorkshopeBundlePreview'
import { QueryPreparedPayrollDataFeedEntries } from '../../../SharedComp/API/Payroll/API_FE_PayrollDataFeed'
import { useAuth } from '../../../AuthProvider'
import { useEffect, useState } from 'react';
import { Tray } from '@phosphor-icons/react';

export default function PayrollHrPass() 
{

    const auth = useAuth();

    const [DataList, SetDataList] = useState([]);
    const [RefreshData, SetRefreshData] = useState(false);

    const GetPrepData = async()=>
    {
        try 
        {
            const main_res = await QueryPreparedPayrollDataFeedEntries(auth.token);
            SetDataList(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        GetPrepData();
    }, [RefreshData])

    const RefresshDataView = () =>
    {
        SetRefreshData(!RefreshData);
    }


    return (
        <div className={`${classes.hr_payroll_pass_master_wrapper}`}>
            

            <div className={`${classes.hr_pass_bundle_outer_wrapper}`}>
                <div className={`${classes.hr_pass_bunder_innter_Wreapper}`}>
                    {
                        DataList.map((item, idx)=>(
                            <HRPassSingleWorkshopeBundlePreview DataRefreshView_CALLBACK={RefresshDataView} TargetData={item}/>
                        ))
                    }
                    {
                        DataList.length <= 0 && 
                        <div className={`${classes.empty_data_hr_data_feed_ready}`}>
                            <span><Tray weight='bold'/></span>
                            <li>There is no available data ready for processing</li>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}
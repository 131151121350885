import classes from './ProductionStack.module.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FetchProductionOrderBranchStockedOut} from '../../api'
import { useAuth } from '../../AuthProvider'
import ProductionStackOrderGroupWrapper from './Component/ProductionStackOrderGroupWrapper/ProductionStackOrderGroupWrapper'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import UIButton from '../Components/UIButton/UIButton'
import { SignOut } from '@phosphor-icons/react'

import ProductionStackCheckOutPreview from './Component/ProductionStackCheckOutPreview/ProductionStackCheckOutPreview'

export default function ProductionStack() 
{
    const [PendningOrder, SetPendningOrder] = useState([]);
    const auth = useAuth();
    const [RefreshView, SetRefreshView] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [OpenCheckOutPreview, SetOpenCheckOutPreview] = useState(false);


    


    const FetchProdReadyOrders = async()=>
    {
        SetLoading(true);
        try {

            const res = await FetchProductionOrderBranchStockedOut(auth.activeWorkShop, auth.token);
            SetPendningOrder(res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }

    const DataRefresh = async(_hash)=>
    {
        SetRefreshView(!RefreshView);
    }
    
    useEffect(()=>{
        FetchProdReadyOrders();
    }, [RefreshView, auth.user])


    return (
        <div className={`${classes.production_stack_main_wrapper}`}>

            {OpenCheckOutPreview && <ProductionStackCheckOutPreview PendingOrderPackage={PendningOrder} OpenController={SetOpenCheckOutPreview}/>}
            
            
            <div className={`${classes.production_stack_action_Wrapper}`}>
                <UIButton onClick={()=>{SetOpenCheckOutPreview(true)}} Icon={<SignOut weight='bold'/>} label={"Check out"}/>
            </div>
            
            <div className={`${classes.prodution_stack_group_wrapper}`}>
                {
                    PendningOrder.map((item, idx)=>(
                        <ProductionStackOrderGroupWrapper 
                                key={item.PO_Hash} 
                                DataPoint={item} 
                                DataRefresh_Callback={DataRefresh}
                            /> 
                    ))
                    
                }

                { Loading && <LoadingPlaceholder/>}

                { PendningOrder.length === 0 && !Loading &&
                    <div className={`${classes.empty_stack}`}>
                        <li><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></li>
                        <li>No Production Stack</li>
                    </div>
                }
            </div>
            
        </div>
    )
}
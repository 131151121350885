import classes from './Merchandise.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';


import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy';


// Group
import CreateMerchGroup from './Component/MerchGroup/CreateMerchGroup/CreateMerchGroup';
import EditMerchGroup from './Component/MerchGroup/EditMerchGroup/EditMerchGroup';

// Product
import CreateMerchProduct from './Component/MerchProduct/CreateMerchProduct/CreateMerchProduct';
import EditMerchProduct from './Component/MerchProduct/EditMerchProduct/EditMerchProduct';

import {FetchMerchGroupAll, PurgeMerchGroupByID, FetchMerchProductAll, PurgeMerchProductByID} from '../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'
import { useEffect } from 'react';
import { useAuth } from '../../../AuthProvider';
import { useToast } from '../../../ToastContext';
import { useDAControl } from '../../../DAControlContext';
import { faBan, faPenNib } from '@fortawesome/free-solid-svg-icons';

import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}


export default function Merchandise() 
{
    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    // Merch Group
    const [OpenCreateMechGroup, SetOpenCreateMechGroup] = useState(false);
    const [OpenEditMerchGroup, SetOpenEditMerchGroup] = useState(false);
    const [RefreshMerchGroupView, SetRefreshMerchGroupView] = useState(false);
    const [MerchGroupListOriginal, SetMerchGroupListOriginal] = useState([]);
    const [MerchGroupList, SetMerchGroupList] = useState([]);
    const [MerchTargetEditData, SetMerchTargetEditData] = useState(null);

    // Merch Product
    const [OpenCreateMerchProduct, SetOpenCreateMerchProduct] = useState(false);
    const [OpenEditMerchProduct, SetOpenEditMerchProduct] = useState(false);
    const [RefreshMerchProductListView, SetRefreshMerchProductListView] = useState(false);
    const [MerchProductListOriginal, SetMerchProductListOriginal] = useState([]);
    const [MerchProductList, SetMerchProductList] = useState([]);
    const [MerchTargetProductEdit, SetMerchTargetProductEdit] = useState(null);

    // Loading
    const [LoadingMerchGroup, SetLoadingMerchGroup] = useState(false);
    const [LoadingMerchItems, SetLoadingMerchItems] = useState(false);


    const FetchMerchGroup = async()=>
    {
        SetLoadingMerchGroup(true);
        try 
        {
            const main_res = await FetchMerchGroupAll(auth.token);
            SetMerchGroupListOriginal(main_res);
            SetMerchGroupList(main_res);
            SetLoadingMerchGroup(false);
        }catch(err) 
        {
            SetLoadingMerchGroup(false);
            notify.ShowError(err.message);
        }
    }
    const FetchMerchProducts = async()=>
    {
        SetLoadingMerchItems(true);
        try 
        {
            const main_res = await FetchMerchProductAll(auth.token);
            SetMerchProductListOriginal(main_res);
            SetMerchProductList(main_res);
            SetLoadingMerchItems(false);
        }catch(err) 
        {
            SetLoadingMerchItems(false);
            notify.ShowError(err.message);
        }
    }
    const HandleMerchGroupManage = async(data)=>
    {
        SetMerchTargetEditData(data);
        SetOpenEditMerchGroup(true);
    }
    const HandleMerchProductManage = async(data)=>
    {
        SetMerchTargetProductEdit(data);
        SetOpenEditMerchProduct(true);
    }
    const HandlePurgeMerchGroup = async(id)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);

            const main_res = await PurgeMerchGroupByID(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshMerchGroupView(!RefreshMerchGroupView);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const HandlePurgeMerchProduct = async(id)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);

            const main_res = await PurgeMerchProductByID(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshMerchProductListView(!RefreshMerchProductListView);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    const FilterMerchProductByGroup = async(group_key) =>
    {
        const temp_list = [];
        for(let i = 0; i < MerchProductListOriginal.length; i++) 
        {
            if(MerchProductListOriginal[i].CMMP_ParentID === group_key)
                temp_list.push(MerchProductListOriginal[i])
        }

        SetMerchProductList(temp_list);
    }
    const FilterMerchGroupByName = async(_key) =>
    {
        const temp_list = [];
        for(let i = 0; i < MerchGroupListOriginal.length; i++) 
        {
            const check = MerchGroupListOriginal[i].CMMG_Name.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(MerchGroupListOriginal[i])
        }

        SetMerchGroupList(temp_list);
    }

    const FilterMerhcProductByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < MerchProductListOriginal.length; i++) 
        {
            const check = MerchProductListOriginal[i].CMMP_Name.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(MerchProductListOriginal[i])
        }

        SetMerchProductList(temp_list);
    }

    useEffect(()=>{
        FetchMerchGroup();
    }, [OpenEditMerchGroup, OpenCreateMechGroup, RefreshMerchGroupView])

    useEffect(()=>{
        FetchMerchProducts();
    }, [OpenCreateMerchProduct, RefreshMerchProductListView, OpenEditMerchProduct])

    
    return (
        <div className={`${classes.merchandise_main_warpper}`}>

            {/* Group */}
            {OpenCreateMechGroup && <CreateMerchGroup OpenController={SetOpenCreateMechGroup}/>}
            {OpenEditMerchGroup && <EditMerchGroup TargetData={MerchTargetEditData} OpenController={SetOpenEditMerchGroup}/>}

            {/* Product */}
            {OpenCreateMerchProduct &&  <CreateMerchProduct OpenController={SetOpenCreateMerchProduct}/>}
            {OpenEditMerchProduct &&  <EditMerchProduct OpenController={SetOpenEditMerchProduct} TargetID={MerchTargetProductEdit.CMMPID}/>}

            <div className={`${classes.data_wrapper_upper}`}>

                <DataViewTableProxy 
                    IsEmpty={MerchGroupList.length === 0 && !LoadingMerchGroup}
                    OnFilter={FilterMerchGroupByName}
                    OpenDataCreate={SetOpenCreateMechGroup}
                    HeaderName={
                        <div>
                            <h2>Master <b>Merchandise </b> Group</h2>
                        </div>
                    }
                    DataArray={
                        <div>

                            {
                                MerchGroupList.map((item, idx)=>(
                                    <li onDoubleClick={()=>{FilterMerchProductByGroup(item.CMMGID)}} key={item.CMMG_Name + idx}>
                                        <span>{item.CMMG_Name}</span>
                                        <span>{item.CMMG_Description}</span>
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Purge Merchandise Group`, 
                                                `You are about to purge Merchandise Products Group '${item.CMMG_Name}'. (MAKE SURE THERE ARE NO LINKED SUB-PRODUCT TO THIS GROUP)
                                                This action is IRREVERSIBLE!`, 
                                                item.CMMGID  ,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;
                                                    await HandlePurgeMerchGroup(item.CMMPID );
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleMerchGroupManage(item)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingMerchGroup && 
                                <LoadingPlaceholder/>
                            }

                            
                            
                        </div>
                    }
                />

                <DataViewTableProxy 
                    IsEmpty={MerchProductList.length === 0 && !LoadingMerchItems}
                    OnFilter={FilterMerhcProductByName}
                    OpenDataCreate={SetOpenCreateMerchProduct}
                    HeaderName={
                        <div>
                            <h2>Master <b>Merchandise </b> Product</h2>
                        </div>
                    }

                    DataArray={
                        <div>

                            {
                                MerchProductList.map((item, idx)=>(
                                    <li onDoubleClick={()=>{FilterMerchProductByGroup(item.CMMPID)}} key={item.CMMP_Name + idx}>
                                        <span>{item.CMMP_Name}</span>
                                        <span><span className={`${classes.cash_view_value}`}>{currency_format(item.CMMP_Price)}</span></span>
                                        <span>{item.CMMG_Name}</span>
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Purge Merchandise Product`, 
                                                `You are about to purge Merchandise Products  '${item.CMMP_Name}'.
                                                This action is IRREVERSIBLE!`, 
                                                item.CMMPID   ,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;

                                                    
                                                    await HandlePurgeMerchProduct(item.CMMPID);
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleMerchProductManage(item)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingMerchItems && 
                                <LoadingPlaceholder/>
                            }
                            
                        </div>
                    }
                />



            </div>

        </div>
    )
}
import classes from './ShiftClosing.module.css'

import {QueryMasterProdCatValidToPreorder} from '../../../../../SharedComp/API/DeskView/API_FE_DeskView'
import {QueryCKProductKitchenID, FetchWorkspaceProductionCenterOnly} from '../../../../../SharedComp/API/CK/AP_CenteraKitchen'
import { FetchBakeryProductAll } from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../AuthProvider';
import { useToast } from '../../../../../ToastContext';

import BakeryDataPackageRC from './Component/BakeryDataPackage/BakeryDataPackageRC';
import PastryDataPackageRC from './Component/PastryDataPackage/PastryDataPackageRC';
import BaverageDataPackageRC from './Component/BaverageDataPackage/BaverageDataPackageRC';
import MerchandiseDataPackageRC from './Component/MerchandiseDataPackage/MerchandiseDataPackageRC';

import ShiftClosingReport from './Component/ShiftClosingReport/ShiftClosingReport';

import { CheckCircle, FileZip } from '@phosphor-icons/react';
import * as XLSX from 'xlsx';
import UIButton from '../../../../Components/UIButton/UIButton';
import { FetchProductLineMasterGroupAll } from '../../../../../SharedComp/API/ProductLine/API_FE_ProductCluster';

export default function ShiftClosing() 
{

    const auth = useAuth();
    const notify = useToast();

    const [Data, setData] = useState();
    const [OpenShiftClosing, SetOpenShiftClosing] = useState(false);

    const [BakeryDataPackage_m, SetBakeryDataPackage_m] = useState(new Map());
    const [PastryDataPackage_m, SetPastryDataPackage_m] = useState(new Map());
    const [BaverageDataPackage_m, SetBaverageDataPackage_m] = useState(new Map());
    const [MerchDataPackage_m, SetMerchDataPackage_m] = useState(new Map());

    const [BakeryInteraction, SetBakeryInteraction] = useState(false);
    const [PastryInteraction, SetPastryInteraction] = useState(false);
    const [BaverageInteraction, SetBaverageInteraction] = useState(false);
    const [MerchInteraction, SetMerchInteraction] = useState(false);

    const [BakeryAllow, SetBakeryAllow] = useState(false);
    const [PastryAllow, SetPastryAllow] = useState(false);
    const [BaverageAllow, SetBaverageAllow] = useState(false);
    const [MerchAllow, SetMerchAllow] = useState(false);

    function CheckInteractionLock() 
    {
        return (
            (!BakeryAllow || BakeryInteraction) &&
            (!PastryAllow || PastryInteraction) &&
            (!BaverageAllow || BaverageInteraction) &&
            (!MerchAllow || MerchInteraction)
        );
    }

    const HandlePrepareGeneralReport = async()=> 
    {
        const is_locked = CheckInteractionLock();

        if(!is_locked) 
        {
            notify.ShowWarn("Please lock all interactions to proceed.");
            return;
        }
        SetOpenShiftClosing(true);
    }

    const FetchProductionLine = async()=>
    {
        const main_res = await FetchProductLineMasterGroupAll(auth.token);
        // console.log(main_res);
        // console.log(auth.activeWorkShop);

        for(let i = 0; i < main_res.length; i++) 
        {
            const __obj = JSON.parse(main_res[i].ProdCatAvailBranch);
            // console.log(__obj[auth.activeWorkShop]);
            if(main_res[i].ProdCatID === 0) /// Bakery 
            {
                SetBakeryAllow(__obj[auth.activeWorkShop]);
            } else if(main_res[i].ProdCatID === 1) 
            {
                SetPastryAllow(__obj[auth.activeWorkShop]);
            }else if(main_res[i].ProdCatID === 2) 
            {
                SetBaverageAllow(__obj[auth.activeWorkShop]);
            }else if(main_res[i].ProdCatID === 3) 
            {
                SetMerchAllow(__obj[auth.activeWorkShop]);
            }
        }
    }


    useEffect(()=>{
        FetchProductionLine();
    }, []);


    return (
        <div className={`${classes.shift_closing_master_wrapper}`}>

           {OpenShiftClosing && <ShiftClosingReport 
                                        BakeryPackage={BakeryDataPackage_m} 
                                        PastryPackage={PastryDataPackage_m}
                                        BaveragePackage={BaverageDataPackage_m}
                                        MerchPackage={MerchDataPackage_m}
                                        OpenController={SetOpenShiftClosing}/>}

            <div className={`${classes.desk_view_receive_order_inner_nav_wrapper}`}>

                <div className={`${classes.desk_view_receive_order_nav}`}>


                    {/* <input  type="file" onChange={handleFileUpload} /> */}
                    <UIButton onClick={()=>{HandlePrepareGeneralReport()}} label={"Prepare Report"} Icon={<CheckCircle weight='bold'/>}/> 

                </div>
            </div>

 
            <div className={`${classes.data_package_master_wrapper}`}>
               {BakeryAllow &&  <BakeryDataPackageRC Interaction={SetBakeryInteraction}  BakeryDataPackage={BakeryDataPackage_m} SetBakeryDataPackage={SetBakeryDataPackage_m} />}
               {PastryAllow &&  <PastryDataPackageRC Interaction={SetPastryInteraction} PastryDataPackage={PastryDataPackage_m} SetPastryDataPackage={SetPastryDataPackage_m} />}
               {BaverageAllow && <BaverageDataPackageRC Interaction={SetBaverageInteraction}  BaverageDataPackage={BaverageDataPackage_m} SetBaverageDataPackage={SetBaverageDataPackage_m}/>}
               {MerchAllow && <MerchandiseDataPackageRC Interaction={SetMerchInteraction} MerchDataPackage={MerchDataPackage_m} SetMerchDataPackage={SetMerchDataPackage_m}/>}
            </div>
            
        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const AddMerchShelvesProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'shelves-merch/add-merch-into-shelves', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAvialMerchMasterDataByBranch = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'shelves-merch/query-merch-master-data-by-branch', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAvialMerchByBranch = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'shelves-merch/query-merch-by-branch', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAvialMerchInvoiceByBranch = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'shelves-merch/query-merch-invoice-by-branch', data, GetRouteConfig(token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReverseProductEntry = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'shelves-merch/reverse-merch-invoice-entry-by-branch', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReverseMerchInvoice = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'shelves-merch/reverse-merch-invoice-omni-by-branch', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
import classes from './SingleWorkshopBundlePreview.module.css'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { useToast } from '../../../../ToastContext';
import { ArrowUpRight, Export, MagnifyingGlass } from '@phosphor-icons/react';
import UIButton from '../../../Components/UIButton/UIButton';
import { useAuth } from '../../../../AuthProvider';
import { QueryBaseEmployeeByBranch } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee';
import { ToEthiopianMonth, ToGregorianCalendar } from '../../../../SharedComp/Utility';
import { CreateInitialPayrollDataFeed } from '../../../../SharedComp/API/Payroll/API_FE_PayrollDataFeed';
import { useDAControl } from '../../../../DAControlContext';


const generateAnimationDelay = (index) => `${index * 0.15}s`;
const MAX_ATTENDY_FILE_SIZE = 5 * 1024 * 1024; //5Mb
export default function SingleWorkshopBundlePreview({UpdateViewBack, WorkspaceData}) 
{

    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [PreComputedData, SetPreComputedData] = useState();
    const [EmployeePackageListOriginal, SetEmployeePackageListOriginal] = useState([]);
    const [EmployeePackageList, SetEmployeePackageList] = useState([]);
    const [SelectedFileName, SetSelectedFileName] = useState(`Select ${WorkspaceData.BranchName} attendance file...`);
    const [EmployeeListStatic, SetEmployeeListStatic] = useState([]);
    const [InSyncDataCount, SetInSyncDataCount] = useState(0);
    const [PayrollDateForGCReadDate, SetPayrollDateForGCReadDate] = useState(new Date());
    const [ScrollToBottomOnUpdate, SetScrollToBottomOnUpdate] = useState(true);

    const QueryBaseEmployee = async() =>
    {
      try 
      {
       const __branch_id = WorkspaceData.BranchID;

        const fd = new FormData();
        fd.append("branch_id", __branch_id)
        const main_res = await QueryBaseEmployeeByBranch(fd, auth.token);
        SetEmployeeListStatic(main_res);
      }catch(err) 
      {
        
      }
    }
    const ParseEtDate = ()=>
    {
        const read_back_ec_day = WorkspaceData.PAYROLLBMS_TargetPayrollECDay;
        const read_back_ec_month = WorkspaceData.PAYROLLBMS_TargetPayrollECMonth;
        const read_back_ec_year = WorkspaceData.PAYROLLBMS_TargetPayrollECYear;

        const gc = ToGregorianCalendar(read_back_ec_year, read_back_ec_month, read_back_ec_day);
        SetPayrollDateForGCReadDate(gc);
    }

    const ZeroAttendaceData = () =>
    {

        const valid_list = [];
        for(let i = 0; i < EmployeeListStatic.length; i++) 
        {
            const temp_object_wrapper = {
                EmployeeID:  EmployeeListStatic[i].JAKSBE_SyntheticID,
                EmployeeName:  EmployeeListStatic[i].JAKSBE_FullName,
                TotalDay: (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2),
                TotalPresent: (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2),
                LateMinute:  0,
                AbsentDate: 0,
                TotalWorkingHour: auth.GlobalConfig.GC_NumberOfWorkingHourInDay * (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2),
                OverTime: 0,
                WasOverTime: 0,
                HasOverTime: auth.GlobalConfig.GC_EnableOverTime,
                InSync: true
            }

            valid_list.push(temp_object_wrapper);
        }
        SetPreComputedData(valid_list);
        SetInSyncDataCount(valid_list.length);
    }

    const handleFileUpload = (e, is_droped = false) => {
        const selectedFile = is_droped ? e[0] :  e.target.files[0];
        SetSelectedFileName(selectedFile ? selectedFile.name : `Select ${WorkspaceData.BranchName} attendance file...`);
        const file = is_droped ? e[0] : e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const excelData = XLSX.utils.sheet_to_json(worksheet);

            
            // console.log(excelData);
            const header_text = excelData[0].__EMPTY_1;
            if("Attendance Summary" !== header_text) 
            {
                notify.ShowError("Attendace file not recognized!")
                return;
            }
            notify.ShowSuccess("Attendace file loaded succesfully");
            const date_text = excelData[1].__EMPTY_1;
            const start_date = date_text.split('-')[0];
            const end_date = date_text.split('-')[1];

            console.log(new Date(start_date).toDateString() + " upto " + new Date(end_date).toDateString());

            let array_data_package = []
            let valid_data_read_rate = 0;
            for(let i = 5; i < excelData.length - 2; i++) 
            {
                const _employee_id = 'JAK' + excelData[i].__EMPTY_2;

                const _res = EmployeeListStatic.find((item)=>item.JAKSBE_SyntheticID === _employee_id);

                let is_valid = false;
                if(_res) 
                {
                    valid_data_read_rate++;
                    is_valid = true;
                }

                let _st_ot = (parseFloat(excelData[i].__EMPTY_32) - auth.GlobalConfig.GC_NumberOfWorkingHourInDay * (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2)).toFixed(1);
                const was_over_time = (parseFloat(excelData[i].__EMPTY_32) - auth.GlobalConfig.GC_NumberOfWorkingHourInDay * (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2)).toFixed(1);
                let __total_day = parseInt(excelData[i].__EMPTY_11);
                if(auth.GlobalConfig.GC_AdjustFlexiRoasterWhenImport) 
                {
                    if(__total_day === 0) 
                    {
                        __total_day = 30;
                    }
                } 

                const _late_minute = parseFloat(excelData[i].__EMPTY_27);
                if(auth.GlobalConfig.GC_DecreaseLateMinuteFromOvertime) 
                {
                    _st_ot = (_st_ot - _late_minute).toFixed(2);
                }

                const temp_object_wrapper = {
                    EmployeeID:  _employee_id,
                    EmployeeName:  excelData[i].__EMPTY_4,
                    TotalDay: __total_day,
                    TotalPresent: excelData[i].__EMPTY_13,
                    LateMinute:  _late_minute,
                    AbsentDate: excelData[i].__EMPTY_19,
                    TotalWorkingHour: excelData[i].__EMPTY_32,
                    OverTime: _st_ot > 0 ? _st_ot : 0,
                    WasOverTime: was_over_time,
                    HasOverTime: auth.GlobalConfig.GC_EnableOverTime,
                    InSync: is_valid
                }

                // 
                if(auth.GlobalConfig.GC_ExcludeUnmatchedDataFromProcessing) 
                {
                    if(is_valid) 
                    {
                        array_data_package.push(temp_object_wrapper);
                    }
                } else 
                {
                    array_data_package.push(temp_object_wrapper);
                }
            }
            SetPreComputedData(array_data_package);
            SetInSyncDataCount(valid_data_read_rate);
            //  console.log(data_map);
        };

        reader.readAsBinaryString(file);
    };

    useEffect(() => {

        if(EmployeePackageList.length <= 0) 
        {
            
            const addItemsWithDelay = () => {
                PreComputedData.forEach((item, idx) => {
                    setTimeout(() => {
                        SetEmployeePackageList((prev) => [...prev, item]);
                        SetEmployeePackageListOriginal((prev) => [...prev, item])
                    }, idx * 250);
                });
            };

            if (PreComputedData && PreComputedData.length > 0) {
                addItemsWithDelay();
            }
        
        }
    }, [PreComputedData]);

    useEffect(()=>{
        ParseEtDate();
        QueryBaseEmployee();
    }, [])
    
    const scrollToBottom = () => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        
    };
    useEffect(()=>{
        if(ScrollToBottomOnUpdate) 
        {
            scrollToBottom();
        } else 
        {
            SetScrollToBottomOnUpdate(true);
        }
    }, [EmployeePackageList])

    const handleDrop = (e) =>
    {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {

            if(validateFileType(files[0])) 
            {
                if(ValidateFileSize(files[0])) 
                {
                    handleFileUpload(files, true);
                } else 
                {
                    notify.ShowError(`The file size ${(files[0].size / (1024 * 1024)).toFixed(2)} Mb is tooooo large please consider converting the file to lower size!`);
                }

            } else 
            {
                notify.ShowError("Invalid file type, please use only excel file only.");
            }


        }
    }
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };
    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const HandleDataFeedProcess = async() =>
    {
        try 
        {
            const fd = new FormData();

            // Filter important list
            const valid_in_sync_list = [];
            for(let i = 0; i < EmployeePackageList.length; i++) 
            {
                if(EmployeePackageList[i].InSync) 
                {
                    valid_in_sync_list.push(EmployeePackageList[i]);
                }
            }

            fd.append("employee_package_list", JSON.stringify(valid_in_sync_list));
            fd.append("processed_by", auth.user.UserAccountID);
            fd.append("branch_id", WorkspaceData.BranchID);
            fd.append("ec_day", WorkspaceData.PAYROLLBMS_TargetPayrollECDay);
            fd.append("ec_month", WorkspaceData.PAYROLLBMS_TargetPayrollECMonth);
            fd.append("ec_year", WorkspaceData.PAYROLLBMS_TargetPayrollECYear);
            fd.append("target_schedule", WorkspaceData.PAYROLLBMS_ID);

            const main_res = await CreateInitialPayrollDataFeed(fd, auth.token);
            notify.ShowSuccess(main_res.Msg);
            UpdateViewBack();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const validateFileType = (file)=>
    {
        const ALLOWED_TYPES = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
        if (!ALLOWED_TYPES.includes(file.type)) 
        {
            return false;
        }

        return true;
    }
    const ValidateFileSize = (file)=>
    {
        if (file.size > MAX_ATTENDY_FILE_SIZE) {
            return false;
          } 

        return true;
    }

    const UpdateMasterAttendyData = (prop_name, data, value)=>
    {
        SetScrollToBottomOnUpdate(false);

       SetEmployeePackageListOriginal((prev) => {

            const _update = prev.map((item) => {
                let _dynamic_data = {...item};
                if (item.EmployeeID === data.EmployeeID) {

                    if(auth.GlobalConfig.GC_EnableAutomaticUpdatesForLinkedEntries) 
                    {
                        if(prop_name === "AbsentDate") 
                        {
                            // TotalPresent
                            _dynamic_data = { ...item, TotalPresent: parseInt(item.TotalDay) - parseInt(value) };
                        }

                        if(prop_name === "TotalPresent") 
                        {
                            _dynamic_data = { ...item, AbsentDate: parseInt(item.TotalDay) - parseInt(value) };
                        }

                        if(prop_name === "HasOverTime") 
                        {
                            if(!value) 
                            {
                                _dynamic_data = { ...item, OverTime: 0 };

                            } else 
                            {
                                let _st_ot = (parseFloat(item.TotalWorkingHour) - auth.GlobalConfig.GC_NumberOfWorkingHourInDay * (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2)).toFixed(1);
                            
                                if(auth.GlobalConfig.GC_DecreaseLateMinuteFromOvertime) 
                                {
                                    _st_ot = (_st_ot - item.LateMinute).toFixed(2);
                                }
                                _dynamic_data = { ...item, OverTime: _st_ot > 0 ? _st_ot : 0  };
                            }
                        }
                    }

                    return { ..._dynamic_data, [prop_name]: value };
                }
                return item;
            });
            return _update; 
        });

        SetEmployeePackageList((prev) => {

            const _update = prev.map((item) => {
                let _dynamic_data = {...item};
                if (item.EmployeeID === data.EmployeeID) {

                    if(auth.GlobalConfig.GC_EnableAutomaticUpdatesForLinkedEntries) 
                    {
                        if(prop_name === "AbsentDate") 
                        {
                            // TotalPresent
                            _dynamic_data = { ...item, TotalPresent: parseInt(item.TotalDay) - parseInt(value) };
                        }

                        if(prop_name === "TotalPresent") 
                        {
                            _dynamic_data = { ...item, AbsentDate: parseInt(item.TotalDay) - parseInt(value) };
                        }

                        if(prop_name === "HasOverTime") 
                            {
                                if(!value) 
                                {
                                    _dynamic_data = { ...item, OverTime: 0 };
    
                                } else 
                                {
                                    let _st_ot = (parseFloat(item.TotalWorkingHour) - auth.GlobalConfig.GC_NumberOfWorkingHourInDay * (auth.GlobalConfig.GC_NumberOfWorkingDayInAweek * 4 + 2)).toFixed(1);
                                
                                    if(auth.GlobalConfig.GC_DecreaseLateMinuteFromOvertime) 
                                    {
                                        _st_ot = (_st_ot - item.LateMinute).toFixed(2);
                                    }
                                    _dynamic_data = { ...item, OverTime: _st_ot > 0 ? _st_ot : 0  };
                                }
                            }
                    }

                    return { ..._dynamic_data, [prop_name]: value };
                }
                return item;
            });
            return _update; 
        });
    }

    const FilterEmployee = (filter_key)=>
    {
        SetScrollToBottomOnUpdate(false);
        const valid_list = [];
        for(let i = 0; i < EmployeePackageListOriginal.length; i++) 
        {
            if(
                EmployeePackageListOriginal[i].EmployeeName.toLowerCase().includes(filter_key.toLowerCase()) ||
                EmployeePackageListOriginal[i].EmployeeID.toLowerCase().includes(filter_key.toLowerCase())
            ) 
            {
                valid_list.push(EmployeePackageListOriginal[i]);
            }
        }

        SetEmployeePackageList(valid_list);
    }

    return (
        <div  className={`${classes.hr_data_workshope_data_bundle}`}>

            <div className={`${classes.hr_data_feed_header}`}>

                <h2>[DF] {WorkspaceData.BranchName} <strong>{ToEthiopianMonth(WorkspaceData.PAYROLLBMS_TargetPayrollECMonth)} - {PayrollDateForGCReadDate.toLocaleString('default', { month: 'long' })}</strong> Payroll</h2>
                <input
                    className={`${classes.hidden_file_input}`} 
                    id="file-input"
                    accept=".xls,.xlsx"
                    onChange={(e)=>{handleFileUpload(e)}} 
                    type="file" 
                    name="attendy_package"
                     />
               <div className={`${classes.top_hr_data_feed_pass_right_wrapper}`}>
                  { EmployeePackageList.length > 0 &&   <UIButton Icon={<Export weight='bold'/>} label={"Process"} onClick={()=>{
                    DAControl.Trigger(
                        `Finish Payroll Data Feed Process`,
                        `Once this process is completed, you will no longer be able to make changes to overtime, absence dates, late minutes, or similar details. The next stage will involve managing employee credits, allowances, and finalizing salaries. Please ensure all information is accurate before proceeding.`,
                        true,
                        5,
                        (data)=>
                        {
                            if(data)
                            {
                                HandleDataFeedProcess();
                            }
                        }
                    )}}/>}
                    <label htmlFor="file-input" className={`${classes.custom_file_input}`}> {SelectedFileName} </label>
                </div>  

            </div>
            
            <div 
                onDragOver={handleDragOver}  
                className={`${classes.hr_data_feed_pass_workspace_wrapper}`}
                >
                 {isDragging &&  <div onDragLeave={handleDragLeave}   onDrop={handleDrop}  className={`${classes.dragging_package_info}`}>
                            <ul>Drop Attendace File here</ul>
                    </div> }

                    <div className={`${classes.filter_workshop_by_name}`}>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <input onChange={(e)=>{FilterEmployee(e.target.value)}} autoComplete='off' placeholder='Eg. Employee Name, Employee ID' type="text" /> 
                            <span><MagnifyingGlass weight='bold'/></span>
                        </div>
                    </div>
                
                    
                        <div className={`${classes.branch_bundle_wrapper}`}>
                                <li className={`${classes.header_tab_tabe}`}>
                                    <span>ID</span>
                                    <span>Full Name</span>
                                    <span>Total Day</span>
                                    <span>Total Present</span>
                                    <span>Absent Date</span>
                                    <span>Late In Hours</span>
                                    <span>Total Working Hour</span>
                                    <span>OverTime</span>
                                    <span>Read State</span>
                                </li>
                        <div ref={scrollRef} className={`${classes.data_roll_hr_data_payroll_feed}`}>
                            {
                                EmployeePackageList.map((item, idx)=>(
                                    <li key={item.EmployeeName + item.EmployeeID}>
                                        <span>{item.EmployeeID}</span>
                                        <span>{item.EmployeeName}</span>
                                        <span>{item.TotalDay}</span>
                                        <span><input disabled={!auth.GlobalConfig.GC_AllowAttendanceDateAdjustments} onChange={(e)=>{UpdateMasterAttendyData('TotalPresent', item, e.target.value)}} className={`${classes.excel_input_like}`} type="text" value={item.TotalPresent} /></span>
                                        <span><input disabled={!auth.GlobalConfig.GC_AllowAbsenceDateAdjustments} onChange={(e)=>{UpdateMasterAttendyData('AbsentDate', item, e.target.value)}} className={`${classes.excel_input_like}`} type="text" value={item.AbsentDate} /></span>
                                        <span><input disabled={!auth.GlobalConfig.GC_AllowLastMinuteAdjustments} onChange={(e)=>{UpdateMasterAttendyData('LateMinute', item, e.target.value)}} className={`${classes.excel_input_like}`} type="text" value={item.LateMinute} /></span>
                                        <span>{item.TotalWorkingHour}</span>
                                        <span>
                                                <input onChange={(e)=>{UpdateMasterAttendyData('HasOverTime', item, e.target.checked)}} checked={item.HasOverTime} className={`${classes.ot_adder_input_wrap}`}  type="checkbox" /> 
                                                <input disabled={!auth.GlobalConfig.GC_AllowOvertimeAdjustment} onChange={(e)=>{UpdateMasterAttendyData('OverTime', item, e.target.value)}} className={`${classes.excel_input_like}`} type="text" title={item.HasOverTime && auth.GlobalConfig.GC_DecreaseLateMinuteFromOvertime && (item.WasOverTime > 0) ? `The late minutes have been deducted from overtime. The original overtime was ${item.WasOverTime}` : ''} value={item.HasOverTime ? item.OverTime : 0} /></span>
                                        <span className={`${item.InSync ? classes.valid_read : classes.invalid_read}`}> {item.InSync ? "Match Found" : "No Match"} </span>
                                    </li>
                                ))
                            }
                            {
                                EmployeePackageList.length === 0 && 
                                <div className={`${classes.employe_employee_attendy_data}`}>
                                        <ul>Import Employee Attendace or</ul>
                                        <UIButton Tip={"By confirming this action, it will be assumed that no attendance file exists for this branch. You may need to manually record employee absences, late minutes, or day-offs as necessary."} Variant='Secondary' label={"Continue without attendace"} Icon={<ArrowUpRight/>} onClick={()=>{ZeroAttendaceData()}}/>
                                </div>
                            }
                            </div>
                        </div>
                
            </div>
            <div className={`${classes.hr_data_feed_footer_data_View}`}>
                <li>Total Read Data Count: {EmployeePackageList.length}, with <strong>{(InSyncDataCount / EmployeePackageList.length).toFixed(2) * 100}% </strong> accuracy.</li>
            </div>
        </div>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './EditInventoryItem.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {faSpinner, faTimesCircle, faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef, useState } from 'react'
import axios from 'axios';
import Select from 'react-select';
import { useEffect } from 'react'
import {mapToObject, objectToMap, Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { FetchBranchAll, UpdateFetchIVItemID, FetchIVItemID} from '../../../../../api'
import { useToast } from '../../../../../ToastContext'
import { useAuth } from '../../../../../AuthProvider'

import DotsGif from '../../../../../SharedComp/CssGif/DotsGif'

import { FetchWarehouseByBranch } from '../../../../../SharedComp/API/Warehouse/API_FE_Warehouse'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'

export default function EditInventoryItem({OpenModal, TargetID}) 
{
    const formRef = useRef();

    const notify = useToast();
    const auth = useAuth();

    const [Quering, SetQuering] = useState(true);
    // For Dropdown selection
    const [GoodsTypeOptionList, SetGoodsTypeOptionList] = useState([]);
    const [GoodsBrandOptionList, SetGoodsBrandOptionList] = useState([]);
    const [GoodsMeasurementOptionList, SetGoodsMeasurementOptionList] = useState([]);

    // Workspace 
    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);
    const [BranchPrimeWarehouseView, SetBranchPrimeWarehouseView] = useState(new Map());


    // If the server is requested but not answerd yet..
    const [registring, SetRegistring] = useState(false);

    // Worshope Warehouse
    const [BranchWarehouse, SetBranchWarehouse] = useState(new Map());
    const [IVInforceStoreChoice, SetIVInforceStoreChoice] = useState(true);
    const [IVMinMovabaleAmount, SetIVMinMovabaleAmount] = useState('');

    // Data
    const [IVItemName, SetIVItemName] = useState();
    const [IVItemType, SetIVItemType] = useState();
    const [IvItemUOM, SetIvItemUOM] = useState();
    const [IVItemFollowParent, SetIVItemFollowParent] = useState(false);


    const handleToggle = (branchID) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("Active", !update_state_inner.get("Active"));

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecMaxBalance = (branchID, max_balance) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("MaxBalance", max_balance);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecMinBalance = (branchID, min_balance) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("MinBalance", min_balance);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchPrimaryStoreChoice = (branch_id, data) =>
    {
        if(!WorkspaceChecked.has(branch_id))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branch_id));
            update_state_inner.set("PrimaryStore", data);

            updatedState.set(branch_id, update_state_inner);
            return updatedState;
        });

        SetBranchPrimeWarehouseView(prev => {
            const updatestate = new Map(prev);
            updatestate.set(branch_id, data);
            return updatestate;
        });

    }
    const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            const updated = mp_val;
            updated.set("Active", val);
            updated_list.set(mp_key, updated);
        }
        SetWorkspaceChecked(updated_list);    
    };
    const QueryWorkspace = async () => {

        SetQuering(true);
        try {
            const res = await FetchBranchAll(auth.token);


            // Fetch all warehouse for all branch
            const _warehouse_temp = new Map();

            for(let i = 0; i < res.length; i++) 
            {
                const fd = new FormData();
                fd.append('ID', res[i].BranchID);
                const __wraehsoue_list = await FetchWarehouseByBranch(fd, auth.token);
                _warehouse_temp.set(res[i].BranchID, __wraehsoue_list);
            }

            SetBranchWarehouse(new Map(_warehouse_temp));

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchID)) 
                    init_check_list.set(res[i].BranchID, new Map([
                        ["Active", false],
                        ["MinBalance", null],
                        ["MaxBalance", null],
                        ["PrimaryStore", null]
                      ])); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }
    // Clear the form when ever the user click dismiss of close the window
    function CloseModal() 
    {
        formRef.current.reset();
        OpenModal(false); 
    }
    // Handle submition
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Processing your request...", 10);
        

        setTimeout( async() => {

            
            try 
            {
                const fd = new FormData();
                fd.append("goods_name", IVItemName);
                fd.append("item_type", JSON.stringify(IVItemType));
                fd.append("item_uom", JSON.stringify(IvItemUOM));
                fd.append("follow_parent", IVItemFollowParent);
                fd.append("workspace_info", JSON.stringify(mapToObject(WorkspaceChecked)));
                fd.append("target_id", TargetID);
                fd.append('min_dispatch_volume', IVMinMovabaleAmount);
                fd.append('enforce_store', IVInforceStoreChoice);
    
                const res = await UpdateFetchIVItemID(fd, auth.token);
                notify.ShowSuccessUpdate(res.Msg, 10);
                SetRegistring(false);
                CloseModal();
            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
                SetRegistring(false);
            }

        }, 500)
    }

    const FetchTargetData = async()=>{

        try 
        {

            const res = await FetchIVItemID(TargetID);
            
            SetIVItemName(res.IVItemName);
            SetIVItemType({value: res.IVItemTypeID, label: res.IVItemTypeName});
            SetIvItemUOM({value: res.UOMID, label: res.UomName});
            SetIVItemFollowParent(res.IVItemFollowParent);
            let p_res = objectToMap(JSON.parse(res.IVItemWorkspaceInfo));

            const __prime_store = new Map();
            for(const [key, value] of p_res.entries()) 
            {
                const _read_store_object = {value: value.get('PrimaryStore').get('value'), label: value.get('PrimaryStore').get('label')}
                __prime_store.set(key, _read_store_object);
            } 
            SetBranchPrimeWarehouseView(new Map(__prime_store));

            for(const [key, value] of WorkspaceChecked.entries()) 
            {
                if(!p_res.has(key)) 
                {
                    p_res.set(key, value);
                }
            }

            SetWorkspaceChecked(p_res);
            SetIVInforceStoreChoice(res.IVItemEnforceStore === 'Yes');
            SetIVMinMovabaleAmount(res.IVItemMinDispatchAmount ? res.IVItemMinDispatchAmount : '');
            SetQuering(false);
        

        }catch(err) 
        {
            notify.ShowError("Failed to load Target Data");
        }
    }

    useEffect(()=>{

        if(WorkspaceList.length > 0)
            FetchTargetData();

    }, [WorkspaceList])



    useEffect(() => 
    {
        
        QueryWorkspace();
        QueryOption();
    }, [])
        

      function QueryOption() 
      {
        fetch(getBaseURL() +  'master-data/get-item-brand')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetGoodsBrandOptionList));

        fetch(getBaseURL() + 'master-data/get-uom')
        .then(res => res.json())
        .then((data) => PrepareDropDownList(data, SetGoodsMeasurementOptionList));


        fetch(getBaseURL() + 'master-data/get-iv-type', { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: "Yes" }),
          })
            .then(res => res.json())
            .then((data) => {PrepareDropDownList(data, SetGoodsTypeOptionList); });
      } 



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Edit <span className={`${data_win.data_create_name}`}> Inventory Item</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body} ${classes.data_body}`}>
                        
                

                             <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                             {!Quering && <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>

                                            <MasterDataRegInput 
                                                    input={<input 
                                                        value={IVItemName} 
                                                        onChange={(e)=>{SetIVItemName(e.target.value)}} 
                                                        name='goods_name' 
                                                        type="text" 
                                                        placeholder='Inventory Good Name' 
                                                        title='Inventory Good Name' 
                                                        autoComplete='off'
                                                        required />} />
                                            <MasterDataRegInput 
                                                    input={<input 
                                                        value={IVMinMovabaleAmount} 
                                                        onChange={(e)=>{SetIVMinMovabaleAmount(e.target.value)}} 
                                                        name='min_quantity'
                                                        step={"any"} 
                                                        min={0}
                                                        type="number" 
                                                        placeholder='Minimum Dispatchable Volume' 
                                                        title='Minimum Dispatchable Volume' 
                                                        autoComplete='off'
                                                    />} />
                                            <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            value={IVItemType}
                                                            onChange={(data)=>{SetIVItemType(data)}}
                                                            name={"iv_type"}
                                                            options={GoodsTypeOptionList}
                                                            placeholder={"Type..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                            
                                                        />
                                                
                                                }  />
                                            <MasterDataRegInput 
                                                    input={
                                                        <Select
                                                            value={IvItemUOM}
                                                            onChange={(data)=>{SetIvItemUOM(data)}}
                                                            name={"iv_uom"}
                                                            options={GoodsMeasurementOptionList}
                                                            placeholder={"UOM..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                    />
                                                    } tip={"Unit of measurement"} />
                                            <div onClick={()=>{SetIVItemFollowParent(!IVItemFollowParent)}} className={`${classes.follow_parent_wrapper}`}>
                                                <input checked={IVItemFollowParent} onChange={(e)=>{SetIVItemFollowParent(e.target.value)}} type="checkbox" />
                                                <span>Inherit and Override from Parent Group</span>
                                            </div>
                                            <div onClick={()=>{SetIVInforceStoreChoice(!IVInforceStoreChoice)}} className={`${classes.follow_parent_wrapper}`}>
                                                <input checked={IVInforceStoreChoice} onChange={(e)=>{SetIVInforceStoreChoice(e.target.value)}} type="checkbox" />
                                                <span>Enforce storage use to designated store only</span>
                                            </div>
                                        </div>

                                        <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                            <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                                <h1>Accessibility</h1>
                                                <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                            </div>
                                            <div className={`${classes.master_prod_avail_list}`}>
                                                {
                                                    WorkspaceList.map((item, idx)=>(
                                                        <div key={idx}  className={`${classes.master_prod_avail_branch}`}>
                                                            
                                                            <div onClick={() => handleToggle(item.BranchID)} className={`${classes.workspace_tick_and_name_wrapper}`}>

                                                                <input 
                                                                    onClick={() => handleToggle(item.BranchID)}
                                                                    checked={WorkspaceChecked.get(item.BranchID)?.get("Active")} 
                                                                    name={item.BranchName} 
                                                                    type="checkbox"
                                                                    onChange={() => handleToggle(item.BranchID)}
                                                                    />
                                                                <li>{item.BranchName}</li>
                                                                
                                                            </div>
                                                            
                                                            <div className={`${classes.workspace_min_max_value_per_type_wrapper}`}>
                                                            <MasterDataRegInput 
                                                                    input={
                                                                        <Select
                                                                            value={BranchPrimeWarehouseView?.get(item.BranchID)}
                                                                            onChange={(data)=>{handleBranchPrimaryStoreChoice(item.BranchID, data)}}
                                                                            name={"prim_store"}
                                                                            options={PrepareDropDownList(BranchWarehouse.get(item.BranchID), ()=>{})}
                                                                            placeholder={"Primary Store..."}
                                                                            isSearchable={true}
                                                                            styles={Custom_DD_Style}
                                                                            required ={IVInforceStoreChoice}
                                                                        />
                                                                
                                                                } 
                                                                tip={"This serves as the primary storage location for this item within this specific branch."} 
                                                                />
                                                            <MasterDataRegInput
                                                                input={
                                                                    <input  
                                                                    value={WorkspaceChecked.get(item.BranchID)?.get("MinBalance")} 
                                                                    onChange={(e)=>{handleBranchSpecMinBalance(item.BranchID, e.target.value)}} 
                                                                    type="number"
                                                                    min={0}
                                                                    step={"any"} 
                                                                    placeholder="Min Balance" />
                                                                }
                                                                />
                                                                

                                                                <MasterDataRegInput
                                                                input={
                                                                    <input 
                                                                    value={WorkspaceChecked.get(item.BranchID)?.get("MaxBalance")} 
                                                                    onChange={(e)=>{handleBranchSpecMaxBalance(item.BranchID, e.target.value)}} 
                                                                    type="number"
                                                                    min={0}
                                                                    step={"any"}
                                                                    placeholder="Max Balance" />
                                                                }
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    </div>
                                </div> }
                                {Quering &&  <div className={`${classes.loader_wrapper}`}><DotsGif/></div> }
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form> 
                            




                </div>
            </div>

        </div>
    )
}
import classes from './Products.module.css'
import { useEffect, useState } from 'react';

import { PurgeProductLineMasterGroup, FetchProductLineMasterSubGroupAll, PurgeProductLineMasterSubGroup } from '../../../api';

import {FetchProductLineMasterGroupAll} from '../../../SharedComp/API/ProductLine/API_FE_ProductCluster'

import MasterProductGroupCard from './Component/MasterProductGroupCard/MasterProductGroupCard';



import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';
import { useDAControl } from '../../../DAControlContext';

import ConfigMasterProduct from './Component/ConfigMasterProduct/ConfigMasterProduct';

import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';

const Products = () => {

    
    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl();

    const [ToggelConfigMasterProduct, SetToggelConfigMasterProduct] = useState(false);
    const [TargetConfigData, SetTargetConfigData] = useState(null);

    const [MasterProductGroup, SetMasterProductGroup] = useState([]);

    // Loading
    const [LoadingData, SetLoadingData] = useState(false);


    const FetchMasterProduct = async()=>
    {
        SetLoadingData(true);
        try 
        {
            const main_res = await FetchProductLineMasterGroupAll(auth.token);
            SetMasterProductGroup(main_res);
            SetLoadingData(false);
        }catch(err) 
        {
            SetLoadingData(false);
            notify.ShowError(err.message);
        }
    }

    const HandleConfigCallback = async(data)=> 
    {
        SetTargetConfigData(data); // Set Data first

        // Open the config window
        SetToggelConfigMasterProduct(true);
    }

    useEffect(()=>{
        if(!ToggelConfigMasterProduct) 
        {
            // dont't empty when opening
            SetMasterProductGroup([]);
        }
        FetchMasterProduct();
    }, [ToggelConfigMasterProduct]);


    return (
        <div className={`${classes.product_main_master_wrapper}`}>
            {ToggelConfigMasterProduct && <ConfigMasterProduct OpenController={SetToggelConfigMasterProduct} TargetData={TargetConfigData}/>}


            <div className={`${classes.product_main_group}`}>
                {
                    MasterProductGroup.map((item, idx)=>(

                        <MasterProductGroupCard 
                                key={item.ProdCatName}
                                TargetData={item} 
                                ConfigCallback={HandleConfigCallback}
                            />

                    ))
                }
                {
                    LoadingData && 
                    <LoadingPlaceholder/>
                }
            </div>
            
            
        </div>
    )
}

export default Products;
import classes from './CompanyBranchStyle.module.css'
import BranchCard from './Component/Card/BranchCard';
import BranchCreator from './Component/Create/BranchCreator';
import { useState } from 'react';
import UIButton from '../Components/UIButton/UIButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import BranchView from './Component/View/BranchView';
import NotificationPopUp from '../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';
import { CirclesThreePlus } from '@phosphor-icons/react';

export default function CompanyBranch() 
{
    const [OpenDetailView, SetOpenDetailView] = useState(false);
    const [SiteList, SetSiteList] = useState([]);
    const [FocuseSite, SetFocuseSite] = useState();

    const [OpenBranchCreator, setOpenBranchCreator] = useState(false);

    const [ProductionCenterList, SetProductionCenterList] = useState([]);
    const [OfficeList, SetOfficeList] = useState([]);
    const [OutletList, SetOutletList] = useState([]);

    const [HasOutlet, SetHasOutlet] = useState(false);
    const [HasProdCenter, SetHasProdCenter] = useState(false);
    const [HasOffice, SetHasOffice] = useState(false);


      // Notifiction
      const [MsgPkg, SetMsgPkg] = useState({});
      const [OpenMyNoti, SetOpenMyNoti] = useState(false);
      const [RefeshList, SetRefeshList] = useState(false);



      function PreProcess() 
      {

        let office_list = [];
        let outlet_list = [];
        let pc_list = [];

        for(let  i = 0; i < SiteList.length; i++) 
        {
            if(SiteList[i].Type === "Production Center") 
            {
                pc_list.push(SiteList[i]);
            } else if(SiteList[i].Type === "Outlet") 
            {
                outlet_list.push(SiteList[i]);
            } else if(SiteList[i].Type === "Office") 
            {
                office_list.push(SiteList[i]);
            }
        }

        SetProductionCenterList(pc_list);
        SetOfficeList(office_list);
        SetOutletList(outlet_list);

        SetHasOutlet(outlet_list.length > 0);
        SetHasProdCenter(pc_list.length > 0);
        SetHasOffice(office_list.length > 0);
      }

      useEffect(()=>{
        PreProcess();
      }, [SiteList])

      function CreateQuickSitePreview(siteData) 
      {
        SetFocuseSite(siteData);
        SetOpenDetailView(true);
      }

      function MsgCallback(res) 
      {
          setOpenBranchCreator(false);
          LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
      }
      function LocalMsgTrigger(msg, stat) 
      {
          if(stat === 1) 
          {
              SetRefeshList(!RefeshList);
          }
          SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
          SetOpenMyNoti(true);
      }


    useEffect(() => 
    {
        fetch(getBaseURL() +  'master-data/get-company-branch')
            .then(res => res.json())
            .then((data) => SetSiteList(data));
    }, [RefeshList]) 

    return (
        <div className={`${classes.company_branch_main_wrapper}`}>


            {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}
            {OpenBranchCreator && <BranchCreator Close_CALLBACK={setOpenBranchCreator}/>} 
            {OpenDetailView &&  <BranchView Close_CALLBACK={SetOpenDetailView} BranchData={FocuseSite} />} 

           <div className={`${classes.branch_body_wrapper}`}>


           <div className={`${classes.action_button_wrapper}`}>
                    <UIButton Variant='Secondary' Icon={<CirclesThreePlus/>} onClick={()=>{setOpenBranchCreator(true)}} label={"Create New Workspace"}/>
                    {/* <button onClick={()=>{}}>Register Location</button> */}
                </div>

                <div className={`${classes.esta_wrapper}`}>


                        <div className={`${classes.production_center_inner_list_wrapper}`}>
                            {SiteList.map((item, index) => (
                                <BranchCard key={index} Data_CALLBK={CreateQuickSitePreview} BranchData={item}  />
                            ))}
                        </div> 

                        {/* { HasOffice && <div className={`${classes.office_site_wrapper}`}>
                                <div className={`${classes.office_site_inner_wrapper}`}>
                                    <div className={`${classes.establishment_header_office}`}>
                                        <h1>Office</h1>
                                    </div>
                                    <div className={`${classes.office_site_inner_list_wrapper}`}>
                                        {OfficeList.map((item, index) => (
                                                <BranchCard  key={index} Data_CALLBK={CreateQuickSitePreview} BranchData={item}  />
                                            ))}
                                    </div>  
                                </div>     
                        </div> }

                        {HasOutlet && <div className={`${classes.Outlet_wrapper}`}>
                                <div className={`${classes.Outlet_inner_wrapper}`}>

                                    <div className={`${classes.establishment_header}`}>
                                        <h1>Active Outlet</h1>
                                    </div>

                                    <div className={`${classes.Outlet_inner_list_wrapper}`}>
                                        {OutletList.map((item, index) => (
                                            <BranchCard key={index} Data_CALLBK={CreateQuickSitePreview}  BranchData={item} />
                                        ))}
                                    </div> 
                                </div>     
                        </div> } */}

                </div>

               
           </div>

        </div>
    );
}
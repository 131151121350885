import classes from './MerchandiseDataPackageRC.module.css'

import { FetchMerchProductAll } from '../../../../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise';
import { useAuth } from '../../../../../../../AuthProvider';
import { useToast } from '../../../../../../../ToastContext';
import { useEffect, useState } from 'react';
import { ArrowBendUpRight, Lock, LockOpen, Warning } from '@phosphor-icons/react';
import UIButton from '../../../../../../Components/UIButton/UIButton';
import { QueryAvialMerchByBranch } from '../../../../../../../SharedComp/API/ShopShelf/API_FE_MerchShelves';

export default function MerchandiseDataPackageRC({Interaction, MerchDataPackage, SetMerchDataPackage}) 
{

    const auth = useAuth();
    const notify = useToast();

    // ///////////////////////////// Merch START 
    const [LockMerchInteraction, SetLockMerchInteraction] = useState(false);
    const [MerchProductList, SetMerchProductList] = useState([]);
    const [MerchProductListOriginal, SetMerchProductListOriginal] = useState([]);

    const InitMerchDataPackage = async(data)=>
    {
        const _init_list = new Map();
        for(let i = 0; i < data.length; i++) 
        {
            _init_list.set(data[i].CMMP_Name,
                {
                    Name: data[i].CMMP_Name,
                    ProductID: data[i].CMMPID,
                    Price: data[i].CMMP_Price,
                    Damage: 0,
                    Unsold: 0,
                    Available: data[i].SPMP_Quantity
                }
            )
        }

        SetMerchDataPackage(new Map(_init_list));
    }
    const UpdateMerchUnsoldAmount = async(name, amount)=>
    {
        SetMerchDataPackage((prevList) => {
            const newList = new Map(prevList);
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Unsold: amount });  
            }
            return newList; 
        });
    }
    const UpdateMerchDamageAmount = async(name, amount)=>
    {
        SetMerchDataPackage((prevList) => {
            const newList = new Map(prevList); 
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Damage: amount });  
            }
            return newList;  
        });
    }
    const HandleMerchFilter = async(_key)=>
        {
            const valid_list = [];
    
            for(let  i = 0; i < MerchProductListOriginal.length; i++) 
            {
                const _seach_val = MerchProductListOriginal[i].CMMP_Name;
                if(_seach_val.toLowerCase().includes(_key.toLowerCase())) 
                {
                    valid_list.push(MerchProductListOriginal[i]);
                }
            }
    
            SortAndSetBakertList(valid_list);
    }
    const SortAndSetBakertList = async(data)=>
    {
        const sortedProducts = data.sort((a, b) => {
            if (a.CMMP_Name < b.CMMP_Name) return -1; 
            if (a.CMMP_Name > b.CMMP_Name) return 1; 
            return 0;                         
        });

        SetMerchProductList(sortedProducts);
    }
    const QueryMerchProductList = async()=>
    {
        try 
        { 
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const main_res = await QueryAvialMerchByBranch(fd, auth.token);
            // const main_res = await FetchMerchProductAll(auth.token);
            InitMerchDataPackage(main_res);
            SortAndSetBakertList(main_res);
            SetMerchProductListOriginal(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Merch Product List: " + err.message);
        }
    }

    // /////////////////////////////// Merch END
    

    useEffect(()=>{
        QueryMerchProductList();
    }, [])

    return (
        <div className={`${classes.list_box_wrapper}`}>

            <div className={`${classes.heder_type_main_wrapper}`}>
                <h3>Merchandise</h3>
                <div className={`${classes.group_filter_and_action}`}>
                    <input onChange={(e)=>{HandleMerchFilter(e.target.value)}} type="text" placeholder='Filter' autoComplete='off' />
                </div>
            </div>

            <div className={`${classes.list_content_section_wrapper}`}>
                <div style={{pointerEvents: LockMerchInteraction ? 'none' : '', filter: LockMerchInteraction ? 'brightness(50%)' : 'brightness(100%)' }} className={`${classes.items_list_wrapper}`}>
                    {
                        MerchProductList.map((item, idx)=>(
                            <div key={item.CMMP_Name} className={`${classes.list_single_item}`}>
                                <div className={`${classes.produc_singluar_name} ${classes.data_inner_wrapper}`}>
                                    {item.CMMP_Name}  <span>#{item.SPMP_Quantity} Pcs</span>
                                </div>

                                <div className={`${classes.data_left_side_input_wrapper}`}>
                                    <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                        <li className={`${classes.damage_prod_report}`}> <span><Warning weight='fill'/></span> Damage</li>
                                        <input 
                                            value={MerchDataPackage.get(item.CMMP_Name)?.Damage || ''}
                                            onChange={(e) => UpdateMerchDamageAmount(item.CMMP_Name, e.target.value)}
                                            type="number" 
                                            name={item.CMMP_Name + '_'+'damage'}
                                            min={1} />
                                    </div>
                                    <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                        <li className={`${classes.unsold_prod_report}`}> <span><ArrowBendUpRight weight='fill'/></span> Unsold</li>
                                        <input
                                            key={item.CMMP_Name + "_unsold"}
                                            value={MerchDataPackage.get(item.CMMP_Name)?.Unsold || ''}
                                            onChange={(e) => UpdateMerchUnsoldAmount(item.CMMP_Name, e.target.value)}
                                            name={item.CMMP_Name + '_'+'unsold'}
                                            type="number" 
                                            min={1} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={`${classes.bottom_action_wrapper}`}>
                <UIButton 
                    Variant={LockMerchInteraction ? "Danger" : "Primary"} 
                    Icon={LockMerchInteraction ? <Lock weight='fill'/> : <LockOpen weight='fill' />} 
                    label={LockMerchInteraction ? "Unlock Interaction" : "Lock Interaction"} 
                    onClick={()=>{
                        SetLockMerchInteraction(!LockMerchInteraction); 
                        Interaction(!LockMerchInteraction);
                        }}/>
            </div>
            </div>
    )
}
import classes from './PayrollConfig.module.css'
import { useDAControl } from '../../../../../DAControlContext'
import { ToEthiopianCalander, ToEthiopianCalanderRaw, ToGregorianCalendar } from '../../../../../SharedComp/Utility';
import { PurgePayrollSchedule } from '../../../../API/Payroll/API_FE_PayrollSchedule';
import { useAuth } from '../../../../../AuthProvider';


const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`;
 };
export default function PayrollConfig({UpdateReq, ConfigData}) 
{
    const DAControl = useDAControl();
    const auth = useAuth();


    const PurgeManualPayroll = async()=>
    {
        try 
        {
            const fd = new FormData();
            const _target_date = formatDate(new Date());
            const _date_split = _target_date.split('-');
            const ec_date_raw = ToEthiopianCalanderRaw(new Date(_date_split[0], parseInt(_date_split[1]) - 1, _date_split[2]));
            fd.append("target_year", ec_date_raw[0]);
            fd.append("target_month", ec_date_raw[1]);
            fd.append("target_day", ec_date_raw[2]);

           const main_res = await PurgePayrollSchedule(fd, auth.token);

        }catch(err) {

        }
    }

    const UpdateAutoShecdule = async (data)=>
    {
        if(data) 
        {
            await PurgeManualPayroll();
        }
        UpdateReq("GC_EnableAutoSchedule", data)
    }
    return (
        <div className={`${classes.payroll_config_wrapper}`}>

            <div className={`${classes.part_of_option}`}>
                <ul>General</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Enable Automatic Updates for Linked Entries</span> <span><input onChange={(e)=>{UpdateReq("GC_EnableAutomaticUpdatesForLinkedEntries", e.target.checked)}} checked={ConfigData.GC_EnableAutomaticUpdatesForLinkedEntries} type="checkbox" /></span></li>
                    <li><span>Number of Working <strong>Days</strong> per Week</span> <span><input onChange={(e)=>{UpdateReq("GC_NumberOfWorkingDayInAweek", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_NumberOfWorkingDayInAweek} max={7} min={1} type="number" /></span></li>
                    <li><span>Number of Working <strong>Hours</strong> per Day</span> <span><input onChange={(e)=>{UpdateReq("GC_NumberOfWorkingHourInDay", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_NumberOfWorkingHourInDay} max={20} min={3} type="number" /></span></li>
                    <li><span>Allow Last-Minute Adjustments</span> <span><input onChange={(e)=>{UpdateReq("GC_AllowLastMinuteAdjustments", e.target.checked)}} checked={ConfigData.GC_AllowLastMinuteAdjustments} type="checkbox" /></span></li>
                    <li><span>Allow Absence Date Adjustments</span> <span><input onChange={(e)=>{UpdateReq("GC_AllowAbsenceDateAdjustments", e.target.checked)}} checked={ConfigData.GC_AllowAbsenceDateAdjustments} type="checkbox" /></span></li>
                    <li><span>Allow Attendance Date Adjustments</span> <span><input onChange={(e)=>{UpdateReq("GC_AllowAttendanceDateAdjustments", e.target.checked)}} checked={ConfigData.GC_AllowAttendanceDateAdjustments} type="checkbox" /></span></li>
                    <li><span>Allow Overtime Adjustment</span> <span><input onChange={(e)=>{UpdateReq("GC_AllowOvertimeAdjustment", e.target.checked)}} checked={ConfigData.GC_AllowOvertimeAdjustment} type="checkbox" /></span></li>
                    <li><span>Enable Over-Time</span> <span><input onChange={(e)=>{UpdateReq("GC_EnableOverTime", e.target.checked)}} checked={ConfigData.GC_EnableOverTime} type="checkbox" /></span></li>
                </div>  
            </div>

            <div className={`${classes.part_of_option}`}>
                <ul>Schedule</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Minimum Accuracy Rate Treshold (Precent)</span> <span><input onChange={(e)=>{UpdateReq("GC_MinimumAccuracyRateTreshold", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_MinimumAccuracyRateTreshold} type="text" /></span></li>
                    <li><span>Payroll Schedule Start Date  (1 - 30)</span> <span><input onChange={(e)=>{UpdateReq("GC_PayrollScheduleStartDate", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_PayrollScheduleStartDate} type="text" /></span></li>
                    <li><span>Payroll Schedule End Date  (1 - 30)</span> <span><input onChange={(e)=>{UpdateReq("GC_PayrollScheduleEndDate", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_PayrollScheduleEndDate} type="text" /></span></li>
                </div>  
            </div>
            <div className={`${classes.part_of_option}`}>
                <ul>Archive</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Keep Payroll Archive History For (Month)</span> <span><input onChange={(e)=>{UpdateReq("GC_KeepPayrollArchiveHistoryForMonthOf", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_KeepPayrollArchiveHistoryForMonthOf} type="text" /></span></li>
                </div>  
            </div>
            <div className={`${classes.part_of_option}`}>
                <ul>Activity Notification</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Address 1 [Critical]</span> <span><input style={{width: 'auto'}} onChange={(e)=>{UpdateReq("GC_JAK_PA_Notification_R1", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_JAK_PA_Notification_R1} type="email" /></span></li>
                    <li><span>Address 2 [Critical]</span> <span><input style={{width: 'auto'}} onChange={(e)=>{UpdateReq("GC_JAK_PA_Notification_R2", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_JAK_PA_Notification_R2} type="email" /></span></li>
                    <li><span>Address 3</span> <span><input style={{width: 'auto'}} onChange={(e)=>{UpdateReq("GC_JAK_PA_Notification_R3", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_JAK_PA_Notification_R3} type="email" /></span></li>
                    <li><span>Address 4</span> <span><input style={{width: 'auto'}} onChange={(e)=>{UpdateReq("GC_JAK_PA_Notification_R4", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_JAK_PA_Notification_R4} type="email" /></span></li>
                    
                </div>  
            </div>

            <div className={`${classes.part_of_option}`}>
                <ul>Automated Schedule</ul>
                <div className={`${classes.setting_items_config}`}>
                <li><span>Allow Automated Schedule System</span> <input checked={ConfigData.GC_EnableAutoSchedule} type="checkbox" onChange={(e)=>{
                        let _new_val = !ConfigData.GC_EnableAutoSchedule;
                        if(!ConfigData.GC_EnableAutoSchedule) 
                        {
                            DAControl.Trigger(
                            'Enable Payroll Auto Schedule',
                            `This action will purge any manually configured payroll schedule for the active month and create a new one with system generated schedule based on pre-defined rules.`,
                            true,
                            7,
                            (data)=>
                            {
                                if(data) 
                                {
                                    UpdateAutoShecdule(_new_val);
                                }
                            })
                        } else 
                        {
                            DAControl.Trigger(
                                'Disable Payroll Auto Schedule',
                                `This action will disable the auto schedule feature, this means you have to set each branch payroll schedule date!`,
                                true,
                                7,
                                (data)=>
                                {
                                    if(data) 
                                    {
                                        UpdateAutoShecdule(_new_val);
                                    }
                                })
                        }
                        
                        }} /></li>
                    <li><span>Automated Schedule before (Days)</span> <span><input onChange={(e)=>{UpdateReq("GC_AutoPayrollScheduleBeforeLastMonthDayOf", e.target.value)}} className={`${classes.input_value}`} value={ConfigData.GC_AutoPayrollScheduleBeforeLastMonthDayOf} type="number" min={1} max={15} /></span></li>
                </div>  
            </div>

            <div className={`${classes.part_of_option}`}>
                <ul>TCMS V3</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Adjust Flexi Roaster When Import</span> <span><input onChange={(e)=>{UpdateReq("GC_AdjustFlexiRoasterWhenImport", e.target.checked)}} checked={ConfigData.GC_AdjustFlexiRoasterWhenImport} type="checkbox" /></span></li>
                    <li><span>Examine Date Before Import</span> <span><input onChange={(e)=>{UpdateReq("GC_ExamineDateBeforeImport", e.target.checked)}} checked={ConfigData.GC_ExamineDateBeforeImport} type="checkbox" /></span></li>
                    <li><span>Decrease Late Minute From Overtime</span> <span><input onChange={(e)=>{UpdateReq("GC_DecreaseLateMinuteFromOvertime", e.target.checked)}} checked={ConfigData.GC_DecreaseLateMinuteFromOvertime} type="checkbox" /></span></li>
                    <li><span>Exclude Unmatched Data from Processing</span> <span><input onChange={(e)=>{UpdateReq("GC_ExcludeUnmatchedDataFromProcessing", e.target.checked)}} checked={ConfigData.GC_ExcludeUnmatchedDataFromProcessing} type="checkbox" /></span></li>
                </div>  
            </div>

        </div>
    )
}
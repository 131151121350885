import classes from './FrontDeskCashier.module.css'
import UIButton from '../../Components/UIButton/UIButton'
import { GearSix, MoneyWavy, SpinnerGap, UserCircle } from '@phosphor-icons/react'
import { useState } from 'react'
import OrderProductCard from './Components/OrderProductCard/OrderProductCard'

import {QueryActiveOrderByBranch, QueryActivePendingPayments} from '../../../SharedComp/API/DeskView/API_FE_DeskView'


import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'
import { useEffect } from 'react'

import ReceiveOrder from './Components/ReceiveOrder/ReceiveOrder'
import ActiveOrder from './Components/ActiveOrder/ActiveOrder'
import PendingPayment from './Components/PendingPayment/PendingPayment'
import ShiftClosing from './Components/ShiftClosing/ShiftClosing'
import { useDAControl } from '../../../DAControlContext'
import { QueryCashCollectionByBranchDate } from '../../../SharedComp/API/DeskView/API_FE_CloseShift'


export default function FrontDeskCashier() 
{
    const DAControl = useDAControl();


    const [ActiveTask, SetActiveTask] = useState(0);
    const [ReadyItemCounts, SetReadyItemCounts] = useState(0);
    const [CashCollectionIsDoneForDate, SetCashCollectionIsDoneForDate] = useState(false);

    const CountReadyItem = (data_array)=>
    {
        let __counter = 0;
        for(let i = 0; i < data_array.length; i++) 
        {
            if(data_array[i].PROPPI_ProductionState === "Ready") 
            {
                __counter++;
            }
        }
        if(__counter === 0) 
        {
            SetReadyItemCounts(null)
        } else 
        {
            SetReadyItemCounts(__counter)
        }
    }

    const HandleSwitchTask = async(idx)=> 
    { 
        if(ActiveTask === 3) 
        {
            DAControl.Trigger("Exiting Shift Close",
                `Any entered values will be discarded, and any changes you made won't be saved.`, 
                true,
                7,
                (data)=>{
                    if(data) 
                    {
                        SetActiveTask(idx);
                    }
                })
        } else 
        {
            SetActiveTask(idx); 
        }
        
    }

    const [ActiveOrderList, SetActiveOrderList] = useState([]);
    const [PendingPaymentList, SetPendingPaymentList] = useState([]);

    const auth = useAuth();
    const notify = useToast();

    
    const FetchActiveOrders = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const main_res = await QueryActiveOrderByBranch(fd, auth.token);
            const main_res2 = await QueryActivePendingPayments(fd, auth.token);
            SetActiveOrderList(main_res);
            SetPendingPaymentList(main_res2);
            CountReadyItem(main_res);
        }catch(err) 
        {
            notify.ShowError("Error Fecthing Active Orders!");
        }
    }

    const FetchCashCollectionByDate = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("date_info", new Date());
           const main_res = await QueryCashCollectionByBranchDate(fd, auth.token);
           if(main_res.length > 0) 
           {
                SetCashCollectionIsDoneForDate(true);
           } else 
           {
                SetCashCollectionIsDoneForDate(false);
           }
        } catch(err) 
        {
            
        }
        
    }

    useEffect(()=>{
        FetchActiveOrders();
        FetchCashCollectionByDate();
    }, [auth.activeWorkShop])


    

    return (
        <div className={`${classes.desk_view_manage_main_wrapper}`}>

            <div className={`${classes.desk_view_manage_header_wrapper}`}>   

                <div className={`${classes.desk_view_manage_header_inner_wrapper}`}>

                    <UIButton Variant={ActiveTask === 0 ? "Primary" : "Secondary"} label={"Receive Order"} onClick={()=>{HandleSwitchTask(0);}} Icon={<UserCircle/>} />
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectActiveOrder && <UIButton CountNew={(ActiveTask !== 1) ? ReadyItemCounts : null } Variant={ActiveTask === 1 ? "Primary" : "Secondary"} label={"Active Orders"} onClick={()=>{HandleSwitchTask(1)}} Icon={<GearSix/>} />}
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectPendingOrder && <UIButton Variant={ActiveTask === 2 ? "Primary" : "Secondary"} label={"Pending Payment"} onClick={()=>{HandleSwitchTask(2)}} Icon={<SpinnerGap/>} />}
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PerformCashCollection && <UIButton  Variant={CashCollectionIsDoneForDate ? "Disabled" :  ActiveTask === 3 ? "Primary" : "Secondary"} label={"Close Today’s Shift"} onClick={()=>{HandleSwitchTask(3)}} Icon={<MoneyWavy/>} />}

                </div>

            </div>

            <div className={`${classes.desk_view_manager_master_list_view_wrapper}`}>

                <div className={`${classes.desk_view_manager_inner_list_view_wrapper}`}>

                    {ActiveTask === 0 && <ReceiveOrder/>}
                    {ActiveTask === 1 && <ActiveOrder DataList={ActiveOrderList}/>}
                    {ActiveTask === 2 && <PendingPayment PaymentList={PendingPaymentList}/>}
                    {ActiveTask === 3 && <ShiftClosing/>}

                </div>

            </div>



        </div>
    )
}
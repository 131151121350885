import { useState } from 'react'
import classes from './EmpStatus.module.css'

import JobPay from './Component/JobPay/JobPay';
import EmpBank from './Component/Bank/EmpBank';
import EmployeeIDCard from './Component/EmployeeIDCard/EmployeeIDCard';
import EmployeeSupportDocument from './Component/EmployeeSupportDocument/EmployeeSupportDocument';


export default function EmpStatus() 
{
    const [InnerMiniTabBar, SetInnerMiniTabBar] = useState([false, true, false, false]);

    function MakeActiveTabBar(idx) 
    {
        const tab_bar_count = 4;
        let temp_bar = [];
        for(var i = 0; i < tab_bar_count; i++)
        {
            if(idx == i)
            {
                temp_bar.push(true);
            } else 
            {
                temp_bar.push(false);
            }
        }

        SetInnerMiniTabBar(temp_bar);
    }

    return (
        <div className={`${classes.emp_status_main_wrapper}`}>

            <div className={`${classes.emp_status_inner_wrapper}`}>
                <div className={`${classes.emp_personal_info_tab_bar}`}>
                    <li className={`${ InnerMiniTabBar[1] ? classes.active_tab_bar_but : ''}`} ><button onClick={() => {MakeActiveTabBar(1)}}>Job & Pay</button></li>
                    <li className={`${ InnerMiniTabBar[2] ? classes.active_tab_bar_but : ''}`} ><button onClick={() => {MakeActiveTabBar(2)}}>Bank</button></li>
                    <li className={`${ InnerMiniTabBar[3] ? classes.active_tab_bar_but : ''}`}><button onClick={() => {MakeActiveTabBar(3)}}>Document</button></li>
                    {/* <li className={`${ InnerMiniTabBar[0] ? classes.active_tab_bar_but : ''}`} ><button onClick={() => {MakeActiveTabBar(0)}}>Employee ID-Card</button></li> */}
                </div>
            </div>


            <div className={`${classes.stat_body}`}>

                {InnerMiniTabBar[0] && <EmployeeIDCard />}
                {InnerMiniTabBar[1] && <JobPay />}
                {InnerMiniTabBar[2] && <EmpBank />}
                {InnerMiniTabBar[3] && <EmployeeSupportDocument/>}

            </div>

        </div>
    )
}
import classes from './DynamicMixInputData.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {Custom_DD_Style, PrepareDropDownList} from './../../../../../SharedComp/Utility'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import Select from 'react-select';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'


export default function DynamicMixInputData({idx, HandleMixQantity, HandleMixRawMaterial, MatValue = null, QantityValue = null}) 
{
    const [ProductionCat, SetProductionCat] = useState([]);
    const [SelectedDD, SetSelectedDD] = useState();
    const [SelectedQantity, SetSelectedQantity] = useState();

    useEffect(() => 
    {
        SetSelectedDD(MatValue);
        SetSelectedQantity(QantityValue)
        QueryOption();
    }, [])
      function QueryOption() 
      {

        fetch(getBaseURL() +'master-data/get-iv-item', { 
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ID: "Yes" }),
        })
            .then(res => res.json())
            .then((data) => {PrepareDropDownList(data, SetProductionCat); });

      } 

    return (
        <div  className={`${classes.input_list_wrapper}`}>
            <div className={`${classes.input_list_main}`}>
                <MasterDataRegInput input={
                        <Select
                            onChange={(data)=>{SetSelectedDD(data);HandleMixRawMaterial(data, idx)}}
                            name={"dd_data"}
                            value={SelectedDD}
                            options={ProductionCat}
                            placeholder={"Raw Material..."}
                            isSearchable={true}
                            styles={Custom_DD_Style}
                            required ={true}
                    />
                    }  /> 
            </div>
            <div className={`${classes.input_list_main}`}>
                <MasterDataRegInput input={<input value={ SelectedQantity} onChange={(e)=>{SetSelectedQantity(e.target.value);HandleMixQantity(e.target.value, idx)}} name='item_quantity' min={0.00001} type="number" step="0.000001" placeholder='Quantity...' title='' autoComplete='off' required />} />
            </div>
        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const VerifyWhatsgpsAccount = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'gps-trace/whatsgps-account', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const VerifyWhatsgpsCars = async(gps_token, jak_token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'gps-trace/whatsgps-cars', {token: gps_token}, GetRouteConfig(jak_token));
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const FetchWhatsgpsCarInfo = async(car_id, gps_token, jak_token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'gps-trace/whatsgps-car-info',  {ID: car_id, token: gps_token}, GetRouteConfig(jak_token));
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const BreakDriverDeviceLinkInfo = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'gps-trace/break-driver-device-link',  data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateVHDriverLink = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'gps-trace/create-driver-vh-link',  data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllVHDriverLink = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'gps-trace/query-all-driver-vh-link',  {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const FetchWorkspaceAllOutlet = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'workspace/fetch-workspace-outlet-all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const FetchWorkspaceAllNotOffice = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'workspace/get-workspaces-not-office', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchWorkspaceBranchAll = async(token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'workspace/fetch-workspace-all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateNewWorkspace = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/add-company-branch', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const UpdateWorkspaceData = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/update-company-branch', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const UpdateWorkspaceSuspendState = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'master-data/update-company-branch-suspend-state', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryWorkspaceByID = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'workspace/get-workspaces-by-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
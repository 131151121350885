import { useAuth } from '../../../../../AuthProvider';
import classes from './OrderProductCard.module.css'
import { useEffect } from 'react';
import { useState } from 'react';
import { Warning } from '@phosphor-icons/react';
const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function OrderProductCard({TargetData, CardName = '##', CardPriceTag = 0, Data_CALLBACK, isGroup = false}) 
{
    const auth = useAuth();
    const [AvailState, SetAvailState] = useState(true);
    const HandleCardClick = ()=>
    {
        if(AvailState) 
        {
            Data_CALLBACK(TargetData, 1);
        }
    }
    useEffect(()=>{
        if(TargetData && !isGroup) 
        {
            if(!TargetData.MixName) 
            {
                SetAvailState(TargetData.CKP_OrderableState);
            }
            
        }
    }, [TargetData])
    return (
        <div onClick={()=>{
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PerformCashCollection) 
            {
                HandleCardClick()
            }
            }} className={`${classes.order_product_card_main_wrapper} ${!AvailState ? classes.unavail : ''}`}>
            <div className={`${classes.order_product_card_content_wrapper}`}>
                <h2>{CardName}</h2>
                <li>{isGroup ? "Group" : currency_format(CardPriceTag)}</li>
                {!AvailState &&  <ul><span><Warning weight='fill'/></span></ul>}
            </div>
        </div>
    )
}
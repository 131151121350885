import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faCircle, faCircleChevronLeft, faCircleChevronRight, faCircleDot, faCubes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import classes from './StockManagerCard.module.css'
import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';
import {Custom_DD_Style, PrepareDropDownList, mapToObject, objectToMap} from './../../../../SharedComp/Utility'
import { useAuth } from '../../../../AuthProvider';


function ClampVal(val, min, max) 
{
    if(val < min)
    {
        return min;
    }
    if(val > max) 
    {
        return max;
    }

    return val;
}
function clampPercentage(value, max) {
   
    const percentage = (value / max) * 100;
    const clampedPercentage = Math.max(0, Math.min(100, percentage));

    return clampedPercentage;
}

export default function StockManagerCard({OpenViewController, AnimDelay, Data, setViewData}) 
{
    const style = {
        '--animation-delay': AnimDelay
    };

    const auth = useAuth();
    const [ActiveIndex, SetActiveIndex] = useState(0);
    const [precent, setPercent] = useState(0.0);
    const [ReloadView, SetReloadView] = useState(false);

    const [BranchSpecData, SetBranchSpecData] = useState();

    const RotateStorePositive = async () =>
    {
        if(ActiveIndex + 1 >= Data.length)
            SetActiveIndex(0);
        else 
        {
            SetActiveIndex(ActiveIndex + 1);
        }
        SetReloadView(!ReloadView);
    }
    const RotateStoreNegative = async () =>
    {
        if(ActiveIndex - 1 <= -1)
            SetActiveIndex(Data.length - 1);
        else 
        {
            SetActiveIndex(ActiveIndex - 1);
        }
        SetReloadView(!ReloadView);
    }

    useEffect(()=>{
        const p_res = objectToMap(JSON.parse(Data[ActiveIndex].IVItemWorkspaceInfo)).get(auth.activeWorkShop);
        SetBranchSpecData(p_res);
        setPercent((clampPercentage(Data[ActiveIndex].AvailQuantity, p_res?.get("MaxBalance"))).toFixed(2));
        const intervalId = setInterval(RotateStorePositive, (60 * (1000)));

        return () => clearInterval(intervalId);
    }, [ReloadView, auth.activeWorkShop])
    return (
        <div className={`${classes.stock_manager_card_main_wrapper}`} style={style}>
            
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.stock_manager_card_inner_wrapper}`} >
                    <div onClick={() => {
                        if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectStock) 
                        {
                            OpenViewController(true); 
                            setViewData(Data[ActiveIndex])
                        }
                        
                        }} className={`${classes.stock_manager_card_header}`}>
                        <div className={`${classes.stock_manager_card_name}`}>
                            <h1>{Data[ActiveIndex].IVItemName}</h1>
                            <h2>[{Data[ActiveIndex].WHName}]</h2>
                        </div>
                       {Data[ActiveIndex].AvailQuantity &&  <div className={`${classes.stock_manager_card_stock_amount}`}>
                            {(Data[ActiveIndex].AvailQuantity).toLocaleString(undefined, {minimumFractionDigits: 2})} {Data[ActiveIndex].UomDescription}
                        </div>}
                    </div>

                    <div className={`${classes.stock_manager_card_body}`}>
                        <div className={`${classes.stock_manager_card_stock_info_bar}`}>
                            
                            <li style={{
                                color: precent > 10.0 ? `var(--color-theme)` : `var(--red-color)`
                            }}>{precent}% </li> 
                            {/* {precent < 10.0 && <span className={`${classes.blip_mg}`}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> Message Sent</span> } */}
                            <div  className={`${classes.stock_manager_card_grp}`}>
                                <div style={
                                {
                                    background: precent > 10.0 ? `var(--default_gradient)` :  `var(--red-color)`,
                                    width: `${precent}%`
                                }
                            } className={`${classes.stock_manager_card_grp_chid}`}> <span className={`${classes.prog_tip}`} style={{
                                boxShadow:  precent < 10.0 ? `var(--red-color) 6px 2px 16px 0px, var(--red-color) -6px -2px 16px 0px` : `rgba(255, 255, 255, 0.38) 6px 2px 16px 0px, rgba(255, 255, 255, 0.6) -6px -2px 16px 0px`
                            }} ><FontAwesomeIcon icon={faCircle}></FontAwesomeIcon></span>  </div>
                            </div>
                        </div>
                        <div className={`${classes.stock_warehouse_swicth_wrapper}`}>

                            <button onClick={(e)=>{RotateStoreNegative()}}><FontAwesomeIcon icon={faCircleChevronLeft}></FontAwesomeIcon></button>
                            <button onClick={(e)=>{RotateStorePositive()}}><FontAwesomeIcon icon={faCircleChevronRight}></FontAwesomeIcon></button>

                        </div>
                    </div>

            </div>
        </div>
    );
}
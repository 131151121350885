import classes from './ProductionStackOrderSingleCardWrapper.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchFinalProductSaved, FetchMixSaved} from '../../../../api'

import { FetchBakeryProductByID } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';

import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../../../AuthProvider';


export default function ProductionStackOrderSingleCardWrapper({Target, PackageList, SingleCard}) 
{

    const [TargetData, SetTargetData] = useState();
    const [IsMix, SetIsMix] = useState(Target.POP_IsMix === 1);
    const [Selected, SetSelected] = useState();
    const auth = useAuth();


    const FetchTargetData = async()=>{
        try 
        {
            const fd = new FormData();
            fd.append("targte_id", Target.POP_ProdID);
            const res = IsMix ? await FetchMixSaved(Target.POP_ProdID) : await FetchBakeryProductByID(fd, auth.token);
            SetTargetData(res[0]);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchTargetData();
    }, [])

    useEffect(()=>{
        if(TargetData) 
        {
            SetSelected(PackageList.has(IsMix ? TargetData?.MixName: TargetData?.CMBP_Name));
        }
    }, [PackageList])


    return (
        <div onClick={()=>{
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PerformProductionLogEntry) 
            {
                SingleCard(TargetData, IsMix, Target.POP_BatchRemain, Target.POP_Hash)
            }
            }} className={`${classes.main_product_shelf_card_wrapper} ${Selected && classes.main_product_shelf_card_wrapper_selected}`}>
            <div className={`${classes.prod_name_info_wrapper}`}>
                <h2><sub className={`${classes.item_type}`}>{IsMix ? ["MIX"] : ["FP"]}</sub>{IsMix ? TargetData?.MixName: TargetData?.CMBP_Name} <sup className={`${classes.batch_preview}`}>RM x{Target.POP_BatchRemain}</sup> <sup className={`${classes.batch_preview_TL}`}>TL x{Target.POP_Batch}</sup></h2>
            </div>
        </div>
    )
}
import classes from './CashSalesSummeryPreview.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useReactToPrint } from 'react-to-print'
import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useAuth } from '../../../../AuthProvider'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { QueryBakerySalesByGroup, QueryBaverageSalesByGroup, QueryFinishedPreorderSalesByDate, QueryMerchSalesByGroup, QueryPastrySalesByGroup, QueryPreorderSalesByDate } from '../../../../SharedComp/API/DeskView/API_FE_CloseShift';
import { useToast } from '../../../../ToastContext'
import { Star, Warning } from '@phosphor-icons/react'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility'


const currency_format = (val)=> 
{
    if(val === undefined || val === null)
    {
        return '--';
    }
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    if(val === undefined || val === null)
    {
        // console.log(val);
        return 0;
    }
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    });
}
export default function CashSalesSummeryPreview({GroupData, Close_CALLBACK})
{

    const auth = useAuth();
    const notify = useToast();
    const [RequesterInfo, SetRequesterInfo] = useState(null);
    const [DataList, SetDataList] = useState([]);

    const [BakerySales, SetBakerySales] = useState([]);
    const [PastrySales, SetPastrySales] = useState([]);
    const [MerchSales, SetMerchSales] = useState([]);
    const [BaverageSales, SetBaverageSales] = useState([]);
    const [DatePreorderSales, SetDatePreorderSales] = useState([]);
    const [DataFinishedPreorderSales, SetDataFinishedPreorderSales] = useState([]);

    const [BakerySummeryCash, SetBakerySummeryCash] = useState({
        Sold: 0,
        Unsold: 0,
        Damage: 0
    });
    const [PastrySummeryCash, SetPastrySummeryCash] = useState({
        Sold: 0,
        Unsold: 0,
        Damage: 0
    });
    const [MerchSummeryCash, SetMerchSummeryCash] = useState({
        Sold: 0,
        Unsold: 0,
        Damage: 0
    });
    const [BaverageSummmeryCash, SetBaverageSummmeryCash] = useState({
        Sold: 0,
        Unsold: 0,
        Damage: 0
    });
    const [TotalPrePayment, SetTotalPrePayment] = useState(0);
    const [TotalFinishedPrepayment, SetTotalFinishedPrepayment] = useState(0);

    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_sales_summery_" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const SummerizeBakery = (data_array)=>
    {
        let temp_summery = {
            Sold: 0,
            Unsold: 0,
            Damage: 0,
            PV: 0
        }
        // get summery
        for(let i = 0; i < data_array.length; i++) 
        {
            temp_summery.PV += data_array[i].DCCB_Summery_Sold_Quantity + data_array[i].DCCB_Summery_Unsold_Quantity + data_array[i].DCCB_Summery_Damaged_Quantity;
            temp_summery.Sold += data_array[i].DCCB_Summery_Sold_Quantity * data_array[i].DCCB_Summery_UnitPrice;
            temp_summery.Unsold += data_array[i].DCCB_Summery_Unsold_Quantity * data_array[i].DCCB_Summery_UnitPrice;
            temp_summery.Damage += data_array[i].DCCB_Summery_Damaged_Quantity * data_array[i].DCCB_Summery_UnitPrice;
        }
        SetBakerySummeryCash(temp_summery);
    }
    const SummerizePastry =(data_array)=>
    {
        let temp_summery = {
            Sold: 0,
            Unsold: 0,
            Damage: 0
        }
        // get summery
        for(let i = 0; i < data_array.length; i++) 
        {
            temp_summery.Sold += data_array[i].DCCP_Summery_Sold_Quantity * data_array[i].DCCP_Summery_UnitPrice;
            temp_summery.Unsold += data_array[i].DCCP_Summery_Unsold_Quantity * data_array[i].DCCP_Summery_UnitPrice;
            temp_summery.Damage += data_array[i].DCCP_Summery_Damaged_Quantity * data_array[i].DCCP_Summery_UnitPrice;
        }
        SetPastrySummeryCash(temp_summery);
    }
    const SummerizeMerch = (data_array)=>
    {
        let temp_summery = {
            Sold: 0,
            Unsold: 0,
            Damage: 0
        }
        // get summery
        for(let i = 0; i < data_array.length; i++) 
        {
            temp_summery.Sold += data_array[i].DCCM_Summery_Sold_Quantity * data_array[i].DCCM_Summery_UnitPrice;
            temp_summery.Unsold += data_array[i].DCCM_Summery_Unsold_Quantity * data_array[i].DCCM_Summery_UnitPrice;
            temp_summery.Damage += data_array[i].DCCM_Summery_Damaged_Quantity * data_array[i].DCCM_Summery_UnitPrice;
        }
        SetMerchSummeryCash(temp_summery);
    }
    const SummerizeBaverage = (data_array)=>
    {
        let temp_summery = {
            Sold: 0,
            Unsold: 0,
            Damage: 0
        }
        // get summery
        for(let i = 0; i < data_array.length; i++) 
        {
            temp_summery.Sold += data_array[i].DCCB_Summery_Sold_Quantity * data_array[i].DCCB_Summery_UnitPrice;
        }
        SetBaverageSummmeryCash(temp_summery);
    }
    const FetchInitPreordersByDate = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("target_date", new Date(GroupData.CCS_CollectionDate));

           const main_res = await QueryPreorderSalesByDate(fd, auth.token);

           let __data_amount = 0;
           for(let i = 0; i < main_res.length; i++) 
           {
            __data_amount += parseFloat(main_res[i].CCATDPREOD_PrePayment);
           }
           SetTotalPrePayment(__data_amount);
           SetDatePreorderSales(main_res);
        }catch(err) 
        {

        }
    }
    const FetchFinishedPreordersByDate = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("target_date", new Date(GroupData.CCS_CollectionDate));

            const main_res = await QueryFinishedPreorderSalesByDate(fd, auth.token);

            let __data_amount = 0;
            for(let i = 0; i < main_res.length; i++) 
            {
                __data_amount += parseFloat(main_res[i].CCATDPREOD_FinalPayment);
            }

            SetTotalFinishedPrepayment(__data_amount);
            SetDataFinishedPreorderSales(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        if(GroupData) 
        {
            const __parse_master_data = JSON.parse(GroupData.CCMDPV2_DataPackage);

            // Bakery
            SummerizeBakery(__parse_master_data.Bakery_Summery);
            SetBakerySales(__parse_master_data.Bakery_Summery);

            // Pastry
            SummerizePastry(__parse_master_data.Pastry_Summery);
            SetPastrySales(__parse_master_data.Pastry_Summery);

            // Merch
            SummerizeMerch(__parse_master_data.Merchandise_Summery);
            SetMerchSales(__parse_master_data.Merchandise_Summery);

            // Baverage
            SummerizeBaverage(__parse_master_data.Beverage_Summery);
            SetBaverageSales(__parse_master_data.Beverage_Summery);
            //FetchTargetGroupBakery();
            //FetchTargetGroupPastry();
           // FetchTargetGroupMerch();
           // FetchTargetGroupBaverage();
            FetchInitPreordersByDate();
            FetchFinishedPreordersByDate();
        }

    }, [GroupData])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Cash Sales Summery </span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_preview_wrapper}`}>

            <div className={`${classes.inner_preview_data_wrapper}`}>
                <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                    <PaperHeader/>
                    <PaperHash/>
                    <div className={`${classes.meta_data_wrapper}`}>
                        <h2>[#] Cash Sales Summery - {auth.activeWorkShopName} @ {new Date(GroupData.CCMDPV2_CollectionTimeDate).toDateString()} </h2>
                    </div>

                    <div className={`${classes.transcation_owner_wrapper}`}>
                        <div className={`${classes.transcation_owner}`}>

                            <div className={`${classes.transcation_init}`}> 
                                <h2>OPERATOR</h2>
                                <li>{GroupData?.FirstName} {GroupData?.LastName}, {GroupData?.RoleNameLazy}</li>
                                <li>{ToEthiopianCalander(new Date(GroupData?.CCMDPV2_CollectionTimeDate))}</li>
                                <li>{new Date(GroupData?.CCMDPV2_CollectionTimeDate).toLocaleTimeString()}</li>
                               
                            </div>

                        </div>
                    </div>

                    <div className={`${classes.entry_detail}`}>
                        {/* <li><span className={`${classes.entry_deatil_tag}`}>Dispatch ID</span> <span className={`${classes.entry_deatil_val}`}>{GroupData.CKAOG_Hash}</span></li> */}
                    </div>  
                    
                    {/* --------------------------- BAKERY */}
                    
                  {BakerySales.length > 0 &&   <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.group_id_wrapper}`}>
                            <li>Bakery</li>
                        </div>

                        <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                            <div className={`${classes.data_marging_wrapper}`}>
                                <li className={`${classes.data_header_list}`}>
                                    <span>Item Name</span>
                                    <span>Sold <Star weight='bold'/></span>
                                    <span>Unsold <Star weight='bold'/></span>
                                    <span>Damage <Star weight='bold'/></span>
                                    <span>Production Volume</span>
                                </li>
                            {
                                BakerySales.map((item, idx)=>(
                                    <li key={idx}>
                                        <span className={`${classes.text_left}`}>{idx+1}. {item.DCCB_Summery_ItemName} {item.DCCB_Summery_ErrorDetection ? <Warning weight='fill'/> : ''}</span>
                                      {item.DCCB_Summery_Sold_Quantity ?  <span>{human_readable_format(item.DCCB_Summery_Sold_Quantity)} Pcs - {currency_format(item.DCCB_Summery_Sold_Quantity * item.DCCB_Summery_UnitPrice)}</span>  : <span>-</span> }  
                                      {item.DCCB_Summery_Unsold_Quantity ? <span>{human_readable_format(item.DCCB_Summery_Unsold_Quantity)} Pcs - {currency_format(item.DCCB_Summery_Unsold_Quantity * item.DCCB_Summery_UnitPrice)} </span> : <span>-</span>}  
                                      {item.DCCB_Summery_Damaged_Quantity ? <span>{human_readable_format(item.DCCB_Summery_Damaged_Quantity)} Pcs - {currency_format(item.DCCB_Summery_Damaged_Quantity * item.DCCB_Summery_UnitPrice)}</span> : <span>-</span>}  
                                      <span>{human_readable_format(item.DCCB_Summery_Sold_Quantity + item.DCCB_Summery_Unsold_Quantity + item.DCCB_Summery_Damaged_Quantity)} Pcs</span>
                                    </li>
                                ))
                            }
                                    <li className={`${classes.total_local_summery}`}>
                                        <span className={`${classes.text_left}`}>Bakery Total</span>
                                        <span>{currency_format(BakerySummeryCash?.Sold)}</span> 
                                         <span>{currency_format(BakerySummeryCash?.Unsold)} </span>
                                        <span>{currency_format(BakerySummeryCash?.Damage)}</span>
                                        <span>{human_readable_format(BakerySummeryCash?.PV)} Pcs</span>
                                    </li>
                            </div>
                        </div>

                        
                        
                    </div>}

                    {/* ----------------------------- PASTRY  */}
                  {PastrySales.length > 0 &&  <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.group_id_wrapper}`}>
                            <li>Pastry</li>
                        </div>

                        <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                            <div className={`${classes.data_marging_wrapper}`}>
                                <li className={`${classes.data_header_list}`}>
                                    <span>Item Name</span>
                                    <span>Sold <Star weight='bold'/></span>
                                    <span>Unsold <Star weight='bold'/></span>
                                    <span>Damage <Star weight='bold'/></span>
                                </li>
                            {
                                PastrySales.map((item, idx)=>(
                                    <li key={idx}>
                                        <span className={`${classes.text_left}`}>{idx+1}. {item.DCCP_Summery_ItemID} {item.DCCP_Summery_ErrorDetection ? <Warning weight='fill'/> : ''} </span>
                                       {item.DCCP_Summery_Sold_Quantity ? <span>{human_readable_format(item.DCCP_Summery_Sold_Quantity)} - {currency_format(item.DCCP_Summery_Sold_Quantity * item.DCCP_Summery_UnitPrice)}</span>  : <span>-</span>} 
                                       {item.DCCP_Summery_Unsold_Quantity ? <span>{human_readable_format(item.DCCP_Summery_Unsold_Quantity)} - {currency_format(item.DCCP_Summery_Unsold_Quantity * item.DCCP_Summery_UnitPrice)} </span> : <span>-</span>} 
                                       {item.DCCP_Summery_Damaged_Quantity ? <span>{human_readable_format(item.DCCP_Summery_Damaged_Quantity)} - {currency_format(item.DCCP_Summery_Damaged_Quantity * item.DCCP_Summery_UnitPrice)}</span> : <span>-</span>} 
                                    </li>
                                ))
                            }
                                    <li className={`${classes.total_local_summery}`}>
                                        <span className={`${classes.text_left}`}>Pastry Total</span>
                                        <span>{currency_format(PastrySummeryCash?.Sold)}</span> 
                                         <span>{currency_format(PastrySummeryCash?.Unsold)} </span>
                                        <span>{currency_format(PastrySummeryCash?.Damage)}</span>
                                    </li>
                            </div>
                        </div>

                    </div>}



                    {/* ----------------------------- Merchandise  */}

                   {MerchSales.length > 0 &&  <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.group_id_wrapper}`}>
                            <li>Merchandise</li>
                        </div>

                        <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                            <div className={`${classes.data_marging_wrapper}`}>
                                <li className={`${classes.data_header_list}`}>
                                    <span>Item Name</span>
                                    <span>Sold <Star weight='bold'/></span>
                                    <span>Unsold <Star weight='bold'/></span>
                                    <span>Damage <Star weight='bold'/></span>
                                </li>
                            {
                                MerchSales.map((item, idx)=>(
                                    <li key={idx}>
                                        <span className={`${classes.text_left}`}>{idx+1}. {item.DCCM_Summery_ItemName} {item.DCCM_Summery_ErrorDetection ? <Warning weight='fill'/> : ''} </span>
                                       {item.DCCM_Summery_Sold_Quantity ? <span>{human_readable_format(item.DCCM_Summery_Sold_Quantity)} - {currency_format(item.DCCM_Summery_Sold_Quantity * item.DCCM_Summery_UnitPrice)}</span>  : <span>-</span>} 
                                       {item.DCCM_Summery_Unsold_Quantity ? <span>{human_readable_format(item.DCCM_Summery_Unsold_Quantity)} - {currency_format(item.DCCM_Summery_Unsold_Quantity * item.DCCM_Summery_UnitPrice)} </span> : <span>-</span>}
                                       {item.DCCM_Summery_Damaged_Quantity ? <span>{human_readable_format()} - {currency_format(item.DCCM_Summery_Damaged_Quantity * item.DCCM_Summery_UnitPrice)}</span> : <span>-</span>} 
                                    </li>
                                ))
                            }
                                    <li className={`${classes.total_local_summery}`}>
                                        <span className={`${classes.text_left}`}>Merchandise Total</span>
                                        <span>{currency_format(MerchSummeryCash?.Sold)}</span> 
                                         <span>{currency_format(MerchSummeryCash?.Unsold)} </span>
                                        <span>{currency_format(MerchSummeryCash?.Damage)}</span>
                                    </li>
                            </div>
                        </div>

                    </div>}


                     {/* ----------------------------- Beverage  */}

                    {BaverageSales.length > 0 && <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.group_id_wrapper}`}>
                            <li>Beverage</li>
                        </div>

                        <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                            <div className={`${classes.data_marging_wrapper}`}>
                                <li className={`${classes.data_header_list}`}>
                                    <span>Item Name</span>
                                    <span>Sold <Star weight='bold'/></span>
                                    <span>Unsold <Star weight='bold'/></span>
                                    <span>Damage <Star weight='bold'/></span>
                                </li>
                            {
                                BaverageSales.map((item, idx)=>(
                                    <li key={idx}>
                                        <span className={`${classes.text_left}`}>{idx+1}. {item.DCCB_Summery_ItemName} </span>
                                        <span>{human_readable_format(item.DCCB_Summery_Sold_Quantity)} - {currency_format(item.DCCB_Summery_Sold_Quantity * item.DCCB_Summery_UnitPrice)}</span> 
                                        <span>--</span>
                                        <span>--</span>
                                    </li>
                                ))
                            }
                                    <li className={`${classes.total_local_summery}`}>
                                        <span className={`${classes.text_left}`}>Beverage Total</span>
                                        <span>{currency_format(BaverageSummmeryCash?.Sold)}</span> 
                                         <span>--</span>
                                        <span>--</span>
                                    </li>
                            </div>
                        </div>

                    </div>}

                    {/* ----------------------------- Pre-Order  */}
                  {DatePreorderSales.length > 0 &&  <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.group_id_wrapper}`}>
                            <li>Preorder - Initialized</li>
                        </div>

                        <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                            <div className={`${classes.data_marging_wrapper}`}>
                                <li className={`${classes.data_header_list}`}>
                                    <span>Order Hash</span>
                                    <span>Pre-Payment <Star weight='bold'/></span>
                                    <span>Final Payment</span>
                                    <span>Full Payment <Star weight='bold'/></span>
                                    <span>Fully Paid<Star weight='bold'/></span>
                                </li>
                            {
                                DatePreorderSales.map((item, idx)=>(
                                    <li key={idx}>
                                        <span className={`${classes.text_left}`}>{idx+1}. {item.CCATDPREOD_Hash}  </span>
                                        <span>{human_readable_format(item.CCATDPREOD_PrePayment)}</span> 
                                        <span style={{textDecoration: 'line-through'}}>{human_readable_format(item.CCATDPREOD_FullPayment - item.CCATDPREOD_PrePayment)}</span> 
                                        <span>{human_readable_format(item.CCATDPREOD_FullPayment)}</span>
                                        <span>{item.CCATDPREOD_IsFullPayment}</span>
                                    </li>
                                ))
                            }
                                    <li className={`${classes.total_local_summery}`}>
                                        <span className={`${classes.text_left}`}>Total Pre-Payment</span>
                                        <span>{currency_format(TotalPrePayment)}</span> 
                                        <span>--</span>
                                        <span>--</span>
                                        <span>--</span>
                                    </li>
                            </div>
                        </div>

                    </div>}


                 {DataFinishedPreorderSales.length > 0 && <div className={`${classes.main_paper_data_wrapper}`}>

                    <div className={`${classes.group_id_wrapper}`}>
                        <li>Preorder - Finalized</li>
                    </div>

                    <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                        <div className={`${classes.data_marging_wrapper}`}>
                            <li className={`${classes.data_header_list}`}>
                                <span>Order Hash</span>
                                <span>Pre-Payment <Star weight='bold'/></span>
                                <span>Final Payment</span>
                                <span>Full Payment <Star weight='bold'/></span>
                            </li>
                        {
                            DataFinishedPreorderSales.map((item, idx)=>(
                                <li key={idx}>
                                    <span className={`${classes.text_left}`}>{idx+1}. {item.CCATDPREOD_Hash}  </span>
                                    <span style={{textDecoration: 'line-through'}}>{human_readable_format(item.CCATDPREOD_PrePayment)}</span> 
                                    <span>{human_readable_format(item.CCATDPREOD_FinalPayment)}</span> 
                                    <span>{human_readable_format(item.CCATDPREOD_FullPayment)}</span>
                                </li>
                            ))
                        }
                                <li className={`${classes.total_local_summery}`}>
                                    <span className={`${classes.text_left}`}>Total Pre-Payment</span>
                                    <span>--</span>
                                    <span>{currency_format(TotalFinishedPrepayment)}</span> 
                                    <span>--</span>
                                    <span>--</span>
                                </li>
                        </div>
                    </div>

                    </div>}

                    {/* ----------------------------- Summery  */}

                    <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.group_id_wrapper}`}>
                            <li>Summery <sub>{GroupData.CCMDPV2_CollectionID}</sub></li>
                        </div>

                        <div className={`${classes.inner_sub_group_cash_sales_data_wrapper}`}>
                            <div className={`${classes.data_marging_wrapper_summery}`}>

                                <li className={`${classes.data_header_list}`}>
                                    <span className={`${classes.summery_tag_sp_wrap}`}>Grand Total [SYS]</span>
                                    <span>{currency_format(
                                        BakerySummeryCash.Sold + 
                                        PastrySummeryCash.Sold + 
                                        MerchSummeryCash.Sold + 
                                        BaverageSummmeryCash.Sold + 
                                        TotalPrePayment + 
                                        TotalFinishedPrepayment
                                        )}</span>
                                </li>
                                <li className={`${classes.data_header_list}`}>
                                    <span className={`${classes.summery_tag_sp_wrap}`}>Cash On Hand</span>
                                    <span>{currency_format(GroupData.CCMDPV2_CashOnHand)}</span>
                                </li>
                                <li className={`${classes.data_header_list}`}>
                                    <span className={`${classes.summery_tag_sp_wrap}`}>Petty Cash Expense</span>
                                    <span>{currency_format(GroupData.CCMDPV2_PettyCashExpense)}</span>
                                </li>
                                <li className={`${classes.data_header_list}`}>
                                    <span className={`${classes.summery_tag_sp_wrap}`}>Initial Cash</span>
                                    <span>{currency_format(GroupData.CCMDPV2_FloatingCash)}</span>
                                </li>
                                <li className={`${classes.data_header_list}`}>
                                    <span className={`${classes.summery_tag_sp_wrap}`}>Operator Note</span>
                                    <span>{currency_format(GroupData.CCMDPV2_CashierNote)}</span>
                                </li>
                            </div>
                        </div>

                    </div>

                    {/* <PaperFooter Name={auth.employeeData.FullName} Role={auth.employeeData.DepName} Phone={auth.employeeData.PhoneNumber}/> */}

                </div>
            </div>



            </div>


            <div className={`${classes.action_button_wrapper}`}>

                <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
                <button onClick={()=>{Close_CALLBACK(false)}}>Close</button>

            </div>

        </div>
    </div>
    )
}
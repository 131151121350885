import { faCirclePlus, faFilter } from '@fortawesome/free-solid-svg-icons'
import classes from './DataViewTableProxy.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EmptyProxyDataView from './EmptyDataViewTable'


export default function DataViewTableProxy({IsEmpty = false, OnFilter, ShowNewButton = true, OpenDataCreate, DataArray, HeaderName}) 
{
    return (
        <div className={`${classes.data_view_table_proxy_main_wrapper}`}>
             
             <div className={`${classes.data_view_table_proxy_main_wrapper_animation}`}>
                <div className={`${classes.data_view_table_proxy_header_wrapper}`}>
                    <div className={`${classes.data_view_table_proxy_header_content}`}>
                        {HeaderName}
                    </div>
                </div>
                <div className={`${classes.data_view_table_proxy_body_wrapper}`}>
                    {IsEmpty ? <EmptyProxyDataView/> :  DataArray}
                </div>
                <div className={`${classes.data_view_table_proxy_footer_wrapper}`}>
                    <div className={`${classes.action_button_bar}`}>
                        {ShowNewButton && <button onClick={()=>{OpenDataCreate(true)}}>
                            <span><FontAwesomeIcon icon={faCirclePlus}/></span>
                            <span>New</span>
                        </button>}
                    </div>
                    <div className={`${classes.action_button_bar_filter}`}>
                        <div className={`${classes.filter_input_wrapper}`}>
                            <span><FontAwesomeIcon icon={faFilter}/></span>
                            <input onChange={(e)=>{OnFilter(e.target.value)}} type="text" placeholder='Search...' autoComplete='off' />
                        </div>
                    </div>
                </div>
             </div>
            
        </div>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateNewEmployeeCashPenality = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/employee-operation-create-cash-penality', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateNewEmployeeNonCashPenality = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/employee-operation-create-non-cash-penality', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllEmployeeCashPenality = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/query-employee-operation-cash-penality', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryEmployeeAllNonCashPenality = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'employee-operation/query-employee-operation-all-non-cash-penality', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
import { useState } from 'react';
import classes from './ManageDistribution.module.css'
import {FetchCKORderGroupForShipping} from '../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import { useEffect } from 'react';
import DistributionGroup from './Component/DistributionGroup/DistributionGroup';
import { useAuth } from '../../../AuthProvider';

import {FetchWorkspaceAllOutlet} from '../../../SharedComp/API/Workspace/API_FE_Workspace'
import { MagnifyingGlass, Timer } from '@phosphor-icons/react';

export default function ManageDistribution() 
{

    const auth = useAuth();
    const [OrderGroupList, SetOrderGroupList] = useState([]);
    const [OrderGroupListOriginal, SetOrderGroupListOriginal] = useState([]);

    const FetchGroupList = async() =>
    {
        try 
        {
            const fd = new FormData();

            fd.append("processor_workshop_id", auth.activeWorkShop);
            

            const main_res = await FetchWorkspaceAllOutlet(auth.token);//await FetchCKORderGroupForShipping(fd, 'token');
            SetOrderGroupListOriginal(main_res);
            SetOrderGroupList(main_res);
        } catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchGroupList();
    }, [auth.activeWorkShop])

    const FilterWorkspaceByName = async(key_filter)=>
    {

        const valid_list = [];
        for(let i = 0; i < OrderGroupListOriginal.length; i++) 
        {
            if(OrderGroupListOriginal[i].BranchName.toLowerCase().includes(key_filter.toLowerCase()))
            {
                valid_list.push(OrderGroupListOriginal[i]);
            }
        }

        SetOrderGroupList(valid_list);
    }



    return (
        <div className={`${classes.manage_distribution_master_wrapper}`}>

            <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilterWorkspaceByName(e.target.value)}} autoComplete='off' placeholder='Search workspace' type="text" /> 
                        <span><MagnifyingGlass weight='bold'/></span>
                    </div>
            </div>

                

                <div className={`${classes.shiping_group_list_wrapper}`}>

                    {
                        OrderGroupList.map((item, idx)=>(

                            <DistributionGroup key={item.BranchName} Data={item} />
                            
                        ))
                    }

                </div>


        </div>
    )
}
import classes from '../PayrollConfig/PayrollConfig.module.css'
import '../../../../../index.css'

export default function InteractionConfig({UpdateReq, ConfigData}) 
{
    const SetScale = (scale_value)=>
    {
        // const _new_value = 0.6 * scale_value; 
        // document.documentElement.style.setProperty("--default-font-scale", `${_new_value}em`);
    }

    return (
        <div className={`${classes.credit_system_wrapper}`}>
        <div className={`${classes.part_of_option}`}>
            <ul>UI Scale</ul>
            <div className={`${classes.setting_items_config}`}>
                <li><span>Over All Visual Scale</span> 
                    <span>
                        <select onChange={(e)=>{UpdateReq("GC_OverAllVisualScale",  e.target.value); SetScale(parseFloat(e.target.value))}} value={String(ConfigData.GC_OverAllVisualScale)}>
                            <option value="1">100%</option>
                            <option value="1.25">125%</option>
                            <option value="1.50">150%</option>
                            <option value="1.75">175%</option>
                            <option value="2">200%</option>
                        </select>
                    </span>
                </li>
                
            </div>  
        </div>
    </div>
    )
}
import { CheckCircle, Cpu, PersonSimpleCircle, Warning, XCircle } from '@phosphor-icons/react'
import classes from './PayrollBranchScheduleCard.module.css'
import UIButton from '../../../Components/UIButton/UIButton'
import { useAuth } from '../../../../AuthProvider'
import { ToEthiopianCalander, ToEthiopianCalanderRaw, ToGregorianCalendar } from '../../../../SharedComp/Utility';
import { useEffect, useState } from 'react';
import { CreateNewPayrollSchedule, QueryPayrollScheduleByBranchByDate } from '../../../../SharedComp/API/Payroll/API_FE_PayrollSchedule';
import { useToast } from '../../../../ToastContext';
import { useDAControl } from '../../../../DAControlContext';


const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`;
 };
export default function PayrollBranchScheduleCard({TargetItem}) 
{
    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl();
    const [TargetSchedule, SetTargetSchedule] = useState(null);
    const [OpenScheduler, SetOpenScheduler] = useState(false);
    const [SelectedDate, SetSelectedDate] = useState();

    const [ScheduleStart, SetScheduleStart] = useState();
    const [ScheduleEnd, SetScheduleEnd] = useState();
    const [TargetScheduleDateAuto, SetTargetScheduleDateAuto] = useState(new Date());


    useEffect(()=>{
        // we want to allow to scehdule only in this month
        
        // get current ec month
        const ec_date_raw = ToEthiopianCalanderRaw(new Date());
        const ec_year = ec_date_raw[0];
        const ec_month = ec_date_raw[1];
        const ec_day = ec_date_raw[2];

        const start_date_gc = ToGregorianCalendar(ec_year, ec_month, parseInt(auth.GlobalConfig.GC_PayrollScheduleStartDate));
        const end_date_gc = ToGregorianCalendar(ec_year, ec_month, parseInt(auth.GlobalConfig.GC_PayrollScheduleEndDate));
        SetTargetScheduleDateAuto(ToGregorianCalendar(ec_year, ec_month, 30 - parseInt(auth.GlobalConfig.GC_AutoPayrollScheduleBeforeLastMonthDayOf)))

        SetScheduleStart(start_date_gc);
        SetScheduleEnd(end_date_gc);

    }, [])


    const QueryScheduleInfo = async()=>
    {
        try 
        {
            const fd = new FormData();
            const _target_date = formatDate(new Date());
            const _date_split = _target_date.split('-');
            const ec_date_raw = ToEthiopianCalanderRaw(new Date(_date_split[0], parseInt(_date_split[1]) - 1, _date_split[2]));
            fd.append("target_year", ec_date_raw[0]);
            fd.append("target_month", ec_date_raw[1]);
            fd.append("target_day", ec_date_raw[2]);
            fd.append("target_branch", TargetItem.BranchID);

            const main_res = await QueryPayrollScheduleByBranchByDate(fd, auth.token);
            if(main_res.length > 0) 
            {
                SetTargetSchedule(main_res[0]);
            } else 
            {
                SetTargetSchedule(null);
            }
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
            QueryScheduleInfo();
    }, [OpenScheduler])

    const CreateSchedule = async(e)=>
    {
        e.preventDefault();
        try 
        {
            const fd = new FormData();
            const _date_split = SelectedDate.split('-');
            const ec_date_raw = ToEthiopianCalanderRaw(new Date(_date_split[0], parseInt(_date_split[1]) - 1, _date_split[2]));
            fd.append("target_year", ec_date_raw[0]);
            fd.append("target_month", ec_date_raw[1]);
            fd.append("target_day", ec_date_raw[2]);
            fd.append("target_branch", TargetItem.BranchID);
            fd.append("processed_by", auth.user.UserAccountID);

            const main_res = await CreateNewPayrollSchedule(fd, auth.token);
            notify.ShowSuccess(main_res.Msg);
            SetOpenScheduler(false);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }


    return (
        <div key={TargetItem.BranchName} className={`${classes.branch_card_preview_payroll_schedule}`}>
            <div className={`${classes.schedulaer_card_left_content_wrapper}`}>
                <li>{TargetItem.BranchName}  {!auth.GlobalConfig.GC_EnableAutoSchedule ? !TargetSchedule && <span className={`${classes.init_schdule_with_manual}`}><Warning weight='fill'/></span> : ''}</li>
                { 
                }
                {
                    auth.GlobalConfig.GC_EnableAutoSchedule ? 
                    <>
                    
                    <ul className={`${classes.init_schduleed_succ}`}>{TargetScheduleDateAuto.toDateString()}</ul>
                    <ul className={`${classes.init_schduleed_succ}`}>{ToEthiopianCalander(TargetScheduleDateAuto)}</ul>
                
                    </> : TargetSchedule ? 
                    <>
                        
                        <ul className={`${classes.init_schduleed_succ}`}>{ToGregorianCalendar(TargetSchedule.PAYROLLBMS_TargetPayrollECYear, TargetSchedule.PAYROLLBMS_TargetPayrollECMonth, TargetSchedule.PAYROLLBMS_TargetPayrollECDay).toDateString()}</ul>
                        <ul className={`${classes.init_schduleed_succ}`}>{ToEthiopianCalander(ToGregorianCalendar(TargetSchedule.PAYROLLBMS_TargetPayrollECYear, TargetSchedule.PAYROLLBMS_TargetPayrollECMonth, TargetSchedule.PAYROLLBMS_TargetPayrollECDay))}</ul>
                    
                    </> 
                : '' 

                }
            </div>
            <div onClick={()=>{
                if(!auth.GlobalConfig.GC_EnableAutoSchedule) 
                {
                    SetOpenScheduler(true);
                }
                }} className={`${classes.schedulaer_card_right_content_wrapper}`}>
                <li>{auth.GlobalConfig.GC_EnableAutoSchedule ? <Cpu /> : <PersonSimpleCircle />}</li>
            </div>

          {OpenScheduler &&   <div className={`${classes.payroll_schedule_setup_date_input_wrapper}`}>
                    <div className={`${classes.mini_header_min_menu}`}>
                        <button onClick={()=>{SetOpenScheduler(false)}}><XCircle weight='fill'/></button>
                    </div>

                    <div className={`${classes.payroll_schedule_setup_date_input_body_wrapper}`}>

                        <form onSubmit={CreateSchedule} method='POST'>
                        <div className={`${classes.payroll_schedule_setup_input_field_wrapper}`}>
                            <input 
                                onChange={(e)=>{SetSelectedDate(e.target.value)}}
                                min={formatDate(ScheduleStart)}
                                max={formatDate(ScheduleEnd)}
                                type="date" 
                                required />
                        </div>
                        <div className={`${classes.payroll_schedule_setup_action_button}`}>
                            <UIButton
                                Type='submit'
                                Variant='Secondary' 
                                Icon={<CheckCircle weight='bold'/>}
                                label={"Set Schedule"} 
                                onClick={()=>{}}
                            />
                        </div>
                        </form>

                    </div>
                   
            </div>}
        </div>
    )
}
import classes from './SingleAccoutingReadyDataBundle.module.css'
import UIButton from '../../../Components/UIButton/UIButton'
import { Export, MicrosoftExcelLogo, Printer } from '@phosphor-icons/react'
import { MagnifyingGlass } from '@phosphor-icons/react/dist/ssr'
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../AuthProvider'
import { FinishPayrollPreparation, QueryHRPreparedData } from '../../../../SharedComp/API/Payroll/API_FE_PayrollAccounting'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { Tooltip } from 'react-tooltip'
import * as XLSX from 'xlsx';
import { ToGregorianCalendar, ToEthiopianCalander } from '../../../../SharedComp/Utility'
import { useDAControl } from '../../../../DAControlContext'
import FinalPayrollPrintablePreview from './FinalPayrollPrintablePreview/FinalPayrollPrintablePreview'

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function SingleAccoutingReadyDataBundle({DataPackage}) 
{

    const DAControl = useDAControl();
    const auth = useAuth();
    const [Loading, SetLoading] = useState(false);

    const [OpenPrintPreview, SetOpenPrintPreview] = useState(false);
    const [EC_Calander, SetEC_Calander] = useState('');
    const [GC_Calander, SetGC_Calander] = useState(new Date());

    const [EmployeeData, SetEmployeeData] = useState([]);
    const [EmployeePackageListOriginal, SetEmployeePackageListOriginal] = useState([]);


    const CalaculateIncomeTax = (salary)=>
    {
        let tax = 0;
    
        if (salary <= 600) {
            tax = 0;
        } else if (salary <= 1650) {
            tax = (salary * 0.1) - 60;
        } else if (salary <= 3200) {
            tax = (salary * 0.15) - 142.5;
        } else if (salary <= 5250) {
            tax = (salary * 0.2) - 302.5;
        } else if (salary <= 7800) {
            tax = (salary * 0.25) - 565;
        } else if (salary <= 10900) {
            tax = (salary * 0.3) - 955;
        } else {
            tax = (salary * 0.35) - 1500;
        }

        if(auth.GlobalConfig.GC_FractionRoundingRule) 
        {
            tax = Math.ceil(tax); 
        } else 
        {
            tax = Math.floor(tax);
        }
    
        return Math.max(tax, 0);
    }
    const ExportExcel = () =>
    {
        const worksheet = XLSX.utils.json_to_sheet(EmployeePackageListOriginal);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Payroll_sheet');
        XLSX.writeFile(workbook, `${DataPackage.BranchName}_${EC_Calander}_payroll.xlsx`);
    }
    const FinishPayrollPrep = async()=>
    {
        try 
        {
            const fd = new FormData();
            FinishPayrollPreparation(fd, auth.token);
        }catch(err) 
        {

        }
    }

    const ParseMasterData = (data_package) =>
    {

        const _employee_preview_working_data = [];

        const __hr_processed_package = JSON.parse(data_package.PAYROLLACCRD_DataPackage);
        const __hr_attendy_processed_package = JSON.parse(data_package.PYROLLDF_DataPackage);

        for(let i = 0; i < __hr_processed_package.length; i++) 
        {
            const __comp_data = __hr_attendy_processed_package.find((item)=>item.EmployeeID === __hr_processed_package[i].SyntID);


            // console.log(__comp_data);
            // console.log(__hr_processed_package[i]);


            const __employee_pension = auth.GlobalConfig.GC_AutoAppliedPension ? parseFloat(__hr_processed_package[i].GrossSalary) * (parseFloat(auth.GlobalConfig.GC_EmployeePensionContributionRate) / 100) : 0;
            const __employer_pension = auth.GlobalConfig.GC_AutoAppliedPension ? parseFloat(__hr_processed_package[i].GrossSalary) * (parseFloat(auth.GlobalConfig.GC_CompanyPensionContributionRate) / 100) : 0;
            const __income_tax = CalaculateIncomeTax(parseFloat(__hr_processed_package[i].GrossSalary));
            const __payment_per_day = parseFloat(__hr_processed_package[i].GrossSalary) / 30;
            const __overtime_cash = ((__payment_per_day / parseInt(auth.GlobalConfig.GC_NumberOfWorkingHourInDay)) * parseFloat(auth.GlobalConfig.GC_OverTimeRate)) * parseFloat(__comp_data.OverTime);

            _employee_preview_working_data.push({
                EmployeeID: __hr_processed_package[i].SyntID,
                FullName: __hr_processed_package[i].FullName,
                GrossSalary: __payment_per_day * parseInt(__comp_data.TotalPresent),
                JobPosition: __hr_processed_package[i].JobPosition,
                
                ApplyDeduction: __hr_processed_package[i].ApplyPenalityDeduction,
                DeductableCredit: __hr_processed_package[i].Credit,
                DeductablePenality: __hr_processed_package[i].Penality,
                TotalAllowance: parseFloat(__hr_processed_package[i].TotalAllowance),

                ShortMinute: __comp_data.LateMinute,
                OverTime: parseFloat(__comp_data.OverTime),
                TotalWorkingDay: parseInt(__comp_data.TotalPresent),
                AbsentDay: parseInt(__comp_data.AbsentDate),
                TotalWorkingHour: parseFloat(__comp_data.TotalWorkingHour),

                PenstionEmployee: __employee_pension,
                PenstionEmployer: __employer_pension,
                NetPay: (__payment_per_day * parseInt(__comp_data.TotalPresent)) - (__income_tax + __employee_pension +  parseFloat(__hr_processed_package[i].Credit) + parseFloat(__hr_processed_package[i].Penality)) +  parseFloat(__hr_processed_package[i].TotalAllowance) + __overtime_cash,
                IncomeTax: __income_tax,
                ApplyPension: auth.GlobalConfig.GC_AutoAppliedPension,

                LateMinueCash: (__payment_per_day / parseInt(auth.GlobalConfig.GC_NumberOfWorkingHourInDay)) * parseFloat(__comp_data.LateMinute),
                OverTimeCash: __overtime_cash,

                BankCode: __hr_processed_package[i].BankCode,
                BankAccount: __hr_processed_package[i].BankAccount,
            });
        } 

        SetEmployeeData(_employee_preview_working_data);
        SetEmployeePackageListOriginal(_employee_preview_working_data);

    }


    useEffect(()=>
    {
        if(DataPackage) 
        {
            ParseMasterData(DataPackage);
            const _date_ec = ToGregorianCalendar(
                            DataPackage.PYROLLDF_Year, 
                            DataPackage.PYROLLDF_Month, 
                            DataPackage.PYROLLDF_Day
                        );
            SetGC_Calander(_date_ec);
            SetEC_Calander(ToEthiopianCalander(_date_ec, true));
        }
    }, [DataPackage])

    function truncateString(str, maxLength) {
        if (str.length <= maxLength) return str;
        return str.slice(0, maxLength) + '...';
    }

    const UpdateMasterAttendyData = (prop_name, data, value)=>
    {
    
           SetEmployeePackageListOriginal((prev) => {
    
                const _update = prev.map((item) => {
                    let _dynamic_data = {...item};
                    if (item.EmployeeID === data.EmployeeID) {
    
                
                        if(prop_name === "ApplyPension") 
                        {
                            if(value) 
                            {
                                _dynamic_data = { ..._dynamic_data, PenstionEmployee: parseFloat(item.GrossSalary) * (parseFloat(auth.GlobalConfig.GC_EmployeePensionContributionRate) / 100) };
                                _dynamic_data = { ..._dynamic_data, PenstionEmployer: parseFloat(item.GrossSalary) * (parseFloat(auth.GlobalConfig.GC_CompanyPensionContributionRate) / 100) };

                                _dynamic_data = { ..._dynamic_data, NetPay: _dynamic_data.NetPay + _dynamic_data.PenstionEmployee };

                            } else 
                            {
                                _dynamic_data = { ..._dynamic_data, NetPay: item.NetPay - item.PenstionEmployee };

                                _dynamic_data = { ..._dynamic_data, PenstionEmployee: 0 };
                                _dynamic_data = { ..._dynamic_data, PenstionEmployer: 0 };

                            }
                        }
    
                        return { ..._dynamic_data, [prop_name]: value };
                    }
                    return item;
                });
                return _update; 
            });
    
            SetEmployeeData((prev) => {
    
                const _update = prev.map((item) => {
                    let _dynamic_data = {...item};
                    if (item.EmployeeID === data.EmployeeID) {
    
                        if(prop_name === "ApplyPension") 
                        {
                            if(value) 
                            {
                                _dynamic_data = { ..._dynamic_data, PenstionEmployee: parseFloat(item.GrossSalary) * (parseFloat(auth.GlobalConfig.GC_EmployeePensionContributionRate) / 100) };
                                _dynamic_data = { ..._dynamic_data, PenstionEmployer: parseFloat(item.GrossSalary) * (parseFloat(auth.GlobalConfig.GC_CompanyPensionContributionRate) / 100) };
                            
                                _dynamic_data = { ..._dynamic_data, NetPay: _dynamic_data.NetPay - _dynamic_data.PenstionEmployee };
                            } else 
                            {
                                _dynamic_data = { ..._dynamic_data, NetPay: item.NetPay + item.PenstionEmployee };

                                _dynamic_data = { ..._dynamic_data, PenstionEmployee: 0 };
                                _dynamic_data = { ..._dynamic_data, PenstionEmployer: 0 };
                            }
                        }
    
                        return { ..._dynamic_data, [prop_name]: value };
                    }
                    return item;
                });
                return _update; 
            });
        }

    const FilterEmployeeByKey = (filter_key)=>
    {
        const valid_array = [];
        for(let i = 0; i < EmployeePackageListOriginal.length; i++) 
        {
            if(EmployeePackageListOriginal[i].FullName.toLowerCase().includes(filter_key.toLowerCase())) 
            {
                valid_array.push(EmployeePackageListOriginal[i]);
            }
        }

        SetEmployeeData(valid_array);
    }

    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>
            <Tooltip multiline={true} className={`${data_win.tool_quick}`} id="accouting_tip" place="top" />

            {OpenPrintPreview && <FinalPayrollPrintablePreview 
                                                                BranchName={DataPackage.BranchName} 
                                                                DateET={EC_Calander}
                                                                DateGC={GC_Calander} 
                                                                PrintDataPackage={EmployeePackageListOriginal} 
                                                                OpenController={SetOpenPrintPreview}/>}
            <div className={`${classes.hr_data_feed_header}`}>

                <h2>[ACCOUTING] <strong>{DataPackage.BranchName} [{EC_Calander}]</strong>  Payroll</h2>
                <div className={`${classes.top_hr_data_feed_pass_right_wrapper}`}>
                    <UIButton Icon={<MicrosoftExcelLogo weight='bold'/>} label={"XLSX"} onClick={()=>{ExportExcel()}}/>
                    <UIButton Icon={<Printer weight='bold'/>} label={"Print"} onClick={()=>{SetOpenPrintPreview(true)}}/>
                    <UIButton Icon={<Export weight='bold'/>} label={"Finish Payroll"} onClick={()=>{
                            DAControl.Trigger(
                                    'Finish Preparing Payroll',
                                    `You are about to finish/close payroll preparation data for ${EC_Calander}, after this action you cannot modify any entry. and make sure to print the document before this action.`,
                                    true,
                                    1,
                                    (data)=>
                                    {
                                        if(data) 
                                        {
                                            FinishPayrollPrep();
                                        }
                                    }
                                    )
                        }}/>
                </div>  

            </div>

            <div className={`${classes.hr_data_process_pass_inner_tbl_wrapper}`}>

                <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilterEmployeeByKey(e.target.value)}} autoComplete='off' placeholder='Eg. Employee Name' type="text" /> 
                        <span><MagnifyingGlass weight='bold'/></span>
                    </div>
                </div>

                <div className={`${classes.list_box_wrapper}`}>

                    <div className={`${classes.active_order_main_list_wrapper}`}>
                        <li className={`${classes.header_tab_tabe}`}>
                            <span>Full Name</span>
                            <span>Workday</span>
                            <span>Allowances</span>
                            <span>Base Salary</span>
                            <span>Taxable Income</span>
                            <span>Pensions</span>
                            <span>Income Tax</span>
                            <span>Total Deduction</span>
                            <span>Net Pay</span>
                            <span>Account</span>
                        </li>
                        <div className={`${classes.list_data_wrapper}`}>
                            { Loading ? 
                            <div className={`${classes.dots_view_loading_hr_pass_wrapper}`}>
                                <li>Syncing your data</li>
                                <DotsGif/>
                            </div> : 
                                EmployeeData.map((item, idx)=>(
                                    <li key={item.FullName + item.EmployeeID + idx}>
                                        <span className={`${classes.employee_name_text}`}>{idx + 1}. {item.FullName}</span>
                                        <span data-tooltip-id="accouting_tip"
                                                data-tooltip-html={`
                                                    -> Work Day: ${item.TotalWorkingDay}<br/>
                                                    -> Absent Day: ${item.AbsentDay}<br/>
                                                    -> Short Minute: ${item.ShortMinute}<br/>
                                                    -> OverTime: ${item.OverTime}
                                                `}>{item.TotalWorkingDay} ...</span>
                                        <span className={`${classes.employee_name_text}`}>{currency_format(item.TotalAllowance)}</span>
                                        <span className={`${classes.employee_name_text}`}>{currency_format(item.GrossSalary + item.TotalAllowance)}</span>
                                        <span className={`${classes.employee_name_text}`}>{currency_format(item.GrossSalary)}</span>
                                        <span
                                        data-tooltip-id="accouting_tip"
                                        data-tooltip-html={`
                                            -> Employee Contribution: ${currency_format(item.PenstionEmployee)}<br/>
                                            -> Employer Contribution: ${currency_format(item.PenstionEmployer)}
                                        `}>
                                        <input onChange={(e)=>{UpdateMasterAttendyData("ApplyPension", item, e.target.checked)}} checked={item.ApplyPension} className={`${classes.ot_adder_input_wrap}`}  type="checkbox" />
                                            {currency_format(item.PenstionEmployee)}
                                        </span>
                                        <span>{currency_format(item.IncomeTax)}</span>
                                        <span
                                        data-tooltip-id="accouting_tip"
                                        data-tooltip-html={`
                                            -> Penality Deduction: ${currency_format(item.DeductablePenality)}<br/>
                                            -> Credit Deduction: ${currency_format(item.DeductableCredit)}<br/>
                                            -> Late Minute Deduction: ${currency_format(item.LateMinueCash)}<br/>
                                            -> Pension Deduction: ${currency_format(item.PenstionEmployee)}<br/>
                                        `}
                                        >{currency_format(item.DeductableCredit + item.DeductablePenality + item.LateMinueCash + item.PenstionEmployee)}</span>
                                        <span className={`${item.NetPay > 0 ? classes.employee_salary : classes.employee_salary_net_neg}`}
                                            data-tooltip-id="accouting_tip"
                                            data-tooltip-html={`
                                                -> Over Time: ${currency_format(item.OverTimeCash)}
                                            `}>
                                            {currency_format(item.NetPay)}
                                        </span>
                                        {/* <span> 
                                            <input onChange={(e)=>{UpdateMasterAttendyData('ApplyDeductionByDefault', item, e.target.checked)}} checked={item.ApplyDeductionByDefault}  className={`${classes.ot_adder_input_wrap}`}  type="checkbox" />
                                            --
                                        </span>
                                        <span>
                                            <input onChange={(e)=>{UpdateMasterAttendyData('ApplyPenalityDeduction', item, e.target.checked)}} checked={item.ApplyPenalityDeduction}  className={`${classes.ot_adder_input_wrap}`}  type="checkbox" />
                                            {auth.GlobalConfig.GC_AllowToModifyPenalityAmount ?
                                                <input disabled={!auth.GlobalConfig.GC_AllowToModifyPenalityAmount} title={'If you modify this value, it will be considered an adjustment to the overall penalty amount for the current month.'} onChange={(e)=>{UpdateMasterAttendyData('Penality', item, e.target.value)}} className={`${classes.excel_input_like}`} type="text" value={item.Penality} />
                                            :  currency_format(item.Penality)
                                            }
                                            --
                                        </span> */}
                                        <span className={`${classes.employee_bank_account_wrapper_item}`}>{truncateString(item.BankAccount, 14)}</span>
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}
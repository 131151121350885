import { CarProfile, MapPinArea } from '@phosphor-icons/react'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig'
import classes from './VehiclePreviewCard.module.css'
import { FetchWhatsgpsCarInfo } from '../../../../SharedComp/API/GPS/API_FE_WhatsGPS'
import { useEffect } from 'react'
import { useState } from 'react'
import { useAuth } from '../../../../AuthProvider'


export default function VehiclePreviewCard({Data, HandleClick}) 
{

    const auth = useAuth();
    const [StaticTime, SetStaticTime] = useState(0);
    const [Speed, SetSpeed] = useState(0);

    const QueryVHState = async(gps_token, car_id)=>
    {
        try {
            const res = await FetchWhatsgpsCarInfo(car_id, gps_token, auth.token);

            if(res.data)
            {
                const { lat, lon } = res.data[0];
                SetStaticTime(res.data[0].status);
                SetSpeed(res.data[0].speed)
            }
    
        } catch (err) {

        }
    }

    useEffect(()=>{

        const _gps_token = localStorage.getItem('GPS-TOKEN');
        const interval = setInterval(() => {
            QueryVHState(_gps_token, Data.VINTKD_CarID);
        }, 2000);
       

        return () => clearInterval(interval);
    },[])


    return (
        <div onClick={()=>{HandleClick(Data)}} className={`${classes.vh_preview_card_master_wrapper}`}>
            {StaticTime === '5' && <div className={`${classes.car_moving}`}>
                    <li className={`${classes.speed_metric}`}>{Speed } km/h</li>
                   <li>  <CarProfile weight='fill'/> </li>
            </div>}

            <div className={`${classes.preview_card_image}`}>
                <li className={`${classes.car_move_icon}`}><MapPinArea weight='bold'/></li>
            </div>
            <div className={`${classes.card_body_wrapper}`}>
                <li><span className={`${classes.vh_preview_card_tag}`}>Driver</span> <span className={`${classes.vh_preview_card_value}`}>{Data.JAKSBE_FullName}</span></li>
                <li><span className={`${classes.vh_preview_card_tag}`}>Last Ping</span> <span className={`${classes.vh_preview_card_value}`}>{Data.VINTKD_LastLocation}</span></li>
                <li><span className={`${classes.vh_preview_card_tag}`}>VH Type</span> <span className={`${classes.vh_preview_card_value}`}>{Data.HRMVT_Name}</span></li>
                <li><span className={`${classes.vh_preview_card_tag}`}>Plate Number</span> <span className={`${classes.vh_preview_card_value}`}>{Data.VINTKD_PlateNumber}</span></li>
            </div>
        </div>
    )
}
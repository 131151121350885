import React, { useState } from "react";
import { useEffect } from "react";
import { createContext, useContext } from "react";
import { QueryEmployeeByID } from "../../../../../SharedComp/API/HR/API_FE_Employee";
import { useAuth } from "../../../../../AuthProvider";
import { useToast } from "../../../../../ToastContext";
import { QueryBaseEmployeeByID } from "../../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee";

const SingleEmployeeContext = createContext();

export const useTargetEmployee = () => useContext(SingleEmployeeContext);

export const TargetEmployeeProvider = ({children, TargetEmployeeData}) =>
{
    const [Core, SetCore] = useState(false);
    const auth = useAuth();
    const notify = useToast();

    function Update()
    {
        FetchEmployee();
    }

    const FetchEmployee = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", TargetEmployeeData.JAKSBE_ID)
            const main_res = await QueryBaseEmployeeByID(fd, auth.token);
            
            if(main_res.length > 0) 
            {
                SetCore(main_res[0]);
            }

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        if(TargetEmployeeData) 
        {
            FetchEmployee();
        }

    }, [TargetEmployeeData])

    return (
        <SingleEmployeeContext.Provider value={{
            Core,
            Update
        }}>
           
            {children}
        </SingleEmployeeContext.Provider>
    )
}
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import classes from './UnknownRoute.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RoadHorizon } from '@phosphor-icons/react'

export default function UnknownRoute()
{
    return (
        <div className={`${classes.unknown_route}`}>
            <div className={`${classes.show_case}`}>
                
                <div className={`${classes.data_wrapper}`}>
                    <div className={`${classes.error_code}`}>
                        <h2>404</h2>
                    </div>
                    <div className={`${classes.inner_wrap}`}>
                        We couldn’t find the page you were looking for. Maybe visiting our <span>Social Hub</span> can help?
                    </div>
                </div>

            </div>
        </div>
    )
}
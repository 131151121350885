import classes from './PasswordVerfiyPage.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import UIButton from '../../../../Components/UIButton/UIButton'
import { SealCheck } from '@phosphor-icons/react'
import { CheckEmailVerificationCode, SendVerificationMail } from '../../../../../SharedComp/API/UserAccess/API_FE_UserAccess'
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { useState } from 'react'


export default function PasswordVerfiyPage({CloseModal, XUser})
{

    const auth = useAuth();
    const notify = useToast();
    const [OtpCode, SetOtpCode] = useState();


    const RequestVerfication = async()=>
    {
        try 
        {
            const _target_email = XUser.Email;
            const _user_full_name = (XUser.FirstName + " " + XUser.LastName);

            const fd = new FormData();
            fd.append("user_email", _target_email);
            fd.append("user_full_name", _user_full_name);
            fd.append("user_id", XUser.UserAccountID);

            const main_res = await SendVerificationMail(fd, auth.token);

        } catch(err) 
        {

        }
    }

    const CheckOTP = async(code)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("otp", OtpCode);
            fd.append("user_id", XUser.UserAccountID);
            
            const main_res = await CheckEmailVerificationCode(fd, auth.token);
            notify.ShowSuccess(main_res.Msg);
            CloseModal();

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        if(XUser) 
        {
            RequestVerfication();
        }

    }, [XUser])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${classes.data_main}`}>
            <div className={`${classes.data_main_header}`}>
                <h3>
                    <span className={`${classes.data_create_name}`}>Confirm Email</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${classes.data_body}`}>
                    <input onChange={(e)=>{SetOtpCode(e.target.value)}} type="text" autoComplete='off' placeholder='verfication code' required />
                    <li>Resend code?</li>
                    <UIButton onClick={()=>{CheckOTP()}} label={"Verify Email"} Icon={<SealCheck/> }/>
            </div>
        </div>

    </div>
    )
}
import classes from './TransferStateIndicator.module.css'


export default function TransferStateIndicator({label}) 
{
    const getLabelClass = (label) => {
        switch (label.toLowerCase()) {
          case 'pending':
            return classes.pending;
          case 'sent':
            return classes.sent;
          case 'received':
            return classes.received;
          case 'underway':
            return classes.underway;
          case 'processing' || 'shipped':
            return classes.processing;
          case 'rejected':
            return classes.rejected;
          default:
            return '';
        }
      };

    return (
        <button className={`${classes.inventory_stock_transfer_state} ${getLabelClass(label)}`} type='button' onClick={()=>{}}>
            {label}
        </button>
    )
}
import classes from './PastryOrderDetail.module.css'

import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'


//lib
import { useAuth } from '../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../ToastContext'


export default function PastryOrderDetail({OpenController, TargetData, AddPastry_Callback, ProductGroupID})
{

    const auth = useAuth();
    const notify = useToast();

    const [ProductChoice, SetProductChoice] = useState();
    const [ProductPrice, SetProductPrice] = useState(0);
    const [ProductQuantity, SetProductQuantity] = useState('');
    const [ProductColor, SetProductColor] = useState('');
    const [ProductKG, SetProductKG] = useState('');
    const [ProductScripture, SetProductScripture] = useState('');
    const [ProductNote, SetProductNote] = useState('');

    const [ResponsePackage, SetResponsePackage] = useState();

    function CloseModal() 
    {
        OpenController(false);
    }

    const HandleKgSelection = async(amount)=>
    {
        SetProductKG(amount);
        if(amount > 6) 
        {
            notify.ShowWarn(`Please make sure you consult with the active chef before proceeding with an order of '${amount}KG'.`);
        }

    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();



        AddPastry_Callback({
            _ProductGroupID: ProductGroupID,
            _ProductPreviewName: TargetData.CKP_ItemName,
            _ProductChoice: JSON.stringify(ProductChoice),
            _ProductPrice: ProductPrice,
            _ProductPriceMod: ProductPrice !== TargetData.CKP_UnitPrice,
            _ProductQuantity: ProductQuantity,
            _ProductColor: ProductColor,
            _ProductKG: ProductKG,
            _ProductScripture: ProductScripture,
            _ProductNote: ProductNote
        });
        notify.ShowSuccess("Product Choice Queued for Processing.")
        CloseModal();
    }

    useEffect(()=>{

        if(TargetData) 
        {
            SetProductPrice(TargetData.CKP_UnitPrice);
            SetProductChoice({ProductID: TargetData.CKPID, ProductName: TargetData.CKP_ItemName, ProductType: TargetData.KV_TypeName});
        }

    }, [TargetData])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Receive <span className={`${data_win.data_create_name}`}>Pastry Product</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            
                                            <MasterDataRegInput
                                                    input={
                                                        <input
                                                        value={TargetData?.CKP_ItemName}  
                                                        onChange={(e)=>{}}
                                                        type="text" 
                                                        readOnly />
                                                    }
                                                />

                                            <div className={`${classes.product_count_price}`}>
                                                
                                                <div className={`${classes.inptu_inner_carry}`}>

                                                    <MasterDataRegInput
                                                            input={
                                                                <input
                                                                value={ProductPrice}  
                                                                onChange={(e)=>{SetProductPrice(e.target.value)}}
                                                                type="number"
                                                                step={"any"} 
                                                                />
                                                            }
                                                            tip={"Product Price: You may adjust this if it is a special order; otherwise, please do not modify."}
                                                        />

                                                </div>
                                                

                                                <div className={`${classes.inptu_inner_carry}`}>
                                                    <MasterDataRegInput
                                                        input={
                                                            <input
                                                                onChange={(e)=>{SetProductQuantity(e.target.value)}}
                                                                value={ProductQuantity}
                                                                placeholder='Quantity' 
                                                                type="number"
                                                                required
                                                            />
                                                        }
                                                        tip={"Product Quantity"}
                                                    />
                                                </div>
                                                
                                            </div>

                                            <div className={`${classes.product_count_price}`}>

                                                <div className={`${classes.inptu_inner_carry}`}>
                                                    <MasterDataRegInput
                                                        input={
                                                            <input  
                                                            onChange={(e)=>{SetProductColor(e.target.value)}}
                                                            value={ProductColor}
                                                            type="text" 
                                                            placeholder='Color Choice'
                                                            />
                                                        }
                                                        tip={"Color Choice: If the customer specifies a color, please enter it. Otherwise, ignore this field and the default color will be applied."}
                                                    />
                                                </div>

                                                <div className={`${classes.inptu_inner_carry}`}>
                                                    <MasterDataRegInput
                                                        input={
                                                            <input  
                                                            onChange={(e)=>{HandleKgSelection(e.target.value)}}
                                                            value={ProductKG}
                                                            type="text" 
                                                            placeholder='KG'
                                                            />
                                                        }
                                                        tip={"Specify kilogram usage; this is appropriate for special orders only. If not, ignore this field."}
                                                    />
                                                </div>

                                            </div>
                                            
                                            
                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                    onChange={(e)=>{SetProductScripture(e.target.value)}}
                                                    value={ProductScripture}
                                                    type="text"
                                                    placeholder='Scripture Detail'
                                                    />
                                                }
                                                tip={"If the customer wants anything written on the product, please specify here. If there are no requests, ignore this field."}
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                    onChange={(e)=>{SetProductNote(e.target.value)}}
                                                    value={ProductNote}
                                                    type="text"
                                                    placeholder='Order Detail if any..'
                                                    />
                                                }
                                                tip={"If you have any additional instructions or requests that might help the chef, please specify here."}
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button className={`${data_win.form_action_button_save}`}>Add</button>
                                <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}
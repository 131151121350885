import classes from './TransferBakeryProductPrintPreview.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faCheckCircle, faTimesCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'
import PaperFooter from '../../../../SharedComp/PaperFooter/PaperFooter'

import QRCode from 'react-qr-code'

import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import {FetchStockTransferRequestByID, FetchTransferRequestPayload, FetchRequestSenderUser, FetchRequestHandlerUser} from '../../../../api'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { QueryTransferByApprover, QueryTransferByInitializer } from '../../../../SharedComp/API/FinalBakeryTransfer/FinalBakeryTransfer'
import { Checks } from '@phosphor-icons/react'

export default function TransferBakeryProductPrintPreview({OpenModal, TargetData}) 
{

    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_finished_product_request_" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const [Loading, SetLoading] = useState(false);
    const [TargetRequest, SetTargetRequest] = useState([]);
    const [RequestPayload, SetRequestPayload] = useState([]);
    const formatter = new Intl.NumberFormat('en-US');

    const [RequestSender, SetRequestSender] = useState();
    const [RequestReciver, SetRequestReciver] = useState();
    const [TransferID, SetTransferID] = useState(null);

    const [ParsedData, SetParsedData] = useState(JSON.parse(TargetData.TWFP_ResponsePackage));

    const GetRequestApprover = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_request_id", TargetData.TWFP_ID)
            const _main_res = await QueryTransferByApprover(fd, auth.token);
            SetRequestReciver(_main_res[0]);
        }catch(err) 
        {

        }
    }
    const GetRequestInitializer = async() =>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_request_id", TargetData.TWFP_ID)
            const _main_res = await QueryTransferByInitializer(fd, auth.token);
            SetRequestSender(_main_res[0]);

        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        GetRequestApprover();
        GetRequestInitializer();
        
    }, [])

    useEffect(()=>{
        if(TargetData) 
        {
            const __array_of_id = TargetData.TWFP_TransactionID.split('-');
            if(__array_of_id.length > 1) 
            {
                SetTransferID(__array_of_id[0] + '-' + __array_of_id[1])
            }
        }

    }, [TargetData])


    // const FetchRequestPayload = async()=>
    // {
    //     try 
    //     {
    //         const res = await FetchTransferRequestPayload(TargetID, auth.token);
    //         SetRequestPayload(res);
    //         SetLoading(false);
    //     }catch(err) 
    //     {

    //     }
    // }

    // const FetchTagetRequest = async()=>{
    //     try 
    //     {
    //         FetchRequestPayload();
    //         const res = await FetchStockTransferRequestByID(TargetID, auth.token);

    //         const __sender = await FetchRequestSenderUser(res[0].IVSTR_SenderUserID, auth.token);
    //         SetRequestSender(__sender[0]);
    //         const __hander = await FetchRequestHandlerUser(res[0].IVSTR_RequestHandledBy, auth.token);
    //         SetRequestReciver(__hander[0]);
    //         SetTargetRequest(res[0]);
    //     }catch(err) 
    //     {
    //         notify.ShowError("Falied to Fetch Invoice!")
    //     } 
    // }

    useEffect(()=>{
        //SetLoading(true);
        //FetchTagetRequest();
    }, [])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        <div className={`${data_win.data_main}`}>
          <div className={`${data_win.data_main_header}`}>
                <h3>
                    Final <span className={`${data_win.data_create_name}`}> Product Transfer</span>
                </h3>
                <button onClick={()=>{OpenModal(false)}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body} ${classes.data_body}`}>

            {Loading === false ? <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>//{RequestSender?.BranchName} <FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon> //{RequestReciver?.BranchName}</h2>
                            <h2>Finished Product Transfer</h2>
                        </div>

                        <div className={`${classes.transcation_owner_wrapper}`}>
                            <div className={`${classes.transcation_owner}`}>

                                <div className={`${classes.transcation_init}`}>
                                    <h2>Request Intialized By</h2>
                                    <li>{RequestSender?.FirstName} {RequestSender?.LastName}</li>
                                    <li>{RequestSender?.Name}</li>
                                    <li>{RequestSender?.BranchName} Branch</li>
                                </div>
                                <div className={`${classes.transcation_recive}`}>
                                    <h2>Request Handled By</h2>
                                    <li>{RequestReciver?.FirstName} {RequestReciver?.LastName}</li>
                                    <li>{RequestReciver?.Name}</li>
                                    <li>{RequestReciver?.BranchName} Branch</li>
                                </div>

                            </div>
                        </div>

                        <div className={`${classes.entry_detail}`}>
                            <li><span className={`${classes.entry_deatil_tag}`}>Transaction ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData.TWFP_TransactionID}</span></li>
                        </div>  
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>
                            <div className={`${classes.paper_list_wrapper}`}>

                            <li className={`${classes.fake_tabke_header}`}>
                                <span>
                                   Product Item Name
                                </span>
                                {/* <span>
                                    Requested Quantity
                                </span> */}
                                <span>
                                    Sent Quantity
                                </span>
                                <span>
                                    Action
                                </span>
                            </li>


                                {
                                    ParsedData.map((item, idx)=>(
                                        <li className={`${item.IVSTRP_TR_Value === 'Transferred' ? '' : classes.ban_view}`} key={idx}>
                                            <span>
                                            {JSON.parse(item.ProductItem).label}
                                            </span>
                                            <span>
                                              {formatter.format(item.Quantity)} Pcs 
                                            </span>
                                            <span className={`${classes.action_wrapper_text_good}`}>
                                               <span className={`${classes.check_valid}`}> <span><Checks/></span></span>
                                               Approved
                                            </span>
                                        </li>
                                    ))  
                                }
                                 

                            </div>
                            
                        </div>

                        <div className={`${classes.entry_detail_code}`}>
                            <div className={`${classes.scan_data}`}>
                               {TransferID && <QRCode
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    fgColor={'var(--background-dark)'}
                                    value={TransferID}
                                    viewBox={`0 0 256 256`}
                                    /> }
                            </div>
                            <div className={`${classes.scan_data_connect}`}>

                            </div>
                            <div className={`${classes.scan_data_text}`}>
                                <li>KEY: {TransferID}</li>
                            </div>
                        </div>

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Authorized By</h2>
                                <li className={`${classes.name_manual}`}>{RequestReciver?.FirstName} {RequestReciver?.LastName}</li>
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>
                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Driver</h2>
                                <li className={`${classes.name_manual}`}>Full Name: ______________</li>
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>
                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Received By</h2>
                                <li className={`${classes.name_manual}`}>Full Name: ______________</li>
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>

                        </div>


                    </div>
                </div> : 'Loading'}
                    

            </div> 

                 <div className={`${classes.form_action_button_wrapper}`}>
                {/* <button className={`${classes.form_action_button_approve}`}>Request Approval</button> */}
                {/* <button className={`${classes.form_action_button_req_dismiss}`}>Request Dismissal </button> */}
               {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PrintTransferDocument && <button onClick={()=>{PrintDoc()}} className={`${classes.form_action_button_print}`}>Print Document</button> }
                <button  onClick={()=>{OpenModal(false)}} className={`${classes.form_action_button_dismiss}`} type='button'>Close</button>
            </div> 
        </div>

    </div>
    )
}
import classes from './PerformCashPenality.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'
import { useTargetEmployee } from '../../SingleEmployeeContextProvider/SingleEmployeeContextProvider'
import { CreateNewEmployeeCashPenality } from '../../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeePenality'

export default function PerformCashPenality({OpenController}) 
{

    const sharedData = useTargetEmployee();
    const auth = useAuth();
    const notify = useToast();

    const [PenalityAmount, SetPenalityAmount] = useState();
    const [Justification, SetJustification] = useState();
    const [Loading, SetLoading] = useState(false);


    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_ID);
            fd.append("penality_init_by", auth.user.UserAccountID);
            fd.append("penality_amount", PenalityAmount);
            fd.append("penality_justifiaction", Justification);
            fd.append("emp_fullname", sharedData.Core.JAKSBE_FullName);
            fd.append("branch_id",  sharedData.Core.JAKSBE_ActiveBranchID);


            const res = await CreateNewEmployeeCashPenality(fd, auth.token);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            sharedData.Update();
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }



    return(
        <div className={`${data_win.main_data_creator_wrapper} ${classes.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main} ${classes.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Initiate  <span className={`${data_win.data_create_name}`}>Cash Penality</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetPenalityAmount(e.target.value)}}
                                                    type="number"
                                                    step={'any'}
                                                    min={1} 
                                                    placeholder='Penality Amount'
                                                    required />
                                                }
                                                tip={`This Penality will be applied in the next payroll cylce`}
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                    onChange={(e)=>{SetJustification(e.target.value)}}
                                                    type="text"
                                                    placeholder='Explanation'
                                                    required />
                                                }
                                                tip={`Be Specific as possible`}
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Perform Penality'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}
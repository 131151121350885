import { useState } from 'react'
import classes from './FinalPayrollPrintablePreview.module.css'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useReactToPrint } from 'react-to-print'
import { useRef } from 'react';
import { useAuth } from '../../../../../AuthProvider';
import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader';
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash';
import QRCode from 'react-qr-code';



const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function FinalPayrollPrintablePreview({DateGC, DateET, BranchName, PrintDataPackage, OpenController}) 
{
    const auth = useAuth();
    const [Loading, SetLoading] = useState(false);
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: "wait_for_the_name",
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        },
    
    })

    const CloseModal = ()=>
    {
        OpenController(false);
    }



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    <span className={`${data_win.data_create_name}`}>Signable Payroll Document</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

            <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash text={"authorized document"}/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>[#] {BranchName} - {DateET} / {DateGC.toDateString()} </h2>
                        </div>
 
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>

                            <div className={`${classes.data_marging_wrapper}`}>
                                <li className={`${classes.data_header_list}`}>
                                    <span>Full Name</span>
                                    <span>Workday</span>
                                    <span>Allowances</span>
                                    <span>Base Salary</span>
                                    <span>Taxable Income</span>
                                    <span>Pensions</span>
                                    <span>Income Tax</span>
                                    <span>Total Deduction</span>
                                    <span>Net Pay</span>
                                    <span>Account</span>
                                </li>
                            {
                                PrintDataPackage.map((item, idx)=>(
                                    <li key={item.FullName + item.EmployeeID + idx}>
                                        <span className={`${classes.text_left}`}>{idx + 1}. {item.FullName}</span>
                                        <span>{item.TotalWorkingDay}</span>
                                        <span>{currency_format(item.TotalAllowance)}</span>
                                        <span>{currency_format(item.GrossSalary + item.TotalAllowance)}</span>
                                        <span>{currency_format(item.GrossSalary)}</span>
                                        <span>{currency_format(item.PenstionEmployee)}</span>
                                        <span>{currency_format(item.IncomeTax)}</span>
                                        <span>{currency_format(item.DeductableCredit + item.DeductablePenality + item.LateMinueCash + item.PenstionEmployee)}</span>
                                        <span>{currency_format(item.NetPay)}</span>
                                        <span>{item.BankAccount}</span>
                                    </li>
                                ))
                            }
                            </div>

                            
                            
                        </div>

                            {/* <div className={`${classes.entry_detail_code}`}>
                                    <div className={`${classes.scan_data}`}>
                                    <QRCode
                                            size={128}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            fgColor={'var(--background-dark)'}
                                            value={OrderGroup?.KOPSRG_TranscationHash ? OrderGroup.KOPSRG_TranscationHash : '--'}
                                            viewBox={`0 0 256 256`}
                                            /> 
                                    </div>
                                    <div className={`${classes.scan_data_connect}`}>

                                    </div>
                                    <div className={`${classes.scan_data_text}`}>
                                        <li>KEY: {OrderGroup?.KOPSRG_TranscationHash ? OrderGroup.KOPSRG_TranscationHash : '--'}</li>
                                    </div>
                            </div> */}

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Accountant</h2>
                                <li className={`${classes.name_manual}`}>--</li>
                                <li className={`${classes.print_date}`}>Date: { new Date().toDateString()}</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Human Resource</h2>
                                <li className={`${classes.name_manual}`}>--</li>
                                <li className={`${classes.print_date}`}>Date: { new Date().toDateString()}</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>General Manager</h2>
                                <li className={`${classes.name_manual}`}>--</li>
                                <li className={`${classes.print_date}`}>Date: { new Date().toDateString()}</li>
                                <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                            </div>

                        </div>

                        <div className={`${classes.final_note_po_stock_out}`}>
                            <li>This document must contain all signatures in place to be considered valid.</li>
                        </div>

                    </div>
                </div>



                </div>
                    <div className={`${data_win.form_action_button_wrapper}`}>
                        <button onClick={()=>{PrintDoc()}} disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Print'}</button>
                        <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Close</button>
                    </div>
            </div>
        </div>

    </div>
    )
}
import classes from './CrossBranchEmployee.module.css'
import EmployeeBundleHandler from '../Component/EmployeeBundleHandler/EmployeeBudleHandler'
import CreateNewEmployee from '../CreateNewEmployee/CreateNewEmployee'
import EmployeeView from '../Component/View/EmployeeView';
import { useState } from 'react';
import { TargetEmployeeProvider } from '../Component/View/SingleEmployeeContextProvider/SingleEmployeeContextProvider';

export default function CrossBranchEmployee() 
{
    const [EmpView, SetEmpView] = useState(false);
    const [OpenCreateNewEmployeeProfile, SetOpenCreateNewEmployeeProfile] = useState(false);
    const [selectedBranch, SetSelectedBranch] = useState();
    const [SelectedEmployeeData, SetSelectedEmployeeData] = useState();


    function HandleEmployeeOpenCreator(id) 
    {
        SetSelectedBranch(id ? id.BranchID : -1);
        SetOpenCreateNewEmployeeProfile(true);
    }
    function HandleEmpViewController(shouldOpen, data) 
    {
        SetEmpView(shouldOpen);
        SetSelectedEmployeeData(data);
    }

    return (
        <div className={`${classes.cross_branch_employee_master_wrapper}`}>

            {OpenCreateNewEmployeeProfile && <CreateNewEmployee SelectedBranch={selectedBranch} OpenController={SetOpenCreateNewEmployeeProfile} />}
            {EmpView && 
                <TargetEmployeeProvider TargetEmployeeData={SelectedEmployeeData}>
                    <EmployeeView EmpViewController={SetEmpView} />
                </TargetEmployeeProvider>
          }

            <div className={`${classes.employee_bundle_wrapper}`}>
           
            <EmployeeBundleHandler
                key={"cross-branch-employee"} 
                BranchData={null} 
                EmployeeCreatorWinController={HandleEmployeeOpenCreator} 
                EmpViewController={HandleEmpViewController}
                />

            </div>
            
        </div>
    )
}
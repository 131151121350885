import { ArrowsLeftRight } from '@phosphor-icons/react';
import classes from '../PayrollConfig/PayrollConfig.module.css'
import class_b from './AccoutingConfig.module.css'

const currency_format = (val)=> 
    {
        return val.toLocaleString('en-US', {
            style: 'currency',
            currency: 'ETB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
export default function AccoutingConfig({UpdateReq, ConfigData}) 
{
    // This is only for display purpose
    const TaxRuleMap = [];
    TaxRuleMap.push({ minIncome: 0, maxIncome: 600, rate: 0, fixedTax: 0 });
    TaxRuleMap.push({ minIncome: 601, maxIncome: 1650, rate: 10, fixedTax: 0 });
    TaxRuleMap.push({ minIncome: 1651, maxIncome: 3200, rate: 15, fixedTax: 105 });
    TaxRuleMap.push({ minIncome: 3201, maxIncome: 5250, rate: 20, fixedTax: 405 });
    TaxRuleMap.push({ minIncome: 5251, maxIncome: 7800, rate: 25, fixedTax: 945 });
    TaxRuleMap.push({ minIncome: 7801, maxIncome: 10900, rate: 30, fixedTax: 1570 });
    TaxRuleMap.push({ minIncome: 10901, maxIncome: Infinity, rate: 35, fixedTax: 2365 });
    


    return (
        <div className={`${classes.payroll_config_wrapper}`}>


            <div className={`${classes.part_of_option}`}>
                <ul>General</ul>
                <div className={`${classes.setting_items_config}`}>
                <li><span>Allow Dynamic Bank Account Modifications</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_AllowDynamicBankAccountModifications",  e.target.value === "true")}} value={ConfigData.GC_AllowDynamicBankAccountModifications}>
                                <option value="true">Allow</option>
                                <option value="false">Block</option>
                            </select>
                        </span>
                </li>
                <li><span>Apply Deduction Before Tax </span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_ApplyDeductionBeforeTax",  e.target.value === "true")}} value={ConfigData.GC_ApplyDeductionBeforeTax}>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </span>
                </li>
               

                </div>  
            </div>
            <div className={`${classes.part_of_option}`}>
                <ul>Over Time</ul>
                <div className={`${classes.setting_items_config}`}>
                <li><span>Over Time Rate</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_OverTimeRate",  e.target.value)}} value={ConfigData.GC_OverTimeRate}>
                                <option value="1">1%</option>
                                <option value="1.5">1.5%</option>
                                <option value="2">2%</option>
                                <option value="2.5">2.5%</option>
                                <option value="3">3%</option>
                            </select>
                        </span>
                </li>
                
               
                </div>  
            </div>
            <div className={`${classes.part_of_option}`}>
                <ul>Pensions</ul>

                <div className={`${class_b.legal_not_about_acc_data}`}>
                    <p><strong>Proclamation no.715/2011 - Private Employee</strong></p>
                    <p>The contribution is <strong>11%</strong> by the employer and <strong>7%</strong> by the employees.</p>
                </div>

                <div className={`${class_b.legal_not_about_acc_data}`}>
                    <p><strong>Proclamation no.714/2011 - Public Employee</strong></p>
                    <p>The contribution is <strong>11%</strong> by the public office and <strong>7%</strong> by the public servant for public employees</p>
                </div>

                <div className={`${class_b.legal_not_about_acc_data}`}>
                    <p><strong>Proclamation no.714/2011 - Military and Police Employee</strong></p>
                    <p>The contribution is <strong>25%</strong> by the public office and <strong>7%</strong> by the public servant for public employees-for military and police.</p>
                </div>

                

               
                
                <div className={`${classes.setting_items_config}`}>
                <li><span>Apply Pension </span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_AutoAppliedPension",  e.target.value === 'true')}} value={ConfigData.GC_AutoAppliedPension}>
                                <option value="true">For Everyone</option>
                                <option value="false">For Choicen Individuals</option>
                            </select>
                        </span>
                </li>
                <li><span>Employee Pension Contribution Rate</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_EmployeePensionContributionRate",  e.target.value)}} value={ConfigData.GC_EmployeePensionContributionRate}>
                                <option value="1">1%</option>
                                <option value="4">4%</option>
                                <option value="7">7% (Standard)</option>
                                <option value="11">11%</option>
                                <option value="15">15%</option>
                            </select>
                        </span>
                </li>
                <li><span>Company Pension Contribution Rate</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_CompanyPensionContributionRate",  e.target.value)}} value={ConfigData.GC_CompanyPensionContributionRate}>
                                <option value="5">5%</option>
                                <option value="7">7%</option>
                                <option value="11">11% (Standard)</option>
                                <option value="15">15%</option>
                                <option value="20">20%</option>
                            </select>
                        </span>
                </li>
               
                </div>  
            </div>


            <div className={`${classes.part_of_option}`}>
                <ul>Taxes on personal income</ul>
                <div className={`${classes.setting_items_config}`}>

                <li><span>Applied Tax Rule</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_AppliedTaxRule",  e.target.value)}} value={ConfigData.GC_AppliedTaxRule}>
                                <option value="Ethiopian">Standard Ethiopian</option>
                            </select>
                        </span>
                </li>
                <li><span>Fraction Rounding Rule</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_FractionRoundingRule",  e.target.value === 'true')}} value={ConfigData.GC_FractionRoundingRule}>
                                <option value="true">Up</option>
                                <option value="false">Down</option>
                            </select>
                        </span>
                </li>

                <div className={`${class_b.legal_not_about_acc_data}`}>
                    <p><strong>Employment income tax rates (Proclamation no.979/2016)</strong></p>
                </div>
                {
                    TaxRuleMap.map((item, idx)=>
                    (
                        <li key={idx}><span>Salary Range  <span className={`${class_b.color_full_salary_indactor}`}>{currency_format(item.minIncome)}</span>  {`<-->`} <span className={`${class_b.color_full_salary_indactor}`}>{currency_format(item.maxIncome)}</span> </span> 
                            <span>
                                <select   value={ConfigData.GC_SalaryInRangeOfTypeA}>
                                    <option value="0">{item.rate}%, ( Fixed Tax {currency_format(item.fixedTax)} )</option>
                                </select>
                            </span>
                        </li>
                    ))
                }

                </div>  
            </div>

            

        </div>
    )
}
import classes from './LoadingPlaceholder.module.css'
import DotsGif from '../CssGif/DotsGif'

export default function LoadingPlaceholder({preview_text = "Retrieving Package Data", placeholder_height=10}) 
{


    return (
        <div style={{height: `${placeholder_height}em`}} className={`${classes.loading_gif_wrapper}`}>
            <li>{preview_text}</li> <DotsGif/>
        </div>
    )
}
import { Skull } from '@phosphor-icons/react'
import classes from './SessionTerminationPreview.module.css'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';


export default function SessionTerminationPreview()
{

    const navigate = useNavigate();

    useEffect(()=>{
        setTimeout(()=>
        {
            navigate("/login");
        }, 20 * 1000)
    }, [])


    return (
        <div className={`${classes.page_termination_preview_main_wrapper}`}>
            
            <div className={`${classes.content_wrapper}`}>
                <h1><Skull/></h1>
                <h2>Administrator <span className={`${classes.force_terminated}`}>terminated</span> your session</h2>
                <h3>If this keeps happening, please contact your IT support</h3>
                <h3>Redirecting to login page in a few seconds</h3> 
                <h3 style={{fontWeight: 'bold', color: `var(--text_light_white)`}}>JAK AND SONS TRADING PLC</h3>
            </div>

        </div>
    )
}
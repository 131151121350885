import classes from './DispatchedInventoryRequestBundle.module.css'
import { ArrowFatUp, CaretCircleDoubleDown } from '@phosphor-icons/react'
import UIButton from '../../../../Components/UIButton/UIButton'
import { useState } from 'react'
import { useAuth } from '../../../../../AuthProvider'
import { useEffect } from 'react'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { QueryAllDispatchedTransaction } from '../../../../../SharedComp/API/InventoryStockTransfer/API_FE_InventoryStockTransfer'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'

import TransferStateIndicator from '../TransferStateIndicator/TransferStateIndicator'

export default function DispatchedInventoryRequestBundle({OrderPreviewCallback, RefreshView}) 
{

    const auth = useAuth();
    const [ShowMinFilterDiag, SetShowMinFilterDiag] = useState(false);
    const [DataBuffer, SetDataBuffer] = useState([]);
    const [DataBufferOrigin, SetDataBufferOrigin] = useState([]);
    const [Loading, SetLoading] = useState(false);

    const SortByDate = (data_array)=>
    {
        data_array.sort((a, b)=> new Date(b.ISTRV2_RequestInitDate) - new Date(a.ISTRV2_RequestInitDate));
        return data_array;
    }
    const FetchAllDispacthedBranchRelatedTransaction = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_branch", auth.activeWorkShop);
            const main_res = await QueryAllDispatchedTransaction(fd, auth.token);

            const _sorted = SortByDate(main_res);
            FilterByToDate(_sorted);
            SetDataBufferOrigin(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }

    useEffect(()=>{
        FetchAllDispacthedBranchRelatedTransaction();
    }, [auth.activeWorkShop, RefreshView])

    const FilterByKeyState = (key_state, data_array)=>
    {
        const _temp_buffer = [];
        for(let i = 0; i < data_array.length; i++) 
        {
            if(data_array[i].ISTRV2_RequestState === key_state)
            {
                _temp_buffer.push(data_array[i]);
            }
        }
        SetDataBuffer(_temp_buffer);
    }
    const FilterByToDate = (data_array)=>
    {
        const _temp_buffer = [];
        for(let i = 0; i < data_array.length; i++) 
        {
            const _base_date = new Date(data_array[i].ISTRV2_RequestInitDate);
            const _today  = new Date();

            if(
                _base_date.getFullYear() === _today.getFullYear() &&
                _base_date.getMonth() === _today.getMonth() && 
                _base_date.getDay() === _today.getDay()
            )
            {
                _temp_buffer.push(data_array[i]);
            }
        }

        SetDataBuffer(_temp_buffer);
    }
    const FilterByTransactionID = (key_value)=>
    {
        const _temp_buffer = [];
        for(let i = 0; i < DataBufferOrigin.length; i++) 
        {
            if(DataBufferOrigin[i].ISTRV2_TransactionID.toLowerCase().includes(key_value.toLowerCase()))
            {
                _temp_buffer.push(DataBufferOrigin[i]);
            }
        }
        SetDataBuffer(_temp_buffer);
    }
    
 


    return (
        <div className={`${classes.dispatched_iniventory_request_bundle_master_wrapper}`}>
            <div tabIndex={0} className={`${classes.dispatched_iniventory_request_bundle_wrapper_header}`}>
                <h2 style={{display: 'inline-flex'}}><span onClick={()=>{SetShowMinFilterDiag(!ShowMinFilterDiag)}} style={{padding: '0px 4px', cursor: 'pointer'}}><CaretCircleDoubleDown  weight='bold' size={17}/></span> Dispatched Request </h2>
                <span><ArrowFatUp weight='fill'/></span>

                {ShowMinFilterDiag && <div tabIndex={1} onBlur={(e)=>{SetShowMinFilterDiag(false)}} className={`${classes.dispatched_iniventory_request_bundle_filter_drop_down_list}`}>
                    <li onClick={()=>{FilterByToDate(DataBufferOrigin);SetShowMinFilterDiag(false)}}>Today Transaction</li>
                    <li onClick={()=>{SetDataBuffer(DataBufferOrigin);SetShowMinFilterDiag(false)}}>Show All Transaction</li>
                    <hr />
                    <li  onClick={()=>{FilterByKeyState("Sent", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Completed Transaction</li>
                    <li onClick={()=>{FilterByKeyState("Pending", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Pending Transaction</li>
                    <li onClick={()=>{FilterByKeyState("Processing", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Processing Transaction</li>
                    <li  onClick={()=>{FilterByKeyState("Shipped", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Underway Transaction</li>
                    <li  onClick={()=>{FilterByKeyState("Rejected", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Rejected Transaction</li>
                </div> }
            </div>

            <div className={`${classes.transaction_list_wrapper}`}>
                    {
                        DataBuffer.map((item, idx)=>(
                            <div 
                                className={`${classes.transaction_list_inner_single}`} 
                                key={item.ISTRV2_TransactionID + idx}
                                onClick={()=>{OrderPreviewCallback(item)}}
                                >
                                <li style={{fontWeight: 'bold'}}>[TO] {item.BranchName}</li>
                                <li>{item.FirstName} {item.LastName}</li>
                                <li>{ToEthiopianCalander(new Date(item.ISTRV2_RequestInitDate))} / {<TimeAgo show_suffix={true} date={new Date(item.ISTRV2_RequestInitDate)}/>}</li>
                                <li className={`${classes.center_align_span}`}>
                                    <TransferStateIndicator label={item.ISTRV2_RequestState}/>
                                </li>
                            </div>
                        ))
                    }
                    {
                        DataBuffer.length === 0 && 
                        <div className={`${classes.empty_stock_transaction_history}`}>
                            <div>
                                <li>Empty <sub>/ˈɛm(p)ti/</sub></li>
                                <li><sub>adjective</sub></li>
                                <li>1.containing nothing; not filled or occupied.</li>
                            </div>
                        </div>
                    }
                    {
                        Loading && <LoadingPlaceholder preview_text='Loading Transactions' />
                    }
 
            </div>
            <div className={`${classes.dispatched_iniventory_request_bundle_wrapper_footer_action}`}>
                <input onChange={(e)=>{FilterByTransactionID(e.target.value)}} placeholder='Transaction ID' type="text" />
            </div>
             
        </div>
    )
}
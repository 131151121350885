import classes from './EmployeeManager.module.css'
import EmployeeBundleHandler from './Component/EmployeeBundleHandler/EmployeeBudleHandler';
import EmployeeView from './Component/View/EmployeeView';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../AuthProvider';
import { TargetEmployeeProvider } from './Component/View/SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import CreateNewEmployee from './CreateNewEmployee/CreateNewEmployee';
import { FetchWorkspaceBranchAll } from '../../SharedComp/API/Workspace/API_FE_Workspace';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';

export default function EmployeeManager() 
{
    const [OpenEmployeePreview, SetOpenEmployeePreview] = useState(false);
    const [OpenCreateNewEmployeeProfile, SetOpenCreateNewEmployeeProfile] = useState(false);
    const [CompanyBranchs, SetCompanyBranchs] = useState([]);
    const [IncomingLoading, SetIncomingLoading] = useState(false);

    const [selectedBranch, SetSelectedBranch] = useState();
    const [TargetEmployee, SetTargetEmployee] = useState();

    const auth  = useAuth();

    function HandleEmployeeOpenCreator(id) 
    {
        SetSelectedBranch(id ? id.BranchID : -1);
        SetOpenCreateNewEmployeeProfile(true);
    }
    function HandleEmpViewController(shouldOpen, data) 
    {
        SetOpenEmployeePreview(shouldOpen);
        SetTargetEmployee(data);
    }

    const FetchCompanyBranch = async()=>
    {
        SetIncomingLoading(true);
        try 
        {
            const main_res = await FetchWorkspaceBranchAll(auth.token);
            SetCompanyBranchs(main_res)
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }
    useEffect(() => 
    {
        FetchCompanyBranch();
        // fetch(getBaseURL() + 'master-data/get-company-branch')
        //     .then(res => res.json())
        //     .then((data) => {SetCompanyBranchs(data);});
    }, [])



    return (
        <div className={`${classes.Employee_manager_main_wrapper}`}>

          {OpenCreateNewEmployeeProfile && <CreateNewEmployee SelectedBranch={selectedBranch} OpenController={SetOpenCreateNewEmployeeProfile}/>}  
          {OpenEmployeePreview && 
                <TargetEmployeeProvider TargetEmployeeData={TargetEmployee}>
                    <EmployeeView EmpViewController={SetOpenEmployeePreview} />
                </TargetEmployeeProvider>
          }

        <div className={`${classes.employee_bundle_wrapper}`}>
                {!IncomingLoading ? CompanyBranchs.map((item, index) => (
                    auth.userAccessWorkspaceIDList.includes(item.BranchID) && 
                    <EmployeeBundleHandler
                        key={index} 
                        BranchData={item} 
                        EmployeeCreatorWinController={HandleEmployeeOpenCreator} 
                        EmpViewController={HandleEmpViewController}
                      />
                )) : <LoadingPlaceholder/>}
        </div>

        

        </div>
    );
}
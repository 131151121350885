import classes from './UIButton.module.css'
import { Tooltip } from 'react-tooltip'


export default function UIButton({ CountNew = null, Type = "submit", Icon, label, onClick, Variant="Primary", Tip=null }) 
{

    const getButtonClasses = () => {
        switch (Variant) {
            case "Primary":
                return `${classes.__ui_button} ${classes.primary}`;
            case "Secondary":
                return `${classes.__ui_button} ${classes.secondary}`;
            case "Danger":
                return `${classes.__ui_button} ${classes.danger}`;
            case "Disabled":
                return `${classes.__ui_button} ${classes.disabled}`;
            default:
                return `${classes.__ui_button}`;
        }
    };

    const handleClick = (e) => {
        if (Variant === "Disabled") {
            e.preventDefault();
            return;
        }
        onClick();
    };


    return (
        <>
        <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
        <button {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': Tip})} type={Type} className={getButtonClasses()} onClick={handleClick}>
            <span className={`${classes.__ui_icon}`}>{Icon}</span>
            <span className={`${classes.__ui_lable}`}>{label}</span>

           {CountNew &&  <div className={`${classes.top_count}`}>
                <li>{CountNew}</li>
            </div>}
        </button>
        </>
    )
}
import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateFinalBakeryProductRequest = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/create-final-bakery-product-request', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const ApproveFinalBakeryProductRequest = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/approve-final-bakery-product-request', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const RejectFinalBakeryProductRequest = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/reject-final-bakery-product-request', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const QueryPendingFinalBakeryRequest = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/query-pending-final-bakery-product-request', data, GetRouteConfig(token));
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}

export const QueryFinalBakeryProductRequestIncomingAll = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/query-final-bakery-product-incoming-all-request', data, GetRouteConfig(token));
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}
export const QueryFinalBakeryProductRequestOutgoingAll = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/query-final-bakery-product-outgoing-all-request', data, GetRouteConfig(token));
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}
export const QueryTransferByApprover = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/query-transfer-entry-by-approver', data, GetRouteConfig(token));
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}
export const QueryTransferByInitializer = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/query-transfer-entry-by-initializer', data, GetRouteConfig(token));
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}


export const ReceiveAndFinishFinalProductTransfer = async(data, token)=>
{
    try 
    {
        const response = await axios.post(getBaseURL() + 'final-bakery-transfer/receive-and-finalize-final-bakery-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
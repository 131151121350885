import classes from './EmpBank.module.css'
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import UIButton from '../../../../../../Components/UIButton/UIButton'
import { ArrowsDownUp } from '@phosphor-icons/react'
import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig'

import UpdateEmployeeBank from './UpdateEmployeeBank/UpdateEmployeeBank'
import { useTargetEmployee } from '../../../SingleEmployeeContextProvider/SingleEmployeeContextProvider'

export default function EmpBank() 
{
    const sharedData = useTargetEmployee();
    const [OpenBankInfo, SetOpenBankInfo] = useState(false);

    return (
        <div className={`${classes.emp_bank_main_wrapper}`}>

        {OpenBankInfo && <UpdateEmployeeBank OpenController={SetOpenBankInfo} />}
           
           <div className={`${classes.user_selectde_bank_image}`}>
                <img  src={getBaseURL() + sharedData.Core.HRMBNKT_Logo} alt={sharedData.Core.HRMBNKT_Name} />
           </div>

           <div className={`${classes.user_bank_info_wrapper}`}>
                <h4>{sharedData.Core.HRMBNKT_Name}</h4>
                <li>{sharedData.Core.JAKBECP_BankAccount}</li>

                <div className={`${classes.action_button_wrapper_bank_edit}`}>
                    <UIButton onClick={()=>{SetOpenBankInfo(true)}} Icon={<ArrowsDownUp weight='bold'/>} label={"Change"}/>
                </div>
           </div>   


        </div>
    )
}
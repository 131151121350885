import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const FetchOrderableBakeryProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/fetch-bakery-products-orderbale-for-x-branch', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBakeryGroupAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/query-all-bakery-group', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const CreateBakeryGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/create_bakery_group', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBakeryGroup = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/update_bakery_group', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const PurgeBakeryGroupByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/purge_bakery_group_by_id', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////// BAKERY PRODUCTS NOT GROUP
export const CreateBakeryProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/create-bakery-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBakeryProduct = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/update-bakery-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const FetchBakeryProductAll = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/fetch-bakery-products-all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBakeryProductByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/purge-bakery-product', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBakeryProductByID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/fetch-bakery-products-by-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBakeryProductByRecipe = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'bakery-cluster/fetch-bakery-products-by-recipe', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
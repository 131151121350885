import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'


function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}

export const CreateNewUserAccess = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'auth/account-access/user-access-create', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserAccess = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'auth/account-access/user-access-update', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateMainMenuPackage = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'auth/account-access/update-menu-pkg', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const SendVerificationMail = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'auth/account-access/send-verification-mail', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CheckEmailVerificationCode = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'auth/account-access/check-email-verification', data, GetRouteConfig(token));
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
import classes from './ShippingDialog.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'

import QRCode from 'react-qr-code'
import { useEffect, useRef } from 'react'
import { useState } from 'react'

import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'
import { useToast } from '../../../../../ToastContext'

import {ShipProducedCkProduct, QueryRequesterInfoByGroup, QueryShippedGroupWithDispatcherInfo, QueryShippedProductGroupByReqGroup} from '../../../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import {QueryShippedProductGroupByGroupID, QueryCustomerWithCasherInfo} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import { Star } from '@phosphor-icons/react'

export default function ShippingDialog({Close_CALLBACK, DataList, GroupData, UpdateView, UpdateViewToggel, Preview = false})
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();
    const [DispatcherInfo, SetDispatcherInfo] = useState();
    const [OrderGroup, SetOrderGroup] = useState(null);
    const [RequesterInfo, SetRequesterInfo] = useState(null);
    const [IsSpecial, SetIsSpecial] = useState(DataList[0]?.KOPREOPKWS_IsSpecial === "Yes");
    const [OrderRecieverInfo, SetOrderRecieverInfo] = useState(null);

    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_production_shipment" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })


    // This only happend if only this is in the privew mode

    const FetchRequesterInfo = async(group_id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("req_group_id", group_id);
            const main_res = await QueryRequesterInfoByGroup(fd, auth.token);
            SetRequesterInfo(main_res[0]);
        }catch(err) 
        {
            notify.ShowError("Failed to Fetch Rquester Info " + err.message);
        }
    }

    const FetchDisptcherInfo = async(_group_id) =>
    {

        try 
        {
            const fd = new FormData();
            fd.append("group_id", _group_id);
            const main_res = await QueryShippedGroupWithDispatcherInfo(fd, auth.token);
            SetDispatcherInfo(main_res[0]);
        }catch(err) 
        {
            notify.ShowError("Failed to Fetch Disptcher Info " + err.message);
        }
    }

    const QuerySpecialOrderReceiver = async(info_id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("info_id", info_id);
            const main_res = await QueryCustomerWithCasherInfo(fd, auth.token);
            SetOrderRecieverInfo(main_res[0]);

        }catch(err) 
        {
            console.log("Failed to QuerySpecialOrderReceiver" + err.message);
        }
    }

    const FetchProductDetailGroup = async(parent_id)=>
    {
        try 
        {
            await FetchRequesterInfo(parent_id);
            const fd = new FormData();
            fd.append("group_parent_id", parent_id);
            const main_res = await QueryShippedProductGroupByGroupID(fd, auth.token);
            SetOrderGroup(main_res[0]);
        }catch(err) 
        {
            notify.ShowError("Failed to Fetch Disptcher Info " + err.message);
        }
    }
      

    useEffect(()=>
    {
        if(Preview && OrderGroup) 
        {
            FetchDisptcherInfo(OrderGroup?.KOPSRG_ID);
        } 
        
    }, [Preview, OrderGroup])

    useEffect(()=>{

        if(DataList.length > 0) 
        {
            FetchProductDetailGroup(DataList[0].KOPSRI_ParentGroupID);
        }
        if(DataList.length === 1) 
        {
            if(DataList[0].KOPREOPKWS_IsSpecial === "Yes") 
            {
                QuerySpecialOrderReceiver(DataList[0].PROCIID);
            }
        }
    }, [DataList])

    const HandleShipment = async()=>
    {

        notify.ShowLoad("Processing your request...", 10);

        setTimeout(async()=>{
            try 
            {
    
                const fd = new FormData();
                // fd.append("request_group_id", GroupData.CKAOGID);
                // fd.append("report_list", JSON.stringify(DataList));
                // fd.append("request_group_hash", GroupData.CKAOG_Hash);

                // fd.append("request_sender_workspace_id", auth.activeWorkShop);
                // fd.append("request_receiver_workspace", GroupData.BranchID);

                // fd.append("user_account_id", auth.user.UserAccountID);

                fd.append("shippment_group_id", OrderGroup.KOPSRG_ID);
                fd.append("base_group_id", OrderGroup.KOPSRG_OriginalGroupID);
                fd.append("action_taker", auth.user.UserAccountID);

                const main_res = await ShipProducedCkProduct(fd, 'token');
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                Close_CALLBACK(false);
                UpdateView(!UpdateViewToggel);
    
            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }, 1500)
        
    }



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>{Preview ? "Dispatch Preview" : "Production Dispatcher"} </span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_preview_wrapper}`}>

            <div className={`${classes.inner_preview_data_wrapper}`}>
                <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                    <PaperHeader/>
                    <PaperHash text={Preview ? "COPY - NOT ORIGINAL" : null}/>
                    <div className={`${classes.meta_data_wrapper}`}>
                        <h2>[#] Destination / {RequesterInfo?.BranchName} </h2>
                        <h2>[#] Production Dispatch / {Preview ? DispatcherInfo?.BranchName : auth.activeWorkShopName}</h2>
                    </div>

                    <div className={`${classes.transcation_owner_wrapper}`}>
                        <div className={`${classes.transcation_owner}`}>

                            <div className={`${classes.transcation_init}`}> 
                                <h2>Source Information</h2>
                                <li>Branch: {RequesterInfo?.BranchName}</li>
                                <li>Name: {RequesterInfo?.FirstName} {RequesterInfo?.LastName}</li>
                                <li>Role: {RequesterInfo?.RoleNameLazy}</li>
                                <li>Order Date: {new Date(RequesterInfo?.KOPREOPKWS_OrdereTimeStamp).toDateString()}</li>
                               
                            </div>
                            <div className={`${classes.transcation_recive}`}>
                                <h2>Dispatcher Information</h2>
                                <li>Branch:  {Preview ? DispatcherInfo?.BranchName : auth.activeWorkShopName}</li>
                                <li>Name: {Preview ? DispatcherInfo?.FirstName + " " + DispatcherInfo?.LastName : auth.user.FirstName + " " + auth.user.LastName}</li>
                                <li>Role: {Preview ? DispatcherInfo?.UARI_Name : auth.user.RoleNameLazy}</li>
                                <li>Shipment Date: {Preview ? new Date(DispatcherInfo?.KOPSRG_TimeStamp).toDateString() :  new Date().toDateString()}</li>
                            </div>

                        </div>
                    </div>

                    <div className={`${classes.entry_detail}`}>
                        {/* <li><span className={`${classes.entry_deatil_tag}`}>Dispatch ID</span> <span className={`${classes.entry_deatil_val}`}>{GroupData.CKAOG_Hash}</span></li> */}
                    </div>  
                    
                    
                    <div className={`${classes.main_paper_data_wrapper}`}>

                        <div className={`${classes.data_marging_wrapper}`}>
                            <li className={`${classes.data_header_list}`}>
                                <span>Name</span>
                                <span>Requested</span>
                                <span>Delivered</span>
                                <span>Rating</span>
                                <span>Request Status</span>
                            </li>
                          {
                            DataList.map((item, idx)=>(
                                <li key={idx}>
                                    <span className={`${classes.text_left}`}>{idx+1}. {item.CKP_ItemName} {item.KOPREOPKWS_IsSpecial === "Yes" ? <Star weight='fill'/> : ''}</span>
                                    <span>{item.KOPREOPD_OrderedQuantity}</span> 
                                    <span>{item.KOPSRI_BatchQuantity} Sheet - {item.KOPSRI_ModSliceCount} Slice </span>
                                    <span>{((item.KOPSRI_BatchQuantity / item.KOPREOPD_OrderedQuantity).toFixed(1) * 100).toFixed(1) + '%'}  </span>
                                    <span  className={`${classes.text_right}`}>{item.KOPREOPD_OrderedQuantity > 0 ? "Accepted" : "Rejected" }</span>
                                </li>
                            ))
                          }
                        </div>

                        {(IsSpecial && DataList.length === 1) && <div className={`${classes.data_marging_wrapper_special}`}>
                            <div className={`${classes.special_order_detail}`}>
                                <h2>Special Order Detail</h2>
                            </div>
                            <div className={`${classes.special_detail_order_list_wrapper}`}>
                                <li><span className={`${classes.info_tag}`}>Received By:-</span> <span className={`${classes.info_tag_value}`}>{OrderRecieverInfo?.FirstName} {OrderRecieverInfo?.LastName} / {OrderRecieverInfo?.RoleNameLazy}</span></li>
                                <li><span className={`${classes.info_tag}`}>Customer Name:-</span> <span className={`${classes.info_tag_value}`}>{DataList[0].PROCI_Name}</span></li>
                                <li><span className={`${classes.info_tag}`}>Customer Phone:-</span> <span className={`${classes.info_tag_value}`}>{DataList[0].PROCI_Phone}</span></li>
                                <li><span className={`${classes.info_tag}`}>Scripture Request:-</span> <span className={`${classes.info_tag_value}`}>{DataList[0].PROPPI_Scripture}</span></li>
                                <li><span className={`${classes.info_tag}`}>Color Request:-</span> <span className={`${classes.info_tag_value}`}>{DataList[0].PROPPI_Color}</span></li>
                            </div>
                            
                        </div>}
                        
                    </div>

                    <div className={`${classes.entry_detail_code}`}>
                            <div className={`${classes.scan_data}`}>
                               <QRCode
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    fgColor={'var(--background-dark)'}
                                    value={OrderGroup?.KOPSRG_TranscationHash ? OrderGroup.KOPSRG_TranscationHash : '--'}
                                    viewBox={`0 0 256 256`}
                                    /> 
                            </div>
                            <div className={`${classes.scan_data_connect}`}>

                            </div>
                            <div className={`${classes.scan_data_text}`}>
                                <li>KEY: {OrderGroup?.KOPSRG_TranscationHash ? OrderGroup.KOPSRG_TranscationHash : '--'}</li>
                            </div>
                        </div>

                    <div className={`${classes.special_footer_local_wrapper}`}>

                        <div className={`${classes.footer_user_wrap}`}>
                            <h2>Shipment Digital Authorization</h2>
                            <li className={`${classes.name_manual}`}>{Preview ? DispatcherInfo?.FirstName + " " + DispatcherInfo?.LastName : auth.user.FirstName + " " + auth.user.LastName}</li>
                            <li className={`${classes.print_date}`}>Date: {Preview ? new Date(DispatcherInfo?.KOPSRG_TimeStamp).toDateString() :  new Date().toDateString()}</li>
                            <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                        </div>

                        <div className={`${classes.footer_user_wrap}`}>
                            <h2>Production Manager</h2>
                            <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                            <li className={`${classes.print_date}`}>Date: {Preview ? new Date(DispatcherInfo?.KOPSRG_TimeStamp).toDateString() :  new Date().toDateString()}</li>
                            <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                        </div>

                        <div className={`${classes.footer_user_wrap}`}>
                            <h2>Driver</h2>
                            <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                            <li className={`${classes.print_date}`}>Date: {Preview ? new Date(DispatcherInfo?.KOPSRG_TimeStamp).toDateString() :  new Date().toDateString()}</li>
                            <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                        </div>

                    </div>

                    <div className={`${classes.final_note_po_stock_out}`}>
                        <li>This document must contain all signatures in place to be considered valid.</li>
                    </div>

                </div>
            </div>



            </div>


            <div className={`${classes.action_button_wrapper}`}>

                <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>
               {!Preview && <button onClick={()=>{
                                    DAControl.Trigger(`Ship Product`, 
                                    `You are about to ship a product. Please ensure that all order items are completed correctly, as they will be hidden after this operation.`,
                                    true,
                                    1, (val)=>{
                                        if(val !== null) 
                                        {
                                            HandleShipment();
                                        }
                                    }   
                                );}} className={`${classes.button_approve}`}>Ship Package</button> }
                <button onClick={()=>{Close_CALLBACK(false)}}>Close</button>

            </div>

        </div>
    </div>
    )
}
import classes from './BranchCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Custom_DD_Style, PrepareDropDownList } from '../../../../SharedComp/Utility'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput';
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import Select from 'react-select'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import { FetchBranchAll } from '../../../../api';
import { useAuth } from '../../../../AuthProvider';
import { useToast } from '../../../../ToastContext'
import { CreateNewWorkspace } from '../../../../SharedComp/API/Workspace/API_FE_Workspace';

export default function BranchCreator({Close_CALLBACK}) 
{

    const notify = useToast();
    const auth = useAuth();
    const [registring, Setregistring] = useState(false);
    const formRef = useRef();

    const [ExistBranchList, SetExistBranchList] = useState([]);
    const [CloneExistingBrancgConfig, SetCloneExistingBrancgConfig] = useState(false);
    const [SiteName, SetSiteName] = useState();
    const [SiteType, SetSiteType] = useState();
    const [SiteAddress, SetSiteAddress] = useState();
    const [SiteCoordinates, SetSiteCoordinates] = useState();
    const [SlackCashCollection, SetSlackCashCollection] = useState('');
    const [SlackOther, SetSlackOther] = useState('');
    const [CloneTargetBranch, SetCloneTargetBranch] = useState('');


    const expenseReasonOptions = [
        { value: 'Production Center', label: 'Production Center' },
        { value: 'Outlet', label: 'Outlet' },
        { value: 'Office', label: 'Office' },
        { value: 'Storage Unit', label: 'Storage Unit' },
      ];


    const QueryExistingWorkspace = async()=>
    {
        try 
        {
            const main_Res = await FetchBranchAll(auth.token);
            PrepareDropDownList(main_Res, SetExistBranchList);
        }catch(err) 
        {

        }
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing your request...", 10);

        try 
        {
            const fd = new FormData();
            fd.append("SiteName", SiteName);
            fd.append("SiteType", SiteType);
            fd.append("SiteAddress", SiteAddress);
            fd.append("siteCoord", SiteCoordinates);
            fd.append("cash_collection_slack", SlackCashCollection);
            fd.append("other_slack", SlackOther);
            fd.append("clone_branch_target", CloneTargetBranch);
            fd.append("enable_clone", CloneExistingBrancgConfig);

            const main_res = await CreateNewWorkspace(fd, auth.token);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            Close_CALLBACK(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message);
        }

        

        // axios.post(getBaseURL() + 'master-data/add-company-branch', fd)
        // .then(res => { RES_CALLBACK(res)})
        // .catch(err => {
        //     console.log(err);
        //    // RES_CALLBACK(res);
        // })
    }

    useEffect(()=>{
        QueryExistingWorkspace();
    }, [])
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Establishment X</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                <div className={`${classes.data_body}`}>

                        <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>

                                            <div className={`${classes.input_package_wrapper}`}>
                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput input={<input onChange={(e)=>{SetSiteName(e.target.value)}} name='site_name' type="text" placeholder='Site Name' title='' autoComplete='off' required />} />
                                                </div>
                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput input={<input onChange={(e)=>{SetSiteAddress(e.target.value)}} name='site_address' type="text" placeholder='Address' title='' autoComplete='off' required />} tip={"Location, Where is it?"}/>
                                                </div>
                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput input={<input onChange={(e)=>{SetSiteCoordinates(e.target.value)}} name='site_address' type="text" placeholder='Satellite coordinates' title='' autoComplete='off' required />} tip={"To find the coordinates, open Google Maps, locate the desired location, right-click on it, select the coordinate numbers, and paste them here."}/>
                                                </div>
                                                

                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput 
                                                        input={
                                                            <Select
                                                                onChange={(data)=>{SetSiteType(data.value)}}
                                                                name={"dd_data"}
                                                                options={expenseReasonOptions}
                                                                placeholder={"Type..."}
                                                                isSearchable={true}
                                                                styles={Custom_DD_Style}
                                                                required ={true}
                                                            />
                                                        
                                                    } tip={"Select Establishment Type"}  forceInput={true}/>
                                                </div>
                                                
                                            </div>
                                           
                                            <div className={`${classes.input_package_wrapper}`}>
                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput 
                                                        input={<input 
                                                            onChange={(e)=>{SetSlackCashCollection(e.target.value)}} 
                                                            type="text" 
                                                            placeholder='Specify Slack channel (CASH-COLLECTION)' 
                                                            title='' 
                                                            autoComplete='off'/>} tip={"Enter the Slack channel where cash collection reports will be sent. Please ensure the channel name is correct, as incorrect names will result in notifications not being delivered."}/>
                                                </div>
                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput 
                                                        input={<input 
                                                            onChange={(e)=>{SetSlackOther(e.target.value)}} 
                                                            type="text" 
                                                            placeholder='Specify Slack channel (OTHER ACTIVITY)' 
                                                            title='' 
                                                            autoComplete='off' />} tip={"Enter the Slack channel where Other ERP activity is report will be sent. Please ensure the channel name is correct, as incorrect names will result in notifications not being delivered."}/>
                                                </div>
                                                <div onClick={()=>{SetCloneExistingBrancgConfig(!CloneExistingBrancgConfig)}} className={`${classes.input_wrap_special_cloner}`}>
                                                        <li>Clone Existing Branch Configuration</li>
                                                        <input 
                                                            checked={CloneExistingBrancgConfig} 
                                                            onChange={(e)=>{SetCloneExistingBrancgConfig(e.target.checked)}} type="checkbox" />
                                                </div>
                                            {
                                                CloneExistingBrancgConfig &&
                                                <div className={`${classes.input_wrap}`}>
                                                    <MasterDataRegInput 
                                                        input={
                                                            <Select
                                                                onChange={(data)=>{SetCloneTargetBranch(data.value)}}
                                                                name={"dd_data"}
                                                                options={ExistBranchList}
                                                                placeholder={"From Branch..."}
                                                                isSearchable={true}
                                                                styles={Custom_DD_Style}
                                                                required={true}
                                                            />
                                                        
                                                    } tip={"Select a workspace which you want to clone the config with"}  forceInput={true}/>
                                                </div>

                                            }
                                            </div>

                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Create'}</button>
                                    <button disabled={registring}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>

                </div>

            </div>

        </div>
    );
}
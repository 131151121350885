import data_win from './DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-tooltip'
import { Cursor, CursorText, RadioButton, SealCheck, Terminal } from '@phosphor-icons/react'
export default function MasterDataRegInput({TickLable = false, LableText = null, Icon = <RadioButton weight="bold"/>,  input, tip, forceInput}) 
{
    return (
        <>
       {LableText && <label className={`${data_win.form_input_child_text_label}`} htmlFor="#">{LableText} {TickLable && <span className={`${data_win.lable_tick}`}><SealCheck size={15} weight='fill'/></span> } </label>} 
        <Tooltip className={`${data_win.tool_quick}`} id="offical_doc2" place="top" />
        <div className={`${data_win.form_input_with_icon} ${forceInput ? data_win.form_input_with_icon_force_input : ''}`} {...({'data-tooltip-id': 'offical_doc2', 'data-tooltip-content': tip})}>
            <div className={`${data_win.form_input_child_icon_wrapper}`}>
                <span className={`${data_win.form_input_icon}`}>
                   {Icon} 
                </span>
            </div>
            <div className={`${data_win.form_input_child_wrapper}`}>
                {input}
            </div>
        </div>
        </>
    )
}
import { faShirt, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './RightSetting.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../../../AuthProvider'
import {KeyColorSchemeBuffer, PaintColorSchemeBuffer} from '../../../ColorScheme'
import Select from 'react-select'

import MasterDataRegInput from '../../../CommonStyle/MasterDataRegInput'
import {Custom_DD_Style} from '../../../Utility'
import { Tooltip } from 'react-tooltip'

import { UpdateUserKeyColorChoice, UpdateUserPaintColorChoice, UpdateUserUIScaleChoice } from '../../../API/UserAccess/API_FE_UserManage'
import { useToast } from '../../../../ToastContext'

export default function RightSetting({IsOpen, Close_CALLBACK}) 
{

    const auth = useAuth();
    const notify = useToast();

    const UIScaleOptions = [
        { value: 1, label: 'Small' },
        { value: 1.25, label: 'Medium' },
        { value: 1.5, label: 'Large' },
        { value: 2, label: 'Extra Large' },
      ];
      


    const HandleKeyColorUpdate = async(val)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("theme_idx", val);
            fd.append("user_id", auth.user.UserAccountID);
            const main_res = await UpdateUserKeyColorChoice(fd, auth.token);
        }catch(err) 
        {
            notify.ShowWarn("Your Theme Choice is not updated!");
        }
    }
    const HandleUIScaleUpdate = async(val)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("scale_factor", val);
            fd.append("user_id", auth.user.UserAccountID);
            const main_res = await UpdateUserUIScaleChoice(fd, auth.token);
        }catch(err) 
        {
            notify.ShowWarn("Your Theme Choice is not updated!");
        }
    }
    const HandlePaintColorUpdate = async(val)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("theme_idx", JSON.stringify(val));
            fd.append("user_id", auth.user.UserAccountID);
            const main_res = await UpdateUserPaintColorChoice(fd, auth.token);
        }catch(err) 
        {
            notify.ShowWarn("Your Theme Choice is not updated!");
        }
    }

    return (
        <div className={`${classes.right_settion_main_wrapper} ${IsOpen ? '' : classes.right_settion_main_wrapper_hide}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.right_option_top}`}>
                <h2>System</h2>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            { auth.userAccessworkshopList.length > 1 &&<div className={`${classes.workspace_access_list}`}>
             <div className={`${classes.usr_option_inner_wrapper_access}`}>
                    <li>Select workspace</li>
                    <MasterDataRegInput 
                            input={
                                <Select
                                    onChange={(data)=>
                                        {
                                            auth.setActiveworkshop(data.value); 
                                            auth.setActiveWorkShopName(data.label)
                                        }}
                                    name={"dd_data"}
                                    options={auth.userAccessworkshopList}
                                    placeholder={"Workspace.."}
                                    isSearchable={true}
                                    styles={Custom_DD_Style}
                                    required ={true}
                                    />
                                } />

                </div>
            </div>}

            <div className={`${classes.color_scheme_wrapper}`}>
                                    
                <div className={`${classes.key_color_scheme_wrapper}`}>
                    {
                        KeyColorSchemeBuffer.map((item, idx)=>(
                            <li 
                                key={idx+item.Name} 
                                {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': item.Name})} 
                                onClick={()=>{
                                    auth.SetAccentColor(item.Value); 
                                    HandleKeyColorUpdate(item.Value)}} 
                                style={{background: `${item.Value}`}}
                                className={`${classes.color_prview}`}> </li>
                        ))
                    }
                </div>
                <div className={`${classes.apint_color_scheme_wrapper}`}>
                    {
                        PaintColorSchemeBuffer.map((item, idx)=>(
                            <li 
                                key={idx+item.Name} 
                                onClick={()=>{
                                    auth.SetSurfaceColor(item.Value);
                                    HandlePaintColorUpdate(item.Value);
                                }}>
                                    {item.Name}
                            </li>
                        ))
                    }
                </div>
                <div className={`${classes.workspace_access_list}`}>
                <div className={`${classes.usr_option_inner_wrapper_access}`}>
                        <li>Choose UI Scale</li>
                        <MasterDataRegInput 
                                input={
                                    <Select
                                        onChange={(data)=>
                                            {
                                                auth.UpdateUIScale(data.value); 
                                                HandleUIScaleUpdate(data.value);
                                            }}
                                        name={"dd_data"}
                                        options={UIScaleOptions}
                                        placeholder={"UI/UX Scale.."}
                                        isSearchable={true}
                                        styles={Custom_DD_Style}
                                        required ={true}
                                        />
                                    } />

                    </div>
                </div>

            </div>

        </div>
    )
}
import classes from './OrderSubGroup.module.css'

import UIButton from '../../../../../Components/UIButton/UIButton'
import { Stack, Star, Van } from '@phosphor-icons/react'

import TimeAgo from '../../../../../../SharedComp/TimeElapsed/TimeAgo'


import ShippingDialog from '../../ShippingDialog/ShippingDialog'
import { useState } from 'react'

export default function OrderSubGroup({SubDataList, _UpdateViewToggel, _UpdateView})
{

    const [OpenShippingDialog, SetOpenShippingDialog] = useState(false);


    return (
        <div className={`${classes.group_body_wrapper}`}>

            {OpenShippingDialog && <ShippingDialog UpdateViewToggel={_UpdateViewToggel} UpdateView={_UpdateView} DataList={SubDataList} Close_CALLBACK={SetOpenShippingDialog}/>}
            
            <div className={`${classes.sub_group_data_list_wrapper}`}>
                {
                    SubDataList?.map((item, idx)=>(
                        <li key={item.CKP_ItemName}>
                            <span>{item.CKP_ItemName} {item.KOPREOPKWS_IsSpecial === "Yes" ? <Star weight='fill'/> : ''}</span>
                            <span className={`${classes.quantity_data_wrapper}`}>
                                <span className={`${classes.total_amount_wrapper}`}>Ordered: {item.KOPREOPD_OrderedQuantity} </span>
                                <span>Produced: {item.KOPSRI_BatchQuantity} </span>
                            </span>
                            <span className={`${classes.action_button_wrapper_group}`}>
                                <UIButton Variant='Secondary' onClick={()=>{}} Tip={"Response Accuracy"} Icon={<Stack/>} label={((item.KOPSRI_BatchQuantity /  item.KOPREOPD_OrderedQuantity).toFixed(1) * 100).toFixed(1) + '%'} />
                            </span> 
                        </li>
                    ))
                }
            </div>

            <div className={`${classes.sub_group_action_wrapper}`}>
                <div className={`${classes.date_info_action_wrap_sub_group}`}>
                   <li>Ordered Date: <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': `${new Date(SubDataList[0].KOPREOPKWS_OrdereTimeStamp).toDateString()}`})} className={`${classes.tag_val}`}>{<TimeAgo show_suffix={true} date={SubDataList[0].KOPREOPKWS_OrdereTimeStamp} />}</span> </li> 
                   <li>Expected Date: <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': `${new Date(SubDataList[0].KOPREOPKWS_ExpectedTimeStamp).toDateString()}`})} className={`${classes.tag_val}`}>{<TimeAgo show_suffix={true} date={SubDataList[0].KOPREOPKWS_ExpectedTimeStamp} />}</span></li>
                </div>
                <div>
                    <UIButton onClick={()=>{SetOpenShippingDialog(true)}} label={"Ship Package"} Icon={<Van weight='fill'/>}/>
                </div>
            </div>

        </div>
    )
}
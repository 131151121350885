import { getBaseURL } from "../../BaseUrlConfig";
import axios from 'axios'

function GetRouteConfig(token)
{
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
}


export const QueryMasterProdCatValidToPreorder = async(token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'desk_view/get_ck_prod_type_all', {}, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveOrderByBranch = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'desk_view/query-order-active-order', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const QueryActivePendingPayments = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'desk_view/query-active-pending-payment', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveOrderBakeryByCustomerID = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'desk_view/query-order-bakery-by-customer-id', data, GetRouteConfig(token));
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveOrderPastryByCustomerID = async(data, token)=>
    {
        try  
        {
            const response = await axios.post(getBaseURL() + 'desk_view/query-order-pastry-by-customer-id', data, GetRouteConfig(token));
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const CreateMasterPreOrder = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'desk_view/create-pre-order-master', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const WrapUpPreorder = async(data, token)=>
{
    try  
    {
        const response = await axios.post(getBaseURL() + 'desk_view/wrap-up-pre-order-cashier', data, GetRouteConfig(token));
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
import { useEffect, useState } from 'react'
import classes from './RecipeCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'
import { faChevronCircleDown, faChevronCircleUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import SpecialOrderDetailView from './SpecialOrderDetailView/SpecialOrderDetailView'

import {QueryVariantIng, CreateChefsStockRequestFromRecipe} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'

import UIButton from '../../../../Components/UIButton/UIButton'

import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext' 
import { ArrowCircleDown, ArrowCircleUp, ArrowCircleUpRight, ArrowUp, CheckCircle, Info, PaperPlaneRight, QuestionMark, XCircle } from '@phosphor-icons/react'
import { useDAControl } from '../../../../../DAControlContext'
const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function RecipeCard({TempStockStorage,  TargetData, DataRef})
{

    const [IngList, SetIngList] = useState([]);
    const [InvalidIngMap, SetInvalidIngMap] = useState(new Map());
    const [ScanStockBuffer, SetScanStockBuffer] = useState(false);
    const [AutoStartAfterAuth, SetAutoStartAfterAuth] = useState(false);
    const [ShowDetailCardInfo, SetShowDetailCardInfo] = useState(false);
    const [ActiveSheetCount, SetActiveSheetCount] = useState(TargetData.KODI_DispatchedAmount - TargetData.KODI_ProcessedAmount);
    const [IsSpecialOrder, SetIsSpecialOrder] = useState(TargetData?.KOPREOPKWS_IsSpecial === "Yes");
    const [OpenOrderDetailView, SetOpenOrderDetailView] = useState(false);

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();


    const QueryIng = async()=>
    {
        try 
        {
            const main_res = await QueryVariantIng(TargetData.CKP_ItemVarianceID);
            SetIngList(main_res);

        }catch(err) 
        {

        }
    }
    const HandleIncreaseActveSheet = ()=>
    {
        if(ActiveSheetCount < (TargetData.KODI_DispatchedAmount - TargetData.KODI_ProcessedAmount)) 
        {
            SetActiveSheetCount(ActiveSheetCount + 1);
        }
    }
    const HandleDecreaseActveSheet = ()=>
    {
        if(ActiveSheetCount > 1) 
        {
            SetActiveSheetCount(ActiveSheetCount - 1);
        }
    }

    const HasAvialResult = (name, req_amount)=>
    {
        if(!TempStockStorage)
        {
            return false;
        }
        
        for(let i = 0; i < TempStockStorage.length; i++) 
        {
            if(TempStockStorage[i].IVItemName === name) 
            {
                if(TempStockStorage[i].KSBID_Quantity >= (ActiveSheetCount * req_amount)) 
                {
                    
                    return true;
                }
            }
        }

        return false;
    }

    useEffect(()=>{

    }, [TempStockStorage])

    const PostRunIngCheck = () =>
    {
            // Check Defects
            let _has_defect = false;
            for(let i = 0; i < IngList.length; i++) 
            {
                const _defect = HasAvialResult(IngList[i].IVItemName, IngList[i].KV_InvQuantity);
                if(!_defect) 
                {
                    _has_defect = true;
                    break;
                }
            }

            if(_has_defect) 
            {
                DAControl.Trigger(
                    `Low Stock Balance Detected`,
                    `Inventory levels are currently low for some stock items. The system will initiate a stock request for the remaining items. Until the request is fulfilled, this production item will not be displayed in the production stack.`,
                    true,
                    3,
                    (data)=>
                    {
                        if(data) 
                        {
                            HandleStockRequest();
                        }
                    }
                )

            } else 
            {
                HandleStockRequest();
            }

    }

    const HandleStockRequest = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("Ing_list", JSON.stringify(IngList));
            fd.append("Rejected_Ing_List", JSON.stringify(Array.from(InvalidIngMap.entries())));
            fd.append("scan_local_buffer", ScanStockBuffer);
            fd.append("auto_order", AutoStartAfterAuth);
            fd.append("sheet_count", ActiveSheetCount);
            fd.append("handler_id", auth.user.UserAccountID);

            fd.append("dispatch_id", TargetData.KODIID);
            fd.append("current_processed_dispatch_count", TargetData.KODI_ProcessedAmount);
            fd.append("preorder_prod_id", TargetData.KODI_PreorderProductID);
            fd.append("assigned_chef", TargetData.KODI_AssignedChefID);
            fd.append("host_branch", TargetData.KODI_HostBranchID);
            fd.append("dispatcher_id", TargetData.KODI_DispatcherID);


            fd.append("handler_workshop", auth.activeWorkShop);
            fd.append("order_prod_hash", TargetData.CKDPC_OrderProdHash);
            fd.append("prod_ck_id", TargetData.CKDPC_ProdCKID); 
            fd.append("dispatch_hash", TargetData.CKDPC_DispatchHash); 
            fd.append("active_order_group_id", TargetData.CKDPC_ActiveOrderGroupID);
            fd.append("distination_workspace", TargetData.CKDPC_DestinationWorkspaceID);

            const main_res = await CreateChefsStockRequestFromRecipe(fd, 'token');

            notify.ShowSuccess(main_res.Msg);
            DataRef();

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const HandleToggelInvalidation = (item)=>
    {
        const _exist  = InvalidIngMap;
        if(_exist.has(item.IVItemName)) 
        {
            _exist.delete(item.IVItemName);
        } else {
            _exist.set(item.IVItemName, item)
        }

        SetInvalidIngMap(new Map(_exist));
    }

    const IsInvalid = (__key)=>
    {
        return InvalidIngMap.has(__key);
    }

    useEffect(()=>{
        QueryIng();
    }, [])


    return (
        <div className={`${classes.recipe_card_main_wrapper} ${IsSpecialOrder ? classes.emulate_sublimi : ''}`}>

            {OpenOrderDetailView && <SpecialOrderDetailView DV_TargetData={TargetData} OpenController={SetOpenOrderDetailView} />}

            <div className={`${classes.recipe_card_header}`}>
                <h2>{TargetData.CKP_ItemName} <sub>Recipe Card</sub>  </h2>
                {IsSpecialOrder &&  <h3 onClick={()=>{SetOpenOrderDetailView(true)}}> <span className={`${classes.local_buffer_count}`}> <ArrowCircleUpRight weight='bold'/> </span></h3>}
            </div>

            <div className={`${classes.recipe_card_ing_list}`}>

                {
                    IngList.map((item, idx)=>
                    (
                        <li onClick={()=>{HandleToggelInvalidation(item)}} key={idx} className={`${HasAvialResult(item.IVItemName, item.KV_InvQuantity) ? '' : classes.ban_stock_out}`}>
                            <span className={`${classes.recipe_card_tag}`}>{item.IVItemName}</span>
                            <span className={`${classes.recipe_card_val}`}>
                                {
                                   item.UomDescription === "KG" ?  currency_format((item.KV_InvQuantity * 1000) * ActiveSheetCount) 
                                    : currency_format(item.KV_InvQuantity * ActiveSheetCount)
                                } {item.UomDescription === "KG" ? "Gram" : item.UomDescription} 
                            </span>

                            <span className={`${ HasAvialResult(item.IVItemName, item.KV_InvQuantity) ? classes.recipe_invt_check : classes.recipe_invt_x }`}>  
                               {HasAvialResult(item.IVItemName, item.KV_InvQuantity) ? <CheckCircle weight='bold' size={16}/> : <XCircle weight='bold' size={16}/>  } 
                            </span>
                        </li>
                    ))
                }
                    
            </div>

            <div className={`${classes.crad_action_wrapper}`}>
    
                <div className={`${classes.show_beh} ${classes.card_action_button}`}>
                    <li> 
                            <span onClick={()=>{HandleIncreaseActveSheet()}} className={`${classes.sheet_amount_manip}`}><ArrowCircleUp weight='bold'/></span> 
                            <span className={`${classes.sheet_amount_active}`}>{ActiveSheetCount}</span> 
                            <span onClick={()=>{HandleDecreaseActveSheet()}} className={`${classes.sheet_amount_manip}`}><ArrowCircleDown weight='bold'/></span>
                    </li>
                    <button onClick={()=>{SetShowDetailCardInfo(!ShowDetailCardInfo)}}>Show {ShowDetailCardInfo ? "Less" : "More"}</button>
                </div>

                <div className={`${ShowDetailCardInfo ? classes.sheet_count : classes.sheet_count_hide}`}>
                    <li onClick={()=>{SetAutoStartAfterAuth(!AutoStartAfterAuth)}} className={`${classes.scan_local_buffer}`}><span>Initiate upon approval</span> <span className={`${classes.sheet_tag_input}`}> <input onChange={()=>{}} checked={AutoStartAfterAuth} type="checkbox" /> </span></li>
                    {/* <li onClick={()=>{SetScanStockBuffer(!ScanStockBuffer)}} className={`${classes.scan_local_buffer}`}><span>Scan Stock Buffer</span> <span className={`${classes.sheet_tag_input}`}> <input onChange={()=>{}} checked={ScanStockBuffer} type="checkbox" /> </span></li> */}
                    <li><span>Total Dispatched Sheet Count</span> <span className={`${classes.sheet_tag_val}`}>{TargetData.KODI_DispatchedAmount} Pcs</span></li>
                    <li><span>Processed Sheet</span> <span className={`${classes.sheet_tag_val}`}>{TargetData.KODI_ProcessedAmount} Pcs</span></li>
                    <li><span>Remaining Sheet</span> <span className={`${classes.sheet_tag_val}`}>{TargetData.KODI_DispatchedAmount - TargetData.KODI_ProcessedAmount} Pcs</span></li>
                    <li><span>Dispatcher</span> <span className={`${classes.sheet_tag_val}`}>{TargetData.FirstName} {TargetData.LastName}</span></li>
                    <li><span>Time</span> <span className={`${classes.sheet_tag_val}`}><TimeElapsed Time={TargetData.KODI_TimeStamp}/></span></li>
                </div>
                <div className={`${classes.card_action_button_wrapper}`}>
                    
                        {/* <UIButton Variant='Secondary' Icon={<QuestionMark  weight="bold" />} label={"Request Stock"} onClick={()=>{HandleStockRequest()}} /> */}
                        <UIButton Icon={<PaperPlaneRight weight="bold" />} label={"Process Request"} onClick={()=>{PostRunIngCheck()}} />

                </div>
            </div>

        </div>
    )
}
import classes from './HRMasterReport.module.css'


export default function HRMasterReport() 
{


    return (
        <div>
            <h1>HR-Master Report</h1>
        </div>
    )
}
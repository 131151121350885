import classes from './TrackSupplyOrder.module.css'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../../../Components/UIButton/UIButton'
import { Camera, CheckCircle, Empty, Key, MagnifyingGlass, MapPin, SmileyWink, SpinnerGap, Tray } from '@phosphor-icons/react'

import { useState } from 'react'
import { useToast } from '../../../../../ToastContext'
import { useAuth } from '../../../../../AuthProvider'
import { useEffect } from 'react'

import { QueryOrdersGroupByBranchID } from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import SingleEntryGroup from './SingleEntryGroup/SingleEntryGroup'

export default function TrackSupplyOrder({FilterSpecial = false}) 
{
    const [DataCopy, SetDataCopy] = useState([]);
    const [UpdateView, SetUpdateView] = useState(false);

    const notify = useToast();
    const auth = useAuth();
    const [AtDayOf, SetAtDayOf] = useState(new Date());



    const FilterOrder = (data_array)=> 
    {

        const item_array = []
        for(let i = 0; i < data_array.length; i++) 
        {
            if(data_array[i].KOPREOPKWS_IsSpecial === "Yes") 
            {
                item_array.push(data_array[i]);
            }
        }

        SetDataCopy(item_array);
    }
    const FetchOrderGroupByBranch = async()=>
    {
        try 
        {
            const fd = new FormData();

            fd.append("branch_id", auth.activeWorkShop);
            fd.append('day_of', AtDayOf);

            const main_res = await QueryOrdersGroupByBranchID(fd, auth.token);
            if(FilterSpecial) 
            {
                FilterOrder(main_res);
            } else 
            {
                SetDataCopy(main_res);
            }

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const RefreshView = ()=>
    {
        SetUpdateView(!UpdateView);
    }
    useEffect(()=>{
        FetchOrderGroupByBranch();
    }, [auth.activeWorkShop, UpdateView]);



    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>

            <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{SetAtDayOf(e.target.value)}} autoComplete='off' type="date" /> 
                        <span>{new Date(AtDayOf).toDateString()}</span>
                    </div>
            </div>
            
            <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>Ordered By</span>
                        <span>Ordered Date</span>
                        <span>Expected</span>
                        <span>Order State</span>
                        <span>Action</span>
                    </li>

                    <div className={`${classes.list_data_wrapper}`}>
                        {
                            DataCopy.map((item, idx)=>(
                                <SingleEntryGroup UpdateRefreshView={RefreshView} key={item.PROCI_Name} item={item}/>
                            ))
                        }
                        {
                            DataCopy.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><SmileyWink fontSize={30}/></ul>
                                <ul>No TRACK RECORD</ul>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
import { faHandPaper, faLayerGroup, faPrint, faScroll, faSpinner } from '@fortawesome/free-solid-svg-icons'
import classes from './Dz.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'
import { useState } from 'react'
import { useRef } from 'react'
import axios from 'axios';
import DecisionAssurance from '../../../../../SharedComp/DecisionAssurance/DecisionAssurance' 
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'


export default function DZ({MsgTrigger, EmpData, HandleMasterRefresh}) 
{
    const formRef = useRef();
    const [registring, SetRegistring] = useState();
    const [IsTerminating, SetIsTerminating] = useState(false);
    const [IsResign, SetIsResign] = useState(false);


    const [daState, SetDaState] = useState(false);
    const [agreement, SetAgreement] = useState();
    const [reqCallbackData, SetReqCallbackData] = useState();
    const [ActionName, setActionName] = useState(null);

    function HandleTermination(event) 
    {
        if(!event) 
        {
            return;
        }
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('full_name', EmpData.FullName);
            fd.append('phone', EmpData.PhoneNumber);
            fd.append('start_date', EmpData.EmploymentStartDate);
            fd.append('department', EmpData.DepName);
            fd.append('job_position', EmpData.PositionName);
            fd.append('salary', EmpData.Salary);
            fd.append('signoff_reason', "Termination");
            fd.append('reason', event.target.reason.value);
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post(getBaseURL() + 'employee/terminate_employee', fd, { 
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    HandleMasterRefresh(true);
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                })

        }, 2000)

    }
    function HandleResignation(event) 
    {
        if(!event) 
        {
            return;
        }
        SetRegistring(true);
        event.preventDefault();

        setTimeout(() =>{

            const fd = new FormData();

            fd.append('full_name', EmpData.FullName);
            fd.append('phone', EmpData.PhoneNumber);
            fd.append('start_date', EmpData.EmploymentStartDate);
            fd.append('department', EmpData.DepName);
            fd.append('job_position', EmpData.PositionName);
            fd.append('salary', EmpData.Salary);
            fd.append('signoff_reason', "Resignation");
            fd.append('reason', event.target.reason.value);
            fd.append('EmpID', EmpData.EmployeeID);

            axios.post(getBaseURL() + 'employee/resign_employee', fd, { 
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                }).then(res => {
                    formRef.current.reset();
                    SetRegistring(false);
                    HandleMasterRefresh(true);
                })
                .catch(err => {
                    formRef.current.reset();
                    SetRegistring(false);
                    MsgTrigger(err);
                })

        }, 2000)
    }
    function HandleResignOpen() 
    {
        SetIsResign(true);
        SetIsTerminating(false);
    }

    function HandleTerminateOpen() 
    {
        SetIsTerminating(true);
        SetIsResign(false);
    }
    function InitiateTerminationRequest(event) 
    {
        event.preventDefault();
        SetAgreement(`You're about to TERMINATE employee ## ${EmpData.FullName} ## You cannot undo this action. Are you sure you want to do this?`);
        SetReqCallbackData(event);
        SetDaState(true);
        setActionName("Employee Termination")
    }
    function InitiateResignationRequest(event) 
    {
        event.preventDefault();
        SetAgreement(`Employee requested Resignation and you are processing for ## ${EmpData.FullName} ## You cannot undo this action. Are you sure you want to do this?`);
        SetReqCallbackData(event);
        SetDaState(true);
        setActionName("Employee Resignation")
    }
    return (
        <div className={`${classes.dz_main_wrapper}`}>
            {daState && 
                <DecisionAssurance  
                    ActionName={ActionName}
                    CallbackData={reqCallbackData} 
                    Msg={agreement} 
                    openController={SetDaState} 
                    HandleResponse={IsTerminating ? HandleTermination : HandleResignation}
                />
            }
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            <div className={`${classes.dz_inner_wrapper}`}>
                
                <div className={`${classes.dz_action_button_wrapper}`}>


                    {/* Termination */}
                    <div className={`${classes.termination_state}`}>
                        <div onClick={()=>{HandleTerminateOpen()}} className={`${classes.terminate_open}  ${IsTerminating ? classes.hide_me : ''}`}>
                            <button>Terminate</button>
                        </div>
                            <div className={`${classes.terminate_body} ${!IsTerminating ? classes.blure_filter : ''}`}>
                                <div className={`${classes.termination_header}`}>
                                    <h1>Termination</h1>
                                </div >
                                <div className={`${classes.termination_form_wrapper}`}>
                                    <form ref={formRef} onSubmit={InitiateTerminationRequest} action="POST">
                                            <div className={`${classes.dz_input_main}`}>
                                                <span className={`${classes.dz_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                                <textarea name="reason" cols='55'  rows="5"  placeholder='Why is this employee terminated?' required></textarea>
                                            </div>
                                            <div className={`${classes.dz_last_emp_action_button}`}>
                                                    <button><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Prepare termination paper</button>
                                            </div>


                                            <div className={`${classes.dz_input_submit_action_button}`}>
                                                <button className={`${registring ? classes.button_in_action : ''}`} disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Terminate"}</button>
                                            </div>
                                    </form>
                                </div>
                            </div>
                    </div>



                    {/* Resi */}

                    <div className={`${classes.resing_state} `}>
                        <div onClick={()=>{HandleResignOpen()}} className={`${classes.resign_open}  ${IsResign ? classes.hide_me : ''}`}>
                            <button>Resign</button>
                        </div>
                        <div className={`${classes.resign_body} ${!IsResign ? classes.blure_filter : ''}`}>
                            <div className={`${classes.resing_header}`}>
                                <h1>Resignation</h1>
                            </div>
                                <div className={`${classes.resing_form_wrapper}`}>
                                    <form ref={formRef} onSubmit={InitiateResignationRequest} action="POST">
                                            <div className={`${classes.resing_input_main}`}>
                                                <span className={`${classes.resing_input_main_icon}`}><FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon></span>
                                                <textarea name="reason" cols='55'  rows="5"  placeholder='Any reason why this employee is resigning!' required></textarea>
                                            </div>
                                            <div className={`${classes.dz_last_emp_action_button}`}>
                                                    <button><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Prepare resignation paper</button>
                                            </div>


                                            <div className={`${classes.resing_input_submit_action_button}`}>
                                                <button className={`${registring ? classes.button_in_action : ''}`}  disabled={registring} >{registring ? <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon> : "Resign"}</button>
                                            </div>
                                    </form>
                                </div>
                        </div>
                    </div>


                </div>
                <div className={`${classes.action_buton_wrapper}`}>
                    <button> <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> Prepare experience paper</button>
                </div>
            </div>
            
        </div>
    )
}